import React, { Component, Fragment } from 'react';

import '../../resources/css/pages.scss';
import '../../resources/css/generals.scss';
import '../../resources/css/responsive.scss';
import '../../resources/css/components.scss';

import LogoInMano from '../../resources/img/logo-inmano.svg';

import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

export default class Login extends Component {

    constructor() {
        super();

        this.state = {
            email: '',
            senha: '',
            emailHelper: '',
            senhaHelper: '',
            redirect: false,
            loading: false,
            alert: false,
            alertText: ''
        };
    }

    validaCampos() {
        const { email, senha } = this.state;
        if (Funcoes.validaEmail(email) !== true) {
            this.setState({ alertText: 'O email não é valido!', alert: true });
        } else {
            if (senha.length < 5) {
                this.setState({ alertText: 'A quantidade mínima de caracteres para a senha é de 5.', alert: true })
            }
        }

        if (Funcoes.validaEmail(email) === true && senha.length >= 5) {
            this.realizaLogin();
        }
    }

    async realizaLogin() {
        const { email, senha } = this.state;
        this.setState({ loading: true });

        const dataPost = {
            usu_email: email,
            usu_senha: senha
        }
        await ApiService.postGeral('login', dataPost)
            .then(result => {
                const res = result.data.data.usuario;
                const salvaSession = {
                    usuario: {
                        id: res.usu_id,
                        nome: res.usu_nome,
                        email: res.usu_email
                    },
                    //tokenEWally: result.data.data.token_ewally,
                    token: result.data.data.token,
                    menus: result.data.data.menus
                }
                localStorage.setItem('userData', JSON.stringify(salvaSession));
                this.setState({ loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handleSubmit(event) {
        if (event.key === "Enter") {
            this.validaCampos();
        }
    }

    render() {
        const { email, senha, redirect, loading, alert, alertText } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path="/home" />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false })} />
                <div className="login">
                    <div className="contentLogin">
                        <div className="panel">
                            <img src={LogoInMano} alt="Logo inMano" />

                            <div className="acessaForm">
                                <Input type="email" placeholder="E-mail" onChange={e => this.setState({ email: e.target.value })} value={email} />
                                <Input type="password" placeholder="Senha" maxLength="12" value={senha}
                                    onChange={e => this.setState({ senha: e.target.value })}
                                    onKeyPress={e => this.handleSubmit(e)} />
                                <button className="btn entrar" onClick={() => this.validaCampos()}>Entrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}