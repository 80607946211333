export const ADD_EMPRESTIMO          = "ADD_EMPRESTIMO";
export const REMOVE_EMPRESTIMO       = "REMOVE_EMPRESTIMO";
export const DELETE_ALL_EMPRESTIMO   = "DELETE_ALL_TUTORIALS";
export const RETRIEVE_ALL_EMPRESTIMO = "RETRIEVE_ALL_EMPRESTIMO";

export const ADD_CONCILIACAO       = "ADD_CONCILIACAO"
export const REMOVE_CONCILIACAO    = "REMOVE_CONCILIACAO"
export const DELETE_ALL_CONCILIACAO = "DELETE_ALL_CONCILIACAO"
export const RETRIEVE_ALL_CONCILIACAO = "RETRIEVE_ALL_CONCILIACAO";

export const ADD_ANTECIPACAO       = "ADD_ANTECIPACAO"
export const REMOVE_ANTECIPACAO    = "REMOVE_ANTECIPACAO"
export const DELETE_ALL_ANTECIPACAO = "DELETE_ALL_ANTECIPACAO"
export const RETRIEVE_ALL_ANTECIPACAO = "RETRIEVE_ALL_ANTECIPACAO";

export const ADD_COMPANY = "ADD_COMPANY"
export const REMOVE_COMPANY = "REMOVE_COMPANY"
export const DELETE_ALL_COMPANYS = "DELETE_ALL_COMPANYS"
export const RETRIEVE_ALL_COMPANYS = "RETRIEVE_ALL_COMPANYS"