import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import { Link } from 'react-router-dom';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import {  moneyMask } from '../../Utils/mask';

export default class HistoricPaymentTradeRepresenataive extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            table: {
                campos: [
                    { titulo: 'Data de Pagamento', dado: 'dtPagamento' },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Valor', dado: 'valorPago' },
                    { titulo: 'Comissão(em %)', dado: 'comissao' },
                ],
                dados: []
            },
            gruposEconomicosOptions: [{ value: '', text: 'Selecione' }],
            empresasOptions: [{ value: '', text: 'Selecione' }],
            grupoEconomico: '',
            empresa: '',
            empresas: [],
            empresasFiltradas: [],
            valorPago: undefined,
            comissao: undefined,
            dataPagamento: '',
            exibeDados: false,
            mostraVazio: false,
            representanteAtual: undefined,
        };
    }

    componentDidMount() {
        this.carregaRepresentante();
        this.carregaHistoricoDePagamentos();
    }

    async carregaRepresentante(){
        const { representante } = this.props.location.state
        this.setState({representanteAtual: representante.id, loading: true})
        await ApiService.getGeral('representante/' + representante.id)
            .then(result => {
                const res = result.data.data;
                let empresasOptions = [{ value: '', text: 'Selecione' }];
                for (let i = 0; i < res.empresas.length; i++) {
                    const item = res.empresas[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({empresasOptions: empresasOptions, empresas: res.empresas, empresasFiltradas: res.empresas})
                this.carregaGrupos();
            })
            .catch(err => {
                console.log(err)
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })

    }   

    async carregaHistoricoDePagamentos(){
        const { representante } = this.props.location.state;
        const { table } = this.state;
        this.setState({loading: true})
        await ApiService.getGeral('historico_pagamento_representante/' + representante.id)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {                    
                    const item = res[i];
                    objects[i] = {
                        id: item.repc_id, 
                        valorPago: "R$ "+ Funcoes.float2Preco(item.hprc_valorpagto), 
                        dtPagamento: Funcoes.data2Brazilian(item.hprc_dtpagamento),
                        empresa: item.emp_nomefantasia,
                        comissao:  Funcoes.float2Preco(item.hprc_comissao)
                    };
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true
                });
                this.setState({loading: false})

            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }
    
    async carregaGrupos(){
        const {empresas} = this.state;
        const empIds = empresas.map(obj => obj.emp_id);

        let url = "grupoeconomico_empresa?empresas="+empIds.join()
        await ApiService.getGeral(url)
        .then(result => {
            const res = result.data;
            let gruposEconomicosOptions = [{ value: '', text: 'Selecione' }];
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    gruposEconomicosOptions[i + 1] = { value: item.gpec_id, text: item.gpec_nome };
                }
                this.setState({gruposEconomicosOptions: gruposEconomicosOptions})
            this.setState({loading: false})            
        })
    }

    async filtraEmpresas(grupo)
    {
        const {empresas} = this.state;
   
        let empresasFiltradas;
        if(grupo === '')
            empresasFiltradas = empresas
        else
            empresasFiltradas = empresas.filter(function(element) {
                return element.gpec_id === Number(grupo)
            });

        let empresasOptions = [{ value: '', text: 'Selecione' }];
        for (let i = 0; i < empresasFiltradas.length; i++) {
            const item = empresasFiltradas[i];
            empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
        }
        this.setState({ grupoEconomico: grupo, empresasOptions: empresasOptions, empresasFiltradas: empresasFiltradas })
    }

    async insereHistoricoDePagamento(){
        const {comissao, dataPagamento, valorPago, empresa, empresasFiltradas, grupoEconomico} = this.state;
        const { representante } = this.props.location.state;
        if(grupoEconomico === ''){
            this.setState({ loading: false, alertType: 2, alertText: "Você deve escolher ao menos um grupo econômico", alert: true });
            return 0;
        }
        let empId;
        let empIds = empresasFiltradas.map(obj => obj.emp_id);
        if(empresa === ''){
            console.log("Todas do grupo")
            empId = empIds.join(",");
        }  
        else{
            console.log("Individual")
            empId = empresa;
        }
        this.setState({loading: true});
        let form = {
            hprc_valorpagto: Funcoes.preco2float(valorPago,"R$"),
            hprc_comissao: Funcoes.preco2float(comissao),
            hprc_dtpagamento: dataPagamento,
            emp_id: empId,
            repc_id: representante.id,
        }
        await ApiService.postGeral('historico_pagamento_representante', form)
            .then(res => {
                this.setState({ alertText: 'Histórico de pagamento cadastrado com sucesso!', alertType: 1,
                alert: true, loading: false});
                this.carregaHistoricoDePagamentos();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertType: 2, alertText: valida.response, alert: true });
            })
    }
    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, alertType, table, exibeDados, mostraVazio,
         empresasOptions, gruposEconomicosOptions, dataPagamento, valorPago, comissao, grupoEconomico, empresa,} = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />

                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={13} />

                <div className="body">
                    <Header />

                    <div className="usuario-empresa mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Histórico de pagamentos</h1>
                                <p className="descricao">Abaixo a listagem de todos os históricos de pagamento do representante comercial</p>
                            </div>
                            <div className="buttons"></div>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="date" isFilterGroups={true} value={dataPagamento} label="Data de pagamento" 
                                        onChange={e => this.setState({dataPagamento: e.target.value})} />
                                    <Input type="text" isFilterGroups={true} placeholder="R$ 0,00" value={valorPago} label="Valor" 
                                        onChange={e => this.setState({valorPago: moneyMask(e.target.value)})}/>
                                     <Input type="text" isFilterGroups={true} placeholder="0,00" value={comissao} label="Comissão(em %)" 
                                        onChange={e => this.setState({comissao: moneyMask(e.target.value, false)})}/>
                                </div>
                                <div className="row">
                                    <Input type="select" isFilterGroups={true}  label="Grupo econômico" optionsData={gruposEconomicosOptions} value={grupoEconomico}
                                        onChange={e => this.filtraEmpresas(e.target.value)} />
                                    <Input type="select" isFilterGroups={true}  label="Empresa" optionsData={empresasOptions} value={empresa}
                                        onChange={e => this.setState({ empresa: e.target.value })} />
                                   
                                </div>

                                <div className="row">

                                    <button className="btn filtrar" onClick={() => this.insereHistoricoDePagamento()}>Incluir Pagamento</button>

                                </div>

                            </div>
                        </div>

                        <div className="detalhes">

                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table 
                                        campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhum histórico de pagamento encontrado para este representante!</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="acoes">
                            <Link to="/representantes" className="btn">{'<'} Voltar</Link>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}