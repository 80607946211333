import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/img/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';


export default class CollaboratorAttendance extends Component {

    constructor(props) {
        super(props);
        
        this.handlePage = this.handlePage.bind(this);
        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoExcluir = this.acaoExcluir.bind(this);

        this.state = {
            redirect: false,
            colaboradorAtual: '',
            path: '',
            propsToPath: null,
            loading: false,
            modal: false,
            alert: false,
            alertText: '',
            id: null,
            nome: '',
            table: {
                campos: [
                    { titulo: 'Anotação',  dado: 'mensagem',thStyle:{width:0}, tdStyle: { width: 400, height: 70, overflowY: "auto", display:"flex" } },
                    { titulo: 'Data/Hora', dado: 'datahora' },
                    { titulo: 'Usuário',   dado: 'usuario' },
                    { titulo: 'Ações', dado: 'acoes'}
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            acoes: [],
            anotacoesDefault: {},
            showAnotacaoNova: false
        };
    }

    componentDidMount() {        
        let acoes = this.carregaAcoes();
        this.carregaAcompanhamentos(1, acoes);
    }
    carregaAcoes(){
        const userData = JSON.parse(localStorage.getItem('userData'));
        const acoes = Funcoes.getMultAcoesPage(userData.menus, ["/colaboradores"]);  
        this.setState({ acoes });
        return acoes;      
    }
    carregaColaborador(){
        if(this.props.location.state !== undefined)
        {
            const { colaborador } = this.props.location.state
            this.setState({colaboradorAtual: colaborador.id})

            return "&func_id=" + colaborador.id;
        }
        this.setState({ colaboradorAtual: ''});
        return '';
    }

    async carregaAcompanhamentos(page, acao) {
        const { table } = this.state;
        this.setState({ loading: true });

        var url, pagina, pagAtual;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;
        var actions = ''
        if(acao.indexOf(35) !== -1){
            actions = 'default|excluir'
        }
        //actions = acao ? acao : acoes; 
        if (pagAtual) {
            url = "funcionarioacompanhamento?per_page=20&page=" + pagAtual;
        } else {
            url = "funcionarioacompanhamento?per_page=20";
        }
        var colaborador =  this.carregaColaborador();
        url += colaborador;
        

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];  
                    let dataHoraUsuario = Funcoes.dataHora2UsuarioFuso(item.funca_datahora)
                    var datahora = Funcoes.dataHora2Brazilian(dataHoraUsuario); 
                    var mensagem = item.funca_mensagem;
                    var usuario = item.usuario.usu_nome;
                    objects[i] = {
                        id: item.funca_id,usuario: usuario, func_id: item.func_id, mensagem: mensagem, datahora: datahora, acoes: actions
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
               
                if(acao.indexOf(35) !== -1 && colaborador!== ''){
                    this.setState({ showAnotacaoNova: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, anotacoesDefault: result.data, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        const {colaboradorAtual} = this.state;
        let func_id = colaboradorAtual.id;
        this.setState({ path: '/colaboradores/anotacoes/editar', propsToPath: { observacao: { id }, colaborador: {id: func_id}}, redirect: true });
    }

     acaoExcluir(id) {
        this.setState({ id, modal: true });
    }
    
    acaoAdicionar()
    {
        const { colaboradorAtual } = this.state;
        let func_id = colaboradorAtual;
        this.setState({path: '/colaboradores/anotacoes/novo',propsToPath: {colaborador: {id: func_id}},  redirect: true })
    }
    
    async excluirAcompanhamento() {
        const { id, acoes } = this.state;
        this.setState({ loading: true });
        await ApiService.customRequest('geral/funcionarioacompanhamento/' + id, 'DELETE', {})
            .then(res => {
                this.setState({ modal: false });
                this.carregaAcompanhamentos(1, acoes);
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/colaboradores/anotacoes?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/colaboradores/anotacoes', redirect: true });
        }
        this.carregaAcompanhamentos(page);
    }
    voltar() {
        this.setState({ path: '/colaboradores/editar', propsToPath: this.props.location.state, redirect: true });
    }
    
    render() {
        const { loading, redirect, path, propsToPath, modal, alert, alertText, table, exibeDados, anotacoesDefault, showAnotacaoNova, mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Modal show={modal}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de Aviso" />
                        <p>Você realmente deseja excluir a anotação?<b>{this.state.nome}</b>?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.excluirAcompanhamento()}>Confirmar</button>
                            <button className="btn" onClick={() => this.setState({ modal: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Anotações do colaborador</h1>
                                <p className="descricao">Abaixo a listagem de anotações do atendimento do colaborador.</p>
                            </div>
                            
                        </div>

                        <div className="detalhes">
                            <div className="headerBtn">
                                {showAnotacaoNova ? (<>
                                    <button className="btn btnAzul" onClick={() => this.acaoAdicionar() } style={{ width: 200 }}>Nova Anotação</button>
                                </>) : null}
                            </div>
                            <div className="funcionario-acompanhamento panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar}
                                        acaoExcluir={this.acaoExcluir}  />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhuma anotação encontrada!</p>
                                ) : null}
                                {exibeDados ? (<>
                                <div className="info">
                                    <p>Total de registros: {anotacoesDefault.total}</p>
                                    <Paginacao dados={anotacoesDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                            </div>
                        </div>

                        <div className="acoes">
                            <button className="btn btnVoltar" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.voltar()}>{'<'} Voltar</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}