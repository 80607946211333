import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

export default class PendingRequests extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '/',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            empresasOptions: [{ value: '', text: 'Selecione' }],
            empresa: '',
            table: {
                campos: [
                    { titulo: 'Data', dado: 'data', thStyle: { textAlign: 'left' }, tdStyle: { } },
                    { titulo: 'Colaborador', dado: 'colaborador' },
                    { titulo: 'CPF', dado: 'cpf' },
                    { titulo: 'Parcela máxima', dado: 'parcelaMax', thStyle: { textAlign: 'left' }, tdStyle: {  } },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Tempo de Casa', dado: 'tempoCasa', thStyle: { textAlign: 'left' }, tdStyle: { } },
                    { titulo: 'Valor Solicitado', dado: 'valSolicitado', thStyle: { textAlign: 'right' }, tdStyle: {  } },
                    { titulo: 'Parcelas', dado: 'parcelas', thStyle: { textAlign: 'left' }, tdStyle: {  } },
                    { titulo: 'Valor da Parcela', dado: 'valParcela', thStyle: { textAlign: 'left' }, tdStyle: { } },
                    { titulo: 'Taxa Juros', dado: 'txJuros', thStyle: { textAlign: 'left' }, tdStyle: { } }
                ],
                dados: []
            },
            dadosDefault: [],
            exibeDados: false,
            mostraVazio: false
        };
    }

    componentDidMount() {
        this.carregaEmpresas();
        this.carregaSolicitacoes();
    }

    async carregaEmpresas() {
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions });
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaSolicitacoes() {
        const { table, empresa } = this.state;
        this.setState({ loading: true });

        var url = "contratos?ppst_status=0";
        if (empresa) url = url + "&emp_id=" + empresa;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [], valTotal = 0, totalSolicitacoes = 0;

                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    let tempoDeCasa = Funcoes.diferencaDataEmMeses(new Date(item.funcionario.func_dtadmissao), new Date());
                    objects.push({
                        id: item.ppst_id, data: Funcoes.data2Brazilian(item.ppst_dthrsolicitacao.split(' ')[0], '-'),
                        colaborador: item.funcionario.func_nome, colabId: item.funcionario.func_id, cpf: item.funcionario.func_cpf,
                        parcelaMax: item.funcionario.func_parcelamaximo, empresa: item.funcionario.empresa.emp_nomefantasia,
                        tempoCasa: tempoDeCasa + " meses", valSolicitado: 'R$ ' + Funcoes.float2Preco(item.ppst_valorsolicitado), 
                        parcelas: Funcoes.completarNumero(item.ppst_prazo), valParcela: 'R$ ' + Funcoes.float2Preco(item.ppst_valorparcela),
                        txJuros: Funcoes.float2Preco(item.ppst_taxajuros) + ' %'
                    });
                    totalSolicitacoes += 1;
                    valTotal += item.ppst_valorsolicitado;
                }
                if (res.length > 0) {
                    objects.push({ data: 'Total Solicitações:', colaborador: totalSolicitacoes, tempoCasa: 'Valor Total:', valSolicitado: 'R$ ' + Funcoes.float2Preco(valTotal) });
                    this.setState({ exibeDados: true, mostraVazio: false });
                } else {
                    this.setState({ exibeDados: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, dadosDefault: objects, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, exibeDados, mostraVazio, empresasOptions, empresa } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={10} />

                <div className="body">
                    <Header />

                    <div className="emprestimo-pessoal mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Solicitações Pendentes</h1>
                                <p className="descricao">Consulte abaixo todas as solicitações pendentes.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row" >
                                    <Input type="select" isFilterGroups={true} optionsData={empresasOptions} label="Empresa" 
                                        value={empresa} onChange={e => this.setState({ empresa: e.target.value })} />
                                    <button className="btn filtrar" onClick={() => this.carregaSolicitacoes()}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="solicitacoespendentes panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoVisualizar={this.acaoVisualizar} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhuma solicitação pendente encontrada!</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}