import React, { Component, Fragment } from 'react';
//import {Row,Col} from 'react-bootstrap';
import iconAviso from '../../resources/img/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import { dataBrMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';

export default class Faq extends Component {

    constructor() {
        super();
        this.acaoEditar = this.acaoEditar.bind(this);

        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            modal: false,
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            faqAtual: null,
            resposta: '',
            mostraModal: '',
            nomeUsuario: '',
            tableFaq: {
                campos: [
                    { titulo: 'Nome de usuário', dado: 'nome' },
                    { titulo: 'Email', dado: 'email' },
                    { titulo: 'Pergunta', dado: 'pergunta' },
                    { titulo: 'Data/Hora', dado: 'datahora', tdStyle: { width: 120 }  },
                    { titulo: 'Status', dado: 'status', tdTagStyle: true,thStyle: {width: 150}, tdStyle: { width: 150 } },

                    { titulo: 'Ações', dado: 'acoes'}
                ],
                dados: []
            },
            statusResposta: '0',
            statusRespostaOptions: [
                { value: '', text: 'Selecione' },
                { value: '0', text: 'Não respondido' },
                { value: '1', text: 'Respondido' },
            ],
            exibeDados: false,
            mostraVazio: true,
            faqDefault: {},
        };
    }

    componentDidMount() {

        this.carregaFaqs(1);
    }

    async carregaFaqs(page){
        const { tableFaq, statusResposta } = this.state;
        this.setState({ loading: true });

        var url, pagina, pagAtual;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "faq/portalrh?per_page=20&page=" + pagAtual;
        } else {
            url = "faq/portalrh?per_page=20";
        }
        if(statusResposta) url = url + "&fsrh_respondida=" + statusResposta;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    let status = "Vencimento inactive";
                    if (item.fsrh_respondida === 1) {
                        status = 'Respondido'
                    } else if(item.fsrh_respondida === 0) {
                        status = 'Não respondido error'
                    }else{
                        status = 'indefinido inactive'
                    }
                    objects[i] = {
                        id: item.fsrh_id, 
                        pergunta: item.fsrh_pergunta,
                        usuario: item.eusu_id,
                        email: item.usuario.eusu_email,
                        nome: item.usuario.eusu_nome,
                        datahora: Funcoes.dataHora2Brazilian(item.created_at),
                        status: status,
                        acoes: 'default'
                    };
                }
                if (res.length > 0) {
                    this.setState({ tableFaq: { campos: [...tableFaq.campos], dados: objects }, faqDefault: result.data, exibeDados: true, loading: false, mostraVazio: false  });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async enviaResposta()
    {
        const { faqAtual, resposta } = this.state;

        let form = {
            resposta: resposta,
            fsrh_id: faqAtual
        }

        await ApiService.postGeral('faq/portalrh', form )
        .then(res => {
            this.setState({ alertText: 'Faq respondida com sucesso!', alertType: 1,
            alert: true, loading: false});        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    acaoEditar(id) {
        const { tableFaq } = this.state;
        console.log(tableFaq);
        let dado = tableFaq.dados.find(x => x.id === id);

        this.setState({ mostraModal: true, faqAtual: id, nomeUsuario: dado.nome });
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/faq/portalrh?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/faq/portalrh', redirect: true });
        }
        this.carregaFaqs(page);
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, tableFaq, faqDefault, resposta, nomeUsuario, mostraModal,
            exibeDados, statusResposta,statusRespostaOptions, mostraVazio} = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />
                <Modal show={mostraModal}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <p className="description">Reposta para {nomeUsuario}.</p>
                        <Input type="textarea" placeholder="" value={resposta} className="custom"
                            onChange={e => this.setState({ resposta: e.target.value })} />
                        <div className="cancelamentoAcao">
                            <button className="btn"
                                style={resposta.length > 0 ? { backgroundColor: '#EA5353', color: '#FFF' } : { backgroundColor: '#999' }}
                                onClick={() => resposta.length > 0 ? this.enviaResposta() : {}}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ mostraModal: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <div className="body">
                    <Header />
                    <div className="colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Faq de dúvidas(Rh)</h1>
                                <p className="descricao">Abaixo a listagem de todas as faqs do portal do rh </p>
                            </div>
                        </div>
                        
                        <div className="panel">
                            <div className="panelBody">
                                <div className="row" >  
                                    <Input type="select" isFilterGroups={true} label="Status" optionsData={statusRespostaOptions} value={statusResposta} 
                                        onChange={e => this.setState({ statusResposta: e.target.value })} />  
                                     <button className="btn filtrar" onClick={() => this.carregaFaqs(1)}>Filtrar</button>
                                </div>
                                
                            </div>
                        </div>
                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={tableFaq.campos}
                                        dados={tableFaq.dados}
                                        acaoEditar={this.acaoEditar}
                                        />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhuma faq encontrada!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <p>Total de registros: {faqDefault.total}</p>
                                    <Paginacao dados={faqDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}