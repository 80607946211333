import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './Utils/PrivateRoute';
import './index.css';
import { Provider } from 'react-redux';
import store from './store';

import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import Company from './Pages/Companies/Company';
import NewCompany from './Pages/Companies/NewCompany';
import CompanyRules from './Pages/Companies/Rules';
import NewCompanyRule from './Pages/Companies/NewRule';
import NewScalingRule from './Pages/Politicy/NewScalingRule'
import UserCompany from './Pages/Users/UserCompany';
import NewUserCompany from './Pages/Users/NewUserCompany';
import Collaborator from './Pages/Collaborators/Collaborator';
import EditCollaborator from './Pages/Collaborators/EditCollaborator';
import ExtractCollaborator from './Pages/Collaborators/ExtractCollaborator';
import RecoverCollaborator from './Pages/Collaborators/RecoverCollaborator';
import ResetHistoricCollaborator from './Pages/Collaborators/ResetHistoricCollaborator';
import ConsultationNeurotechCollaborator from './Pages/Collaborators/ConsultationNeurotechCollaborator';
import Contract from './Pages/Contracts/Contract';
import ContractDetails from './Pages/Contracts/ContractDetails';
import ContractRepayment from './Pages/Contracts/ContractRepayment';
import Maturity from './Pages/Maturities/Maturity';
import AntecipatedParcel from './Pages/AnticipatedParcel/AntecipatedParcel';
import Refinancing from './Pages/Refinancing/Refinancing';
import Bill from './Pages/Bills/Bill';
import BillDetail from './Pages/Bills/BillDetail';
import Disconnect from './Pages/Disconnect/Disconnect';
import PersonalLoan from './Pages/PersonalLoan/PersonalLoan';
import LoanDetails from './Pages/PersonalLoan/LoanDetails';
import PendingRequests from './Pages/PendingRequests/PendingRequests';
import LoanReasons from './Pages/Loans/LoanReasons';
import NewLoanReason from './Pages/Loans/NewLoanReason';
import ProfileAccess from './Pages/Config/ProfileAccess';
import NewProfileAccess from './Pages/Config/NewProfileAccess';
import Users from './Pages/Config/Users';
import NewUser from './Pages/Config/NewUser';
import NotFound from './Pages/NotFound/NotFound';
import TradeRepresentative from './Pages/TradeRepresentative/TradeRepresentative';
import NewTradeRepresentative from './Pages/TradeRepresentative/NewTradeRepresentative';
import HistoricPaymentTradeRepresenataive from './Pages/TradeRepresentative/HistoricPaymentTradeRepresenataive';
import EconomicGroup from './Pages/EconomicGroup/EconomicGroup';
import NewEconomicGroup from './Pages/EconomicGroup/NewEconomicGroup';
import Leads from './Pages/Leads/Leads';
import VertConciliation from './Pages/VertConciliation/VertConciliation';
import MonthlyVertConciliation from './Pages/VertConciliation/MonthlyVertConciliation';
import MonthlyVertRenegotiation from './Pages/VertConciliation/MonthlyVertRenegotiation'
import Edufin from './Pages/Edufin/Edufin';
import Politicy from './Pages/Politicy/Politicy';
import NewPoliticy from './Pages/Politicy/NewPoliticy';
import NewDeadLine from './Pages/Politicy/NewDeadLine';
import NewRating from './Pages/Politicy/NewRating';
import NewPenalty from './Pages/Politicy/NewPenalty';

import NewEdufin from './Pages/Edufin/NewEdufin';

import Faq from './Pages/Faq/Faq';

import CollaboratorAttendance from './Pages/Collaborators/CollaboratorAttendance';
import EditCollaboratorAttendance from './Pages/Collaborators/EditCollaboratorAttendance';
import NewCollaboratorAttendance from './Pages/Collaborators/NewCollaboratorAttendance';
import CollaboratorSimulation from './Pages/Collaborators/CollaboratorSimulation';
import ContractApproval from './Pages/Contracts/ContractApproval';

import CollaboratorImportation from './Pages/Importations/CollaboratorImportation'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={Login} />
        <PrivateRoute path="/home" component={Dashboard} />
        <PrivateRoute path="/empresas" exact={true} component={Company} />
        <PrivateRoute path="/empresas/nova" exact={true} component={NewCompany} />
        <PrivateRoute path="/empresas/regras" exact={true} component={CompanyRules} />
        <PrivateRoute path="/empresas/regras/nova" exact={true} component={NewCompanyRule} />
        <PrivateRoute path="/politicas" exact={true} component={Politicy} />
        <PrivateRoute path="/politicas/novo" exact={true} component={NewPoliticy} />
        <PrivateRoute path="/prazo/novo" exact={true} component={NewDeadLine} />
        <PrivateRoute path="/rating/novo" exact={true} component={NewRating} />
        <PrivateRoute path="/penalidade/novo" exact={true} component={NewPenalty} />
        <PrivateRoute path="/regua-de-escalonamento/novo" exact={true} component={NewScalingRule} />

        <PrivateRoute path="/usuario-empresa" exact={true} component={UserCompany} />
        <PrivateRoute path="/usuario-empresa/novo" exact={true} component={NewUserCompany} />
        <PrivateRoute path="/colaboradores" exact={true} component={Collaborator} />
        <PrivateRoute path="/colaboradores/editar" exact={true} component={EditCollaborator} />
        <PrivateRoute path="/colaboradores/extrato" exact={true} component={ExtractCollaborator} />
        <PrivateRoute path="/colaboradores/recuperacao" exact={true} component={RecoverCollaborator} />
        {/*<PrivateRoute path="/colaboradores/simuladornaoconveniado" exact={true} component={NotConvenedSimulator} />*/}
        {/* <PrivateRoute path="/colaboradores/documentos" exact={true} component={DocumentCollaborator} /> */}
        <PrivateRoute path="/colaboradores/historicoreset" exact={true} component={ResetHistoricCollaborator} />
        <PrivateRoute path="/colaboradores/logneurotech" exact={true} component={ConsultationNeurotechCollaborator} />
        <PrivateRoute path="/contratos" exact={true} component={Contract} />
        <PrivateRoute path="/contratos/aprovacao" exact={true} component={ContractApproval} />
        <PrivateRoute path="/contratos/detalhes" exact={true} component={ContractDetails} />
        <PrivateRoute path="/contratos/repagamento" exact={true} component={ContractRepayment} />
        <PrivateRoute path="/vencimentos" component={Maturity} />
        <PrivateRoute path="/parcelas-antecipadas" exact={true} component={AntecipatedParcel} />
        <PrivateRoute path="/analise_de_contratos/refinanciamento" exact={true} component={Refinancing} />
        <PrivateRoute path="/faturas" exact={true} component={Bill} />
        <PrivateRoute path="/faturas/detalhes" exact={true} component={BillDetail} />
        <PrivateRoute path="/faq/portalrh" exact={true} component={Faq} />
        <PrivateRoute path="/desligamento" component={Disconnect} />
        <PrivateRoute path="/emprestimo-pessoal" exact={true} component={PersonalLoan} />
        <PrivateRoute path="/emprestimo-pessoal/detalhes" exact={true} component={LoanDetails} />
        <PrivateRoute path="/solicitacoes-pendentes" component={PendingRequests} />
        <PrivateRoute path="/motivo-emprestimo" exact={true} component={LoanReasons} />
        <PrivateRoute path="/motivo-emprestimo/novo" exact={true} component={NewLoanReason} />
        <PrivateRoute path="/perfis-acesso" exact={true} component={ProfileAccess} />
        <PrivateRoute path="/perfis-acesso/novo" exact={true} component={NewProfileAccess} />
        <PrivateRoute path="/usuarios" exact={true} component={Users} />
        <PrivateRoute path="/usuarios/novo" exact={true} component={NewUser} />
        <PrivateRoute path="/representantes" exact={true} component={TradeRepresentative} />
        <PrivateRoute path="/representantes/novo" exact={true} component={NewTradeRepresentative} />
        <PrivateRoute path="/representantes/historico-pagamento" exact={true} component={HistoricPaymentTradeRepresenataive} />
        <PrivateRoute path="/grupos-economicos" exact={true} component={EconomicGroup} />
        <PrivateRoute path="/grupos-economicos/novo" exact={true} component={NewEconomicGroup} />
        <PrivateRoute path="/leads" exact={true} component={Leads} />
        <PrivateRoute path="/conciliacao-vert" exact={true} component={VertConciliation} />
        <PrivateRoute path="/conciliacao-vert-mensal" exact={true} component={MonthlyVertConciliation} />
        <PrivateRoute path="/renegociacao-vert-mensal" exact={true} component={MonthlyVertRenegotiation} />
        <PrivateRoute path="/edufin" exact={true} component={Edufin} />
        <PrivateRoute path="/edufin/novo" exact={true} component={NewEdufin} />
        <PrivateRoute path="/colaboradores/anotacoes" exact={true} component={CollaboratorAttendance} />
        <PrivateRoute path="/colaboradores/anotacoes/editar" exact={true} component={EditCollaboratorAttendance} />
        <PrivateRoute path="/colaboradores/anotacoes/novo" exact={true} component={NewCollaboratorAttendance} />
        <PrivateRoute path="/colaboradores/simulacao" exact={true} component={CollaboratorSimulation} />
        <PrivateRoute path="/importacoes/colaboradores" exact={true} component={CollaboratorImportation} />

        <PrivateRoute path="/anotacoes" exact={true} component={CollaboratorAttendance} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'));