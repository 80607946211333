import React, { Component, Fragment } from 'react';
import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Panel from '../../Components/Panel';
import Row from '../../Components/Row';

import Paginacao from '../../Components/Paginacao';

import Summary from '../../Components/Summary/Summary';

import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { cpfMask } from '../../Utils/mask';

export default class Refinancing extends Component {

    constructor() {
        super();
        this.handlePage = this.handlePage.bind(this);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            btnGerar: false,
            dtPagto: '',
            gruposEconomicosOptions: [{ value: '', text: 'Selecione' }],
            empresasOptions: [{ value: '', text: 'Selecione' }],
            grupoEconomico: '',
            empresa: '',
            status: '',
            table: {
                campos: [
                    { titulo: 'Data de Desembolso', dado: 'dtDesembolso' },
                    { titulo: 'Nome', dado: 'nome' },    
                    { titulo: 'CPF', dado: 'cpf' },
                    { titulo: 'CCB Refinanciada', dado: 'contratoRefinanciado' },
                    { titulo: 'CCB Quitada', dado: 'contratoQuitado' },
                    { titulo: 'Saldo Devedor', dado: 'saldoDevedor' },
                    { titulo: 'Valor Solicitado', dado: 'valorSolicitado' },
                    { titulo: 'Troco', dado: 'troco'},
                    { titulo: 'Data de Solicitação', dado: 'dtSolicitacao'},
                    { titulo: 'Data Calculo Vpl', dado: 'dtCalculoVpl'},
                ],
                dados: []
            },
            parcelaAntecipadaDefault: {},
            exibeDados: false,
            ccb: '',
            totalSelecionado: 0,
            acoes: [],
            selectedBackup: [],
            colaborador: '',
            dataFiltroInicio: '',
            dataFiltroFim: ''
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);
        this.carregaRefinanciamentos(1);
        this.carregaEmpresas();
        this.carregaGrupos();
        this.setState({ acoes })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/parcelas-antecipadas?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/parcelas-antecipadas', redirect: true });
        }
        this.carregaParcelaAntecipada(page);
    }

    async carregaEmpresas() {
        let url = 'empresa?per_page=-1';
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaEmpresasPorGrupo(grupo){
        this.setState({ loading: true});
        if(grupo) this.setState({ grupoEconomico: grupo });
        let url = 'empresa?per_page=-1';
        if(grupo) url+= "&gpec_id=" + grupo;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions, loading: false});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }
    async carregaGrupos() {
        this.setState({ loading: true });
        await ApiService.getGeral('grupoeconomico?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var gruposEconomicosOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    gruposEconomicosOptions[i + 1] = { value: item.gpec_id, text: item.gpec_nome };
                }
                this.setState({ gruposEconomicosOptions})
            })
            .catch(err => {
                this.setState({ gruposEconomicosOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaRefinanciamentos(page) {
        const { table,  empresa, colaborador, ccb,  grupoEconomico, dataFiltroInicio, dataFiltroFim } = this.state;

        this.setState({ loading: true });
        var url;
        url = "refinanciamentos?page=" + page;

        //Filter Params
        if(empresa) url = url + '&empresa=' + empresa;
        if(grupoEconomico) url = url + '&grupo=' + grupoEconomico;
        if(colaborador) url = url + '&colaborador=' + colaborador;
        if(ccb) url = url + '&ccb=' + ccb;
        if(dataFiltroInicio) url = url+ '&data_inicio=' + dataFiltroInicio.toString()
        if(dataFiltroFim) url = url+ '&data_fim=' + dataFiltroFim.toString()

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.pparca_id, 
                        dtDesembolso: Funcoes.data2Brazilian(item.data_desembolso), 
                        contratoRefinanciado: item.ccb_refinanciada,
                        contratoQuitado: item.ccb_quitada,
                        cpf: item.cpf, 
                        nome: item.nome, 
                        saldoDevedor: "R$" +  Funcoes.float2Preco(item.valor_vpl_total), 
                        valorSolicitado: "R$" +  Funcoes.float2Preco(item.valor_solicitado), 
                        troco: "R$" +  Funcoes.float2Preco(item.troco), 
                        dtSolicitacao: Funcoes.dataHora2Brazilian(item.data_solicitacao), 
                        dtCalculoVpl:  Funcoes.data2Brazilian(item.data_calculo_vpl)
                    };
        
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, parcelaAntecipadaDefault: result.data });
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }


    async exportarRefinanciamento() {
        const { empresa, status, colaborador, ccb,  grupoEconomico, dataFiltroInicio, dataFiltroFim } = this.state;
        let url = '/geral/refinanciamentos/exportar?page=1';
        this.setState({ loading: true });

        //Filter Params
        if(empresa) url = url + '&empresa=' + empresa;
        if(grupoEconomico) url = url + '&grupo=' + grupoEconomico;
        if(status) url = url + '&status=' + status;
        if(colaborador) url = url + '&colaborador=' + colaborador;
        if(ccb) url = url + '&ccb=' + ccb;
        if(dataFiltroInicio) url = url+ '&data_inicio=' + dataFiltroInicio
        if(dataFiltroFim) url = url+ '&data_fim=' + dataFiltroFim
        if(empresa) url = url + '&empresa=' + empresa;
        if(grupoEconomico) url = url + '&grupo=' + grupoEconomico;

        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'contrato_refinanciado.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    handleFiltro(e) {
        const val = e.value;
        if (val.startsWith(0) || val.startsWith(1) || val.startsWith(2) || val.startsWith(3) || val.startsWith(4) || val.startsWith(5) || val.startsWith(6) || val.startsWith(7) ||
            val.startsWith(8) || val.startsWith(9)) {
            e.setAttribute('maxlength', 14);
            this.setState({ colaborador: cpfMask(val) });
        } else {
            e.removeAttribute('maxLength');
            this.setState({ colaborador: val });
        }
    }

    handleFiltroCcb(e) {
        const val = e.value;
        e.setAttribute('maxlength', 7);

        this.setState({ ccb: val });
        
    }
    async recarregaEmpresaSelect(grupo){
        this.carregaEmpresasPorGrupo(grupo);
    }
    async resetarEstado(){
        this.setState({status: '', grupoEconomico: '', empresa: '',ccb: '', colaborador: '', totalSelecionado: 0})
    }

    processaStatusDePagamento(status)
    {
        if(status === 0)
        {
            return "Á vencer inactive";

        }
        
        if(status === 1)
        {
            return "Pago";

        }

        if(status === 2)
        {
            return "Vencido warning";
        }

        if(status === 3)
        {
            return "Cancelado error";
        }

        return status;
    }
   
    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, parcelaAntecipadaDefault, exibeDados, empresasOptions,
            empresa, grupoEconomico, gruposEconomicosOptions, colaborador, ccb, dataFiltroInicio, dataFiltroFim } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={6} />

                <div className="body">
                    <Header />

                    <div className="parcelas-antecipadas mgDefault">
                        <Summary title="Refinanciamento" description="Relatório de refinanciamentos.">
                            <button className="btn btnAzul" onClick={() =>  this.exportarRefinanciamento()}>Exportar (.xlsx)</button> 
                        </Summary>
                    
                        <Panel>
                            <Row>
                                <Input type="text" value={colaborador} isFilterGroups={true} label="Colaborador ou CPF" 
                                    onChange={e => this.handleFiltro(e.target)} />
                                <Input type="text"  value={ccb} isFilterGroups={true} label="Nº CCB" 
                                    onChange={e => this.handleFiltroCcb(e.target)} />
                                <Input type="select" isFilterGroups={true} optionsData={gruposEconomicosOptions} label="Grupo Econômico" value={grupoEconomico} 
                                    onChange={e => this.recarregaEmpresaSelect(e.target.value)} />      
                               
                            </Row>    
                            <Row>
                                <Input type="select" isFilterGroups={true} label="Empresa" optionsData={empresasOptions} value={empresa}
                                    onChange={e => this.setState({ empresa: e.target.value })} />
                                <Input type = "date" isFilterGroups={true} label = "Periodo de:" value={dataFiltroInicio} 
                                    onChange={e => this.setState({ dataFiltroInicio: e.target.value })} />
                                <Input type = "date" isFilterGroups={true} label = "Periodo até:" value={dataFiltroFim}
                                    onChange={e => this.setState({ dataFiltroFim: e.target.value })}  />
                            </Row>
                            <button  className="button-component" onClick={() => this.carregaRefinanciamentos(1)}>Filtrar</button>
                            <button className="button-component" onClick={() => this.resetarEstado()}>Resetar</button>       
                        </Panel>

                        <div className="detalhes">

                            <div className="panel">
                                <div className="panelBody">
                                    {exibeDados ? (
                                        <Table campos={table.campos}
                                            dados={table.dados}
                                        />
                                    ) : null}
                                </div>
                            </div>

                            {exibeDados ? (<>
                                <div className="info">
                                    <Paginacao dados={parcelaAntecipadaDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}

                            <div className="info">
                                {exibeDados ? (<>
                                    <p>Total de registros: {parcelaAntecipadaDefault.total}</p>
                                </>) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}
