import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import { cpfMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';

import { dataBrMask } from '../../Utils/mask';

import listaBancos from '../../resources/bancos.json';

export default class ExtractCollaborator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            tiposOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Geral' },
                { value: 2, text: 'TED' },
            ],
            periodoOptions: [
                { value: '', text: 'Selecione' },
                { value: 7, text: '7 Dias' },
                { value: 15, text: '15 Dias' },
                { value: 30, text: '30 Dias' },
                { value: 60, text: '60 Dias' },
                { value: 90, text: '90 Dias' }
            ],
            operacoes: [
                { type: 'RECEIVED_TRANSFERENCE', value: 'Transferência recebida' },
                { type: 'SENDED_TRANSFERENCE', value: 'Transferência enviada' }
            ],
            tipo: '',
            table: {
                campos: [],
                dados: []
            },
            periodo: '',
            colaborador: {},
            exibeDados: false,
            mostraVazio: false,
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });

        this.carregaColaborador();
    }

    async carregaColaborador() {
        const { colaborador } = this.props.location.state;
        this.setState({ loading: true });
        await ApiService.getGeral('funcionario/' + colaborador.id)
            .then(result => {
                const item = result.data.data;
                this.setState({colaborador: item, loading: false});
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    carregaExtrato() {
        const { tipo, periodo, colaborador, operacoes } = this.state;
        const userData = JSON.parse(localStorage.getItem('userData'));
        if(tipo === ''){
            this.setState({alertText: 'Selecione o tipo do extrato para continuar!', alert: true});
        }else if(tipo == 1 && periodo == ''){
            this.setState({alertText: 'Informe o período para continuar!', alert: true});
        }else{
            this.setState({ loading: true });
            if(tipo == 1){ // Geral
                this.setState({
                    table: {
                        campos: [
                            { titulo: 'Operação', dado: 'operacao' },
                            { titulo: 'Data', dado: 'data' },
                            { titulo: 'Valor', dado: 'valor' },
                            { titulo: 'Saldo', dado: 'saldo' },
                            { titulo: 'Referência', dado: 'referencia' },
                            { titulo: 'Status', dado: 'status' }
                        ],
                        dados: []
                    }
                })
                var dataInicio = new Date();
                dataInicio.setDate(dataInicio.getDate() - periodo);
                dataInicio = dataInicio.toISOString().split('T')[0];
                var dataFim = new Date();
                dataFim = dataFim.toISOString().split('T')[0];
                ApiService.getEWally('account/statements?initialDate='+dataInicio+'&finalDate='+dataFim, userData.tokenEWally, Funcoes.removeMascara('cpf', colaborador.func_cpf))
                    .then(res => {
                        const { table } = this.state;
                        const result = res.data.statement;
                        var objects = [];
                        var operacao;
                        for(var i = 0; i < result.length; i++){
                            for(var x = 0; x < operacoes.length; x++){
                                if(result[i].operationType == operacoes[x].type){
                                    operacao = operacoes[x].value;
                                }
                            }

                            let status = 'N/D';

                            if(result[i].status == 'COMPLETED'){ status = 'CONCLUÍDA'; }
                            if(result[i].status == 'FAILED'){ status = 'FALHOU'; }
                            if(result[i].status == 'WAITING_ANALYSIS'){ status = 'AGUARDANDO ANÁLISE'; }

                            let amount = result[i].amount / 100;
                            let balance = result[i].balance / 100;

                            objects.push({
                                operacao: operacao,
                                data: Funcoes.data2Brazilian(result[i].createdAt.split('T')[0]),
                                valor: 'R$ '+Funcoes.float2Preco(amount),
                                saldo: 'R$ '+Funcoes.float2Preco(balance),
                                referencia: result[i].id,
                                status: status
                            });
                        }
                        this.setState({ table: { campos: [...table.campos], dados: objects }, loading: false });
                    })
                    .catch(err => {
                        const valida = ApiService.handleErros(err.response);
                        if (valida.status === "unauthorized") {
                            sessionStorage.removeItem('userData');
                            this.setState({ path: '/' });
                        }
                        if (valida.status === "serverError") {
                            this.setState({ path: '' });
                        } else if (valida.status === "badRequest") {
                            this.setState({ path: '' });
                        } else {
                            this.setState({ path: '' });
                        }
                        this.setState({ alertText: valida.response ? valida.response : err.msg ? err.msg : 'Não foi possível atualizar o usuário!', alertType: 2, loading: false, alert: true });
                    })
            }else{ // TED
                this.setState({
                    table: {
                        campos: [
                            { titulo: 'Data', dado: 'data' },
                            { titulo: 'Valor', dado: 'valor' },
                            { titulo: 'Banco', dado: 'banco' },
                            { titulo: 'Favorecido', dado: 'favorecido' },
                            { titulo: 'Referência', dado: 'referencia' },
                            { titulo: 'Status', dado: 'status' },
                        ],
                        dados: []
                    }
                })
                ApiService.getEWally('multibank/transfer', userData.tokenEWally, Funcoes.removeMascara('cpf', colaborador.func_cpf))
                    .then(res => {
                        const { table } = this.state;
                        const result = res.data;
                        console.log(result);
                        var objects = [];
                        for(var i = 0; i < result.length; i++){
                            var banco = '';
                            for(var x = 0; x < listaBancos.length; x++){
                                if(result[i].bankCode == listaBancos[x].value){
                                    banco = listaBancos[x].label;
                                }
                            }

                            let status = 'N/D';

                            if(result[i].status == 'COMPLETED'){ status = 'CONCLUÍDA'; }
                            if(result[i].status == 'FAILED'){ status = 'FALHOU'; }
                            if(result[i].status == 'WAITING_ANALYSIS'){ status = 'AGUARDANDO ANÁLISE'; }

                            let amount = result[i].amount / 100;

                            objects.push({
                                data: Funcoes.data2Brazilian(result[i].createdAt.split('T')[0]),
                                valor: 'R$ '+Funcoes.float2Preco(amount),
                                banco: result[i].transfers[0].bankAccount.bankCode + ' - ' + banco,
                                favorecido: result[i].transfers[0].bankAccount.fullName,
                                referencia: result[i].id,
                                status:status
                            });
                        }
                        this.setState({ table: { campos: [...table.campos], dados: objects }, loading: false });
                    })
                    .catch(err => {
                        console.log(err);
                        const valida = ApiService.handleErros(err.response);
                        if (valida.status === "unauthorized") {
                            sessionStorage.removeItem('userData');
                            this.setState({ path: '/' });
                        }
                        if (valida.status === "serverError") {
                            this.setState({ path: '' });
                        } else if (valida.status === "badRequest") {
                            this.setState({ path: '' });
                        } else {
                            this.setState({ path: '' });
                        }
                        this.setState({ alertText: valida.response ? valida.response : err.msg ? err.msg : 'Não foi possível atualizar o usuário!', alertType: 2, loading: false, alert: true });
                    })
            }
        }
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, tiposOptions, tipo, table, periodo, periodoOptions,
            exibeDados, mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Extrato</h1>
                                <p className="descricao">Abaixo a listagem do extrato do colaborador.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="select" isFilterGroups={true} label="Tipo" optionsData={tiposOptions} value={tipo} 
                                        onChange={e => this.setState({ tipo: e.target.value })} />
                                    {tipo == 1 ? (<>
                                        <Input type="select" isFilterGroups={true} label="Período" optionsData={periodoOptions} value={periodo} 
                                        onChange={e => this.setState({ periodo: e.target.value })} />
                                    </>) : null}
                                    <button className="btn filtrar" onClick={() => this.carregaExtrato()}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        {table.dados.length > 0 ? (<>
                            <div className="detalhes">
                                <div className="panel">
                                    <Table campos={table.campos}
                                        dados={table.dados} />
                                </div>
                            </div>
                        </>) : null}
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}