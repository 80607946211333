import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/img/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';

export default class UserCompany extends Component {

    constructor() {
        super();

        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoExcluir = this.acaoExcluir.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            modal: false,
            alert: false,
            alertText: '',
            email: '',
            table: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: 'E-mail', dado: 'email' },
                    { titulo: 'Status', dado: 'tag' },
                    { titulo: 'Ações', dado: 'acoes' },
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            usuariosDefault: {},
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });
        this.carregaUsuarios(1,acoes);
    }

    async carregaUsuarios(page, acao) {
        const { table, email, acoes } = this.state;
        this.setState({ loading: true });

        const userData = JSON.parse(localStorage.getItem('userData'));
        var url = "empresausuario?per_page=20";
        if (page) {
            url += "&page=" + page;
        } 

        var actions = acao ? acao : acoes
        if (email) url = url + "&eusu_email=" + email;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    var tableAction = "";
                    if(actions.indexOf(4) !== -1 && actions.indexOf(5) !== -1){
                        tableAction = "default|excluir";
                    }else{
                        if(actions.indexOf(4) !== -1){
                            tableAction = "default";
                        }
                        if(actions.indexOf(5) !== -1){
                            tableAction = tableAction + "|excluir";
                        }
                    }
                    objects[i] = {
                        id: item.eusu_id, nome: item.eusu_nome.toUpperCase(), email: item.eusu_email,
                        tag: item.eusu_status === 1 ? 'Ativo' : 'Inativo', acoes: tableAction,
                        nomeExcluir: item.eusu_nome
                    };
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, loading: false, usuariosDefault: result.data
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        this.setState({ path: '/usuario-empresa/novo', propsToPath: { usuario: { id } }, redirect: true });
    }

    acaoExcluir(idUsu, nome) {
        this.setState({ nome, idUsu, modal: true });
    }

    async excluirUsuario() {
        const { idUsu } = this.state;
        this.setState({ loading: true });
        await ApiService.putGeral('empresausuario/' + idUsu, { eusu_status: 3 })
            .then(res => {
                this.setState({ modal: false });
                this.carregaUsuarios();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }
    
    handlePage(page) {
        const {acoes} = this.state;
        if (page) {
            this.setState({ path: '/usuario-empresa?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/usuario-empresa', redirect: true });
        }
        this.carregaUsuarios(page, acoes);
    }
    
    render() {
        const { loading, redirect, path, propsToPath, modal, alert, alertText, email, table, exibeDados, acoes, usuariosDefault, mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Modal show={modal}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de Aviso" />
                        <p>Você realmente deseja excluir o usuário <b>{this.state.nome}</b>?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.excluirUsuario()}>Confirmar</button>
                            <button className="btn" onClick={() => this.setState({ modal: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={3} />

                <div className="body">
                    <Header />

                    <div className="usuario-empresa mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Usuário Empresas</h1>
                                <p className="descricao">Abaixo a listagem de todos os usuários de empresas</p>
                            </div>
                            {acoes.indexOf(3) !== -1 ?
                                <button className="btn" onClick={() => this.setState({ path: '/usuario-empresa/novo', redirect: true })}>Novo Usuário</button>
                            : null}
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="email" isFilterGroups={true} label="E-mail" value={email} 
                                        onChange={e => this.setState({ email: e.target.value })} />
                                    <button className="btn filtrar" onClick={() => this.carregaUsuarios()}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar}
                                        acaoExcluir={this.acaoExcluir} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhum usuário encontrada!</p>
                                ) : null}
                            </div>
                              {exibeDados ? (<>
                                <div className="info">
                                    <Paginacao dados={usuariosDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}