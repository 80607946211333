import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Funcoes from '../../Utils/functions';
import Input from '../../Components/Input';
import { moneyMask, dataBrMask } from '../../Utils/mask';
import Modal from '../../Components/Modal';
import iconAviso from '../../resources/img/icon-aviso.svg';
import ApiService from '../../Utils/services';
import { validaForm } from '../../Utils/FormValidator';

export default class ContractDetails extends Component {

    constructor(props) {
        super(props);
        this.acaoEditar = this.acaoEditar.bind(this);

        this.state = {
            path: this.props.location.state ? '' : '/emprestimo-pessoal',
            redirect: this.props.location.state ? false : true,
            
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            table: {
                campos: [
                    { titulo: 'Data Vencimento', dado: 'dtVencimento' },
                    { titulo: 'Valor', dado: 'valor' },
                    { titulo: 'Status', dado: 'status', tdTagStyle: true  },
                    { titulo: 'Ações', dado: 'acoes', tdStyle: {width: "120px"} },
                ],
                dados: []
            },

            tableAbatimentos: {
                campos: [
                    { titulo: 'Data Pagamento', dado: 'dtpagamento' },
                    { titulo: 'Valor', dado: 'valor' },
                ],
                dados: []
            },

            tableSimulacao: {
                campos: [
                    { titulo: 'Nº Parcela', dado: 'numeroParcela', thStyle: { textAlign: 'center'} },
                    { titulo: 'Data Vencimento', dado: 'dtVencimento', thStyle: { textAlign: 'center'} },
                    { titulo: 'Valor Juros', dado: 'vlrJuros', thStyle: { textAlign: 'center'} },
                    { titulo: 'Valor principal', dado: 'vlrPrincipal', thStyle: { textAlign: 'center'} },
                    { titulo: 'Valor parcela', dado: 'vlrParcela',  thStyle: { textAlign: 'center'} },
                ],
                dados: []
            },

            parcelaRenegociacaoStatusOptions: [{ value: '', text: 'Selecione' }],
            exibeDados: false,
            mostraVazio: false,
            mostraVazioAbatimento: false,
            mostraVazioSimulacao: false,
            
            exibeDadosAbatimento: false,
            exibeDadosSimulacao: false, 

            modalAbatimento: false,
            modalRenegociacao: false,
            modalAlterarParcelaRenegociacao: false,
            modalSimulacao: false,
            statusParcelaDeRenegociacao: '',
            taxaJuros: '',
            prazoRenegociacao: '',
            valorAbatimento: '',
            valorDidiva: '',
            valorTotalAbatido: 0.0,
            dtAbatimento: '',
            dtPrimeiroVencimento: '',
            dataDePagamentoDeParcelaDeRenegociacao: '',
            dtRenegociacao: '',
            parcelaRenegociacaoSelecionada: undefined,
            ppst_id: '',
            proposta: {},
            valorVpl: '',
            dataReferencia: '',
            taxaRenegociacao: '-', 
            dividaAtualRenegociacao: 0.0, 
            dividaRenegociacaoPaga: 0.0,
            dividaTotalDeRenegociacao: 0.0
        };
    }

    componentDidMount() {
        const { emprestimo } = this.props.location.state;
        if (emprestimo) {
           this.carregaRenegociacaoDeContrato();
           this.carregaHistoricoAbatimento();
           this.carregaProposta(emprestimo.ppst_id);
           this.carregaStatusParcelaRenegociacao()
        }
    }
    acaoEditar(id) {
        this.setState({ modalAlterarParcelaRenegociacao: true, parcelaRenegociacaoSelecionada: id });
    }
    async carregaHistoricoAbatimento()
    {        
        const {tableAbatimentos} = this.state;
        const { emprestimo } = this.props.location.state;
        if (emprestimo) {
            let emppes_id = emprestimo.emppes_id;
            this.setState({ loading: true });
            await ApiService.getGeral('emprestimopessoal/parcelas/historicobaixa/' + emppes_id + '?per_page=-1')
            .then(result => {
                const res = result.data;
                var objects = [];
                let valorTotalAbatido = 0.0;
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({
                        dtpagamento: Funcoes.data2Brazilian( item.eppba_dtpagto, '-'), valor: 'R$ ' + Funcoes.float2Preco(item.eppba_valor)
                    });
                    valorTotalAbatido += item.eppba_valor;
                }
                if (res.length > 0) {
                    this.setState({ exibeDadosAbatimento: true, mostraVazioAbatimento: false });
                } else {
                    this.setState({ exibeDadosAbatimento: false, mostraVazioAbatimento: true });
                }  
                this.setState({ tableAbatimentos: { campos: [...tableAbatimentos.campos], dados: objects }, valorTotalAbatido: valorTotalAbatido, dadosDefault: objects, ppst_id: emprestimo.ppst_id });

            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
           
        }
       
    }
    
    trataStatusDeParcelaDeRenegociacao(status)
    {
        if (status === "Atrasada") {
           status += " warning"
        } else if(status === "Á vencer") {
            status += ' inactive'
        }
        return status
    }
    
    async carregaStatusParcelaRenegociacao(){

        await ApiService.getGeral('renegociacao_parcela/status')
        .then(result => {
            const res = result.data;
            var parcelaRenegociacaoStatusOptions = [{ value: '', text: 'Selecione' }];
            for (var i = 0; i < res.length; i++) {
                const item = res[i];
                parcelaRenegociacaoStatusOptions[i + 1] = { value: item.renps_id, text: item.renps_descricao };
            }
            this.setState({parcelaRenegociacaoStatusOptions: parcelaRenegociacaoStatusOptions});
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alertType: 2 ,alert: true });
        })
    }

    async carregaRenegociacaoDeContrato()
    {        
        const {table} = this.state;
        const { emprestimo } = this.props.location.state;
        let idProposta = emprestimo.ppst_id;
        this.setState({ loading: true });
        await ApiService.getGeral('renegociacao/contrato/' + idProposta )
            .then(result => {
                const res = result.data;
                var objects = [];
                let valorTotalDivida = 0.0;
                let valorDividaPaga = 0.0;
                let valorDividaAtual = 0.0;                
                let taxaDeJuros;
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    let status = this.trataStatusDeParcelaDeRenegociacao(item.status)

                    objects.push({
                        id: item.renp_id,
                        dtpagamento: Funcoes.data2Brazilian( item.renp_dtpagamento, '-'), 
                        dtVencimento: Funcoes.data2Brazilian( item.renp_dtvencimento, '-'), 
                        valor: 'R$ ' + Funcoes.float2Preco(item.renp_valor_parcela),
                        taxa: item.ren_taxajuros,
                        status: status,
                        acoes: 'default'
                    });
                    valorTotalDivida += item.renp_valor_parcela;
                    valorDividaPaga += (item.status === "Paga") ? item.renp_valor_parcela : 0.0;
                    valorDividaAtual += (item.status === "Á vencer") ? item.renp_valor_parcela : 0.0;

                    taxaDeJuros = item.ren_taxajuros
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, mostraVazio: false });
                } else {
                    this.setState({ exibeDados: false, mostraVazio: true });
                }  
                this.setState({ table: { campos: [...table.campos], dados: objects },
                    taxaRenegociacao: taxaDeJuros ,dividaAtualRenegociacao: valorDividaAtual, dividaRenegociacaoPaga: valorDividaPaga, 
                    dividaTotalDeRenegociacao: valorTotalDivida, dadosDefault: objects, loading: false});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })       
    }
    
    async carregaProposta(id) {
        this.setState({ loading: true });
        await ApiService.getGeral('contratos/' + id)
            .then(result => {
                const res = result.data.data;
                this.setState({  proposta: res });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async abaterEmprestimo(){
        const {  valorAbatimento, dtAbatimento } = this.state;
        const { emprestimo } = this.props.location.state;
        //pega ultima parcela para abater
        let id = emprestimo.emppes_id

        this.setState({loading: true})
        let form = {
            valorAbatimento: Funcoes.preco2float(valorAbatimento,"R$"),
            dtAbatimento: Funcoes.data2Americano(dtAbatimento)
        }
        
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if(!Funcoes.data2Americano(dtAbatimento).match(regEx)){
            this.setState({ loading: false, alertText: "Data de abatimento inválida", alertType: 2, alert: true });
            return false;
        } 
        var d = new Date(Funcoes.data2Americano(dtAbatimento));
        var dNum = d.getTime();
        if(!dNum && dNum !== 0){
            this.setState({ loading: false, alertText: "Data de abatimento inválida", alertType: 2 ,alert: true });
            return false;
        }
        
        await ApiService.postGeral('emprestimopessoal/parcelas/abatervalor/' + id, form)
        .then(res => {
            this.carregaHistoricoAbatimento();
            this.setState({ alertText: 'O abatimento foi feito com sucesso!', alertType: 1,
                 alert: true, loading: false, modalAbatimento: false});
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alertType: 2 ,alert: true });
        })
        
    }

    validaSimulacao(){
        const { dtPrimeiroVencimento, valorDidiva, dtRenegociacao, taxaJuros, prazoRenegociacao } = this.state;
        const { emprestimo } = this.props.location.state;

        let form = [
            { campo: 'Valor da dívida', attr: 'valorDidiva', valor: valorDidiva },
            { campo: 'Taxa de juros', attr: 'taxaJuros', valor: taxaJuros },
            { campo: 'Prazo', attr: 'prazoRenegociacao', valor: prazoRenegociacao },
            { campo: 'Dt. primeiro vencimento', attr: 'dtPrimeiroVencimento', valor: dtPrimeiroVencimento, minLength: 10, maxLength: 10 },
            { campo: 'Dt. de renegociação', attr: 'dtRenegociacao', valor: dtRenegociacao, minLength: 10, maxLength: 10 }
        ];

        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            const formData = {
                ren_taxajuros: Funcoes.preco2float(taxaJuros),
                ren_divida: Funcoes.preco2float(valorDidiva, 'R$'),
                ren_dtprimeirovencto: Funcoes.data2Americano(dtPrimeiroVencimento),
                ppst_id: emprestimo.ppst_id,
                ren_prazo: parseInt(prazoRenegociacao),
                ren_dtrenegociacao: Funcoes.data2Americano(dtRenegociacao),
            };
            this.simularRenegociacao(formData)
        }
        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async simularRenegociacao(formData){
        const { tableSimulacao} = this.state;

        this.setState({ loading: true, modalRenegociacao: false });
    
        await ApiService.postGeral('renegociacao/simulacao', formData)
        .then(result => {
            const res = result.data.data;
            var objects = [];
            for (var i = 0; i < res.length; i++) {
                const item = res[i];
                objects.push({
                    numeroParcela: item.numero_parcela,
                    dtVencimento: Funcoes.data2Brazilian( item.data_vencimento, '-'), 
                    vlrJuros: 'R$ ' + Funcoes.float2Preco(item.valor_juros),
                    vlrPrincipal: 'R$ ' + Funcoes.float2Preco(item.valor_principal),
                    vlrParcela: 'R$ ' + Funcoes.float2Preco(item.valor_parcela),
                });
            }
            if (res.length > 0) {
                this.setState({ exibeDadosSimulacao: true, mostraVazioSimulacao: false });
            } else {
                this.setState({ exibeDadosSimulacao: false, mostraVazioSimulacao: true });
            }  
            this.setState({ tableSimulacao: { campos: [...tableSimulacao.campos], dados: objects }, loading: false, modalSimulacao: true});
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    async renegociarEmprestimo()
    {
        const { dtPrimeiroVencimento, valorDidiva, dtRenegociacao, taxaJuros, prazoRenegociacao } = this.state;
        const { emprestimo } = this.props.location.state;

        const formData = {
            ren_taxajuros: Funcoes.preco2float(taxaJuros),
            ren_divida: Funcoes.preco2float(valorDidiva, 'R$'),
            ren_dtprimeirovencto: Funcoes.data2Americano(dtPrimeiroVencimento),
            ppst_id: emprestimo.ppst_id,
            ren_prazo: parseInt(prazoRenegociacao),
            ren_dtrenegociacao: Funcoes.data2Americano(dtRenegociacao),
        };
        
        this.setState({ loading: true, modalRenegociacao: false,  modalSimulacao: false });
    
        await ApiService.postGeral('renegociacao', formData)
        .then(result => {
            this.setState({ alertText: 'Renegociação criada com sucesso!', alertType: 1,
            alert: true});
            this.carregaRenegociacaoDeContrato();
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }   
    async alterarParcelaDeRenegociacao(id){
        const { statusParcelaDeRenegociacao, dataDePagamentoDeParcelaDeRenegociacao, valorVpl} = this.state;
        this.setState({loading: true, modalAlterarParcelaRenegociacao: false})
        let form = {
            renps_id: statusParcelaDeRenegociacao,
            renp_dtpagamento: Funcoes.data2Americano(dataDePagamentoDeParcelaDeRenegociacao)
        }
        if(statusParcelaDeRenegociacao == 3){
            form.renp_valor_parcela = Funcoes.preco2float(valorVpl,"R$");
        }
        await ApiService.putGeral('renegociacao_parcela/' + id, form)
        .then(result => {
            this.carregaRenegociacaoDeContrato();
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }
    voltar() {
        this.setState({ path: '/emprestimo-pessoal', propsToPath: this.props.location.state, redirect: true });
    }

    async validaCalculoVpl(data){
        const {table, parcelaRenegociacaoSelecionada} = this.state;
        
        this.setState({dataReferencia: dataBrMask(data)})
        let parcela =  table.dados.find(function(element) {
            return element.id == parcelaRenegociacaoSelecionada
        });

        if(data.length < 10){
            return 0;
        }
        let valorParcela = Funcoes.preco2float(parcela.valor,"R$")
        let dataVencimento = new Date(Funcoes.data2Americano(parcela.dtVencimento,'/'))
        let dataRef = new Date(Funcoes.data2Americano(data,'/'));
        let taxa = parcela.taxa
        let vpl = Funcoes.calculaVplDeParcela(valorParcela, taxa, dataVencimento, dataRef)
        this.setState({valorVpl: "R$" + Funcoes.float2Preco(vpl)});
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertType, alertText, table,tableAbatimentos,tableSimulacao, proposta, exibeDados, valorAbatimento, 
            valorTotalAbatido, parcelaRenegociacaoStatusOptions,statusParcelaDeRenegociacao,parcelaRenegociacaoSelecionada, 
            valorDidiva, dtAbatimento, dtPrimeiroVencimento, dtRenegociacao, mostraVazio, modalAbatimento,modalSimulacao, 
            modalAlterarParcelaRenegociacao, modalRenegociacao, mostraVazioAbatimento, mostraVazioSimulacao, exibeDadosAbatimento, exibeDadosSimulacao, 
            taxaJuros, prazoRenegociacao,dataDePagamentoDeParcelaDeRenegociacao, dataReferencia, valorVpl,
            taxaRenegociacao, dividaAtualRenegociacao, dividaRenegociacaoPaga,dividaTotalDeRenegociacao
        } = this.state;
        const { emprestimo } = this.props.location.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Modal show={modalAbatimento}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <Input type="text" label="Valor Abatimento." placeholder="R$ 0,00" value={valorAbatimento} className="custom"
                            onChange={e => this.setState({ valorAbatimento: moneyMask(e.target.value) })} />
                        <Input type="text" label="Data do abatimento."  placeholder="dd/mm/aaaa" value={dtAbatimento} className="custom"
                            onChange={e => this.setState({ dtAbatimento: dataBrMask(e.target.value) })} />
                          <div className="cancelamentoAcao">
                            <button className="btn"
                                style={(valorAbatimento !== '' && dtAbatimento !== '') ? { backgroundColor: '#EA5353', color: '#FFF' } : { backgroundColor: '#999' }}
                                onClick={() => (valorAbatimento !== '' && dtAbatimento !== '') ? this.abaterEmprestimo() : {}}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalAbatimento: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>

                <Modal show={modalSimulacao} largura = {1200}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <div className="multPanel">

                            <div className="panel simulacao" style={{overflowX: "hidden"}}>
                                    <div className="panelHeader">
                                        <h3>Simulação de renegociação</h3>
                                    </div>
                                    <div style={{overflowX: "scroll",maxHeight: 500}} className="panelBody">
                                        {exibeDadosSimulacao ? (<>
                                            <Table campos={tableSimulacao.campos}
                                                dados={tableSimulacao.dados} />
                                        </>) : null}
                                        {mostraVazioSimulacao ? (
                                            <p className="textEmpty">Nenhuma parcela gerada na simulação!</p>
                                        ) : null}
                                    </div>
                            </div>
                        </div>
                        <div className="cancelamentoAcao">
                            <button className="btn"
                                style={  {backgroundColor: '#EA5353', color: '#FFF'} }
                                onClick={() =>  this.renegociarEmprestimo()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalSimulacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalAlterarParcelaRenegociacao}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <p className="warning">Escolhe o novo status da parcela</p>
                        <Input type="select" optionsData={parcelaRenegociacaoStatusOptions} label="Status" value={statusParcelaDeRenegociacao} 
                                        onChange={e => this.setState({statusParcelaDeRenegociacao: e.target.value})} />

                        { 
                            statusParcelaDeRenegociacao == 2 ?
                            (<> 
                            <Input type="text" label="Data de pagamento."  placeholder="dd/mm/aaaa" value={dataDePagamentoDeParcelaDeRenegociacao} className="custom"
                                onChange={e => this.setState({ dataDePagamentoDeParcelaDeRenegociacao: dataBrMask(e.target.value) })} />
                              </>)
                        : null}


                        { statusParcelaDeRenegociacao == 3 ?
                            (<> 
                                <Input type="text" label="Data de Referencia" maxLength={10}  placeholder="dd/mm/aaaa" value={dataReferencia} className="custom"
                                    onChange={e => this.validaCalculoVpl(e.target.value)} />
                                <Input type="text" disabled className="disabled" label="Valor" value={valorVpl}/>
                              </>)
                        : null}

                        <div className="cancelamentoAcao">
                            <button className="btn"
                                style={{ backgroundColor: '#EA5353', color: '#FFF' } }
                                onClick={() => this.alterarParcelaDeRenegociacao(parcelaRenegociacaoSelecionada)}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalAlterarParcelaRenegociacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalRenegociacao}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <Input type="text" label="Valor da dívida" placeholder="R$ 0,00" value={valorDidiva} className="custom"
                            onChange={e => this.setState({ valorDidiva: moneyMask(e.target.value) })} />
                        <Input type="tel" label="Taxa de Juros" placeholder="0,00" value={taxaJuros} className="custom" 
                            onChange={e => this.setState({ taxaJuros: moneyMask(e.target.value, false) })} />
                        <Input type="number" label="Prazo" placeholder="0" value={prazoRenegociacao} className="custom" 
                            onChange={e => this.setState({ prazoRenegociacao: e.target.value })} />     
                        <Input type="text" label="Data do primeiro vencimento."  placeholder="dd/mm/aaaa" value={dtPrimeiroVencimento} className="custom"
                            onChange={e => this.setState({ dtPrimeiroVencimento: dataBrMask(e.target.value) })} />
                        <Input type="text" label="Data Renegociação."  placeholder="dd/mm/aaaa" value={dtRenegociacao} className="custom"
                            onChange={e => this.setState({ dtRenegociacao: dataBrMask(e.target.value) })} />
                          <div className="cancelamentoAcao">
                            <button className="btn"
                                style={  {backgroundColor: '#EA5353', color: '#FFF'} }
                                onClick={() =>  this.validaSimulacao()}>Simular</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalRenegociacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <SideMenu menuAtivo={9} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText}  tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />

                <div className="body">
                    <Header />

                    <div className="emprestimo-pessoal-detalhes mgDefault">
                        <div className="headerBtn">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h1>Emp. Pessoal - {Funcoes.leftPad(emprestimo.emppes_id, 5, 0)}</h1>
                                <div className="tag">
                                    <p className= {emprestimo.ina_status == 0 ? 'inadimplente' : emprestimo.ina_status == 1 ? 'quitado' : '-'}>
                                        {emprestimo.ina_status == 0 ? 'Inadimplente' : emprestimo.ina_status == 1 ? 'Quitado' : '-'}
                                    </p>
                                </div>  
                                <div style={{ marginLeft: 'auto', display: 'flex' }}>  
                                    <button className="btn" style={{ marginLeft: '25px'}} onClick={() => this.setState({modalRenegociacao: true})}>Gerar Renegociação</button>                         
                                    <button className="btn" style={{ marginLeft: '25px'}} onClick={() => this.setState({modalAbatimento: true})}>Abater Dívida</button>
                                
                                    <button className="btn" style={{ marginLeft: '25px'}} onClick={() => {
                                        const url = ApiService.InManoApiUrl();
                                        const token = ApiService.getUserToken();
                                        const id = proposta.ppst_linkcontrato.replace('/geral/arquivo/', '').replace('/ver', '');
                                        this.setState({loading: true})
                                        fetch(url + '/geral/ccb/' + id + '/ver', {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer '  + token
                                        }
                                        })
                                        .then(response => response.blob())
                                            .then(blob => {
                                                // Create a URL for the PDF file
                                                const url = URL.createObjectURL(blob);

                                                // Open the PDF file in a new window
                                               window.open(url);
                                               this.setState({loading: false})

                                            })
                                            .catch(err => {
                                                const valida = ApiService.handleErros(err.response);
                                                if (valida.status === "unauthorized") {
                                                    sessionStorage.removeItem('userData');
                                                    this.setState({ path: '/' });
                                                }
                                                if (valida.status === "serverError") {
                                                    this.setState({ path: null });
                                                }
                                    
                                                if (valida.status === "badRequest") {
                                                    this.setState({ path: null });
                                                }
                                                this.setState({ loading: false, alertText: valida.response, alert: true });
                                            })
                                    }}>Visualizar CCB</button>
                                 </div>

                            </div>
                            <p className="descricao">Abaixo detalhes do empréstimo selecionado.</p>
                        </div>

                        <div className="multPanel">
                            <div className="panel detalhes">
                                <div className="panelHeader">
                                    <h3>Detalhe do Empréstimo</h3>
                                </div>
                                <div className="panelBody">
                                    <div className="multArea">
                                        <p><span>Nº do Empréstimo: </span> {Funcoes.leftPad(emprestimo.emppes_id, 5, 0)}</p>
                                        <p><span>CCB: </span> {proposta.ppst_numeroccb ? Funcoes.leftPad(proposta.ppst_numeroccb) : '-'}</p>
                                        <p><span>Data Empréstimo: </span> {Funcoes.data2Brazilian(emprestimo.emppes_data)}</p>
                                    </div>
                                    <div className="multArea">
                                        <p style={{ marginBottom: 21 }}><span>Cliente: </span> {emprestimo.func_nome ? emprestimo.func_nome.toUpperCase() : ''}</p>
                                        <p style={{ marginBottom: 21 }}><span>CPF: </span> {emprestimo.func_cpf}</p>
                                    </div>
                                    
                                    <div className="multArea">
                                        <p><span>Valor Emprestado: </span> {proposta.ppst_valorsolicitado ? 'R$ ' + Funcoes.float2Preco(proposta.ppst_valorsolicitado) : '-'}</p>
                                    </div>
                                    
                                    <div className="multArea">
                                        <p className="flex"><span>Dt. Recisão : </span> {emprestimo.emppes_datapagtorescisao ?  Funcoes.data2Brazilian(emprestimo.emppes_datapagtorescisao) : '-'}</p>
                                        <p><span>Saldo Devedor: </span> {emprestimo.emppes_saldodevedor ? 'R$ ' + Funcoes.float2Preco(emprestimo.emppes_saldodevedor) : '-'}</p>
                                        <p><span>Valor Recisão: </span> {emprestimo.emppes_valorpagtorescisao ? 'R$ ' + Funcoes.float2Preco(emprestimo.emppes_valorpagtorescisao) : '-'}</p>
                                        <p><span>Total Dívida: </span> {emprestimo.emppes_saldofinal ? 'R$ ' + Funcoes.float2Preco(emprestimo.emppes_saldofinal) : '-'}</p>

                                    </div>
                                    
                                    <div className="multArea">
                                        <p><span>Dívida Atual: </span> {emprestimo.emppes_saldofinal ? 'R$ ' + Funcoes.float2Preco( emprestimo.emppes_saldofinal - valorTotalAbatido) : '-'}</p>
                                        <p><span>Dívida Paga: </span> {emprestimo.emppes_saldofinal ? 'R$ ' + Funcoes.float2Preco( valorTotalAbatido) : '-'}</p>

                                    </div>
                                    
                                </div>
                            </div>
                            <div className="panel detalhes">
                                    <div className="panelHeader">
                                        <h3>Histórico de Abatimentos</h3>
                                    </div>
                                    <div className="panelBody">
                                        {exibeDadosAbatimento ? (<>
                                            <Table campos={tableAbatimentos.campos}
                                                dados={tableAbatimentos.dados}
                                                acaoVisualizar={this.acaoVisualizar} />
                                        </>) : null}
                                        {mostraVazioAbatimento ? (
                                            <p className="textEmpty">Nenhum histórico encontrado!</p>
                                        ) : null}
                                    </div>
                            </div>
                            
                        </div>
                        <div className="multPanel">
                        <div className="panel detalhes">
                            <div className="panelHeader">
                                <h3>Detalhe de Renegociação</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multArea">
                                    <p><span>CCB: </span> {proposta.ppst_numeroccb ? Funcoes.leftPad(proposta.ppst_numeroccb) : '-'}</p>
                                    <p><span>Taxa de Juros: </span> {taxaRenegociacao + "%"}</p>
                                </div>
                                <div className="multArea">
                                    <p style={{ marginBottom: 21 }}><span>Cliente: </span> {emprestimo.func_nome ? emprestimo.func_nome.toUpperCase() : ''}</p>
                                    <p style={{ marginBottom: 21 }}><span>CPF: </span> {emprestimo.func_cpf}</p>
                                </div>
                                
                                <div className="multArea">
                                    <p><span>Dívida Atual: </span> {dividaAtualRenegociacao ? "R$" + Funcoes.float2Preco(dividaAtualRenegociacao) : '-' }</p>
                                    <p><span>Dívida Paga: </span> {dividaRenegociacaoPaga ? "R$" + Funcoes.float2Preco(dividaRenegociacaoPaga) : '-' }</p>
                                    <p><span>Dívida Inicial: </span> {dividaTotalDeRenegociacao ? "R$" + Funcoes.float2Preco(dividaTotalDeRenegociacao) : '-' }</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel parcelas">
                                <div className="panelHeader">
                                    <h3>Fluxo de renegociação</h3>
                                </div>
                                <div className="panelBody">
                                    {exibeDados ? (<>
                                        <Table campos={table.campos}
                                            dados={table.dados}
                                            acaoEditar={this.acaoEditar} />
                                    </>) : null}
                                    {mostraVazio ? (
                                        <p className="textEmpty">Nenhuma parcela encontrada!</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="acoes">
                            <button className="btn btnVoltar" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.voltar()}>{'<'} Voltar</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}