import {
    ADD_EMPRESTIMO,
    REMOVE_EMPRESTIMO,
    DELETE_ALL_EMPRESTIMO,
    RETRIEVE_ALL_EMPRESTIMO
} from "./types";


import DisconnectDataService from "../Services/DisconnectDataService";


export const addEmprestimo = (obj) => async (dispatch) => {
  try {
    
    const res = await DisconnectDataService.retrieve(obj);
    dispatch({
      type: ADD_EMPRESTIMO,
      payload: res,
    });
    return Promise.resolve( res);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const removeEmprestimo = (obj) => async (dispatch) => {
  try {
    const res = await DisconnectDataService.retrieve(obj);
    dispatch({
      type: REMOVE_EMPRESTIMO,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteAllEmprestimos = () => async (dispatch) => {
  try {

    dispatch({
      type: DELETE_ALL_EMPRESTIMO,
      payload: {  },
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveAllEmprestimo = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_ALL_EMPRESTIMO,
      payload: {  },
    });
  } catch (err) {
    console.log(err);
  }
};




