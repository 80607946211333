import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import CargaService from '../../Utils/cargaService'
import Paginacao from '../../Components/Paginacao';
import { validaForm } from '../../Utils/FormValidator';

export default class CollaboratorImportation extends Component {

    constructor() {
        super();
        this.pegaArquivo = this.pegaArquivo.bind(this);
        this.handlePage = this.handlePage.bind(this);

        this.state = {
            alertType: 2,
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            formData: new FormData(),
            formDataIncorpora: new FormData(),
            nomeArquivo: null,
            idUsuario: null,
            reprocessamento: false,
            table: {
                campos: [
                    { titulo: 'ID Carga', dado: 'idCarga' },
                    { titulo: 'CPF', dado: 'cpf' },
                    { titulo: 'CNPJ', dado: 'cnpj' },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Data/Hora', dado: 'dataHora' },
                    { titulo: 'Descrição do erro', dado: 'descErro' },
                ],
                dados: []
            },
            tableEmpresas: {
                campos: [
                    { titulo: 'Nome', dado: 'nome', tdStyle: { textAlign: 'left'} },
                    { titulo: 'CNPJ', dado: 'cnpj', tdStyle: { textAlign: 'left'} },
                ],
                dados: []
            },
            empresasSelecionadasIncorpora: [],
            grupoEconomico: '',
            modalConfirmacao: false,
            empresasIncorpora: [],
            empresa: '',
            empresasOptions: [{ value: '', text: 'Selecione', nome: '', cnpj: '' }],
            gruposEconomicosOptions: [{ value: '', text: 'Selecione' }],
            colaboradoresDefault: {},

        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        let idUsuario = userData.usuario.id;
        this.carregaEmpresas();
        this.carregaGrupos();
        this.carregaInconsistentes(1);
        this.setState({idUsuario: idUsuario})
    }

    async carregaInconsistentes(page){
        const { table} = this.state;
        var url, pagina, pagAtual;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "colaboradores_inconsistente?per_page=10&page=" + pagAtual;
        } else {
            url = "colaboradores_inconsistente?per_page=10";
        }
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.colinco_id,
                        idCarga: item.colinco_carga,
                        cpf: item.colincofunc_cpf,
                        cnpj: item.emp_cnpj,
                        empresa: item.emp_nomefantasia,
                        descErro: item.colinco_descricao,
                        dataHora: Funcoes.dataHora2Brazilian(item.timestamp_carga)
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true,  mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, colaboradoresDefault: result.data, loading: false });
            
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });            })

        
    }

    async carregaGrupos() {
        this.setState({ loading: true });
        await ApiService.getGeral('grupoeconomico?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var gruposEconomicosOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    gruposEconomicosOptions[i + 1] = { value: item.gpec_id, text: item.gpec_nome };
                }
                this.setState({ gruposEconomicosOptions });
            })
            .catch(err => {
                this.setState({ gruposEconomicosOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async atualizaEmpresaIncorpora(empresa)
    {
        const { empresasOptions} = this.state;
        let empresasSelecionadas = [];

        if(!empresa)
        {
            let empresas = [];
            empresasOptions.forEach(element => {
                if(element.value){
                    empresas.push(element.value)
                    empresasSelecionadas.push(element)
                }
            });
            this.setState({empresa: empresa, empresasIncorpora: empresas, empresasSelecionadasIncorpora: empresasSelecionadas})   

        }else{
            empresasOptions.forEach(element => {
                console.log(element, empresa)
                if(element.value === empresa){
                    empresasSelecionadas.push(element)
                }
            });
            this.setState({empresa: empresa, empresasIncorpora: [parseInt(empresa)], empresasSelecionadasIncorpora: empresasSelecionadas})   
        }
    }

    async carregaEmpresas() {
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione', nome: '', cnpj: '' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia, nome: item.emp_nomefantasia, cnpj: item.emp_cnpj };
                }
                this.setState({ empresasOptions});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async recarregaEmpresaSelect(grupo){
        this.carregaEmpresasPorGrupo(grupo);
    }

    async carregaEmpresasPorGrupo(grupo){
        this.setState({ loading: true});
        this.setState({ grupoEconomico: grupo });
        let url = 'empresa?per_page=-1';
        if(grupo) url+= "&gpec_id=" + grupo;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                let empresas = [];
                var empresasOptions = [{ value: '', text: 'Selecione', nome: '', cnpj: '' }];
                let objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia, nome: item.emp_nomefantasia, cnpj: item.emp_cnpj };
                    objects[i] = {
                        nome: item.emp_nomefantasia,
                        cnpj: item.emp_cnpj,
                    };
                    empresas.push(item.emp_id);
                }

                if(!grupo)
                {
                    this.setState({ empresasIncorpora: []});
                }else{
                    this.setState({ empresasIncorpora: empresas, empresasSelecionadasIncorpora: objects});

                }

                this.setState({ empresasOptions, loading: false});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    pegaArquivo(arquivo) {
        const {formData} = this.state;
        formData.set("arquivo_excel", arquivo[0])
        this.setState({arquivo: arquivo[0]})

    }
    
    handlePage(page) {
        this.setState({loading: true});
        if (page) {
            this.setState({ path: '/importacoes/colaboradores?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/importacoes/colaboradores', redirect: true });
        }
        this.carregaInconsistentes(page);
    }

    async incorporar()
    {
        const {idUsuario, formDataIncorpora} = this.state;
        this.setState({loading: true})
        formDataIncorpora.set("usuario_id", idUsuario);
        formDataIncorpora.set('_method', 'POST');

        let url = "incorporacolaboradores";
        await CargaService.postMultiformData(url, formDataIncorpora)
            .then(result => {
                const res = result.data;
                let msg = res.message;
                this.setState({ loading: false, alertText: msg, alert: true, alertType: 1 });
            })
            .catch(err => {
                const valida = CargaService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }
    
                this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
            })
    }

    async cargaDeColaboradores(form){
        CargaService.postMultiformData('carregacolaboradores', form)
        .then(result => {
            const res = result.data;
            console.log(res)
            let msg = res.message;
            this.setState({alertText: msg, alert: true, alertType: 1 });
            this.carregaInconsistentes(1);
        })
        .catch(err => {
            const valida = CargaService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") { this.setState({ path: '' }); }
            if (valida.status === "badRequest") { this.setState({ path: '' }); }

            this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
        })
    }

    validaIncorpora()
    {
        const {grupoEconomico, empresasIncorpora} = this.state;
        var form = [
            { campo: 'Grupo Econômico', attr: 'grupo', valor: grupoEconomico},
        ];

        const valida = validaForm(form);
        if (valida.valido && empresasIncorpora.length > 0) {
            this.setState({ modalConfirmacao: true });
        }else if(!valida.valido) {
            
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alertType: 2, alert: true });
                    break;
                }
            }
        } else if (empresasIncorpora.length === 0){
            this.setState({ alertText: "O grupo selecionado não possui empresas. Não é possível incorporar", alertType: 2, alert: true });
        }

    }

    validaCampos(){
        const {formData,idUsuario, reprocessamento, arquivo} = this.state;

        var form = [
            { campo: 'Arquivo', attr: 'arquivo', valor: arquivo},
        ];

        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            let reproc =  reprocessamento ? 'True' : 'False'
            formData.set("reprocesso", reproc)
            formData.set("usuario_id", idUsuario)
            formData.set('_method', 'POST')
            this.cargaDeColaboradores(formData);
        }
        else {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alertType: 2, alert: true });
                    break;
                }
            }
        }
    }

    render() {
        const { loading, redirect,reprocessamento, path, propsToPath, alert, alertText, alertType, table, exibeDados, 
             colaboradoresDefault } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={17} />
                {/* <Modal show={modalConfirmacao} largura = {800}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de Aviso" />
                        <p className="warning">As empresas abaixo terão os dados de seus funcionários incorporados no sistema. Essa ação não poderá ser desfeita. Tem certeza que deseja prosseguir?</p>
                        <div className="modal-parcelas-edit-colaboradores ">
                            <Table campos={tableEmpresas.campos}
                                        dados={empresasSelecionadasIncorpora}
                                    />
                        </div>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.incorporar()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalConfirmacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal> */}
                <div className="body">
                    <Header />

                    <div className="importacao-colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Importação de Colaboradores</h1>
                                <p className="descricao">Abaixo você pode relaizar carga de colaboradores</p>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                <div className="panelHeader">
                                    <h3>Dados da importação</h3>
                                </div>
                                <div className="panelBody">
                                    <div className="row" >
                                        <Input type="file" id="url"  isFilterGroups={true} label="Planilha" pegaArquivo={this.pegaArquivo}/>
                                        <button className="btn filtrar" onClick={() => this.validaCampos()}>Importar</button>                                          
                                    </div>
                                        <div className="multInput" >    
                                            <Input type="checkbox" defaultChecked={reprocessamento}  className="checkBoxtable" style={{marginBottom: 0}} label="Reprocessamento" value={reprocessamento}  onChange={e => this.setState({reprocessamento: e.target.checked})} />                                       
                                        </div>
                                    <div className="panelHeader">
                                        <h3>Colaboradores inconsistentes</h3>
                                    </div>

                                    <div className="detalhes">
                                        <div className="panel">
                                            {exibeDados ? (<>
                                                <Table 
                                                    campos={table.campos}
                                                    dados={table.dados}
                                                />
                                            </>) : null}
                                            {table.dados == null ? (
                                                <p className="textEmpty">Nenhum inconsistente encontrado!</p>
                                            ) : null}
                                        </div>
                                        {exibeDados ? (<>
                                        <div className="info">
                                            <p>Total de registros: {colaboradoresDefault.total}</p>
                                            <Paginacao dados={colaboradoresDefault}
                                                handlePage={this.handlePage} />
                                        </div>
                                    </>) : null}
                                        <div className="panelHeader">
                                            <h3>Incorporação de colaboradores</h3>
                                        </div>
                                        <button className="btn filtrar" onClick={() => this.incorporar()}>Incorporar</button>                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}