import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

import { Line, Bar, Chart } from 'react-chartjs-2';

export default class Dashboard extends Component {

    constructor() {
        super();

        this.optionsHistorico = {
            legend: { display: false, }, maintainAspectRatio: false, plugins: {
                datalabels: {
                    display: false,
                },
            },
            scales: {
                xAxes: [{ gridLines: { display: false }, barPercentage: 0.3 }],
                yAxes: [{ gridLines: { display: false, drawTicks: false }, ticks: { display: false, max: 0 } }]
            },
            "hover": {
                "animationDuration": 0
            },
            "animation": {
                "duration": 1,
                "onComplete": function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;

                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data, bar._model.x, bar._model.y - 5);
                        });
                    });
                }
            }
        };
        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            dataHoje: '',
            filtroSelecionado: 1,
            meses: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
            horas: ['0h', '3h', '6h', '9h', '12h', '15h', '18h', '21h', '24h'],
            mainChart: {
                data: {
                    labels: ['0h', '3h', '6h', '9h', '12h', '15h', '18h', '21h', '24h'],
                    datasets: [{
                        label: '', fill: false, lineTension: 0.1, backgroundColor: '#3E4F74', borderColor: '#3E4F74',
                        borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#3E4F74',
                        pointBackgroundColor: '#3E4F74', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#3E4F74',
                        pointHoverBorderColor: '#3E4F74', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
                    }]
                },
                options: {
                    showScale: true, showLines: true, legend: { display: false, },
                    scales: {
                        xAxes: [{ gridLines: { display: false } }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true, min: 0, max: 2000,
                                callback: function (value) {
                                    return value < 1000 ? value : Funcoes.nFormatter(value);
                                }
                            }
                        }]
                    }
                }
            },
            faturamentoTotal: 0,
            faturamentoChart: {
                data: {
                    labels: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun'],
                    datasets: [{
                        backgroundColor: '#3E4F74', borderColor: '#3E4F74', hoverBackgroundColor: '#3E4F74', hoverBorderColor: '#3E4F74',
                        borderWidth: 1, data: [1, 1, 1, 1, 1, 1]
                    }]
                },
                options: this.optionsHistorico
            },
            pagamentosChart: {
                data: {
                    labels: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun'],
                    datasets: [{
                        backgroundColor: '#3E4F74', borderColor: '#3E4F74', hoverBackgroundColor: '#3E4F74', hoverBorderColor: '#3E4F74',
                        borderWidth: 1, data: [0, 0, 0, 0, 0, 0]
                    }]
                },
                options: this.optionsHistorico
            }
        };
    }

    componentDidMount() {
        const dataHoje = Funcoes.geraDataExtenso();
        this.setState({ dataHoje });
        this.carregaDashboard();
    }

    carregaDashboard() {
        this.setState({ loading: true });
        this.carregaEmprestimos(true, 1);
    }

    async carregaEmprestimos(carregaProximoGrafico, filtro) {
        const { horas } = this.state;
        if (!carregaProximoGrafico) this.setState({ loading: true });

        await ApiService.getGeral('dashboard/emprestimos?periodo=' + filtro)
            .then(result => {
                const res = result.data;
                var labels = [], dataValues = [], faturamentoTotal = 0, maxValue = 0;
                if (res.length > 0) {
                    if (filtro === 1 || filtro === 2) {
                        for (var i = 0; i < res.length; i++) {
                            labels.push(res[i].intervalo + "h");
                            dataValues.push(res[i].valor);
                            faturamentoTotal += res[i].valor;
                            if (res[i].valor > maxValue)
                                maxValue = res[i].valor;
                        }
                        for (var x = dataValues.length; x < labels.length; x++) {
                            dataValues.push(0);
                        }
                    } else {
                        for (var i = 0; i < res.length; i++) {
                            labels.push(res[i].intervalo.split('-')[2]);
                            dataValues.push(res[i].valor);
                            faturamentoTotal += res[i].valor;
                        }
                    }
                } else {
                    if (filtro === 1 || filtro === 2) {
                        labels = horas;
                    } else {
                        if (filtro === 3) {
                            for (var i = 1; i <= 7; i++) {
                                labels.push('0' + i);
                            }
                        }
                        if (filtro === 4) {
                            for (var i = 1; i <= 30; i++) {
                                labels.push(i < 10 ? '0' + i : i);
                            }
                        }
                    }
                    for (var i = 0; i < labels.length; i++) {
                        dataValues.push(0);
                    }
                }
                this.setState({
                    mainChart: {
                        data: {
                            labels,
                            datasets: [{
                                label: '', fill: false, lineTension: 0.1, backgroundColor: '#3E4F74', borderColor: '#3E4F74',
                                borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#3E4F74',
                                pointBackgroundColor: '#3E4F74', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                                pointHoverBorderColor: 'rgba(220,220,220,1)', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                                data: dataValues
                            }]
                        },
                        options: {
                            showScale: true, showLines: true, legend: { display: false, },
                            scales: {
                                xAxes: [{ gridLines: { display: false } }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true, min: 0, max: maxValue + 1000,
                                        callback: function (value) {
                                            return value < 1000 ? value : Funcoes.nFormatter(value);
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    faturamentoTotal
                })

                if (carregaProximoGrafico) {
                    this.carregaHistoricoFaturamento();
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async carregaHistoricoFaturamento() {
        const { meses } = this.state;
        await ApiService.getGeral('dashboard/faturamento/historico')
            .then(result => {
                var res, maiorMes, data = [], valorMaior = 0, sair = false, contador = 5, primeiroMes, labels = [];

                res = result.data;
                maiorMes = res[0].intervalo - 1;

                //Pega os valores dos faturamento
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    data[item.intervalo - 1] = parseInt(item.valor);
                    if (parseInt(item.valor) > valorMaior)
                        valorMaior = parseInt(item.valor);
                }
                //Pega os labels dos faturamento
                while (sair === false) {
                    primeiroMes = maiorMes - contador;
                    if (primeiroMes < 0)
                        primeiroMes = (12 - (primeiroMes * -1))

                    labels.push(meses[primeiroMes]);
                    if (contador === 0) {
                        sair = true
                    } else {
                        contador--
                    }
                }
                this.setState({
                    faturamentoChart: {
                        data: {
                            labels,
                            datasets: [{
                                backgroundColor: '#3E4F74', borderColor: '#3E4F74', hoverBackgroundColor: 'r#3E4F74', hoverBorderColor: '#3E4F74',
                                borderWidth: 1, data
                            }]
                        },
                        options: {
                            ...this.optionsHistorico,
                            scales: {
                                xAxes: { ...this.optionsHistorico.scales.xAxes },
                                yAxes: [{ gridLines: { display: false, drawTicks: false }, ticks: { display: false, max: valorMaior + 200 } }]
                            }
                        }
                    }
                });
                this.carregaHistoricoEmprestimo();
            })
            .catch(err => {
                console.log(err);
                this.setState({ loading: false });
            })
    }

    async carregaHistoricoEmprestimo() {
        const { meses } = this.state;
        await ApiService.getGeral('dashboard/emprestimos/historico')
            .then(result => {
                var res, maiorMes, data = [], valorMaior = 0, sair = false, contador = 5, primeiroMes, labels = [];

                res = result.data;
                maiorMes = res[0].intervalo - 1;

                //Pega os valores dos emprestimos
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    data[item.intervalo - 1] = parseInt(item.emprestimos);
                    if (parseInt(item.emprestimos) > valorMaior)
                        valorMaior = parseInt(item.emprestimos);
                }
                //Pega os labels dos emprestimos
                while (sair === false) {
                    primeiroMes = maiorMes - contador;
                    if (primeiroMes < 0)
                        primeiroMes = (12 - (primeiroMes * -1))

                    labels.push(meses[primeiroMes]);
                    if (contador === 0) {
                        sair = true
                    } else {
                        contador--
                    }
                }
                this.setState({
                    loading: false,
                    pagamentosChart: {
                        data: {
                            labels,
                            datasets: [{
                                backgroundColor: '#3E4F74', borderColor: '#3E4F74', hoverBackgroundColor: 'r#3E4F74', hoverBorderColor: '#3E4F74',
                                borderWidth: 1, data
                            }]
                        },
                        options: {
                            ...this.optionsHistorico,
                            scales: {
                                xAxes: { ...this.optionsHistorico.scales.xAxes },
                                yAxes: [{ gridLines: { display: false, drawTicks: false }, ticks: { display: false, max: valorMaior + 100 } }]
                            }
                        }
                    }
                });
            })
            .catch(err => {
                this.setState({ loading: false });
            })
    }

    async handleFiltro(filtroSelecionado) {
        this.setState({ filtroSelecionado });
        this.carregaEmprestimos(false, filtroSelecionado);
    }

    render() {
        const { redirect, path, loading, alert, alertText, dataHoje, filtroSelecionado, mainChart, faturamentoChart, pagamentosChart, faturamentoTotal } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={1} />

                <div className="body">
                    <Header />

                    <div className="dashboard mgDefault">
                        <h1>Dashboard</h1>
                        <p className="descricao">{dataHoje}</p>

                        <div className="panel">
                            <div className="header">
                                <div className="filtros">
                                    <button className={filtroSelecionado === 1 ? "btn active" : "btn"}
                                        onClick={() => this.handleFiltro(1)}>hoje</button>
                                    <button className={filtroSelecionado === 2 ? "btn active" : "btn"}
                                        onClick={() => this.handleFiltro(2)}>ontem</button>
                                    <button className={filtroSelecionado === 3 ? "btn active" : "btn"}
                                        onClick={() => this.handleFiltro(3)}>últimos 7 dias</button>
                                    <button className={filtroSelecionado === 4 ? "btn active" : "btn"}
                                        onClick={() => this.handleFiltro(4)}>esse mês</button>
                                </div>
                                <div className="faturamento">
                                    <p>empréstimo total</p>
                                    <h3 className="cl-roxo">R$ {Funcoes.float2Preco(faturamentoTotal)}</h3>
                                </div>
                            </div>
                            <Line data={mainChart.data} options={mainChart.options} height={80} />
                        </div>

                        <div className="multiGraph">
                            <div className="panel mr">
                                <p className="titulo">histórico mensal de faturamento (R$)</p>
                                <Bar data={faturamentoChart.data} options={faturamentoChart.options} height={100} />
                            </div>
                            <div className="panel">
                                <p className="titulo">histórico mensal de empréstimo</p>
                                <Bar data={pagamentosChart.data} options={pagamentosChart.options} height={100} />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </Fragment>
        )
    }
}