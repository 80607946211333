import {
    ADD_COMPANY,
    REMOVE_COMPANY,
    DELETE_ALL_COMPANYS,
    RETRIEVE_ALL_COMPANYS
} from "./types";


import CompanyDataService from "../Services/CompanyDataService";


export const addCompany = (obj) => async (dispatch) => {
    try {
      
      const res = await CompanyDataService.retrieve(obj);
      dispatch({
        type: ADD_COMPANY,
        payload: res,
      });
      return Promise.resolve( res);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  
  export const removeCompany = (obj) => async (dispatch) => {
    try {
      const res = await CompanyDataService.retrieve(obj);
      dispatch({
        type: REMOVE_COMPANY,
        payload: res,
      });
  
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteAllCompanys = () => async (dispatch) => {
    try {
  
      dispatch({
        type: DELETE_ALL_COMPANYS,
        payload: {  },
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const retrieveAllCompanys = () => async (dispatch) => {
    try {
      dispatch({
        type: RETRIEVE_ALL_COMPANYS,
        payload: {  },
      });
    } catch (err) {
      console.log(err);
    }
  };
  