//deve conter a chamada de api que recupera os dados
//import http from "../http-common";
import ApiService from '../Utils/services';
class DisconnectDataService {

  retrieve(obj){
    return obj;
    /*const userData = JSON.parse(localStorage.getItem('userData'));
    let url = "emprestimopessoal/" + id;
    let res = ApiService.getGeral(url).then(result => {
      const res = result.data;
      return res;
    })

    .catch(err => {
        const valida = ApiService.handleErros(err.response);
        if (valida.status === "unauthorized") {
            sessionStorage.removeItem('userData');
            //this.setState({ path: '/' });
        }
        if (valida.status === "serverError") {
           // this.setState({ path: '' });
        }

        if (valida.status === "badRequest") {
            //this.setState({ path: '' });
            console.log('badRequest');
        }
    })

    return res
    */
  }    

  getAll() {
    //return context;
    //realizar chamada de api retornando todos
    //return http.get("/tutorials");
  }

}
export default new DisconnectDataService();