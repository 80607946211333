import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import { cpfMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';

export default class RecoverCollaborator extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            empresa: '',
            colaborador: '',
            dados_colaborador: {},
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });
    }

    async carregaColaborador(acao) {
        const { colaborador } = this.state;
        this.setState({ loading: true });
        await ApiService.getGeral('funcionario/'+colaborador+'?quiz=true')
            .then(result => {
                const res = result.data.data;
                this.setState({ loading: false, dados_colaborador: res });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true, alertType: 2 });
            })
    }

    async alterarColaborador(tipo) {
        const { dados_colaborador } = this.state;
        this.setState({ loading: true });
        await ApiService.getGeral('funcionario/alterar/'+dados_colaborador.func_id+'?tipo='+tipo)
            .then(result => {
                const res = result.data.data;
                var msg;
                if(Number(tipo) === 1){
                    msg = 'A senha do colaborador foi alterada com sucesso! A nova senha é: '+res.codigo;
                }else{
                    msg = 'O PIN do colaborador foi alterado com sucesso! O novo PIN é: '+res.codigo;
                }
                this.setState({ loading: false, alertText: msg, path: "/colaboradores", alert: true, alertType: 1 });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true, alertType: 2 });
            })
    }

    handleFiltro(e) {
        const val = e.target.value;
        if (val.startsWith(0) || val.startsWith(1) || val.startsWith(2) || val.startsWith(3) || val.startsWith(4) || val.startsWith(5) || val.startsWith(6) || val.startsWith(7) ||
            val.startsWith(8) || val.startsWith(9)) {
            document.getElementById('filtro').setAttribute('maxlength', 14);
            this.setState({ colaborador: cpfMask(val) });
        } else {
            document.getElementById('filtro').removeAttribute('maxLength');
            this.setState({ colaborador: val });
        }
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, alertType, colaborador, dados_colaborador } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} tipo={alertType} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="colaboradores change mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Alterar  PIN</h1>
                                <p className="descricao">Altera o PIN do funcionário</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row" >
                                    <Input type="text" isFilterGroups={true} label="CPF do colaborador" value={colaborador}  id="filtro"
                                        onChange={e => this.handleFiltro(e)} />
                                    <button className="btn filtrar" onClick={() => this.carregaColaborador()}>Filtrar</button>
                                </div>
                            </div>
                        </div>
                        {dados_colaborador.func_id ? (<>
                            <div className="detalhes">
                                <div className="panel">
                                    <div className="nome">Funcionário: {dados_colaborador.func_nome}</div>
                                    <div className="pergunta">
                                        <div className="titulo">
                                            Qual lugar você trabalha?
                                        </div>
                                        <div className="resposta">
                                            {dados_colaborador.empresa.emp_nomefantasia}
                                        </div>
                                    </div>
                                    {dados_colaborador.quiz.map((campo, index) => {
                                        return (<>
                                            <div className="pergunta">
                                                <div className="titulo">
                                                    {campo.titulo}
                                                </div>
                                                <div className="resposta">
                                                    Resposta: {campo.valor}
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                </div>
                                <div className="acoes">
                                    {/*<button className="btn">Resetar Senha</button>*/}
                                    <button className="btn" onClick={() => this.alterarColaborador(2)}>Resetar PIN</button>
                                </div>
                            </div>
                        </>) : null}
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}