import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/img/icon-aviso.svg';
import iconCheck from '../../resources/img/icon-sucesso.svg';
import iconCopiar from '../../resources/img/icon-copiar.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { dataBrMask, periodoMask } from '../../Utils/mask';

export default class Maturity extends Component {

    constructor() {
        super();

        this.acaoSelecionavel = this.acaoSelecionavel.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            empresasOptions: [{ value: '', text: 'Selecione' }],
            gruposOptions: [{ value: '', text: 'Selecione' }],
            mesOptions: [{ value: '', text: 'Selecione' }],
            tipoBaixaOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Vencimento' },
                { value: 2, text: 'Boleto Vencido' },
            ],
            statusParcelaOptions: [
                {value: '', text: 'Selecione'},
                {value: 0, text: 'Á vencer'},
                {value: 2, text: 'Em cobrança'},
                {value: 3, text: 'Atrasada'}
            ],
            tipoBaixa: '',
            modalTipoBaixa: false,
            modalGeraBoleto: false,
            modalVpl: false,
            modalBoleto: false,
            btnGerar: false,
            novoValor: '',
            dtPagto: '',
            retornoBoletoGerado: {},
            table: {
                campos: [
                    { titulo: 'Nº Contrato', dado: 'numContrato' },
                    { titulo: 'Cliente/Colaborador', dado: 'cliColab' },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Parcela de Cobrança', dado: 'parcelaCobranca'},
                    { titulo: 'Parcelas Pagas', dado: 'parcelasPagas'},
                    { titulo: 'Valor da Parcela', dado: 'valParcela' },
                    { titulo: 'Status Parcela', dado: 'status', tdTagStyle: true },
                ],
                dados: []
            },
            vencimentosBackup: [],
            vencimentosDefault: {},
            exibeDados: false,
            empresa: '',
            grupo: '',
            mes: '',
            totalSelecionado: 0,
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.carregaGrupos();
        var i, objects = [{ value: '', text: 'Selecione' }];
        for (i = 0; i < 7; i++) {
            const date = new Date();
            const item = new Date(date.setMonth(date.getMonth() + i)).toJSON().split('T')[0];
            const separete = item.split('-');
            objects[i + 1] = { value: separete[0] + '-' + separete[1], text: separete[1] + '/' + separete[0] };
        }
        this.setState({ acoes, mesOptions: objects })
    }

    async carregaEmpresas(grupo) {
        this.setState({ loading: true, grupo: grupo });
        await ApiService.getGeral('empresa?gpec_id='+grupo+'&per_page=-1')
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions, loading: false });
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }], loading: false });
            })
    }

    async carregaGrupos() {
        this.setState({ loading: true });
        await ApiService.getGeral('grupoeconomico')
            .then(result => {
                const res = result.data.data;
                var gruposOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    gruposOptions[i + 1] = { value: item.gpec_id, text: item.gpec_nome };
                }
                this.setState({ gruposOptions, loading: false });
            })
            .catch(err => {
                this.setState({ gruposOptions: [{ value: '', text: 'Nenhum grupo encontrada!' }], loading: false });
            })
    }

    async carregaVencimentos() {
        const { table, empresa, mes, filtroStatus } = this.state;

        this.setState({ loading: true });

        let url =  "vencimentos?per_page=-1";

        if (filtroStatus) url += "&pparc_status=" + filtroStatus;
        if (empresa) url = url + "&emp_id=" + empresa;
        if (mes) url = url + "&periodo=" + Funcoes.data2Americano(mes);
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    let statusParcela;
                    if (item.pparc_status === 2) {
                        statusParcela = 'Vencimento warning';
                    } else if(item.pparc_status === 3) {
                        statusParcela ='Atrasada error';
                    }else if(item.pparc_status === 0){
                        statusParcela ='Á vencer inactive';
                    }else {
                        statusParcela ='N/A inactive';

                    }


                    objects[i] = {
                        id: item.pparc_id, numContrato: item.proposta.ppst_numeroccb ? item.proposta.ppst_numeroccb : '-', cliColab: item.proposta.funcionario.func_nome,
                        empresa: item.proposta.funcionario.empresa.emp_nomefantasia,
                        parcelaCobranca: Funcoes.completarNumero(item.pparc_numero),
                        status: statusParcela,
                        statusId: item.pparc_status,
                        parcelasPagas: Funcoes.completarNumero(item.ppst_parcelaspagas) + ' de ' + Funcoes.completarNumero(item.proposta.ppst_prazo),
                        valParcela: 'R$ ' + Funcoes.float2Preco(item.pparc_valor), valSelect: item.pparc_valor, proposta: item.proposta
                    };
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, vencimentosBackup: objects, vencimentosDefault: result.data });
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async gerarBoleto() {
        const { dtPagto, empresa, tipoBaixa, novoValor } = this.state;
       
        this.setState({ loading: true });
        
        if(tipoBaixa === ''){
            this.setState({ loading: false, alertText: "O tipo de baixa não foi selecionado", alert: true });
            return 0;
        }   

        if(dtPagto.length < 10)
        {
            this.setState({ loading: false, alertText: "A data de pagamento é inválida", alert: true });
            return 0;
        }
    
        var checkboxs, parcelas = [], i;
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                parcelas.push(checkboxs[i].getAttribute('rel'));
        }
        const form = {
            emp_id: empresa,
            parcelas: parcelas.join(),
            data_pagamento: Funcoes.data2Americano(dtPagto),
            tipo_baixa: tipoBaixa,
            valor_boleto: novoValor
        };
        await ApiService.postGeral('faturas/vencimento', form)
            .then(res => {
                this.setState({ retornoBoletoGerado: res.data.data, loading: false, modalVpl: false, modalTipoBaixa: false, modalBoleto: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    checaStatusDeParcelas(){
        const { table } = this.state;

        let checkboxs, parcelas = [], i;
        
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                parcelas.push(checkboxs[i].getAttribute('rel'));
        }
        let erro = false;

        parcelas.forEach(parcelaId => {
            let parcela = table.dados.find(x => x.id === parseInt(parcelaId));
            if(parcela.statusId === 0){
                erro = true;
            }  
        });
        
        if(erro){
            this.setState({ loading: false, modalTipoBaixa: false ,alertText: "Não é permitido gerar boletos para parcelas que estão Á vencer", alert: true });            
            return 0;
        }
        this.setState({modalTipoBaixa: true});
    }

    async calculaFatura() {
        const { dtPagto, empresa, tipoBaixa, totalSelecionado } = this.state;
        this.setState({ loading: true, modalTipoBaixa: false });
        //pagamento normal, o valor da parcela é pago cheio
        if(tipoBaixa === 1)
        {
            this.setState({ novoValor: totalSelecionado, loading: false, modalVpl: true });
            return 0;
        }
        var checkboxs, parcelas = [], i;
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                parcelas.push(checkboxs[i].getAttribute('rel'));
        }
        const form = {
            emp_id: empresa,
            pparc_id: parcelas.join(),
            data_pagamento: Funcoes.data2Americano(dtPagto)
        };
        await ApiService.postGeral('faturas/calculavalor', form)
            .then(result => result.data)
            .then(res => {
                console.log(res.data)
                this.setState({ novoValor: res.data.valor, loading: false, modalVpl: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async exportarVencimentos() {
        const { empresa, mes } = this.state;
        this.setState({ loading: true });
        var checkboxs, parcelas = [], i;
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                parcelas.push(checkboxs[i].getAttribute('rel'));
        }

        var url = '/geral/vencimentos?per_page=-1&export=true&pparc_id=' + parcelas.join();
        if (empresa) url = url + '&emp_id=' + empresa;
        if (mes) url = url + '&periodo=' + mes;

        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'vencimentos.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    updateValue(value)
    {          
        const { mes } = this.state;  
        let dataVencimento = "10/" + mes;
        this.setState({ tipoBaixa: value, dtPagto: dataVencimento});
    }

    acaoSelecionavel(e, header) {
        const { totalSelecionado } = this.state;
        if (header === 1) {
            const checkboxs = document.querySelectorAll('input[type="checkbox"]');
            var totalSelected = 0;
            var ativo;
            for (var i = 1; i < checkboxs.length; i++) {
                ativo = checkboxs[i].checked;
                totalSelected += parseFloat(checkboxs[i].value);
            }
            this.setState({ totalSelecionado: ativo ? totalSelected : 0 });
        } else {
            const total = parseFloat(e.target.value);
            this.setState({ totalSelecionado: e.target.checked ? total + totalSelecionado : totalSelecionado - total });
        }
        const checkStatus = document.querySelectorAll('input[type="checkbox"]:checked');
        this.setState({ btnGerar: checkStatus.length > 0 ? true : false });
    }

    handleSearch(colaborador) {
        const { table, vencimentosBackup } = this.state;
        var search = colaborador.toLowerCase(), txtValue, objects = [];
        vencimentosBackup.map((item, i) => {
            txtValue = item.proposta.funcionario.func_nome.toLowerCase();
            if (txtValue.indexOf(search) > -1) {
                objects.push(item);
            } else {
                if (objects[i]) objects.slice(i);
            }
        })
        this.setState({ table: { campos: [...table.campos], dados: objects } });
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, vencimentosDefault, exibeDados, tipoBaixaOptions, 
            tipoBaixa, empresasOptions, gruposOptions, filtroStatus, empresa, mes, grupo, totalSelecionado, modalTipoBaixa, 
            modalVpl, statusParcelaOptions, modalBoleto, btnGerar, novoValor, retornoBoletoGerado, dtPagto, acoes } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Modal show={modalTipoBaixa}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <p className="description">Informe o tipo de baixa para este boleto.</p>
                        <Input className="col-xs-12 col-ms-12 col-md-12 col-lg-12" type="select" optionsData={tipoBaixaOptions} label="Tipo de baixa" value={tipoBaixa}
                            onChange={e => this.updateValue(e.target.value)} />
                        <Input type="tel" label="Data de Pagamento" placeholder="dd/mm/aaaa" disabled={tipoBaixa === 1 ? true : false} value={dtPagto} className={tipoBaixa === 1 ? "custom disabled" : "custom"} maxLength="10"
                            onChange={e => this.setState({ dtPagto: dataBrMask(e.target.value) })} />   
                          <div className="cancelamentoAcao">
                            <button className="btn"
                                style={tipoBaixa !== '' && dtPagto.length >=10  ? { backgroundColor: '#EA5353', color: '#FFF' } : { backgroundColor: '#999' }}
                                onClick={() =>  this.calculaFatura()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalTipoBaixa: false, tipoBaixa: '', dtPagto: "" })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalVpl}>
                    <div className="modal-tipobaixa">
                        <p>O valor atualizado da fatura é de</p>
                        <h2>R$ {Funcoes.float2Preco(novoValor)}</h2>
                        <p>Confirmar geração do boleto?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                onClick={() => this.gerarBoleto()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalVpl: false})}>Cancelar</button>
                        </div>
                    </div>

                </Modal>
                <Modal show={modalBoleto}>
                    <div className="">
                         <div>
                            <button className="close"  onClick={() => this.setState({ modalBoleto: false })}>X</button>
                        </div>
                        <img src={iconCheck} alt="Ícone de aviso" />
                        <p className="description">O seu boleto foi gerado com sucesso!</p>
                        <p style={{ fontSize: 14, marginBottom: 5 }}>Linha Digitável</p>
                        <div className="boletoGerado">
                            <Input value={retornoBoletoGerado.linha_digitavel} className="custom" id="linhaDigitavel" style={{ fontSize: 16 }} readOnly={true}
                                onClick={() => document.getElementById("linhaDigitavel").select()} />
                            <button onClick={() => {
                                const input = document.getElementById("linhaDigitavel");
                                input.select();
                                input.setSelectionRange(0, 99999)
                                document.execCommand("copy");
                            }}>
                                <img src={iconCopiar} alt="Copiar linha digitável" title="Copiar linha digitável"></img>
                            </button>
                        </div>
                        <button className="btn visualizaBoleto" onClick={() => window.open(retornoBoletoGerado.url_boleto, '_blank')}>Visualizar boleto</button>
                    </div>
                </Modal>
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={6} />

                <div className="body">
                    <Header />

                    <div className="vencimentos mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Vencimentos</h1>
                                <p className="descricao">Consulte abaixo todos os vencimentos de parcelas de contratos.</p>
                            </div>
                            
                                {acoes.indexOf(13) !== -1 ?
                                    <button className="btn" onClick={() => btnGerar ? this.checaStatusDeParcelas() :
                                        this.setState({ alertText: 'Selecione um ou mais vencimentos para continuar', path: null, alert: true })}>Gerar Boleto</button>
                                    : null}
                                {acoes.indexOf(14) !== -1 ?
                                    <button className="btn btnAzul" onClick={() => btnGerar ? this.exportarVencimentos() :
                                        this.setState({ alertText: 'Selecione um ou mais vencimentos para continuar', path: null, alert: true })}>Exportar (.xlsx)</button>
                                    : null}
                            
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="text" isFilterGroups={true} label="Cliente/Colaborador" 
                                        onChange={e => this.handleSearch(e.target.value)} />
                                    <Input type="select" isFilterGroups={true} optionsData={gruposOptions} label="Grupo Econômico" value={grupo}
                                        onChange={e => this.carregaEmpresas(e.target.value) } />
                                    {grupo ? (<>
                                        <Input type="select" isFilterGroups={true} optionsData={empresasOptions} label="Empresa" value={empresa}
                                            onChange={e => this.setState({ empresa: e.target.value })} />
                                    </>) : null}
                                    <Input type="tel" isFilterGroups={true}  label="Mês" placeholder="mm/aaaa"  value={mes} maxLength="7"
                                        onChange={e => this.setState({ mes: periodoMask(e.target.value) })} /> 
                                    <Input type="select" isFilterGroups={true} optionsData={statusParcelaOptions} label="Status Parcela" value={filtroStatus} 
                                        onChange = {e => this.setState({filtroStatus: e.target.value})}/>
                                    <button className="btn filtrar" onClick={() => empresa && mes ? this.carregaVencimentos() :
                                        this.setState({ alertText: 'Selecione um período e uma empresa para continuar!', path: null, alert: true })}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">

                            <div className="panel">
                                <div className="panelBody">
                                    {exibeDados ? (
                                        <Table campos={table.campos}
                                            dados={table.dados}
                                            selecionavel={true}
                                            acaoSelecionavel={this.acaoSelecionavel} />
                                    ) : null}
                                </div>
                            </div>

                            <div className="info">
                                {exibeDados ? (<>
                                    <p>Total de registros: {vencimentosDefault.total}</p>
                                    <p style={{ marginLeft: 'auto' }}>Total selecionado: <span>R$ {Funcoes.float2Preco(totalSelecionado)}</span></p>
                                </>) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}