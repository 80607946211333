import axios from 'axios';
//Api de carga de colaboradores em python

const CargaApiType = process.env.REACT_APP_CARGA_API_TYPE;
const cargaApiUrl =  CargaApiType === "production" ? process.env.REACT_APP_CARGA_API_URL_PRD : process.env.REACT_APP_CARGA_API_URL_DEV;

export default class CargaService {

    static cargaApiUrl() {
        return cargaApiUrl;
    }

    static getHeaders() {
        return {
            "Access-Control-Allow-Origin": "*",
            "Content-type": 'application/json',
            'Accept': 'application/json'
        }
    }
    
    static getHeadersMultiformData() {
        return {
            "Access-Control-Allow-Origin": "*",
            "Content-type": 'multipart/form-data',
            'Accept': 'application/json'
        }
    }

    static get(url) {
        const options = {
            url: `${cargaApiUrl}/${url}`,
            method: 'GET',
            headers: this.getHeaders()
        };
        return axios(options);
    }

    static postGeral(url, dataPost) {
        const options = {
            url: `${cargaApiUrl}/${url}`,
            method: 'POST',
            headers: this.getHeaders(),
            data: dataPost
        };
        return axios(options);
    }

    static postMultiformData(url, dataPost) {
        const options = {
            url: `${cargaApiUrl}/${url}`,
            method: 'POST',
            headers: this.getHeadersMultiformData(),
            data: dataPost
        };
        return axios(options);
    }

    static customRequest(url, metodo, dados, token, employer) {
        const options = {
            url: `${cargaApiUrl}/${url}`,
            method: metodo,
            headers: this.getHeaders(),
            data: dados ? dados : {}
        }
        return axios(options);
    }

    static handleErros(response) {
        if (response.status === 400) {
            return { status: 'badRequest', response: response.data.mensagem }
        } else if (response.status === 401) {
            return { status: 'unauthorized', response: 'Ops, parece que sua sessão expirou, faça um novo login para continuar!' }
        }else if (response.status === 422) {
            let error_message = 'Falha na requisição,\n'
            Object.keys( response.data.errors).map(function(key, index) {
                error_message += response.data.errors[key][0] +'\n';
              });
        
            return { status: 'badRequest', response: error_message }
        } else {
            return { status: 'serverError', response: 'Ops, ocorreu um erro interno em nosso servidor!' }
        }
    }
}