import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { cpfMask, onlyNumbersMask, celularMask, dataBrMask, cepMask, moneyMask } from '../../Utils/mask';
import Modal from '../../Components/Modal';
import iconAviso from '../../resources/img/icon-aviso.svg';
import Table from '../../Components/Table';

import { Link } from 'react-router-dom';

export default class CollaboratorSimulation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parcelas: [],
            tableContratos: {
                campos: [
                    { titulo: 'CCB', dado: 'numContrato', thStyle: { minWidth: 60 } },
                    { titulo: 'Valor', dado: 'valEmprestado', thStyle: { textAlign: 'right', minWidth: 200 }, tdStyle: { textAlign: 'right' } },
                    { titulo: 'Parcelas', dado: 'parcela', thStyle: { minWidth: 150, textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Status', dado: 'tag', thStyle: { minWidth: 100 } , tdStyle: { width: 100 } },
                    { titulo: 'Ação', dado: 'select', thStyle: { minWidth: 100 } , tdStyle: { width: 100 } },

                ],
                dados: []
            },
            selectDefault : {
                    options: [
                    { value: 0, text: 'Não simular quitado'},
                    { value: 1, text: 'Simular quitado' },
                    ],
                    selected: 0
            },
            valor: '',
            limitePreAprovado: '',
            limiteDisponivelGeral: ''
            
        }

    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ loading: true });
            this.carregaContratos();
            
        }
    }

    async carregaContratos(){
        const {tableContratos, selectDefault} = this.state;
        const { colaborador } = this.props.location.state;
        var url;
        url = "contratoSimulacao";
        if (colaborador) url = url + "?func_id=" + colaborador.id;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    
                    objects[i] = {
                        id: item.ppst_id, 
                        numContrato: item.ppst_numeroccb ? item.ppst_numeroccb : '-', cliColab: item.funcionario.func_nome.toUpperCase(),
                        tipoContrato: item.ppst_tipo === 1 ? 'Empréstimo' : 'Refinanciamento',
                        empresa: item.funcionario.empresa.emp_nomefantasia.toUpperCase(),
                        valEmprestado: 'R$ ' + Funcoes.float2Preco(item.ppst_valorsolicitado),
                        parcela: Funcoes.completarNumero(item.ppst_parcelaspagas) + ' de ' + Funcoes.completarNumero(item.ppst_prazo), 
                        data: item.ppst_dthrsolicitacao ? Funcoes.data2Brazilian(item.ppst_dthrsolicitacao.split(' ')[0]) : '',
                        tag: item.ppst_status === 5 ? 'Finalizado' : item.ppst_status === 6 ? 'Recusado' : item.ppst_status === 7 ? 'Cancelado' : 'Aberto',
                        select: selectDefault
                    };
                }
                console.log(objects);
                if (res.length > 0) {
                    this.setState({ exibeDados: true, mostraVazio: false });
                } else {
                    this.setState({  mostraVazio: true });
                }
                this.setState({ loading: false,tableContratos: { campos: [...tableContratos.campos], dados: objects }, 
                    propostasDefault: result.data, cpf: colaborador.cpf,celular: colaborador.celular,
                    dtnascimento: colaborador.dtnascimento });
            })
            .catch(err => {
                console.log(err)
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async simuladorDeCredito(){
        const userData = JSON.parse(localStorage.getItem('userData'));
        const { colaborador } = this.props.location.state;
        const {valor, tableContratos} = this.state;

        this.setState({ loading: true});

        let dados = tableContratos.dados;
        var selects = document.querySelectorAll('select');
        selects = Array.from(selects).map(a => a.value == 1)

        let propostas = [] ;
        selects.forEach(function(status, i) {
                            if(status){
                                propostas.push(dados[i].id)
                            }
                        });

        propostas = propostas.join(' ')
        this.setState({ loading: true });
        let dataPost = {
            cpf: colaborador.cpf,
            dtnascimento: Funcoes.data2Americano(colaborador.dtNascimento),
            telefone: colaborador.celular,
            valor: Funcoes.preco2float(valor,"R$"),
            propostas: propostas
        };

       await ApiService.customRequest('proposta/simulacaocreditobackoffice', 'POST', dataPost)
        .then(result => {
           
            let jsonParcelas = JSON.parse(result.data.simulacao);
            if(jsonParcelas.erro){
                this.setState({ loading: false, alertText: jsonParcelas.mensagem, alert: true });
                return 0;
            }
            let parcelas = jsonParcelas.data;
            parcelas.forEach(parcela => {
                let valorParcela = "R$" + Funcoes.float2Preco(parcela.valorparcela)
                console.log(valorParcela)
                parcela.valorparcela = valorParcela;
            });
            let limitePreAprovado = "R$ "+ Funcoes.float2Preco( result.data.limitePreAprovado);
            let limiteDisponivelGeral ="R$ " +  Funcoes.float2Preco(result.data.limiteDisponivelGeral);
            this.setState({ loading: false, showModalSimulacao: false, showModalParcelas: true, parcelas: parcelas, limitePreAprovado: limitePreAprovado, limiteDisponivelGeral: limiteDisponivelGeral });
            
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
        
        this.setState({ loading: false}) 
        
    }

    voltar() {
        this.setState({ path: '/colaboradores/editar', propsToPath: this.props.location.state, redirect: true });
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, alertType,  
            showModalParcelas, valor, parcelas, tableContratos, exibeDados, limitePreAprovado,limiteDisponivelGeral, propostasDefault } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
               
                <Modal show={showModalParcelas} largura = {700}>
                        <p className="description">Resultado da simulação.</p>
                        <p className="description">Limite geral disponível: <b>{limiteDisponivelGeral}</b> </p>
                        <p className="description">Limite pre aprovado disponível: <b>{limitePreAprovado}</b> </p>
                        <p className="description">Valor Solicitado: <b>{valor}</b> </p>
                        <div className="modal-parcelas-edit-colaboradores ">
                            <div className="">
                            <div className=""> 
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" align>Nº de Parcelas</th>
                                                        <th className="text-center" align>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                {parcelas.map((parcela, i) => {                                 
                                    return (
                                    <Fragment>
                                        
                                        <tr>
                                            <td key={i}>{parcela.parcelas}</td>
                                            <td key={i}>{parcela.valorparcela}</td>
                                        </tr>
                                                
                                    </Fragment>) 
                                })}
                                        </tbody>                                    
                                    </table>                
                                </div>    
                            </div>
                        </div>    
                        <div className="cancelamentoAcao">
                            <button className="btn"
                                style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                onClick={() => this.setState({ showModalParcelas: false })}>Confirmar</button>
                        </div>
                </Modal>
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="editar-colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Simulador de crédito</h1>
                                <p className="descricao">Parametrize abaixo a simulação.</p>
                            </div>
                        </div>
                        <div className="panel">
                           <div className="panel-contratos-simulacao detalhes">
                                {exibeDados ? (
                                    <Table campos={tableContratos.campos}
                                        dados={tableContratos.dados}
                                    />
                                ) : null}
                            </div>   
                        </div>  
                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Bancários</h3>
                            </div>   
                            <p className="description">Informe o valor da simulação.</p>
                            <Input type="text" placeholder="R$ 0,00" value={valor} className="custom"
                                onChange={e => this.setState({ valor: moneyMask(e.target.value) })} />
                        </div> 
                       
                        <div className="acoes">
                        <button className="btn btnVoltar" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.voltar()}>{'<'} Voltar</button>
                                <button className="btn btnCadastrar" style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                    onClick={() => this.simuladorDeCredito()}>Confirmar</button>
            
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}