import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';

export default class NewProfileAccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            alertType: 2,
            nome: '',
            menus: []
        };
    }

    componentDidMount() {
        this.listaMenus();
    }

    async listaMenus() {
        this.setState({ loading: true });
        await ApiService.getGeral('menus')
            .then(result => {
                const res = result.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    objects.push({ ...res[i], checado: res[i].bmnu_padrao === 1 ? true : false });
                }
                this.setState({ menus: objects, loading: this.props.location.state ? true : false });
                if (this.props.location.state) this.exibePerfil();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    async exibePerfil() {
        const { menus } = this.state;
        const { perfil } = this.props.location.state;
        await ApiService.getGeral('perfilacesso/' + perfil.id)
            .then(result => {
                const res = result.data.data[0];
                console.log(res.menus);
                for (var x = 0; x < menus.length; x++) {
                    for (var i = 0; i < res.menus.length; i++) {
                        const item = res.menus[i];
                        if (item.bmnu_id === menus[x].bmnu_id) {
                            menus[x].checado = true;
                        }
                    }
                    for (var y = 0; y < res.acoes.length; y++) {
                        if (res.acoes[y].bmnu_id === menus[x].bmnu_id) {
                            for (var z = 0; z < menus[x].acoes.length; z++) {
                                if (menus[x].acoes[z].acmnu_id === res.acoes[y].acmnu_id) menus[x].acoes[z].checado = true;
                            }
                        }
                    }
                }
                console.log(menus);
                this.setState({ nome: res.perf_nome, menus, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    validaCampos() {
        const { nome } = this.state;
        const menuForm = document.getElementsByClassName('menuCheck');
        var menus = [];
        for (var i = 0; i < menuForm.length; i++) {
            const item = menuForm[i];
            if (item.checked) {
                menus[i] = { bmnu_id: item.value, acoes: [] }
                const acaoForm = document.getElementsByClassName(`acoes[${i}]`);
                for (var x = 0; x < acaoForm.length; x++) {
                    const acao = acaoForm[x];
                    if (acao.checked) menus[i].acoes.push({ acmnu_id: acao.value });
                }
            }
        }
        if (nome && menus.length > 0) {
            const menusEnvia = [];
            for (var y = 0; y < menus.length; y++) {
                if (menus[y] !== undefined) menusEnvia.push(menus[y]);
            }
            this.setState({ loading: true });
            const formData = {
                "perf_nome": nome,
                "menus": menusEnvia
            };
            if (this.props.location.state) {
                this.editarPerfil(formData);
            } else {
                this.incluirPerfil(formData);
            }
        } else {
            if (menus.length === 0) this.setState({ alertMessage: 'Escolha pelo menos um menu!', alert: true });
            if (!nome) this.setState({ alertMessage: 'O campo Nome do Perfil não pode ficar vazio!', alert: true });
        }
    }

    async incluirPerfil(form) {
        await ApiService.postGeral('perfilacesso', form)
            .then(res => {
                this.setState({ path: '/perfis-acesso', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    async editarPerfil(form) {
        const { perfil } = this.props.location.state;
        await ApiService.putGeral('perfilacesso/' + perfil.id, form)
            .then(res => {
                this.setState({ alertMessage: 'Perfil alterado com sucesso', alertType: 1, loading: false, alert: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    handleChange(index, indexFilho) {
        const { menus } = this.state;
        for (var i = 0; i < menus.length; i++) {
            if (indexFilho !== undefined) {
                for (var x = 0; x < menus[i].acoes.length; x++) {
                    if (i === index && x === indexFilho) {
                        menus[i].acoes[x].checado = !menus[i].acoes[x].checado
                    }
                }
            } else {
                if (i === index) {
                    if (menus[i].bmnu_padrao !== 1)
                        menus[i].checado = !menus[i].checado
                }
            }
        }
        this.setState({ menus: menus });
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, alertType, nome, menus } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={12} />

                <div className="body">
                    <Header />

                    <div className="configuracao mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>{this.props.location.state ? 'Editar' : 'Novo'} Perfil de Usuário</h1>
                                <p className="descricao">{this.props.location.state ? 'Editar' : 'Incluir novo'} perfil de acesso dos usuários.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Informações</h3>
                            </div>
                            <div className="panelBody menus">
                                <div className="multiInput" style={{ marginBottom: 20 }}>
                                    <Input type="text" label="Nome do Perfil" value={nome} onChange={e => this.setState({ nome: e.target.value })} style={{ maxWidth: 400 }} />
                                </div>
                                {menus.map((item, idx) => {
                                    return (
                                        <div className="menu" key={idx}>
                                            <p style={{ fontSize: 16, fontWeight: '500' }}>{item.bmnu_nome}</p>
                                            <div>
                                                <label style={{ marginRight: 10, marginLeft: 10 }}>
                                                    <input type="checkbox" name={`menu[${idx}]`} className="menuCheck" value={item.bmnu_id} defaultChecked={item.bmnu_padrao}
                                                        checked={item.checado ? true : false} onChange={e => this.handleChange(idx)} /> Visualizar
                                                </label>
                                                {item.acoes.map((acao, i) => {
                                                    return (
                                                        <label key={i + "a"} style={{ marginRight: 10 }}>
                                                            <input type="checkbox" name={`acoes[${idx}]`} className={`acoes[${idx}]`} value={acao.acmnu_id}
                                                                checked={acao.checado ? true : false} onChange={e => this.handleChange(idx, i)} /> {acao.acmnu_nome}
                                                        </label>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="actions">
                            <Link to="/perfis-acesso" className="btn btnVoltar">{'<'} Voltar</Link>
                            <button className="btn bg-roxo cl-branco" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Salvar'}</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}