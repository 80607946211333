import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Button from '../../Components/Button';
import PageBottomActions from '../../Components/PageBottomActions';
import Summary from '../../Components/Summary/Summary';
import Panel from '../../Components/Panel';
import Row from '../../Components/Row';

import Loading from '../../Components/LoaderScreen';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';



export default class NewDeadLine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            propsToPath: null,
            path: '',
            alert: false,
            alertText: '',
            nomeRegua: '',
            intervalos: [

            ],
            tableIntervalo: {
                campos: [
                    { titulo: 'De', dado: 'pra_intervalode' },
                    { titulo: 'Até', dado: 'pra_intervaloate' },
                    { titulo: 'Prazo máximo', dado: 'pra_prazo_maximo' },
                    { titulo: 'Ações', dado: 'acoes'}
                ],
                dados: []
            },
            politicaId: undefined,
            idReguaPrazo: undefined
        };
    }

    componentDidMount() 
    {
        this.setState({politicaId: this.props.location.state.politica.id})
        
        if (this.props.location.state.reguaPrazo) {
            let idReguaPrazo = this.props.location.state.reguaPrazo;
            this.setState({idReguaPrazo: idReguaPrazo, loading: true})
            this.carregaReguaPrazo(idReguaPrazo);
        }

    }

    async carregaReguaPrazo(id)
    {
        let url = 'reguaprazo/' + id;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                let prazos = res.grupo_prazo.map( (item) => {
                    return item;
                })
                this.setState({ 
                    nomeRegua: res.rep_descricao,
                    intervalos: prazos,
                    loading: false});
            })
            .catch(err => {
               
            })
    }

    async atualizaReguaPrazo(form){
        const { idReguaPrazo } = this.state;
        await ApiService.putGeral('reguaprazo/' + idReguaPrazo, form)
        .then(res => {
            this.setState({ loading: false,  alertText: 'Régua de prazo atualizado com sucesso!', alert: true , 
            alertType: 1});
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }


    validaCampos() {
        const { idReguaPrazo, nomeRegua, intervalos } = this.state;
        let form = [
            { campo: 'Nome', attr: 'nomeRegua', valor: nomeRegua },
            { campo: 'Intervalo', attr: 'intervalos', valor: intervalos, minLength: 1 }

        ];

        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            const formData = {
                regua: {
                    rep_descricao: nomeRegua,
                },
                intervalos: intervalos
            }

            if(idReguaPrazo){
                this.atualizaReguaPrazo(formData);
            }else{
                this.novaRegua(formData);
            }
            
            
        }

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async removerIntervalo(index){
        const { intervalos } = this.state;
        intervalos.splice(index, 1);
        this.setState({intervalos: intervalos});
    }

    async novoIntervalo(){
        const { idReguaPrazo ,intervalos } = this.state;
        if(idReguaPrazo){
            intervalos.push({rep_id: idReguaPrazo,pra_intervalode: 0, pra_intervaloate: 0, pra_prazo_maximo: 0});
        }else{
            intervalos.push({pra_intervalode: 0, pra_intervaloate: 0, pra_prazo_maximo: 0});
        }
        
        this.setState({intervalos: intervalos});
    }

    async novaRegua(form) {

        await ApiService.postGeral('prazo', form)
            .then(res => {
                this.setState({ idReguaPrazo: res.data.data.rep_id, loading: false,  alertText: 'Régua de prazo cadastrada com sucesso!', alert: true , 
                alertType: 1});
                this.carregaReguaPrazo(res.data.data.rep_id);
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleValor(e, index, campo){
        const { intervalos } = this.state;
        var newValores = [];
        for(var i = 0; i < intervalos.length; i++){
            newValores[i] = intervalos[i];
            if(i === index){
                if(campo === "pra_intervalode"){
                    newValores[i].pra_intervalode = e.target.value;
                }else if(campo === "pra_intervaloate"){
                    newValores[i].pra_intervaloate = e.target.value;
                }else if(campo === "pra_prazo_maximo"){
                    newValores[i].pra_prazo_maximo = e.target.value;
                }
            }
        }
        this.setState({ intervalos: newValores });
    }
    render() {
        const { loading, alertType,redirect, path, propsToPath, alert, alertText,nomeRegua, intervalos,
        idReguaPrazo } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} tipo={alertType} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-nova mgDefault">
                        <Summary title={idReguaPrazo ? 'Editar régua de prazo' : 'Nova régua de prazo'} 
                            description="Parametrize a régua de prazo." />
                        
                        <Panel header="Dados Cadastrais">
                            <Input type="text" label="Nome" value={nomeRegua} divStyle={{ flexGrow: 10 }}
                                onChange={e => this.setState({nomeRegua: e.target.value})} /> 
                        </Panel>

                        <Panel header="Intervalos">                          
                                {intervalos.map((campo,index) => {
                                    return (<>
                                        <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                            <Row>
                                                <Input isFilterGroups={true} type="number" label="De" className="tx" id={index+'-inicial'} value={campo.pra_intervalode} onChange={e => this.handleValor(e, index, 'pra_intervalode')} placeholder="0" />
                                                <Input isFilterGroups={true} type="number" label="Até" className="tx" id={index+'-final'}  value={campo.pra_intervaloate} onChange={e => this.handleValor(e, index, 'pra_intervaloate')}  placeholder="0" />
                                                <Input isFilterGroups={true} type="number" label="Máximo de Parcelas" maxLength="2" value={campo.pra_prazo_maximo} onChange={e => this.handleValor(e, index, 'pra_prazo_maximo')} style={{ marginRight: 0 }} placeholder="0" />
                                                <Button name="Excluir" isFilterGroups={true} action={() => this.removerIntervalo(index)} />
                                            </Row>
                                        </div>
                                    </>)
                                })}
                                <Button name="Novo Intervalo" action={() => this.novoIntervalo()} />
                        </Panel>
                                
                        <PageBottomActions>
                            <Button type="back" name={'< Voltar'} action={() => this.setState({ path: '/politicas/novo', propsToPath: { politica: { id: this.state.politicaId } }, redirect: true })} />
                            <Button name={idReguaPrazo ? 'Alterar' : 'Cadastrar'} action={() => this.validaCampos()}/>
                        </PageBottomActions>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
    
}