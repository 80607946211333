import React, { Component, Fragment } from 'react';
//import {Row,Col} from 'react-bootstrap';
import iconAviso from '../../resources/img/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Summary from '../../Components/Summary/Summary';
import Panel from '../../Components/Panel';
import Row from '../../Components/Row';
import Button from '../../Components/Button';
import PageBottomActions from '../../Components/PageBottomActions';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import { onlyNumbersMask, moneyMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';
import { validaForm } from '../../Utils/FormValidator';

export default class NewPoliticy extends Component {

    constructor(props) {
        super(props);
        this.acaoVisualizar = this.acaoVisualizar.bind(this);
        this.acaoSelecionavel = this.acaoSelecionavel.bind(this);
        this.acaoEditarIntervaloDeEscalonamentoDeParcela = this.acaoEditarIntervaloDeEscalonamentoDeParcela.bind(this);
        this.acaoEditarIntervaloDePrazo = this.acaoEditarIntervaloDePrazo.bind(this);
        this.acaoEditarIntervaloDeRating = this.acaoEditarIntervaloDeRating.bind(this);
        this.acaoEditarPenalidade = this.acaoEditarPenalidade.bind(this);

        this.state = {
            redirect: false,
            path: '',
            modalPenalidade: false,
            modalIntervaloEscalonamento: false,
            modalIntervaloPrazo: false,
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: '',
            acaoLabel: "Alterar",
            //dados cadastrais
            politicaId: undefined,
            nomePolitica: "",
            descricaoPolitica: "",
            tac: 0.0,
            diasAprovacao: 5,
            diasToleranciaRefinanciamento: 7,
            diaFechaFolha: 20,
            diaPagamento: 10,
            idadeMaxima: 65,
            execaoParcelaMaxima: 12,
            parcelaMaxima: 48,
            parcelaMinima: 6,
            taxaDeJurosMaxima: '99,00',
            taxaDeJurosMinima: '1,29',
            limiteComprometimento: '',
            aprovacaoAutomatica: false,
            carregouPolitica: false,
            penalidadeAtual: "",
            contrato: false,
            penalidades: [],
            tablePenalidade: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: 'Data/Hora', dado: 'datahora' },
                    { titulo: 'Ações', dado: 'acoes'}
                ],
                dados: []
            },
            tablePenalidadeIntervalos: {
                campos: [
                    { titulo: 'Intervalo', dado: 'intervalo' },
                    { titulo: 'Penalidade', dado: 'penalidade' },
                ],
                dados: []
            },
            tableRating: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: 'Taxa de Juros', dado: 'taxa' },
                    { titulo: 'Intervalo', dado: 'intervalo' },
                    { titulo: 'Data/Hora', dado: 'datahora' },
                    { titulo: 'Ações', dado: 'acoes'}

                ],
                dados: []
            },
            reguaPrazo : '',
            reguaRating: '',
            reguaEscalonamentoDeParcela: '',
            reguaPrazoOptions: [],
            reguaRatingOptions: [],
            reguaEscalonamentoParcelaOptions: [],
            tableReguaEscalonamento: {
                campos: [
                    { titulo: 'De', dado: 'intervaloade' },
                    { titulo: 'Até', dado: 'intervaloate' },
                    { titulo: 'Prazo máximo', dado: 'prazo' },
                    { titulo: 'Ações', dado: 'acoes'}
                ],
                dados: []
            },
            tablePrazo: {
                campos: [
                    { titulo: 'De', dado: 'pra_intervalode' },
                    { titulo: 'Até', dado: 'pra_intervaloate' },
                    { titulo: 'Prazo máximo', dado: 'pra_prazo_maximo' },
                    { titulo: 'Ações', dado: 'acoes'}
                ],
                dados: []
            },
            penalidadeIntervalos: [],
            exibeDados: false,
            exibeIntervaloDeEscalonamento: false,
            idIntervaloEscalonamentoEditado: undefined,
            idIntervaloPrazo: undefined,
            idIntervaloDeRating: undefined,
            mostraVazio: false,
            edufinDefault: {},
            ratingDefault: {},
            penalidadesDefault: {},
            prazoDefault: {},
            titulo: '',
            ativo: '',
            data: '',
            acoes: [],
            idPost: '',

            actionsRatingPanel: [
                {name: "Novo Rating", function: () => this.setState({ path: '/rating/novo',  propsToPath:  { politica: { id: this.state.politicaId } }, redirect: true })}
            ],
            actionsPenalidadesPanel: [
                {name: "Nova Penalidade", function: () =>  this.setState({ path: '/penalidade/novo', propsToPath:  { politica: { id: this.state.politicaId } }, redirect: true })}
            ],
            actionsPrazoPanel: [
                {name: "Novo Prazo", function: ()=> this.setState({ path: '/prazo/novo', propsToPath:  { politica: { id: this.state.politicaId } }, redirect: true })}
            ],
            actionsEscalonamentoPanel: [
                {name: "Nova Régua", function: ()=> this.setState({ path: '/regua-de-escalonamento/novo',  propsToPath:  { politica: { id: this.state.politicaId } }, redirect: true })}

            ]
        };
    }

    componentDidMount() {
        console.log(this.props.location.state)
        let politicaId = this.props.location.state?.politica.id;

        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);
        this.setState({loading: true});
        
        if (politicaId) {
            this.setState({politicaId: politicaId})
            this.carregaPolitica(politicaId);
        }else{
            this.carregaPenalidades();
            this.carregaReguaPrazo();
            this.carregaReguasDeEscalonamento();
            this.carregaReguaRating();
            this.carregaIntervalosDePrazo(1)
            this.carregaIntervalosDeEscalonamentoDeParcela(1)
            this.carregaIntervalosDeRating(1)

            this.setState({acaoLabel: "Cadastrar"});

        }
      
        this.setState({ acoes });

    }

    async carregaPolitica(id)
    {

        let url = 'politica/' + id;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;

                this.setState({
                    nomePolitica: res.pol_nome, descricaoPolitica: res.pol_descricao, diasAprovacao: res.pol_diasaprovacao,
                    diasToleranciaRefinanciamento: res.pol_diastoleranciarefinanciamento, diaFechaFolha: res.pol_fechafolha, 
                    diaPagamento: res.pol_diapagamento, idadeMaxima: res.pol_execao_maxima_idade ,execaoParcelaMaxima: res.pol_execao_maxima_parcela,
                    limiteComprometimento: res.pol_limitecomprometimento, aprovacaoAutomatica: res.pol_aprovacaoautomatica, 
                    contrato: res.pol_por_contrato,
                    carregouPolitica: true,
                    tac:   Funcoes.float2Preco(res.pol_tac),
                    reguaPrazo: res.politica_regua_prazo?.rep_id,
                    reguaRating: res.politica_regua_rating?.rer_id,
                    reguaEscalonamentoDeParcela: res.politica_regua_escalonamento?.reep_id,
                    penalidades: res.penalidades, parcelaMaxima: res.pol_max_parcela, parcelaMinima: res.pol_min_parcela,
                    taxaDeJurosMaxima: Funcoes.float2Preco(res.pol_max_taxa), 
                    taxaDeJurosMinima: Funcoes.float2Preco(res.pol_min_taxa)
                });
                this.carregaPenalidades();

                this.carregaReguaRating();
                this.carregaReguaPrazo();
                this.carregaReguasDeEscalonamento();

                if(res.politica_regua_prazo == null)
                {
                    this.carregaIntervalosDePrazo(1)
                }else{
                    this.carregaIntervalosDePrazo(res.politica_regua_prazo.rep_id)
                }

                //colocar if para intervalos de escalonamento
                if(res.politica_regua_escalonamento == null){
                    this.carregaIntervalosDeEscalonamentoDeParcela(1)
                }else{
                    this.carregaIntervalosDeEscalonamentoDeParcela(res.politica_regua_escalonamento.reep_id)
                }

                if(res.politica_regua_rating == null)
                {
                    this.carregaIntervalosDeRating(1)
                }else{
                    this.carregaIntervalosDeRating(res.politica_regua_rating.rer_id)
                }

            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });            })

    }

    async carregaPenalidades(){
        const { tablePenalidade } = this.state;
        let url = 'penalidade';
        //checar se pra cada penalidade no banco existe no array de penalidades se existir checar o select 
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data;
                let objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.pen_id, 
                        nome: item.pen_descricao,
                        datahora: Funcoes.dataHora2Brazilian(item.created_at),
                        intervalos: item.intervalos,
                        acoes: "default|regras"
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true,  mostraVazio: false, carregouPolitica: true });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ tablePenalidade: { campos: [...tablePenalidade.campos], dados: objects }, penalidadesDefault: result.data });
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })

            this.carregaPenalidadesDePolitica();

    }

    async carregaPenalidadesDePolitica()
    {
        const {penalidades } = this.state;

        penalidades.forEach(penalidade => {
            document.querySelector('#penalidade input[type="checkbox"][rel="'+ penalidade.pen_id +'"]').checked = true;
        });
        return 0;
    }

    async carregaReguaPrazo()
    {
        let url = 'reguaprazo';
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data;
                var reguaPrazoOptions = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    reguaPrazoOptions[i + 1] = { value: item.rep_id, text: item.rep_descricao };
                }
                this.setState({ reguaPrazoOptions: reguaPrazoOptions});
            })
            .catch(err => {
                this.setState({ reguaPrazoOptions: [{ value: '', text: 'Nenhuma régua de prazos encontrada!' }] });
            })
    }

    async carregaReguaRating()
    {
        let url = 'reguarating';
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data;
                var reguaRatingOptions = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    reguaRatingOptions[i + 1] = { value: item.rer_id, text: item.rer_descricao };
                }
                this.setState({ reguaRatingOptions: reguaRatingOptions});
            })
            .catch(err => {
                this.setState({ reguaRatingOptions: [{ value: '', text: 'Nenhuma régua de ratings encontrada!' }] });
            })
    }

    
    async carregaIntervalosDePrazo(value)
    {
        const { tablePrazo} = this.state;

        this.setState({loading: true});

        let url = 'prazo/' + value;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                let objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.pra_id, 
                        pra_intervalode: item.pra_intervalode + " meses",
                        pra_intervaloate: item.pra_intervaloate + " meses",
                        pra_prazo_maximo: item.pra_prazo_maximo + 'X',
                        acoes: "default"
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ tablePrazo: { campos: [...tablePrazo.campos], dados: objects }, prazoDefault: result.data, reguaPrazo: value });

            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });            })
    }
    async carregaReguasDeEscalonamento(){
        let url = 'regua_escalonamento_parcela';
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data;                
                var reguaEscalonamentoParcelaOptions = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    reguaEscalonamentoParcelaOptions[i + 1] = { value: item.reep_id, text: item.reep_descricao };
                }
                this.setState({ reguaEscalonamentoParcelaOptions: reguaEscalonamentoParcelaOptions});
            })
            .catch(err => {
                this.setState({ reguaEscalonamentoParcelaOptions: [{ value: '', text: 'Nenhuma régua de escalonamento encontrada!' }] });
            })
    }

    async carregaIntervalosDeEscalonamentoDeParcela(value)
    {
        const { tableReguaEscalonamento} = this.state;

        this.setState({loading: true});

        let url = 'intervalo_escalonamento_parcela/' + value;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                let objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.iep_id, 
                        intervaloade: "R$ " + Funcoes.float2Preco(item.iep_intervalode),
                        intervaloate: "R$ " + Funcoes.float2Preco(item.iep_intervaloate),
                        prazo: item.iep_prazo + "X",
                        acoes: "default"
                    };
                }
                
                this.setState({reguaEscalonamentoDeParcela: value ,tableReguaEscalonamento: { campos: [...tableReguaEscalonamento.campos], dados: objects }, exibeIntervaloDeEscalonamento: true, loading: false, mostraVazio: false });
                    
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });            })
    }

    async carregaIntervalosDeRating(value)
    {
        const { tableRating} = this.state;

        this.setState({loading: true});

        let url = 'reguarating/' + value;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data.grupo_rating;
                let objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i].rating;
                    objects[i] = {
                        id: item.ratg_id, 
                        nome: item.ratg_descricao,
                        taxa: item.ratg_valor,
                        intervalo: "De " + item.ratg_intervalode + " até " + item.ratg_intervaloate,
                        ratg_intervalode: item.ratg_intervalode,
                        ratg_intervaloate: item.ratg_intervaloate,
                        datahora: Funcoes.dataHora2Brazilian(item.created_at),
                        rer_id: item.rer_id,
                        acoes: "default"
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ tableRating: { campos: [...tableRating.campos], dados: objects }, prazoDefault: result.data, reguaRating: value });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });            })
    }


    acaoVisualizar(id)
    {
        const { tablePenalidade } = this.state;
        let filtro = tablePenalidade.dados.filter(function(element) {
            return element.id === id
        });
        let intervalos = filtro[0]["intervalos"]
        let penalidadeAtual = filtro[0]["nome"]
        let objects = [];
        for (var i = 0; i < intervalos.length; i++) {
            const item = intervalos[i];
            objects[i] = {
                intervalo: "De " + item.peni_intervalode + " até " + item.peni_intervaloate,
                penalidade: item.peni_valor,
            };
        }
        this.setState({modalPenalidade: true, penalidadeIntervalos: objects, penalidadeAtual: penalidadeAtual });

    }
    
    acaoEditarIntervaloDeRating(id){
        const {reguaRating} = this.state;
        this.setState({ path: '/rating/novo', propsToPath: {  reguaRating, politica: {id: this.state.politicaId}  }, redirect: true });
    }

    acaoEditarIntervaloDePrazo(id){
        const {reguaPrazo} = this.state;
        this.setState({ path: '/prazo/novo', propsToPath:  { reguaPrazo, politica: { id: this.state.politicaId } }, redirect: true })
    }

    acaoEditarPenalidade(id)
    {
        this.setState({ path: '/penalidade/novo', propsToPath:  { penalidade: {id: id}, politica: { id: this.state.politicaId } }, redirect: true })
    }

    acaoEditarIntervaloDeEscalonamentoDeParcela(id)
    {
        const {reguaEscalonamentoDeParcela} = this.state;
        this.setState({ path: '/regua-de-escalonamento/novo', propsToPath:  { reguaEscalonamentoDeParcela, politica: { id: this.state.politicaId } }, redirect: true })
    }
    //busca os itens selecionados de cada tabela e sobrescreve os arrays
    buscaSelecionados()
    {
        
        let checkBoxRatingsdocument = document.querySelectorAll('#rating input[type="checkbox"]:checked');
        let checkBoxPenalidadesdocument = document.querySelectorAll('#penalidade input[type="checkbox"]:checked');
        let ratingArr = [];
        let penalidadeArr = [];
        checkBoxRatingsdocument.forEach((e) => {
            ratingArr.push(parseInt(e.getAttribute('rel')));
          });
        checkBoxPenalidadesdocument.forEach((e) => {
            penalidadeArr.push(parseInt(e.getAttribute('rel')));
          });

          return {
            penalidades: penalidadeArr,
            ratings: ratingArr
          };
    }

    async acaoSelecionavel(e, header) {
        
    }

    validaCampos() {
        const { 
            nomePolitica, descricaoPolitica, diasAprovacao, diasToleranciaRefinanciamento, diaFechaFolha, diaPagamento, idadeMaxima,
            execaoParcelaMaxima, parcelaMaxima, parcelaMinima, taxaDeJurosMaxima, taxaDeJurosMinima, limiteComprometimento ,aprovacaoAutomatica,
            contrato, reguaRating, reguaPrazo, reguaEscalonamentoDeParcela, tac, politicaId
         } = this.state;
         let regras = this.buscaSelecionados();

        let form = [
            { campo: 'Nome de Política', attr: 'nomePolitica', valor: nomePolitica },
            { campo: 'Descrição de Politica', attr: 'descricaoPolitica', valor: descricaoPolitica },
            { campo: 'Dias para aprovação', attr: 'diasAprovacao', valor: diasAprovacao, minLength: 1 },
            { campo: 'Dias de tolerância de refinanciamento', attr: 'diasToleranciaRefinanciamento', valor: diasToleranciaRefinanciamento},
            { campo: 'Dia fechamento de folha', attr: 'diaFechaFolha', valor: diaFechaFolha},
            { campo: 'Dia de pagamento', attr: 'diaPagamento', valor: diaPagamento},
            { campo: 'idadeMaxima', attr: 'idadeMaxima', valor: idadeMaxima},
            { campo: 'Limite de comprometimento', attr: 'limiteComprometimento', valor: limiteComprometimento},
            { campo: 'Penalidades', attr: 'penalidades', valor: regras.penalidades, minLength: 1}

        ];

        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });


            const formData = {
                politica: {
                    pol_nome: nomePolitica,
                    pol_descricao: descricaoPolitica,
                    pol_fechafolha: diaFechaFolha,
                    pol_limitecomprometimento: limiteComprometimento,
                    pol_diasaprovacao: diasAprovacao,
                    pol_diastoleranciarefinanciamento: diasToleranciaRefinanciamento,
                    pol_aprovacaoautomatica: aprovacaoAutomatica,
                    pol_diapagamento: diaPagamento,
                    pol_execao_maxima_idade: idadeMaxima,
                    pol_execao_maxima_parcela: execaoParcelaMaxima,
                    pol_por_contrato: contrato,
                    pol_min_taxa:  Funcoes.preco2float(taxaDeJurosMinima),
                    pol_max_taxa:  Funcoes.preco2float(taxaDeJurosMaxima),
                    pol_tac: Funcoes.preco2float(tac),
                    pol_min_parcela: parcelaMinima,
                    pol_max_parcela: parcelaMaxima
                },
                penalidades: regras.penalidades,
                regua_rating: reguaRating,
                regua_prazo:  reguaPrazo,
                regua_escalonamento: reguaEscalonamentoDeParcela
            }

            if (politicaId) 
            {
                this.atualizarPolitica(formData);
            }
            else
            {
                this.novaPolitica(formData);
            }
        }

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaPolitica(form)
    {
        await ApiService.postGeral('politica', form)
        .then(result => {
            let politicaId = result.data.data.pol_id
            this.setState({politicaId: politicaId, loading: false, alertText: 'Política cadastrada com sucesso!', alertType: 1, acaoLabel: "Alterar", alert: true });
            this.carregaPolitica(politicaId);

        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    async atualizarPolitica(form)
    {        
        const { politicaId } = this.state;

        console.log("Atualizando poltiica")
        console.log(form)

        await ApiService.putGeral('politica/'+ politicaId, form)
        .then(result => {
            this.setState({ loading: false,  alertText: 'Política atualizada com sucesso!', alert: true ,alertType: 1, path: '/politicas/novo', propsToPath:  { politica: { id: politicaId } }, redirect: true});
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    voltar() {
        this.setState({ path: '/politicas', loading: false, redirect: true });
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText,alertType, nomePolitica, descricaoPolitica, 
            diasAprovacao,diasToleranciaRefinanciamento, penalidadeAtual,acaoLabel,
            idadeMaxima,execaoParcelaMaxima, aprovacaoAutomatica, limiteComprometimento,carregouPolitica,
            diaFechaFolha, diaPagamento, tablePenalidade, tableRating, tablePrazo, reguaPrazoOptions, reguaPrazo,tablePenalidadeIntervalos, penalidadeIntervalos,
            exibeDados, modalPenalidade, contrato, parcelaMaxima, parcelaMinima, taxaDeJurosMaxima, taxaDeJurosMinima, 
            tac, reguaRating, reguaRatingOptions, actionsRatingPanel, actionsPenalidadesPanel, actionsPrazoPanel, 
            actionsEscalonamentoPanel, reguaEscalonamentoDeParcela, reguaEscalonamentoParcelaOptions, tableReguaEscalonamento
        } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
    
                <Modal show={modalPenalidade} largura = {800}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de Aviso" />
                        <p className="warning">Intervalos da penalidade de {penalidadeAtual}.</p>
                        <div className="modal-parcelas-edit-colaboradores ">
                            <Table campos={tablePenalidadeIntervalos.campos}
                                        dados={penalidadeIntervalos}
                                    />
                        </div>
                        <div className="cancelamentoAcao">
                            <button 
                                className="btn"                                 
                                style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                onClick={() => this.setState({ modalPenalidade: false })}>Ok
                            </button>
                        </div>
                    </div>
                </Modal>
                
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                <div className="editar-colaboradores mgDefault">

                    <Summary title= "Nova Política" description="Parametriza abaixo a sua política"/>
                    <Panel header={"Dados cadastrais"}>
                        <Row>
                            <Input type="text" maxLength="45" isFilterGroups={true} label="Nome" value={nomePolitica} onChange={e => this.setState({nomePolitica: e.target.value })} />
                            <Input type="text" maxLength="100" isFilterGroups={true} label="Descrição" value={descricaoPolitica} divStyle={{ flexGrow: 5 }}  onChange={e => this.setState({descricaoPolitica: e.target.value} )} />
                        </Row>
                        
                        <Row>
                            <Input type="tel"   className="disabled" isFilterGroups={true} disabled label="Dias para aprovação" value={diasAprovacao} maxLength="2" divStyle={{ flexGrow: 5 }}  onChange={e => this.setState({diasAprovacao: onlyNumbersMask(e.target.value)})} />
                            <Input type="tel" label="Dia Fecha Folha" isFilterGroups={true} value={diaFechaFolha} maxLength="2" divStyle={{ flexGrow: 5 }}  onChange={e => this.setState({diaFechaFolha: onlyNumbersMask(e.target.value)})} />
                            <Input type="tel" label="Dia do pagamento" isFilterGroups={true} value={diaPagamento} maxLength="2" divStyle={{ flexGrow: 5 }} onChange={e => this.setState({diaPagamento: onlyNumbersMask(e.target.value)})} />
                        </Row>
                        <Row>
                            <Input type="tel" className="disabled" isFilterGroups={true} disabled label="Dias de tolerância para refinanciamento" value={diasToleranciaRefinanciamento} maxLength="2" divStyle={{ flexGrow: 5 }}  onChange={e => this.setState({diasToleranciaRefinanciamento: onlyNumbersMask(e.target.value)})} />
                            <Input type="tel" isFilterGroups={true} label="Idade limite" value={idadeMaxima} maxLength="3" onChange={e => this.setState({idadeMaxima: onlyNumbersMask(e.target.value)})} />
                            <Input type="tel" isFilterGroups={true} label="Parcela Máxima por idade" value={execaoParcelaMaxima}  onChange={e => this.setState({execaoParcelaMaxima: onlyNumbersMask(e.target.value)})} />
                        </Row>

                        <Row>
                            <Input type="tel" isFilterGroups={true} label="Limite de comprometimento" value={limiteComprometimento}  onChange={e => this.setState({limiteComprometimento: onlyNumbersMask(e.target.value)})} />
                            <Input type="tel" isFilterGroups={true} label="TAC" value={tac}  onChange={e => this.setState({tac: moneyMask(e.target.value, false)})} /> 
                        </Row>
                        

                        <Row>
                            <Input type="tel" label="Taxa de juros mínima" isFilterGroups={true} value={taxaDeJurosMinima}  onChange={e => this.setState({taxaDeJurosMinima: moneyMask(e.target.value, false)})} />
                            <Input type="tel" label="Taxa de juros máxima" isFilterGroups={true} value={taxaDeJurosMaxima}  onChange={e => this.setState({taxaDeJurosMaxima: moneyMask(e.target.value, false)})} />
                        </Row>
                        <Row>
                            <Input type="tel" label="Mínima de parcelas" isFilterGroups={true} value={parcelaMinima}  onChange={e => this.setState({parcelaMinima: onlyNumbersMask(e.target.value)})} />
                            <Input type="tel" label="Máximo de parcelas" isFilterGroups={true} value={parcelaMaxima}  onChange={e => this.setState({parcelaMaxima: onlyNumbersMask(e.target.value)})} />
                        </Row>
                        <Row>
                            {
                                carregouPolitica ?
                                (
                                    <Fragment>
                                            <Input type="checkbox" isFilterGroups={true} defaultChecked={aprovacaoAutomatica}  className="checkBoxtable" style={{marginBottom: 0}} label="Aprovação automática de propostas" value={aprovacaoAutomatica}  onChange={e => this.setState({aprovacaoAutomatica: e.target.checked})} />
                                            <Input type="checkbox" isFilterGroups={true} defaultChecked={contrato}  className="checkBoxtable" style={{marginBottom: 0}} label="Emprego por contrato" value={contrato}  onChange={e => this.setState({contrato:  e.target.checked})} />
                                    </Fragment>
                                ) : null
                            }
                        </Row>
                    </Panel>
                    
                    <Panel header="Rating" actions={actionsRatingPanel}>
                        <Input type="select" optionsData={reguaRatingOptions} style={{width: 200}} label="Régua de Rating" value={reguaRating} 
                            onChange={e => this.carregaIntervalosDeRating(e.target.value)} />  
                        <Panel>
                            {exibeDados ? (<>
                                            <Table 
                                                idTabela="rating"
                                                campos={tableRating.campos}
                                                acaoEditar={this.acaoEditarIntervaloDeRating}
                                                dados={tableRating.dados}
                                            />
                                        </>) : null}
                                        {tableRating.dados == null ? (
                                            <p className="textEmpty">Nenhuma rating encontrado!</p>
                                        ) : null}
                        </Panel>
                    </Panel>

                    <Panel header="Penalidades" actions={actionsPenalidadesPanel}>
                        <Panel>
                            {exibeDados ? (<>
                                        <Table 
                                            idTabela="penalidade"
                                            campos={tablePenalidade.campos}
                                            acaoSelecionavel={this.acaoSelecionavel}
                                            acaoEditar={this.acaoEditarPenalidade}
                                            acaoRegras={this.acaoVisualizar}
                                            selecionavel={true}
                                            dados={tablePenalidade.dados}
                                        />
                                    </>) : null}
                                    {tablePenalidade.dados === [] ? (
                                        <p className="textEmpty">Nenhuma Penalidade encontrada!</p>
                                    ) : null}
                        </Panel>
                    </Panel>
                    
                    <Panel header="Prazo" actions={actionsPrazoPanel}>
                        <Input type="select" optionsData={reguaPrazoOptions}  style={{width: 250}} label="Régua de prazo" value={reguaPrazo} 
                                onChange={e => this.carregaIntervalosDePrazo(e.target.value)} />  
                            
                        <Panel>
                            {exibeDados ? (<>
                                <Table 
                                    campos={tablePrazo.campos}
                                    acaoEditar={this.acaoEditarIntervaloDePrazo}
                                    dados={tablePrazo.dados}
                                />
                            </>) : null}
                            {tablePrazo.dados === [] ? (
                                <p className="textEmpty">Nenhuma intervalo de prazo encontrado!</p>
                            ) : null}
                                    
                        </Panel>
                    </Panel>

                    <Panel header="Escalonamento de parcelas" actions={actionsEscalonamentoPanel}>
                        <Input type="select" optionsData={reguaEscalonamentoParcelaOptions}  style={{width: 250}} label="Régua de Escalonamento" value={reguaEscalonamentoDeParcela} 
                            onChange={e => this.carregaIntervalosDeEscalonamentoDeParcela(e.target.value)} />      
                        <Panel>  
                            <Table 
                                campos={tableReguaEscalonamento.campos}
                                acaoEditar={this.acaoEditarIntervaloDeEscalonamentoDeParcela}
                                dados={tableReguaEscalonamento.dados}
                            />     
                        </Panel>
                    </Panel>

                        
                    <PageBottomActions>
                        <Button type="back" name={'< Voltar'} action={() => this.setState({ path: '/politicas', redirect: true })} />
                        <Button  name= {acaoLabel} action={() => this.validaCampos()}/>                                 
                    </PageBottomActions>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}