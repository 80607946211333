import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Modal from '../../Components/Modal';
import iconAviso from '../../resources/img/icon-aviso.svg';
import Table from '../../Components/Table';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

import { Link } from 'react-router-dom';

export default class BillDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            path: this.props.location.state ? '' : '/faturas',
            redirect: this.props.location.state ? false : true,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            table: {
                campos: [
                    { titulo: 'Nº CCB', dado: 'numCCB', thStyle: { width: 80 } },
                    { titulo: 'Funcionário', dado: 'funcionario', thStyle: { width: 200 } },
                    { titulo: 'Valor', dado: 'valor', thStyle: { width: 190 } }
                ],
                dados: []
            },
            modalConfirmacao: false,
            exibeDados: false,
            mostraVazio: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        const { table } = this.state;
        const { data } = this.props.location.state;
        var objects = [];
        for (var i = 0; i < data.parcelas.length; i++) {
            const item = data.parcelas[i].parcela.proposta;
            objects.push({
                id: item.ppst_id, numCCB: item.ppst_numeroccb, funcionario: item.funcionario ? item.funcionario.func_nome : '',
                valor: 'R$ ' + Funcoes.float2Preco(item.ppst_valorparcela), acoes: ''
            });
        }
        if (data.parcelas.length > 0) {
            this.setState({ exibeDados: true, loading: false, mostraVazio: false });
        } else {
            this.setState({ loading: false, mostraVazio: true });
        }
        this.setState({ table: { campos: [...table.campos], dados: objects } });
    }

    async cancelarFatura()
    {
        const { data } = this.props.location.state;
        
        this.setState({loading: true, modalConfirmacao: false})
        
        await ApiService.postGeral('faturas/iugu/boleto/cancelar/'  + data.fat_boletoid)
        .then(res => {
            this.setState({ alertText: 'Fatura cancelada com sucesso!', alertType: 1,
            alert: true, loading: false});        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, textoBtn, alertType,modalConfirmacao, table, exibeDados } = this.state;
        const { data } = this.props.location.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Modal show={modalConfirmacao}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <p className="description">Você realmente deseja cancelar a fatura?</p>

                        <div className="cancelamentoAcao">
                            <button className="btn"
                                style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                onClick={() =>  this.cancelarFatura() }>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalConfirmacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <SideMenu menuAtivo={7} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} textoBtn={textoBtn} tipo={alertType}
                    action={() => this.setState({ alert: false, redirect: path ? true : false })} />

                <div className="body">
                    <Header />

                    <div className="contratos-detalhes mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Faturas</h1>
                                <p className="descricao">Abaixo detalhes do contrato selecionado.</p>
                            </div>
                            <div style={{ marginLeft: 'auto', display: 'flex' }}>
                                <button className="btn" style={{ marginLeft: '25px'}} onClick={() => this.setState({modalConfirmacao: true})}>Cancelar Fatura</button>                         

                            </div>
                        </div>

                        <div className="panel detalhes">
                            <div className="panelHeader">
                                <h3>Detalhe do Contrato</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multArea">
                                    <p><span>Nº: </span> {data.fat_id}</p>
                                    <p><span>Código do boleto: </span> {data.fat_boletoid}</p>
                                    <p><span>Empresa: </span> {data.empresa.emp_nomefantasia}</p>
                                </div>
                                <div className="multArea">
                                    <p><span>Data de Soliciatação: </span> {Funcoes.data2Brazilian(data.fat_datahora.split(' ')[0], '-')} {data.fat_datahora.split(' ')[1]}</p>
                                    <p><span>Data Pagto: </span> {data.fat_dtpagto ? Funcoes.data2Brazilian(data.fat_dtpagto, '-') : '-'}</p>
                                    <p><span>Data Vencimento: </span> {Funcoes.data2Brazilian(data.fat_dtvencto, '-')}</p>
                                </div>
                                <div className="multArea">
                                    <p><span>Pago: </span> {data.fat_pago ? "Sim" : "Não"}</p>
                                    <p><span>Valor: </span> R$ {Funcoes.float2Preco(data.fat_valor)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Proposta</h3>
                            </div>
                            <div className="panelBody">
                                {exibeDados ? (
                                    <Table campos={table.campos}
                                        dados={table.dados}/>
                                ) : null}
                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/faturas" className="btn btnVoltar">{'<'} Voltar</Link>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}