import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';

export default class ProfileAccess extends Component {

    constructor() {
        super();

        this.acao = this.acao.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertMessage: '',
            table: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: '', dado: 'acoes', thStyle: { width: 100 } }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            perfisDefault: {},
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        var acoes = [];
        for (var i = 0; i < userData.menus.length; i++) {
            const item = userData.menus[i];
            if (item.filhos.length > 0) {
                for (var x = 0; x < item.filhos.length; x++) {
                    if (item.filhos[x].bmnu_url === url) {
                        for (var y = 0; y < item.filhos[x].acoes.length; y++) {
                            acoes.push(item.filhos[x].acoes[y].acmnu_id);
                        }
                    }
                }
            }
        }
        this.setState({ acoes });
        this.listaPerfis(1, acoes);
    }

    async listaPerfis(page, acao) {
        const { table, acoes } = this.state;
        this.setState({ loading: true });

        var actions = acao ? acao : acoes;
        var url ='perfilacesso?per_page=20'
        if(page)
        {
            url += '&page=' + page
        }
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ id: item.perf_id, nome: item.perf_nome, acoes: actions.indexOf(22) !== -1 ? item.perf_master === 1 ? '' : 'default' : '' });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, loading: false, perfisDefault: result.data })
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    acao(id) {
        this.setState({ path: '/perfis-acesso/novo', propsToPath: { perfil: { id } }, redirect: true })
    }

    handlePage(page) {
        const {acoes} = this.state;
        if (page) {
            this.setState({ path: '/perfis-acesso?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/perfis-acesso', redirect: true });
        }
        this.listaPerfis(page, acoes);
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertMessage, table, exibeDados, mostraVazio, acoes, perfisDefault } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} props={propsToPath} />
                <SideMenu menuAtivo={12} />

                <div className="body">
                    <Header />

                    <div className="configuracao mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Perfil de Acesso</h1>
                                <p className="descricao">Abaixo listamos os perfis de acesso dos usuários.</p>
                            </div>
                            {acoes.indexOf(21) !== -1 ?
                                <button className="btn bd-roxo cl-roxo" style={{ width: 175 }}
                                    onClick={() => this.setState({ path: '/perfis-acesso/novo', redirect: true })}>Incluir perfil</button>
                            : null}
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                            {exibeDados ?
                                <Table campos={table.campos}
                                    dados={table.dados}
                                    acaoEditar={this.acao} />
                                : null}
                                {mostraVazio ?
                                    <p className="textEmpty">Nenhum usuário foi encontrado!</p>
                                : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <Paginacao dados={perfisDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment >
        )
    }
}