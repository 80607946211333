import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import iconAviso from '../../resources/img/icon-aviso.svg';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Paginacao from '../../Components/Paginacao';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';

import { connect } from "react-redux";
import { addCompany, removeCompany, retrieveAllCompanys} from "../../Actions/Company";

class Company extends Component {

    constructor() {
        super();

        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoRegras = this.acaoRegras.bind(this);
        this.acaoUsuarios = this.acaoUsuarios.bind(this);
        this.acaoSelecionavel = this.acaoSelecionavel.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            table: {
                campos: [
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Fantasia', dado: 'fantasia' },
                    { titulo: 'CNPJ', dado: 'cnpj', tdStyle: {width: "180px"} },
                    { titulo: 'Status', dado: 'tag' },
                    { titulo: 'Ações', dado: 'acoes', tdStyle: {width: "120px"} },
                ],
                dados: []
            },
            empresasDefault: [],
            grupos: [],
            exibeDados: false,
            mostraVazio: false,
            exibeModalConfirmacao: false,
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        var acoes = [];

        for (var i = 0; i < userData.menus.length; i++) {
            const item = userData.menus[i];
            if (item.bmnu_url === url) {
                for (var x = 0; x < item.acoes.length; x++) {
                    acoes.push(item.acoes[x].acmnu_id);
                }
            }
        }
        this.setState({ acoes });
        this.carregaGrupos();
        this.carregaEmpresas(1, acoes);
    }

    async carregaEmpresas(page, acao) {
        const { table, acoes, grupo } = this.state;

        this.setState({ loading: true });

        var url, pagina, pagAtual, actions;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;
        actions = acao ? acao : acoes; 

        if (pagAtual) {
            url = "empresa?per_page=20&page=" + pagAtual;
        } else {
            url = "empresa?per_page=20";
        }

        url += grupo ? "&gpec_id="+grupo : "";
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.emp_id, empresa: item.emp_razao.toUpperCase(), fantasia: item.emp_nomefantasia.toUpperCase(), cnpj: item.emp_cnpj,
                        tag: item.emp_status === 1 ? 'Ativo' : 'Inativo', acoes: actions.indexOf(2) !== -1 ? 'default|regras' : ''
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, empresasDefault: result.data });
                this.reloadCheckbox();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async carregaGrupos() {
        this.setState({ loading: true })
        await ApiService.getGeral("grupoeconomico?per_page=-1")
            .then(result => {
                const res = result.data.data;
                var objects = [];
                objects.push({ value: '', text: 'Selecione' });
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ value: item.gpec_id, text: item.gpec_nome });
                }
                this.setState({ grupos: objects });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        this.setState({ path: '/empresas/nova', propsToPath: { empresa: { id } }, redirect: true });
    }

    acaoRegras(id) {
        this.setState({ path: '/empresas/regras', propsToPath: { id }, redirect: true });
    }

    acaoUsuarios(id, nome) {
        this.setState({ path: '/empresas/usuarios', propsToPath: { empresa: { id, nome } }, redirect: true });
    }

    async rodarMotorDeCredito(){
        const  {empresas}  = this.props;
        let idEmpresas = [];
        this.setState({ loading: true, exibeModalConfirmacao: false})
        for (let i = 0; i < empresas.length; i++) {
            //if (checkboxs[i].getAttribute('rel'))
            idEmpresas.push(empresas[i].id);
        }

        let dataPost = {
            empresas: idEmpresas.join()
        }
        await ApiService.postGeral('empresa/motor_de_credito', dataPost)
        .then(result => {
            let data = result.data
            console.log(data.length)

            //let total_colaboradores = result.length()
            this.setState({ alertText: data.length + ' colaboradores afetados', alertType: 1,
            alert: true, loading: false});   
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    validarEmpresasSelecionadas(){
        const  {empresas}  = this.props;
        if(empresas.length === 0){
            this.setState({ alertText: 'Selecione uma ou mais empresas para continuar', path: null, alert: true })
        }else{
            this.setState({exibeModalConfirmacao: true});
        }
    }

    async acaoSelecionavel(e, header) {
        const { table } = this.state;
        const checkboxs = document.querySelectorAll('input[type="checkbox"]');
        if (header === 1) {
            this.setState({ loading: true });
            for (var i = 1; i < checkboxs.length; i++) {
                let checked = table.dados.find(x => x.id === parseInt(checkboxs[i].attributes.rel.value));
        
                if(checkboxs[i].checked)
                {
                    await this.props.addCompany(checked)
                }
                else{
                    await this.props.removeCompany(checked) 
                }   
            }
        } else {
            let checked = table.dados.find(x => x.id === parseInt(e.target.attributes.rel.value));
            this.setState({ loading: true });
            if(e.target.checked)
            {
                await this.props.addCompany(checked)
            }
            else{
                await this.props.removeCompany(checked) 
            }   
        }       
        this.setState({ loading: false });
    }

    async reloadCheckbox(){
        const  {empresas}  = this.props;
        var totalCheckbox = document.querySelectorAll('input[type="checkbox"]');
        let result = '';
        if(empresas === undefined)
        {
            return null;
        }
        for (var i = 1; i < totalCheckbox.length; i++) {
            
            result = empresas.find(obj => {
                return obj.id === parseInt(totalCheckbox[i].attributes.rel.value)
            });  
            if (totalCheckbox[i].checked == false) {
                if(result != undefined){
                    totalCheckbox[i].checked = true;
                }
            }
            if(totalCheckbox[i].checked){
                if(result === undefined){
                    totalCheckbox[i].checked = false;
                }
            }
        }          
        totalCheckbox[0].checked = false;
    }
   
    handlePage(page) {
        if (page) {
            this.setState({ path: '/empresas?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/empresas', redirect: true });
        }
        this.carregaEmpresas(page);
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, empresasDefault, grupos, 
            exibeDados, exibeModalConfirmacao, alertType, mostraVazio, acoes } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Alert show={alert} texto={alertText} tipo={alertType}
                    action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <Modal show={exibeModalConfirmacao}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <p className="description">Isso irá recalcular o rating dos colaboradores das empresas selecionadas. Deseja continuar.</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() =>  this.rodarMotorDeCredito()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ exibeModalConfirmacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>

                <div className="body">
                    <Header />

                    <div className="empresas mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Empresas</h1>
                                <p className="descricao">Abaixo a listagem de tods empresa cadastradas na plataforma</p>
                            </div>
                            <div className="buttons">
                                <button className="btn" onClick={() => this.validarEmpresasSelecionadas()}>Rodar motor de crédito</button>                
                                {acoes.indexOf(1) !== -1 ?
                                    <button className="btn" onClick={() => this.setState({ path: '/empresas/nova', redirect: true })}>Nova Empresa</button>
                                : null}                           
                            </div> 
                           
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="select" isFilterGroups={true} label="Grupo Econômico" optionsData={grupos} 
                                        onChange={e => this.setState({ grupo: e.target.value })} />
                                    <button className="btn filtrar" onClick={() => this.carregaEmpresas(1)}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">

                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar}
                                        acaoRegras={this.acaoRegras}
                                        acaoUsuarios={this.acaoUsuarios}
                                        selecionavel={true}
                                        acaoSelecionavel={this.acaoSelecionavel} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhuma empresa encontrada!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <p>Total de registros: {empresasDefault.total}</p>
                                    <Paginacao dados={empresasDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        empresas: state.Company
    };
}

export default connect(mapStateToProps,{ addCompany ,removeCompany, retrieveAllCompanys })(Company);