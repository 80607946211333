import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

export default class LoanReasons extends Component {

    constructor() {
        super();

        this.acaoEditar = this.acaoEditar.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            modal: false,
            alert: false,
            alertText: '',
            table: {
                campos: [
                    { titulo: 'Motivo', dado: 'descricao' },
                    { titulo: 'Ativo', dado: 'tag' },
                    { titulo: 'Ações', dado: 'acoes' },
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });
        this.carregaMotivos(acoes);
    }

    async carregaMotivos(acao) {
        const { table, acoes } = this.state;
        this.setState({ loading: true });

        const userData = JSON.parse(localStorage.getItem('userData'));
        var url = "propostamotivo";
        var actions = acao ? acao : acoes;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.pmtv_id, descricao: item.pmtv_descricao, tag: item.pmtv_ativo === 1 ? 'Ativo' : 'Inativo', acoes: actions.indexOf(20) !== -1 ? 'default' : ''
                    };
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        this.setState({ path: '/motivo-emprestimo/novo', propsToPath: { razao: { id } }, redirect: true });
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, exibeDados, mostraVazio, acoes } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={11} />

                <div className="body">
                    <Header />

                    <div className="motivo-emprestimo mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Motivo Empréstimo</h1>
                                <p className="descricao">Abaixo a listagem de todos os motivos de empréstimo</p>
                            </div>
                            {acoes.indexOf(19) !== -1 ?
                                <button className="btn" onClick={() => this.setState({ path: '/motivo-emprestimo/novo', redirect: true })}>Novo Motivo</button>
                            : null}
                        </div>

                        <div className="detalhes">

                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar} />
                                </>) : null}
                                {mostraVazio ?
                                    <p className="textEmpty">Nenhum motivo foi encontrado!</p>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}