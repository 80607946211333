import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/img/icon-aviso.svg';
import iconCheck from '../../resources/img/icon-sucesso.svg';
import iconCopiar from '../../resources/img/icon-copiar.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import Modal from '../../Components/Modal';
import { cpfMask, dataBrMask, onlyNumbersMask, moneyMask } from '../../Utils/mask';
import Paginacao from '../../Components/Paginacao';
import { connect } from "react-redux";
import { addEmprestimo, removeEmprestimo, retrieveAllEmprestimo} from "../../Actions/Disconnect";

class Disconnect extends Component {
    constructor() {
        super();

        this.acaoVisualizar = this.acaoVisualizar.bind(this);
        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoSelecionavel = this.acaoSelecionavel.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '/',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            empresasOptions: [{ value: '', text: 'Selecione' }],
            statusAverbacaoOptions: [
                { value: '', text: 'Selecione' },
                { value: 0, text: 'Sem averbar' },
                { value: 1, text: 'Averbado' }

            ],
            statusEmprestimoOptions: [
                { value: '', text: 'Selecione' },
                { value: 0, text: 'Em análise' },
                { value: 1, text: 'Inadimplente' },
                { value: 3, text: 'Quitado' },
                { value: 4, text: 'Sem débito'}

            ],
            statusAverbacao: '',
            statusEmprestimo: '',
            empresa: '',
            colaborador: '',
            ccb: '',
            dataFiltroInicio: '',
            dataFiltroFim: '',
            table: {
                campos: [
                    { titulo: 'Cliente/Colaborador', dado: 'cliente', thStyle:{ width: 150 } },
                    { titulo: 'Empresa', dado: 'empresa', thStyle:{ width: 130 }  },
                    { titulo: 'CCB', dado: 'ccb', thStyle:{ width: 100 }  },
                    { titulo: 'Dt. Desligamento', dado: 'dataDesligamento', thStyle:{width: 120} },
                    { titulo: 'Saldo Devedor', dado: 'saldoDevedor', thStyle:{width: 120} },
                    { titulo: 'Averbação', dado: 'averbacao', thStyle:{width: 100}   },
                    { titulo: 'Saldo Final', dado: 'saldoFinal', thStyle:{width: 100}  },
                    { titulo: 'Status Averbação', dado: 'statusAverbacao', tdTagStyle: true, thStyle:{ width: 80 }  },
                    { titulo: 'Status Empréstimo', dado: 'statusEmprestimo', tdTagStyle: true, thStyle:{ width: 80 }  },
                    { titulo: 'Ações', dado: 'acoes', thStyle: { textAlign: 'center', width: 132 }, tdStyle: { textAlign: 'center' } }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            modalGeraBoleto: false,
            modalAverbacao: false,
            valorAverbacao: 'R$ 0,00',
            emprestimoEditado: undefined,
            acoes: [],
            btnGerar: false,
            dtPagto: '',
            retornoBoletoGerado: {},
            desligamentosDefault: {},
            totalSelecionado: 0,
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });

        this.carregaEmpresas();
        this.carregaDesligamentos();
    }

    async carregaEmpresas() {
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var objects = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ value: item.emp_id, text: item.emp_nomefantasia })
                }
                this.setState({ empresasOptions: objects, loading: false });
            })
            .catch(err => this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa disponível' }], loading: false }))
    }

    async carregaDesligamentos(page) {
        const { ccb, table, empresa, statusAverbacao, statusEmprestimo, colaborador, dataFiltroInicio, dataFiltroFim } = this.state;
        this.setState({ loading: true });

        var url, pagina, pagAtual;
        url = "emprestimopessoal?per_page=20";
        url += empresa ? "&emp_id=" + empresa : "";
        if (colaborador) {
            if (colaborador.startsWith('0') || colaborador.startsWith('1') || colaborador.startsWith('2') || colaborador.startsWith('3') || colaborador.startsWith('4') ||
                colaborador.startsWith('5') || colaborador.startsWith('6') || colaborador.startsWith('7') || colaborador.startsWith('8') || colaborador.startsWith('9')) {
                url = url + "&func_cpf=" + colaborador;
            } else {
                url = url + "&func_nome=" + colaborador;
            }
        }
        url += statusAverbacao ? "&emppes_statusaverbacao=" + statusAverbacao : "";
        url += statusEmprestimo ? "&emppes_status=" + statusEmprestimo : "";
        url += ccb ? "&ppst_numeroccb=" + ccb : "";

        url += dataFiltroInicio ? "&emppes_data_inicio=" + dataFiltroInicio : "";
        url += dataFiltroFim ? "&emppes_data_fim=" + dataFiltroFim : "";
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url += "&page=" + pagAtual;
        } 

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];

                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    let statusEmprestimo;
                
                    if(item.emppes_status === 4){
                        statusEmprestimo = "Sem débito inactive"
                    }
                    if(item.emppes_status === 3){
                        statusEmprestimo = "Quitado"
                    }
                    
                    if(item.emppes_status === 1){
                        statusEmprestimo = "Inadimplente warning";
                    }

                    if(item.emppes_status === 0){
                        statusEmprestimo = "Em análise warning";
                    }

                    objects.push({
                        id: item.emppes_id, 
                        cliente: item.funcionario.func_nome.toUpperCase(),
                        empresa: item.funcionario.empresa ?  item.funcionario.empresa.emp_nomefantasia.toUpperCase() : "-",
                        emp_id: item.funcionario.empresa ? item.funcionario.empresa.emp_id : "" ,
                        ccb: item.ccb ? item.ccb : '-', //mudara a query e incluir ccb
                        dataDesligamento:  Funcoes.data2Brazilian( item.emppes_data),
                        saldoDevedor: 'R$ ' + Funcoes.float2Preco(item.emppes_saldodevedor), 
                        averbacao: 'R$ ' + Funcoes.float2Preco(item.emppes_valorpagtorescisao),
                        saldoFinal: 'R$ ' + Funcoes.float2Preco(item.emppes_saldofinal),
                        statusAverbacao: item.emppes_statusaverbacao ? "Averbado" : "Sem averbar error",
                        statusEmprestimo: statusEmprestimo ?  statusEmprestimo : '-',
                        valSelect: item.emppes_valorpagtorescisao,
                        acoes: 'default'
                    })
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, mostraVazio: false });
                } else {
                    this.setState({ exibeDados: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, desligamentosDefault: result.data, loading: false });
                this.reloadCheckbox();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: '' });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: '' });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    //acao = 1-Aprova, 2-Reprova
    async acaoVisualizar(acao, id) {
        this.setState({ loading: true });
        const formData = {
            "emppes_status": acao
        };

        await ApiService.putGeral('emprestimopessoal/' + id, formData)
            .then(res => {
                const aprovaReprova = acao === 1 ? 'aprovado' : 'reprovado';
                this.setState({
                    alertText: 'O Desligamento foi ' + aprovaReprova + ' com sucesso!', path: '/desligamento',
                    loading: false, alert: true
                });
                this.carregaDesligamentos();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: '' });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: '' });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async acaoEditar(id){
        this.setState({ modalAverbacao: true, emprestimoEditado: id });
    }

    async reloadCheckbox(){
        const  {emprestimos}  = this.props;
        var totalCheckbox = document.querySelectorAll('input[type="checkbox"]');
        let result = '';
        if(emprestimos == undefined)
        {
            return null;
        }

        for (var i = 1; i < totalCheckbox.length; i++) {
            
            result = emprestimos.find(obj => {
                return obj.id === parseInt(totalCheckbox[i].attributes.rel.value)
            });  
            if (totalCheckbox[i].checked == false) {
                if(result != undefined){
                    totalCheckbox[i].checked = true;
                }
            }
            if(totalCheckbox[i].checked){
                if(result === undefined){
                    totalCheckbox[i].checked = false;
                }
            }
        }  

        let total = 0; 
        emprestimos.forEach(function(emprestimo, i) {
            total+=  emprestimo.valSelect
            
        });

        this.setState({ totalSelecionado: total});
    }
   
    async acaoSelecionavel(e, header) {
        const { totalSelecionado, table } = this.state;
        const checkboxs = document.querySelectorAll('input[type="checkbox"]');
        if (header === 1) {
            this.setState({ loading: true });
            for (var i = 1; i < checkboxs.length; i++) {
                let checked = table.dados.find(x => x.id === parseInt(checkboxs[i].attributes.rel.value));
                let total = parseFloat(checkboxs[i].value);
                if(checkboxs[i].checked)
                {
                    await this.props.addEmprestimo(checked)
                }
                else{
                    await this.props.removeEmprestimo(checked) 
                }   
                this.setState({ totalSelecionado: checkboxs[i].checked ? total + totalSelecionado : totalSelecionado - total });
            }
        } else {
            let checked = table.dados.find(x => x.id === parseInt(e.target.attributes.rel.value));
            this.setState({ loading: true });
            if(e.target.checked)
            {
                await this.props.addEmprestimo(checked)
            }
            else{
                await this.props.removeEmprestimo(checked) 
            }   
        }       
        const  {emprestimos}  = this.props;
    
        this.setState({ btnGerar: emprestimos.length > 0 ? true : false });
        let total = 0;
        
        emprestimos.forEach(function(emprestimo, i) {
            total+=  emprestimo.valSelect
        });

        this.setState({ totalSelecionado: total});
        this.setState({ loading: false });
    }

    async gerarBoleto() {
        const { dtPagto } = this.state;
        const  {emprestimos}  = this.props;
        this.setState({ loading: true });

        let emprestimosCobrados = []
        emprestimos.forEach(function(emprestimo) {
            emprestimosCobrados.push(emprestimo.id)
        });

        const form = {
            emp_id: emprestimos[0].emp_id,
            emppes_id: emprestimosCobrados.join(),
            data_pagamento: Funcoes.data2Americano(dtPagto)
        };
        
        await ApiService.postGeral('emprestimopessoal/boleto/gerar', form)
            .then(res => {
                this.setState({ retornoBoletoGerado: res.data.data, loading: false, modalGeraBoleto: false, modalBoleto: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    // this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async exportarDesligamentos() {
        const { ccb, statusAverbacao, statusEmprestimo, empresa, dataFiltroInicio, 
                dataFiltroFim, colaborador } = this.state;

        this.setState({ loading: true });
      
        var url = '/geral/emprestimopessoal?per_page=-1&export=true';
        url += empresa ? "&emp_id=" + empresa : "";
       
        if (colaborador) {
            if (colaborador.startsWith('0') || colaborador.startsWith('1') || colaborador.startsWith('2') || colaborador.startsWith('3') || colaborador.startsWith('4') ||
                colaborador.startsWith('5') || colaborador.startsWith('6') || colaborador.startsWith('7') || colaborador.startsWith('8') || colaborador.startsWith('9')) {
                url = url + "&func_cpf=" + colaborador;
            } else {
                url = url + "&func_nome=" + colaborador;
            }
        }
        url += statusAverbacao ? "&emppes_statusaverbacao=" + statusAverbacao : "";
        url += statusEmprestimo ? "&emppes_status=" + statusEmprestimo : "";
        url += ccb ? "&ppst_numeroccb=" + ccb : "";

        url += dataFiltroInicio ? "&emppes_data_inicio=" + dataFiltroInicio : "";
        url += dataFiltroFim ? "&emppes_data_fim=" + dataFiltroFim : "";
      
        //adicionar os filtros escolhidos tbm

        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'desligamentos.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/desligamento?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/desligamento', redirect: true });
        }
        this.carregaDesligamentos(page);
        this.reloadCheckbox();

    }
    
    async validaGeraBoleto()
    {
        const { btnGerar } = this.state;
        const  {emprestimos}  = this.props;
         
        if(!btnGerar)
        {
            this.setState({ alertText: 'Selecione um ou mais vencimentos para continuar', path: null, alert: true })
            return true;
        }
        
        let geraBoleto = true;
        
        let primeiraEmpresa = emprestimos[0].emp_id;

        await emprestimos.forEach(function(emprestimo) {
                        
            if(emprestimo.emp_id != primeiraEmpresa)
            {
                geraBoleto = false;
                return false;
            }
            
        });

        if(!geraBoleto)
        {
            await this.setState({alertText: 'Não podemos gerar o boleto pois há mais de uma empresa vinculada aos desligamentos selecionados!',path: null, alert: true });
            return false;
        }
        
        this.setState({ modalGeraBoleto: true })
     
                                    
    }

    handleFiltro(e) {
        const val = e.target.value;
        if (val.startsWith(0) || val.startsWith(1) || val.startsWith(2) || val.startsWith(3) || val.startsWith(4) || val.startsWith(5) || val.startsWith(6) || val.startsWith(7) ||
            val.startsWith(8) || val.startsWith(9)) {
            document.getElementById('filtro').setAttribute('maxlength', 14);
            this.setState({ colaborador: cpfMask(val) });
        } else {
            document.getElementById('filtro').removeAttribute('maxLength');
            this.setState({ colaborador: val });
        }
    }
    async recalcularAverbacao(){
        const {valorAverbacao, emprestimoEditado} = this.state;

        this.setState({ modalAverbacao: false, loading: true });
        let formData = {
            "averbacao": Funcoes.preco2float(valorAverbacao,"R$"),
        }

        await ApiService.patchGeral('emprestimopessoal/recalcular-averbacao/' + emprestimoEditado, formData)
        .then(res => {
            this.setState({
                alertText: 'Averbação alterada com sucesso', path: '/desligamento',valorAverbacao: "R$ 0,00",
                loading: false, alert: true, alertType: 1,
            });
            this.carregaDesligamentos();
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: '' });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: '' });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true, alertType: 0 });
        })
    }
    render() {
        const { loading, path, alert, alertText, alertType, table, exibeDados, mostraVazio, empresasOptions,
                statusAverbacaoOptions, statusEmprestimoOptions, empresa, statusAverbacao, statusEmprestimo, colaborador, ccb,  dataFiltroInicio, dataFiltroFim,
                modalGeraBoleto, modalAverbacao, modalBoleto, dtPagto, retornoBoletoGerado, totalSelecionado, desligamentosDefault, valorAverbacao } = this.state;

        return (
            <Fragment>
                 <Modal show={modalAverbacao}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <Input type="text" label="Valor de averbação." placeholder="R$ 0,00" value={valorAverbacao} className="custom"
                            onChange={e => this.setState({ valorAverbacao: moneyMask(e.target.value) })} />
                          <div className="cancelamentoAcao">
                            <button className="btn"
                                style={(valorAverbacao !== '') ? { backgroundColor: '#EA5353', color: '#FFF' } : { backgroundColor: '#999' }}
                                onClick={() => (valorAverbacao !== '') ? this.recalcularAverbacao() : {}}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalAverbacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalGeraBoleto}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <p className="description">Informe a data que o pagamento será realizado.</p>
                        <Input type="tel" placeholder="99/99/9999" value={dtPagto} className="custom" maxLength="10"
                            onChange={e => this.setState({ dtPagto: dataBrMask(e.target.value) })} />
                        <div className="cancelamentoAcao">
                            <button className="btn"
                                style={dtPagto.length === 10 ? { backgroundColor: '#EA5353', color: '#FFF' } : { backgroundColor: '#999' }}
                                onClick={() => dtPagto.length === 10 ? this.gerarBoleto() : {}}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalGeraBoleto: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalBoleto}>
                    <div className="">
                        <div>
                            <button className="close"  onClick={() => this.setState({ modalBoleto: false })}>X</button>
                        </div>    
                        <img src={iconCheck} alt="Ícone de aviso" />
                        <p className="description">O seu boleto foi gerado com sucesso!</p>
                        <p style={{ fontSize: 14, marginBottom: 5 }}>Linha Digitável</p>
                        <div className="boletoGerado">
                            <Input value={retornoBoletoGerado.linha_digitavel} className="custom" id="linhaDigitavel" style={{ fontSize: 16 }} readOnly={true}
                                onClick={() => document.getElementById("linhaDigitavel").select()} />
                            <button onClick={() => {
                                const input = document.getElementById("linhaDigitavel");
                                input.select();
                                input.setSelectionRange(0, 99999)
                                document.execCommand("copy");
                            }}>
                                <img src={iconCopiar} alt="Copiar linha digitável" title="Copiar linha digitável"></img>
                            </button>
                        </div>
                        <button className="btn visualizaBoleto" onClick={() => window.open(retornoBoletoGerado.url_boleto, '_blank')}>Visualizar boleto</button>
                    </div>
                </Modal>
                <Loading show={loading} />
                <Alert show={alert} tipo={alertType} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={8} />

                <div className="body">
                    <Header />

                    <div className="desligamentos mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Desligamentos</h1>
                                <p className="descricao">Consulte abaixo todas solicitações de desligamento de colaboradores.</p>
                            </div>  
                            <div className="buttons">
                                <button className="btn" onClick={() => this.validaGeraBoleto()}>Gerar Boleto</button>                
                                <button className="btn btnAzul" onClick={() => this.exportarDesligamentos() }>Exportar (.xlsx)</button>
                            </div>     
                            
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="select" isFilterGroups={true} optionsData={empresasOptions} label="Empresa" 
                                        value={empresa} onChange={e => this.setState({ empresa: e.target.value })} />
                                    
                                    <Input type="text" isFilterGroups={true} label="Colaborador ou CPF" value={colaborador} id="filtro"
                                        onChange={e => this.handleFiltro(e)} />    
                                    
                                    <Input type="text" isFilterGroups={true} label="CCB" value={ccb} id="filtro"
                                        onChange={e => this.setState({ccb: onlyNumbersMask(e.target.value)})} />   

                                    <Input type = "select" isFilterGroups={true} label = "Status Averbação" 
                                        optionsData={statusAverbacaoOptions} value={statusAverbacao} 
                                        onChange={e => this.setState({ statusAverbacao: e.target.value })}  />    
                                    
                                    <Input type = "select" isFilterGroups={true} label = "Status Empréstimo" 
                                        optionsData={statusEmprestimoOptions} value={statusEmprestimo} 
                                        onChange={e => this.setState({ statusEmprestimo: e.target.value })}  />    
                                    
                                    <Input type = "date" isFilterGroups={true} label = "Periodo de:" value={dataFiltroInicio} onChange={e => this.setState({ dataFiltroInicio: e.target.value })} />
                                    
                                    <Input type = "date" isFilterGroups={true} label = "Periodo até:" value={dataFiltroFim} onChange={e => this.setState({ dataFiltroFim: e.target.value })}  />    
                                    <button className="btn filtrar" onClick={() => this.carregaDesligamentos(1)}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoVisualizar={this.acaoVisualizar}
                                        acaoEditar={this.acaoEditar}
                                        selecionavel={true}
                                        acaoSelecionavel={this.acaoSelecionavel} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhum desligamento encontrado!</p>
                                ) : null}
                            </div>

                            {exibeDados ? (<>
                                <div className="info">
                                    <p style={{ marginRight: 'auto' }}>Total selecionado: <span className="valor">R$ {Funcoes.float2Preco(totalSelecionado)}</span></p>
                                    <Paginacao dados={desligamentosDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        emprestimos: state.Disconnect
    };
}

export default connect(mapStateToProps,{ addEmprestimo ,removeEmprestimo, retrieveAllEmprestimo })(Disconnect);