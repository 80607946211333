import React, { Component, Fragment } from 'react';

export default class Row extends Component {
    render() {
        const {children} = this.props;
        return (
            <Fragment>
                <div className="row">
                    {children}
                </div>
            </Fragment>
        );
    }
}
