import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { cpfMask, onlyNumbersMask,onlyTextMask, celularMask, dataBrMask, cepMask, moneyMask } from '../../Utils/mask';

import { Link } from 'react-router-dom';


export default class EditCollaborator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            empresasOptions: [{ value: '', text: 'Selecione' }],
            situacaoOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Cadastrado' },
                { value: 2, text: 'Apto' },
                { value: 3, text: 'Inapto' },
                { value: 4, text: 'Desligado' }
            ],
            estadoCivilOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Solteiro(a)' },
                { value: 2, text: 'Casado(a)' },
                { value: 3, text: 'Divorciado(a)' },
                { value: 4, text: 'Viúvo(a)' },
                { value: 5, text: 'Separado(a)' }
            ],
            nacionalidadeOptions: [
                { value: '', text: 'Nacionalidade' },
                { value: 'Brasileiro', text: 'Brasileiro' },
                { value: 'Alemão', text: 'Alemão' },
                { value: 'Americano', text: 'Americano' },
                { value: 'Argentino', text: 'Argentino' },
                { value: 'Boliviano', text: 'Boliviano' },
                { value: 'Britânico', text: 'Britânico' },
                { value: 'Canadense', text: 'Canadense' },
                { value: 'Chinês', text: 'Chinês' },
                { value: 'Cubano', text: 'Cubano' },
                { value: 'Espanhol', text: 'Espanhol' },
                { value: 'Inglês', text: 'Inglês' },
                { value: 'Francês', text: 'Francês' },
                { value: 'Italiano', text: 'Italiano' },
                { value: 'Japonês', text: 'Japonês' },
                { value: 'Russo', text: 'Russo' },
                { value: 'Suiço', text: 'Suiço' },
            ],
            bancoOptions: [{ value: '', text: 'Selecione' }],
            regraPersonalizadaOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Sim' },
                { value: 0, text: 'Não' }
            ],
            contaAtiva: '',
            contaAtivaOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Ativada' },
                { value: 0, text: 'Desativada' }
            ],
            conveniado: '',
            conveniadoOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Conveniado' },
                { value: 0, text: 'Não Conveniado' }
            ],
            empresa: '',
            nome: '',
            sexo: '',
            email: '',
            nomeMae: '',
            rg: '',
            cpf: '',
            dtNascimento: '',
            celular: '',
            situacao: '',
            matricula: '',
            nacionalidade: 'Brasileiro',
            estadoCivil: '',
            dtAdmissao: '',
            dtFimContrato: '',
            dtDesligamento: '',
            tempoDeCasa: '',
            regraPersonalizada:'',
            minSal: '',
            maxSal: '',
            minParcelas: '',
            maxParcelas: '',
            valorMaximoDePrestacao: '',
            maxParcelaPersonalizada: '',
            banco: '',
            agencia: '',
            conta: '',
            digito: '',
            endereco: {},
            statusEwally: 0,
            motivoReprovaEwally: '',
            cep: '',
            codocupacao: '',
            salario_centavos: 0,
            numero: '',
            bairro: '',
            limitePreAprovado: '',
            limitePreAprovadoContrato: '',
            vplVencimento: '',
            rating: '',
            acoes: [],
            showEditar: false,
            showStatus: false,
            showHistorico: false,
            showAnotacao: false,
            empresaComprometimento: 0,
            taxaJuros: 0,
            scoreQuod: '',
            func_id: '',
            valor: '',
            parcelas: [], 
            salBruto: 0,
            salLiq: 0,
            limiteComprometimento: 0,
            dataInicioConvenio: ''
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ loading: true });
            this.carregaEmpresas();
            this.carregaBancos();
            this.carregaColaborador();
        }
    }

    async carregaEmpresas() {
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var objects = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ value: item.emp_id, text: item.emp_nomefantasia })
                }
                this.setState({ empresasOptions: objects });
            })
            .catch(err => this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa disponível' }] }))
    }

    async carregaBancos() {
        await ApiService.getGeral('banco')
            .then(result => {
                const res = result.data.data;
                var objects = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ value: item.bco_id, text: item.bco_numero + ' - ' + item.bco_nome });
                }
                this.setState({ bancoOptions: objects });
            })
            .catch(err => this.setState({ bancoOptions: [{ value: '', text: 'Nenhum banco disponível' }] }))
    }

    async carregaColaborador() {
        const userData = JSON.parse(localStorage.getItem('userData'));    
        const { colaborador } = this.props.location.state;
             
        this.setState({ loading: true });
        const acoes = Funcoes.getMultAcoesPage(userData.menus, ["/colaboradores","/anotacoes"]);
        await ApiService.getGeral('funcionario/' + colaborador.id)
            .then(result => {
                const item = result.data.data;
                //var max_parc = 0;
                // Valida o status do funcionário para não permitir religar
                if(acoes.indexOf(25) !== -1){
                    this.setState({ showStatus: true });
                }else{
                    if(Number(item.func_situacao) === 4){
                        this.setState(
                            {  
                                situacaoOptions: [
                                    { value: '', text: 'Selecione' },
                                    { value: 4, text: 'Desligado' }
                                ]
                            }
                        );
                    }else{
                        this.setState({ showStatus: true });
                    }
                }
                if(acoes.indexOf(6) !== -1){
                    this.setState({ showEditar: true });
                }
                if(acoes.indexOf(34) !== -1){
                    this.setState({ showHistorico: true });
                }
                if(acoes.indexOf(35) !== -1){
                    this.setState({ showAnotacao: true });
                }
                this.setState({
                    empresa: item.emp_id, nome: item.func_nome, sexo: item.func_sexo, email: item.func_email, nomeMae: item.func_nomemae, rg: item.func_rg, cpf: item.func_cpf,
                    dtNascimento: Funcoes.data2Brazilian(item.func_dtnascimento), celular: item.func_celular, situacao: item.func_situacao, 
                    matricula: item.func_matricula, conveniado: item.func_conveniado, contaAtiva: item.func_ativo,
                    nacionalidade: item.func_nacionalidade, estadoCivil: item.func_estadocivil, dtAdmissao: Funcoes.data2Brazilian(item.func_dtadmissao),
                    dtFimContrato: Funcoes.data2Brazilian(item.func_dtfimcontrato), dataInicioConvenio: Funcoes.dataHora2Brazilian(item.func_dthrinclusao).split(' ')[0], dtDesligamento: Funcoes.data2Brazilian(item.func_dtdesligamento),
                    tempoDeCasa: item.tempo_de_casa + " meses",
                    regraPersonalizada: item.func_regrapersonalizada, 
                    minSal: item.func_qtdsalariominimo, maxSal: item.func_qtdsalariomaximo, taxaJuros: Funcoes.float2Preco((item.func_taxajuros )),
                    minParcelas: item.func_parcelaminimo, maxParcelas: item.func_parcelamaximo, maxParcelaPersonalizada: item.func_maximaparcelapersonalizada, 
                    valorMaximoDePrestacao: 'R$ ' + Funcoes.float2Preco(item.valor_maximo_prestacao),
                    banco: item.bco_id, agencia: item.func_ctaagencia, conta: item.func_ctanumero, digito: item.func_ctadigito, statusEwally: item.func_aprovado, 
                    motivoReprovaEwally: item.func_motivoreprova, rating: item.func_rating,
                    endereco: {
                        cep: item.func_endcep, endereco: item.func_endlogradouro, numero: item.func_endnumero, bairro: item.func_endbairro, cidade: item.cidade ? item.cidade.cid_nome : '',
                        estado: item.cidade ? item.cidade.estado.uf_sigla : '', complemento: item.func_endcomplemento
                    },
                    limitePreAprovado: 'R$ ' + Funcoes.float2Preco(item.func_valorpreaprovado),
                    vplVencimento: 'R$ ' + Funcoes.float2Preco(item.vpl_vencimento),
                    vplRefinanciamento: 'R$ ' + Funcoes.float2Preco(item.vpl_refinanciamento),
                    limitePreAprovadoContrato: 'R$ ' + Funcoes.float2Preco(item.func_valorpreaprovadodisponivel),
                    limiteComprometimento: Funcoes.float2Preco(item.limite_comprometimento),
                    codocupacao: item.func_codocupacao,    
                    salario_centavos: item.func_salariobruto * 100 , salBruto:  Funcoes.float2Preco(item.func_salariobruto), salLiq:  Funcoes.float2Preco(item.func_salarioliquido),
                    empresaComprometimento: item.limite_comprometimento,  scoreQuod: item.func_ultimoscore, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    updateValues(attr, val, type) {
        const {  empresaComprometimento, limiteComprometimento, taxaJuros } = this.state;
        if (type === "cpf") {
            this.setState({ [attr]: cpfMask(val) })
        } else if (type === "nome") {
            this.setState({ [attr]: onlyTextMask(val) })
        } else if (type === "dataBr") {
            this.setState({ [attr]: dataBrMask(val) })
        } else if (type === "numbers") {
            this.setState({ [attr]: onlyNumbersMask(val) })
        } else if (type === "celular") {
            this.setState({ [attr]: celularMask(val) })
        } else {
            if(attr === "regraPersonalizada"){
                if(Number(val) === 0){
                    this.setState({ limiteComprometimento: Funcoes.float2Preco(empresaComprometimento), taxaJuros: taxaJuros })
                }else{
                    this.setState({ limiteComprometimento: limiteComprometimento, taxaJuros: taxaJuros })
                }
            }
            this.setState({ [attr]: val })
        }
    }

    async alterarColaborador() {
        const { empresa, nome, email, nomeMae, rg, cpf, dtNascimento, celular, situacao, matricula, nacionalidade, estadoCivil, dtAdmissao, dtFimContrato, dtDesligamento, 
            regraPersonalizada, minSal, maxSal, minParcelas, maxParcelas, maxParcelaPersonalizada, banco, agencia, conta, digito,endereco, salBruto, salLiq,
            taxaJuros, limiteComprometimento, conveniado, contaAtiva
        } = this.state;
        const { colaborador } = this.props.location.state;

        this.setState({ loading: true });

        let dataPost = {
            func_nome: nome,
            func_email: email,
            func_nomemae: nomeMae,
            func_cpf: cpf,
            func_rg: rg,
            func_conveniado : conveniado,
            func_ativo: contaAtiva,
            func_celular: celular,
            func_matricula: matricula,
            func_nacionalidade: nacionalidade,
            func_estadocivil: estadoCivil,
            func_salariobruto: Funcoes.preco2float(salBruto),
            func_salarioliquido: Funcoes.preco2float(salLiq),
            func_dtnascimento: Funcoes.data2Americano(dtNascimento, '/'),
            func_dtadmissao: Funcoes.data2Americano(dtAdmissao, '/'),
            func_dtfimcontrato: Funcoes.data2Americano(dtFimContrato, '/'),
            func_regrapersonalizada: regraPersonalizada,
            func_taxajuros: Funcoes.preco2float(taxaJuros),
            func_limitecomprometimento: Funcoes.preco2float(limiteComprometimento),
            func_qtdsalariominimo: parseInt(minSal),
            func_qtdsalariomaximo: parseInt(maxSal),
            func_parcelaminimo: parseInt(minParcelas),
            func_parcelamaximo: parseInt(maxParcelas),
            func_situacao: parseInt(situacao),
            func_dtdesligamento: dtDesligamento ? Funcoes.data2Americano(dtDesligamento, '/') : '',
            bco_id: banco,
            func_ctaagencia: agencia,
            func_ctanumero: conta,
            func_ctadigito: digito,
            emp_id: empresa,
            func_endlogradouro: endereco.endereco,
            func_endnumero: endereco.numero,
            func_endbairro: endereco.bairro,
            func_endcomplemento: endereco.complemento,
            func_endcep: endereco.cep,
            func_maximaparcelapersonalizada: maxParcelaPersonalizada
        };
        await ApiService.putGeral('funcionario/' + colaborador.id, dataPost)
            .then(res => {
                this.setState({ alertText: 'Colaborador alterado com sucesso', alertType: 1, loading: false, alert: true });
                this.carregaColaborador();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async resetaColaborador() {
        const { colaborador } = this.props.location.state;
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.setState({ loading: true });
        await ApiService.getGeral('funcionario/resetar/' + colaborador.id + '?usu_id=' + userData.usuario.id)
            .then(res => {
                this.setState({ alertType: 1, alertText: 'O colaborador foi resetado com sucesso!', loading: false, alert: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: '' });
                } else if (valida.status === "badRequest") {
                    this.setState({ path: '' });
                } else {
                    this.setState({ path: '' });
                }
                this.setState({ alertText: valida.response ? valida.response : 'Não foi possível resetar o usuário', alertType: 2, loading: false, alert: true });
            })
    }

    async calculaScore(){
        const userData = JSON.parse(localStorage.getItem('userData'));
        const {cpf, nome, dtNascimento} = this.state;
        let dataPost = {
            usu_id: userData.usuario.id,
            func_cpf: cpf,
            func_nome: nome,
            func_dtnascimento: Funcoes.data2Americano(dtNascimento)
        };
        this.setState({ loading: true });
        await ApiService.postGeral('consultaneurotech', dataPost)
            .then(result => {
                this.carregaColaborador();
                //this.setState({ loading: false, propsToPath: {colaborador: colaborador.id}, path: '/colaboradores/logneurotech', redirect: true});
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async simulador(){
        const { colaborador } = this.props.location.state;
        const {cpf, dtNascimento, celular, valor} = this.state;
        this.setState({ path: '/colaboradores/simulacao', propsToPath: { 
            colaborador: {
                id: colaborador.id, 
                cpf: cpf,
                dtNascimento: dtNascimento, 
                celular: celular, 
                valor: valor 
            } 
        }, redirect: true });

    }

    async verificarUltimoScore(){
        
        const { colaborador } = this.props.location.state;
        this.setState({ loading: false, propsToPath: {colaborador: colaborador.id}, path: '/colaboradores/logneurotech', redirect: true});
    }

    async handleCep(e) {
        const { endereco } = this.state;
        const val = e.target.value;
        this.setState({ endereco: { ...endereco, cep: cepMask(val) } });
        if (val.length === 9) {
            this.setState({ loading: true });
            await ApiService.postGeral('consultacep', { cep: val })
                .then(result => {
                    const res = result.data.data;
                    document.getElementById('endNum').focus();
                    this.setState({
                        endereco: { cep: res.cep, endereco: res.logradouro, bairro: res.bairro, complemento: res.complemento, cidade: res.localidade, estado: res.estado },
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({ loading: false });
                })
        }
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, alertType, empresasOptions, situacaoOptions, estadoCivilOptions, bancoOptions, empresa, nome,
            email, nomeMae, rg, cpf, dtNascimento, celular, situacao, matricula, nacionalidade, estadoCivil, dtAdmissao, dataInicioConvenio, dtFimContrato, dtDesligamento, tempoDeCasa,
            maxParcelas, maxParcelaPersonalizada, valorMaximoDePrestacao, banco, agencia, conta, digito, nacionalidadeOptions, endereco,
            limitePreAprovado, limitePreAprovadoContrato, vplRefinanciamento, vplVencimento, showEditar, showStatus, regraPersonalizada, 
            rating, regraPersonalizadaOptions,contaAtivaOptions, contaAtiva, conveniadoOptions, conveniado, scoreQuod, salBruto, salLiq, limiteComprometimento, taxaJuros} = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="editar-colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Editar Colaborador</h1>
                                <p className="descricao">Parametrize abaixo o colaborador.</p>
                            </div>
                           
                        </div>
                        <div className="headerBtn">
                            <button className="btn" style={{ width: 240, marginTop: 10 }} onClick={() => this.setState({ propsToPath: this.props.location.state, path: '/colaboradores/anotacoes', redirect: true })}>Anotações</button>
                            <button className="btn" style={{ width: 240, marginTop: 10 }} onClick={() => this.resetaColaborador()}>Resetar Cadastro</button>
                            <button className="btn" style={{ width: 240, marginTop: 10 }} onClick={() => this.calculaScore()}>Rodar Motor de Crédito</button>
                        </div>
                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Trabalho</h3>
                            </div>
                            <div className="panelBody">
                                <Input type="select" optionsData={empresasOptions} label="Empresa" divStyle={{ maxWidth: 700 }} value={empresa}
                                    onChange={e => this.updateValues('empresa', e.target.value)} />
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Pessoais</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="text" label="Nome Completo" value={nome} onChange={e => this.updateValues('nome', e.target.value, 'nome')} />
                                    <Input type="text" label="E-mail" value={email} onChange={e => this.updateValues('email', e.target.value)} />
                                    <Input type="text" label="Nome Mãe" value={nomeMae} onChange={e => this.updateValues('nomeMae', e.target.value)} style={{ marginRight: 0 }} />
                                </div>
                                <div className="multInput">
                                    <Input type="tel" label="RG" value={rg} onChange={e => this.updateValues('rg', e.target.value, 'numbers')}
                                        maxLength="20" />
                                    <Input type="tel" label="CPF" value={cpf} onChange={e => this.updateValues('cpf', e.target.value, 'cpf')}
                                        maxLength="14" />
                                    <Input type="tel" label="Data de Nascimento" value={dtNascimento} maxLength="10"
                                        onChange={e => this.setState({ dtNascimento: dataBrMask(e.target.value) } )} />
                                    <Input type="tel" label="Celular" placeholder="(00) 00000-0000" value={celular} onChange={e => this.updateValues('celular', e.target.value, 'celular')}
                                        style={{ marginRight: 0 }} />
                                </div>
                                <div className="multInput">
                                    <Input type="select" optionsData={situacaoOptions} label="Situação" value={situacao}
                                            onChange={e => this.updateValues('situacao', e.target.value)} disabled={!showStatus} readOnly={!showStatus} />
                                    <Input type="text" label="Matrícula" value={matricula} onChange={e => this.updateValues('matricula', e.target.value)} />
                                    <Input type="select" label="Nacionalidade" value={nacionalidade} optionsData={nacionalidadeOptions}
                                        onChange={e => this.updateValues('nacionalidade', e.target.value)} />
                                    <Input type="select" value={estadoCivil} optionsData={estadoCivilOptions} label="Estado Civil" style={{ marginRight: 0 }}
                                        onChange={e => this.updateValues('estadoCivil', e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Endereço</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="text" label="CEP" value={endereco.cep} onChange={e => this.handleCep(e)} />
                                    <Input type="text" label="Endereço" divStyle={{ flexGrow: 10 }} value={endereco.endereco}
                                        onChange={e => this.setState({ endereco: { ...endereco, endereco: e.target.value } })} />
                                    <Input type="tel" label="Número" value={endereco.numero} id="endNum"
                                        onChange={e => this.setState({ endereco: { ...endereco, numero: e.target.value } })} />
                                </div>
                                <div className="multInput">
                                    <Input type="text" label="Bairro" value={endereco.bairro} onChange={e => this.setState({ endereco: { ...endereco, bairro: e.target.value } })} />
                                    <Input type="text" label="Complemento" value={endereco.complemento}
                                        onChange={e => this.setState({ endereco: { ...endereco, complemento: e.target.value } })} />
                                    <Input type="text" label="Cidade" value={endereco.cidade} disabled className="disabled" />
                                    <Input type="text" label="Estado" value={endereco.estado} disabled className="disabled" />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Internos</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="tel" label="Data de inicío de convênio" value={dataInicioConvenio} maxLength="10" className="disabled" disabled={true}
                                       onChange={e => this.setState({ dataInicioConvenio: dataBrMask(e.target.value) } )} />
                                    <Input type="tel" label="Data de Admissão" value={dtAdmissao} maxLength="10"
                                       onChange={e => this.setState({ dtAdmissao: dataBrMask(e.target.value) } )} />
                                    <Input type="tel" label="Salário Bruto" value={salBruto} onChange={e => this.setState({ salBruto: moneyMask(e.target.value, false) })} className="salBruto" placeholder="0,00" />
                                    <Input type="tel" label="Salário Liquído" value={salLiq} onChange={e => this.setState({ salLiq: moneyMask(e.target.value, false) })}  className="salLiq" placeholder="0,00" />
                                </div>
                                <div className="multInput">
                                    <Input type="tel" label="Data de Desligamento" value={dtDesligamento} maxLength="10"
                                        onChange={e => this.setState({ dtDesligamento: dataBrMask(e.target.value) } )} />
                                    <Input type="tel" label="Data Fim de Contrato" value={dtFimContrato} maxLength="10"
                                        onChange={e => this.setState({ dtFimContrato: dataBrMask(e.target.value) } )} /> 
                                    <Input type="select" value={contaAtiva} optionsData={contaAtivaOptions} label="Conta Ativa"
                                        onChange={e => this.updateValues('contaAtiva', e.target.value)} />   
                                    <Input type="select" value={conveniado} optionsData={conveniadoOptions} label="Conveniado" style={{ marginRight: 0 }}
                                        onChange={e => this.updateValues('conveniado', e.target.value)} />
                                </div>    
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados do Crédito</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="select" optionsData={regraPersonalizadaOptions} label="Regra Personalizada" divStyle={{ maxWidth: 200 }} value={regraPersonalizada}
                                    onChange={e => this.updateValues('regraPersonalizada', e.target.value)} />
                                    <Input type="text" label="Rating" value={rating} disabled={true} divStyle={{ flexGrow: 0, maxWidth: 120 }} className="disabled" />
                                    <Input type="tel" label="Taxa de Juros" className={Number(regraPersonalizada) === 0 ? "disabled txJuros" : "txJuros"} value={taxaJuros} onChange={e => this.setState({ taxaJuros: moneyMask(e.target.value, false) })} placeholder="0,00" disabled={Number(regraPersonalizada) === 0 ? true : false} 
                                        divStyle={{ flexGrow: 0, maxWidth: 204 }} />
                                    <Input type = "tel" label = "Score Quod" divStyle = {{ flexGrow: 0, maxWidth: 204 }} value = {scoreQuod} disabled = { true } className = "disabled"
                                        maxLength = "4" />
                                    <Input type="tel" label="Max. Parcelas" divStyle={{ flexGrow: 0, maxWidth: 204 }} value={maxParcelas } disabled={true} className={"disabled"}
                                        onChange={e => this.updateValues('maxParcelas', e.target.value, 'numbers')} maxLength="2" />
                                    <Input type="text" label="Tempo De Casa" divStyle={{ flexGrow: 0, maxWidth: 300, marginRight: 0 }} value={tempoDeCasa}
                                            disabled className="disabled" />    
                                    <Input type="tel" label="Max. Parcelas Personalizada" divStyle={{ flexGrow: 0, maxWidth: 204 }} value={maxParcelaPersonalizada} disabled={Number(regraPersonalizada) === 0 ? true : false} className={Number(regraPersonalizada) === 0 ? "disabled" : ""}
                                        onChange={e => this.updateValues('maxParcelaPersonalizada', e.target.value, 'numbers')} maxLength="2" />
                                    <Input type="tel" label="Limite Comprometimento" className={Number(regraPersonalizada) === 0 ? "disabled limiteComp" : "limiteComp"} value={limiteComprometimento} onChange={e => this.setState({ limiteComprometimento: moneyMask(e.target.value, false) })}  placeholder="0,00" disabled={Number(regraPersonalizada) === 0 ? true : false} 
                                        divStyle={{ flexGrow: 0, maxWidth: 204 }} />
                                    {limitePreAprovado ? (
                                        <Input type="tel" label="Limite Pré-aprovado" divStyle={{ flexGrow: 0, maxWidth: 300, marginRight: 0 }} value={limitePreAprovado}
                                            disabled className="disabled" />
                                    ) : null}
                                    {vplVencimento ? (
                                        <Input type="tel" label="Vpl no Vencimento" divStyle={{ flexGrow: 0, maxWidth: 300, marginRight: 0 }} value={vplVencimento}
                                            disabled className="disabled" />
                                    ) : null}
                                    {vplRefinanciamento ? (
                                        <Input type="tel" label="Vpl de Refinanciamento" divStyle={{ flexGrow: 0, maxWidth: 300, marginRight: 0 }} value={vplRefinanciamento}
                                            disabled className="disabled" />
                                    ) : null}
                                    {limitePreAprovadoContrato ? (
                                        <Input type="tel" label="Limite Pré-aprovado Disponível" divStyle={{ flexGrow: 0, maxWidth: 300, marginRight: 0 }} value={limitePreAprovadoContrato}
                                            disabled className="disabled" />
                                    ) : null}
                                    <Input type="text" label="Valor Máxima de Prestação" divStyle={{ flexGrow: 0, maxWidth: 300, marginRight: 0 }} value={valorMaximoDePrestacao}
                                            disabled className="disabled" />        
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Bancários</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="select" optionsData={bancoOptions} label="Banco" value={banco}
                                        onChange={e => this.updateValues('banco', e.target.value)} />
                                    <Input type="tel" label="Agência" value={agencia} onChange={e => this.updateValues('agencia', e.target.value)}
                                        maxLength="10" />
                                    <Input type="tel" label="Conta" value={conta} onChange={e => this.updateValues('conta', e.target.value)}
                                        maxLength="10" />
                                    <Input type="tel" label="Dígito" divStyle={{ maxWidth: 100, marginRight: 0 }} value={digito}
                                        onChange={e => this.updateValues('digito', e.target.value)} maxLength="1" />
                                </div>

                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/colaboradores" className="btn btnVoltar">{'<'} Voltar</Link>
                            {showEditar ? (<>
                                <button className="btn btnCadastrar" onClick={() => this.alterarColaborador()}>Alterar</button>
                            </>) : '' }
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}