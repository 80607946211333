import ApiService from '../Utils/services';
class CompanyDataService {

  retrieve(obj){
    console.log(obj)
    return obj;
  }    

  getAll() {
  }

}
export default new CompanyDataService();