import React, { Component, Fragment } from 'react';

export default class PageBottomActions extends Component {
    render() {
        const {children} = this.props;
        return (
            <Fragment>
                <div className="page-bottom-actions-component">
                    {children}
                </div>
            </Fragment>
        );
    }
}
