import React, { Component, Fragment } from 'react';
//import {Row,Col} from 'react-bootstrap';
import iconAviso from '../../resources/img/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import { dataBrMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';

export default class Edufin extends Component {

    constructor() {
        super();

        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoExcluir = this.acaoExcluir.bind(this);

        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            modal: false,
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            ativoOptions: [
                { value: '', text: 'Selecione' },
                { value: '0', text: 'Desativado' },
                { value: '1', text: 'Ativo' },
            ],
         
            table: {
                campos: [
                    { titulo: 'Titulo', dado: 'titulo' },
                    { titulo: 'Data/Hora', dado: 'datahora' },
                    { titulo: 'Ativo', dado: 'ativo' },
                    { titulo: 'Ações', dado: 'acoes'}
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            edufinDefault: {},
            titulo: '',
            ativo: '',
            data: '',
            acoes: [],
            idPost: ''
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });

        this.carregaPostagens(1, acoes);
    }

    async carregaPostagens(page, acoes) {
        const { table, titulo, ativo, data } = this.state;
        var url = "edufin?per_page=20&" + "page=" + page  

        url += titulo ? '&edufin_titulo='  + titulo : "";
        url += ativo ? '&edufin_ativo='    + ativo : "";
        url += data ? '&edufin_datahora=' + data : "";
        this.setState({ loading: true });
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    var arrAtivo = ['Desativado', 'Ativado'];
                    let arrData = item.edufin_datahora.split(" ")
                    console.log(arrData)
                    objects[i] = {
                        id: item.edufin_id, tipo: item.edufin_tipo, titulo: item.edufin_titulo,
                        resumo: item.edufin_resumo, texto: item.edufin_texto,
                        url: item.edufin_urlimagem, destaque: item.edufin_destaque,
                        datahora: Funcoes.data2Brazilian(arrData[0]), ativo: arrAtivo[item.edufin_ativo],
                        categoria: item.edufin_categoria, acoes: 'default|excluir'
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, edufinDefault: result.data, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }


    acaoEditar(id) {
        this.setState({ path: '/edufin/novo', propsToPath: { edufin: { id } }, redirect: true });
    }

    acaoExcluir(idPost, titulo) {
        this.setState({ titulo, idPost, modal: true });
    }

    async excluirPost() {
        const { idPost } = this.state;
        this.setState({ loading: true });
        await ApiService.customRequest('geral/edufin/' + idPost, 'DELETE', {})
            .then(res => {
                this.setState({ modal: false });
                this.carregaPostagens(1);
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handleFiltro(e) {
        const val = e.target.value;
            this.setState({ titulo: val });
    }

    updateValues(attr, val, type) {
        this.setState({ [attr]: dataBrMask(val) })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/edufin?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/edudin', redirect: true });
        }
        this.carregaPostagens(page);
    }


    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, colaborador, table, edufinDefault,
            exibeDados, mostraVazio, modal, ativoOptions, titulo, ativo, data } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Modal show={modal}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de Aviso" />
                        <p>Você realmente deseja excluir o post do Edufin <b>{this.state.nome}</b>?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.excluirPost()}>Confirmar</button>
                            <button className="btn" onClick={() => this.setState({ modal: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Edufin</h1>
                                <p className="descricao">Abaixo a listagem de todos os posts da Edufin cadastrados na plataforma</p>
                            </div>
                            {//acoes.indexOf(1) !== -1 ?
                                <button className="btn" onClick={() => this.setState({ path: '/edufin/novo', redirect: true })}>Novo Post</button>
                            //: null
                        }
                        </div>
                        <div className="panel">
                            <div className="panelBody">
                                <div className="row" >
                                    <Input type="text" isFilterGroups={true} label="Título" value={titulo}
                                        onChange={e => this.setState({ titulo: e.target.value })} />
                                    <Input type="date" isFilterGroups={true} label="Data"  value={data}
                                        onChange={e => this.setState({ data: e.target.value })} />
                                    <Input type="select" isFilterGroups={true} optionsData={ativoOptions} label="Ativo"
                                        value={ativo} onChange={e => this.setState({ ativo: e.target.value })} />
                                     <button className="btn filtrar" onClick={() => this.carregaPostagens(1)}>Filtrar</button>
                                </div>
                                
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar}
                                        acaoExcluir = {this.acaoExcluir} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhuma postagem encontrada!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <p>Total de registros: {edufinDefault.total}</p>
                                    <Paginacao dados={edufinDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}