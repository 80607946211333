import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/img/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

export default class ResetHistoricCollaborator extends Component {

    constructor() {
        super();
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            modal: false,
            alert: false,
            alertText: '',
            nome: '',
            table: {
                campos: [
                    { titulo: 'Usuário', dado: 'usuario' },
                    { titulo: 'Data e Hora', dado: 'datahora' },
                    { titulo: 'Tipo', dado: 'tipo' },
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });
        this.carregaHistorico(1, acoes);
    }

    async carregaHistorico(page, acao) {
        const { table, acoes } = this.state;
        const { colaborador } = this.props.location.state;

        this.setState({ loading: true });

        var url, pagina, pagAtual, actions;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;
        actions = acao ? acao : acoes; 

        if (pagAtual) {
            url = "funcionariohistoricoreset?per_page=20&page=" + pagAtual;
        } else {
            url = "funcionariohistoricoreset?per_page=20";
        }

        url += "&func_id="+colaborador.id;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    var arrTipos = ['', 'Atualizacao Ewally', 'Resubmissao Ewall', 'Reset Cadastro interno'];
                    var datahora = Funcoes.dataHora2Brazilian(item.fhistres_datahora);
                    objects[i] = {
                        usuario: item.usuario.usu_nome, datahora: datahora, tipo: arrTipos[item.fhistres_tipo]
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    voltar() {
        this.setState({ path: '/colaboradores/editar', propsToPath: this.props.location.state, redirect: true });
    }

    render() {
        const { loading, redirect, path, propsToPath, modal, alert, alertText, nome, table, exibeDados, acoes } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="usuario-empresa mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Histórico de Reset</h1>
                                <p className="descricao">Abaixo a listagem de histórico de reset do funcionário.</p>
                            </div>
                        </div>

                        <div className="detalhes">

                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados} />
                                </>) : null}
                                {!exibeDados ? (
                                    <p className="textEmpty">Nenhum histórico do colaborador encontrado!</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="acoes">
                            <button className="btn btnVoltar" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.voltar()}>{'<'} Voltar</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}