import {
    ADD_COMPANY,
    REMOVE_COMPANY,
    DELETE_ALL_COMPANYS,
    RETRIEVE_ALL_COMPANYS
} from "../Actions/types";

const initialState = [];

function companyReducer(empresas = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_COMPANY:
      if(empresas.find(x => x.id === payload.id)){
        return empresas;
      }
      return [...empresas, payload];

    case REMOVE_COMPANY:
      return empresas.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_COMPANYS:
      return [];
      
    case RETRIEVE_ALL_COMPANYS:
      return empresas;
      
    default:
      return empresas;  
  }
};

export default companyReducer;