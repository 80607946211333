import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import { validaForm } from '../../Utils/FormValidator';

export default class NewUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            alertType: 2,
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 0, text: 'Inativo' },
                { value: 1, text: 'Ativo' }
            ],
            perfisOptions: [{ value: '', text: 'Selecione' }],
            nome: '',
            email: '',
            senha: '',
            status: 1,
            perfil: ''
        };
    }

    componentDidMount() {
        this.listaPerfis();
    }

    async listaPerfis() {
        this.setState({ loading: true });
        await ApiService.getGeral('perfilacesso')
            .then(result => {
                const res = result.data.data;
                var objects = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    objects.push({ value: res[i].perf_id, text: res[i].perf_nome });
                }
                this.setState({ perfisOptions: objects, loading: this.props.location.state ? true : false });
                if (this.props.location.state) this.getUsuario();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
            })
    }

    async getUsuario() {
        const { usuario } = this.props.location.state;
        await ApiService.getGeral('usuario/' + usuario.id)
            .then(result => {
                const res = result.data.data;
                this.setState({ id: res.usu_id, perfil: res.perf_id, nome: res.usu_nome, email: res.usu_email, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
            })
    }

    handleInputs = (name, value) => {
        this.setState({ [name]: value });
    }

    validaCampos() {
        const { nome, email, senha, status, perfil } = this.state;
        const form = [
            { campo: 'Perfil de Acesso', attr: 'perfil', valor: perfil },
            { campo: 'Nome', attr: 'nome', valor: nome },
            { campo: 'E-mail', attr: 'email', valor: email, tipo: 'email' },
        ];
        if (!this.props.location.state)
            form.push({ campo: 'Senha', attr: 'senha', valor: senha });

        const valida = validaForm(form);
        if (valida.valido && (status || status === 0)) {
            this.setState({ loading: true });

            const formData = {
                "usu_nome": nome,
                "usu_email": email,
                "usu_senha": senha,
                "perf_id": perfil
            }

            if (this.props.location.state) {
                this.editaUsuario(formData);
            } else {
                this.adicionaUsuario(formData);
            }
        } else {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertMessage: '* ' + item.mensagem, alertType: 2, alert: true });
                    break;
                }
            }
        }
        if (!status)
            this.setState({ alertMessage: '* Status não pode ficar vazio!', alertType: 2, alert: true });
    }

    async adicionaUsuario(form) {
        ApiService.postGeral('usuario', form)
            .then(result => {
                this.setState({ path: '/usuarios', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
            })
    }

    async editaUsuario(form) {
        const { usuario } = this.props.location.state;
        ApiService.putGeral('usuario/' + usuario.id, form)
            .then(result => {
                this.setState({ alertMessage: 'O Usuário foi alterado com sucesso!', alertType: 1, alert: true, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
            })
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, alertType, perfisOptions, nome, email, senha, perfil } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => {
                    if (alertType === 1) {
                        this.setState({ alert: false });
                        this.getUsuario();
                    } else {
                        this.setState({ alert: false, redirect: path ? true : false })
                    }
                }} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={12} />

                <div className="body">
                    <Header />

                    <div className="configuracao mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>{this.props.location.state ? 'Editar' : 'Incluir'} Usuários</h1>
                                <p className="descricao">{this.props.location.state ? 'Editar' : 'Incluir novo'} usuário para acesso a plataforma.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Informações</h3>
                            </div>
                            <div className="panelBody">
                                <Input type="select" label="Perfil de Acesso" optionsData={perfisOptions} style={{ maxWidth: 250 }} value={perfil}
                                    onChange={e => this.handleInputs('perfil', e.target.value)} />
                                <div className="multiInput" style={{ marginTop: 20 }}>
                                    <Input type="text" label="Nome" grow={2} value={nome} onChange={e => this.handleInputs('nome', e.target.value)} />
                                    <Input type="email" label="E-mail" grow={2} value={email} onChange={e => this.handleInputs('email', e.target.value)} />
                                    <Input type="password" label="Senha" grow={0} value={senha} onChange={e => this.handleInputs('senha', e.target.value)} />
                                    {/* <Input type="select" label="Status" optionsData={statusOptions} value={status} onChange={e => this.handleInputs('status', e.target.value)} /> */}
                                </div>
                            </div>
                        </div>

                        <div className="actions">
                            <Link to="/usuarios" className="btn">{'<'} Voltar</Link>
                            <button className="btn bg-roxo cl-branco" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Salvar'}</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}