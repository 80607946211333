import React, { Component, Fragment } from 'react';

import logoInManoBranco from '../resources/img/logo-inmano-branco.svg';
import iconExpandir from '../resources/img/icon-expandir.svg';

import { Link } from 'react-router-dom';

export default class SideMenu extends Component {

    constructor() {
        super();

        this.state = {
            nomeUsuario: '',
            menus: [
                // { nome: 'Dashboard', url: '/home', icone: iconDashboard },
                // { nome: 'Empresas', url: '/empresas', icone: iconEmpresas },
                // { nome: 'Usuário Empresa', url: '/usuario-empresa', icone: iconUsuariosEmp },
                // { nome: 'Colaboradoes', url: '/colaboradores', icone: iconColaboradores },
                // { nome: 'Contratos', url: '/contratos', icone: iconContratos },
                // { nome: 'Vencimentos', url: '/vencimentos', icone: iconVencimentos },
                // { nome: 'Faturas', url: '/faturas', icone: iconFaturas },
                // { nome: 'Desligamentos', url: '/desligamento', icone: iconDesligamento },
                // { nome: 'Emp. Pessoal', url: '/emprestimo-pessoal', icone: iconEmpPessoal },
                // { nome: 'Solici. Pend.', url: '/solicitacoes-pendentes', icone: iconPendentes },
                // { nome: 'Motivo Empréstimo', url: '/motivo-emprestimo', icone: iconPendentes }
            ]
        }
    }

    componentDidMount() {
        const userData = localStorage.getItem('userData');
        if (userData) {
            const user = JSON.parse(userData);
            this.setState({ nomeUsuario: user.usuario.nome, menus: user.menus });
        } else {
            this.setState({ nomeUsuario: 'Usuário' })
        }
    }

    handleDropdown = (idx) => {
        var dropdown = document.getElementsByClassName("dropdown-container");
        var btns = document.getElementsByClassName("dropdown-btn");
        var imgs = document.getElementsByClassName("iconExpandir");

        for (var x = 0; x < btns.length; x++) {
            if (btns[x].attributes.rel.value == idx) {
                if (btns[x].classList.contains('ativo')) {
                    var el = btns[x];
                    setTimeout(() => {
                        el.classList.remove('ativo');
                    }, 350);
                } else {
                    btns[x].classList.add('ativo');
                }
            }
        }

        for (var i = 0; i < dropdown.length; i++) {
            if (dropdown[i].attributes.rel.value == idx) {
                if (dropdown[i].style.display === "block") {
                    dropdown[i].style.height = "0";
                    dropdown[i].classList.remove('active');
                    var element = dropdown[i];
                    setTimeout(() => {
                        element.style.display = "none"
                    }, 350);
                } else {
                    dropdown[i].style.display = "block";

                    const height = dropdown[i].scrollHeight + "px";
                    dropdown[i].classList.add('active');
                    dropdown[i].style.height = height;
                }
            }
        }

        for (var y = 0; y < imgs.length; y++) {
            if (imgs[y].attributes.rel.value == idx) {
                if (imgs[y].classList.contains('ativo')) {
                    imgs[y].classList.remove('ativo');
                } else {
                    imgs[y].classList.add('ativo');
                }
            }
        }
    }

    render() {
        const { menuAtivo } = this.props;
        const { nomeUsuario, menus } = this.state;
        return (
            <Fragment>
                <div className="sideMenuBar bg-roxo">
                    <div className="profile">
                        <img src={logoInManoBranco} alt="Logo InMano" />
                        <p>Olá, {nomeUsuario}.</p>
                    </div>
                    <div className="navigation">
                        <ul>
                            {menus.map((item, idx) => {
                                var btnClass = "dropdown-btn";
                                const ultimoItem = (idx + 1) === menus.length ? "noBorder" : "";
                                const url = window.location.pathname.split("/");
                                if ("/"+url[1] === item.bmnu_url) btnClass = btnClass + " active";
                                if (item.filhos.length > 0) {
                                    return (
                                        <li key={idx}>
                                            <a className={btnClass} rel={idx} onClick={() => this.handleDropdown(idx)}>
                                                <div dangerouslySetInnerHTML={{ __html: item.bmnu_icone }} className="svgAbsolute" /> {item.bmnu_nome}
                                                <img src={iconExpandir} className="iconExpandir" rel={idx} alt="Expandir menu" style={{ width: 15, marginLeft: 'auto' }} />
                                            </a>
                                            <div className="dropdown-container" rel={idx}>
                                                {item.filhos.map((child, i) => {
                                                    return (
                                                        <Link key={i + "a"} to={child.bmnu_url}>&raquo; &nbsp;{child.bmnu_nome}</Link>
                                                    )
                                                })}
                                            </div>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li key={idx}>
                                            <Link to={item.bmnu_url} className={"/"+url[1] === item.bmnu_url ? `active ${ultimoItem}` : ultimoItem}
                                                style={menuAtivo === (idx + 2) ? { borderBottom: 'none' } : {}}>
                                                <div className="menuIcone">
                                                    <div dangerouslySetInnerHTML={{ __html: item.bmnu_icone }} /> <span>{item.bmnu_nome}</span>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}