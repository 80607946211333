import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import { dataBrMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';


export default class MonthlyVertRenegotiation extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            exibeDados: false,
            mostraVazio: false,
            vertsDefault: {},
            totalSelecionado: 0,
            data_inicio: '',
            data_fim: '',
            acoes: []
        };
    }

    componentDidMount() {}

    async baixarConciliacao(){
        const { data_inicio, data_fim } = this.state;
        let url = "/geral/vert/renegociacao"
        url += data_inicio ? "?data_inicio="+Funcoes.data2Americano(data_inicio, '/') : "";
        url += data_fim ? "&data_fim="+Funcoes.data2Americano(data_fim, '/') : "";
        this.setState({ loading: true });
        var type = "application/vnd.ms-excel;";

        let hoje = new Date()
        
        const yyyy = hoje.getFullYear();
        let mm = hoje.getMonth() + 1; // Months start at 0!
        let dd = hoje.getDate();
        
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "text/csv");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: type });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Renegociações_" +  dd + "_"+ mm + "_" + yyyy + ".csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.onerror = function (err) {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") { this.setState({ path: '' }); }
            if (valida.status === "badRequest") { this.setState({ path: '' }); }

            this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
        };
        xhr.send();

    }
   
    updateValues(attr, val, type) {
        this.setState({ [attr]: dataBrMask(val) })
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, alertType, data_inicio, data_fim } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={18} />

                <div className="body">
                    <Header />

                    <div className="colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Relatório de renegociações</h1>
                                <p className="descricao">Abaixo a listagem das renegociações para conciliação mensal da vert</p>
                            </div>
                           
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row" >
                                    <Input type="tel" isFilterGroups={true} label="Dt. Início" value={data_inicio}
                                        onChange={e => this.updateValues('data_inicio', e.target.value)} />
                                    <Input type="tel" isFilterGroups={true} label="Dt. Fim"  value={data_fim}
                                        onChange={e => this.updateValues('data_fim', e.target.value)} />
                                    <button className="btn filtrar" onClick={() => data_inicio || data_fim ? this.baixarConciliacao() :
                                        this.setState({ alertText: 'Informe uma data para continuar!', path: null, alert: true })}>Download</button>
                                </div>
                            </div>
                        </div>

                       
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}
// const mapStateToProps = (state) => {
//     return {
//         conciliacoes: state.Conciliation
//     };
// }

//export default connect(mapStateToProps,{ addConciliacao ,removeConciliacao, retrieveAllConciliacao, deleteAllConciliacaos })(VertConciliation);