import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/img/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';

export default class EconomicGroup extends Component {

    constructor() {
        super();

        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoExcluir = this.acaoExcluir.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            modal: false,
            alert: false,
            alertText: '',
            nome: '',
            table: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: 'Ações', dado: 'acoes' },
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            gruposDefault:{},
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });
        this.carregaGrupos(1,acoes);
    }

    async carregaGrupos(page, acao) {
        const { table, nome, acoes } = this.state;
        this.setState({ loading: true });
        var actions = acao ? acao : acoes

        const userData = JSON.parse(localStorage.getItem('userData'));
        var url = "grupoeconomico?per_page=20";
        if(page){
            url += "&page=" + page; 
        }

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    var tableAction = "";
                    if(actions.indexOf(30) !== -1 && actions.indexOf(33) !== -1){
                        tableAction = "default|excluir";
                    }else{
                        if(actions.indexOf(30) !== -1){
                            tableAction = "default";
                        }
                        if(actions.indexOf(33) !== -1){
                            tableAction = tableAction + "|excluir";
                        }
                    }
                    objects[i] = {
                        id: item.gpec_id, nome: item.gpec_nome.toUpperCase(), acoes: tableAction,
                        nomeExcluir: item.gpec_nome
                    };
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, loading: false, gruposDefault: result.data
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        this.setState({ path: '/grupos-economicos/novo', propsToPath: { grupo: { id } }, redirect: true });
    }

    acaoExcluir(idGrupo, nome) {
        this.setState({ nome, idGrupo, modal: true });
    }

    async excluirGrupo() {
        const { idGrupo } = this.state;
        this.setState({ loading: true });
        await ApiService.customRequest('geral/grupoeconomico/' + idGrupo, 'DELETE', {})
            .then(res => {
                this.setState({ modal: false });
                this.carregaGrupos(1);
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handlePage(page) {
        const {acoes} = this.state;
        if (page) {
            this.setState({ path: '/grupos-economicos?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/grupos-economicos', redirect: true });
        }
        this.carregaGrupos(page);
    }

    render() {
        const { loading, redirect, path, propsToPath, modal, alert, alertText, nome, table, exibeDados, mostraVazio, gruposDefault, acoes } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Modal show={modal}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de Aviso" />
                        <p>Você realmente deseja excluir o grupo econômico <b>{this.state.nome}</b>?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.excluirGrupo()}>Confirmar</button>
                            <button className="btn" onClick={() => this.setState({ modal: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={14} />

                <div className="body">
                    <Header />
                    <div className="usuario-empresa mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Grupos Econômicos</h1>
                                <p className="descricao">Abaixo a listagem de todos os grupos econômicos de empresas</p>
                            </div>
                            {acoes.indexOf(31) !== -1 ?
                                <button className="btn" onClick={() => this.setState({ path: '/grupos-economicos/novo', redirect: true })}>Novo Grupo</button>
                            : null}
                        </div>
                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar}
                                        acaoExcluir={this.acaoExcluir} />
                                </>) : null}
                                 {mostraVazio ? (
                                    <p className="textEmpty">Nenhum grupo econômico encontrado!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <Paginacao dados={gruposDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}