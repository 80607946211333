import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import ApiService from '../../Utils/services';

import { Link } from 'react-router-dom';

export default class NewUserCompany extends Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Ativo' },
                { value: 0, text: 'Inativo' }
            ],
            motivo: '',
            status: ''
        };
    }

    componentDidMount() {
        if (this.props.location.state) this.getReason();
    }

    async getReason() {
        const { razao } = this.props.location.state;
        this.setState({ loading: true });
        const userData = JSON.parse(localStorage.getItem('userData'));

        await ApiService.getGeral('propostamotivo/' + razao.id)
            .then(result => {
                const res = result.data.data;
                this.setState({ motivo: res.pmtv_descricao, status: res.pmtv_ativo, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    validaCampos() {
        const { motivo, status } = this.state;
        if (!motivo) {
            this.setState({ alertText: 'O campo Motivo não pode ficar vazio!', alert: true });
        } else {
            if (status || status === 0) {
                this.setState({ loading: true });
                const userData = JSON.parse(localStorage.getItem('userData'));
                const formData = {
                    "pmtv_descricao": motivo,
                    "pmtv_ativo": status
                };
                if (this.props.location.state) {
                    this.editarMotivo(formData);
                } else {
                    this.novoMotivo(formData);
                }
            } else {
                this.setState({ alertText: 'O campo Status não pode ficar vazio!', alert: true });
            }
        }
    }

    async novoMotivo(form) {
        await ApiService.postGeral('propostamotivo', form)
            .then(res => {
                this.setState({ path: '/motivo-emprestimo', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async editarMotivo(form) {
        const { razao } = this.props.location.state;
        await ApiService.putGeral('propostamotivo/' + razao.id, form)
            .then(res => {
                this.getReason();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { loading, redirect, path, alert, alertText, statusOptions, motivo, status } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={11} />

                <div className="body">
                    <Header />

                    <div className="usuario-empresa-novo mgDefault">
                        <h1>{this.props.location.state ? 'Editar' : 'Novo'} Motivo</h1>
                        <p className="descricao">Informe abaixo os dados do motivo.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Básicos</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Motivo" value={motivo} onChange={e => this.setState({ motivo: e.target.value })} />
                                    <Input type="select" label="Status" optionsData={statusOptions} divStyle={{ maxWidth: 346 }}
                                        onChange={e => this.setState({ status: e.target.value })} value={status} />
                                </div>
                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/motivo-emprestimo" className="btn">{'<'} Voltar</Link>
                            <button className="btn btnCadastrar" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}