import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import iconLixeira from '../../resources/img/icon-lixeira.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import { validaForm } from '../../Utils/FormValidator';

export default class NewEdufin extends Component {

    constructor(props) {
        super(props);
        this.pegaArquivo = this.pegaArquivo.bind(this);

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            alertType: 2,
            ativoOptions: [
                { value: '', text: 'Selecione' },
                { value: '0', text: 'Desativado' },
                { value: '1', text: 'Ativo' },
            ],
            tipoOptions: [
                { value: '', text: 'Selecione' },
                { value: '1', text: 'Vídeo' },
                { value: '2', text: 'Arquivo' },
            ],
            destaqueOptions: [
                { value: '', text: 'Selecione' },
                { value: '1', text: 'Destacado' },
                { value: '2', text: 'Sem destaque' },
            ],
            categoriaOptions: [
                { value: '', text: 'Selecione' },
                { value: '1', text: 'Conteúdo' },
                { value: '2', text: 'E-book' },
                { value: '3', text: 'Planilha'}
            ],
            perfisOptions: [{ value: '', text: 'Selecione' }],
            id: '',
            titulo: '',
            resumo: '',
            texto: '',
            tipo: '',
            url: '',
            destaque: '',
            ativo: '',
            categoria: '',
            arquivo: '',
            formData: new FormData(),
            nomeArquivo: '',
            arquivos: [],
            showUpload: true,
        };
    }

    componentDidMount() {
        if (this.props.location.state) this.getPost();
    }  

    async getPost() {
        this.setState({loading: true});
        const { edufin } = this.props.location.state;
        await ApiService.getGeral('edufin/' + edufin.id)
            .then(result => {
                const res = result.data.data;
                const arquivos = result.data.arquivos;
                this.setState({ id: res.edufin_id, titulo: res.edufin_titulo, resumo: res.edufin_resumo, texto: res.edufin_texto,
                    tipo: res.edufin_tipo, url: res.edufin_urlimagem ? res.edufin_urlimagem : '' , destaque: res.edufin_destaque, 
                    ativo: res.edufin_ativo  ? res.edufin_ativo : '0', categoria: res.edufin_categoria, nomeArquivo: res.nome_arquivo,
                    arquivos: arquivos, showUpload:  res.edufin_categoria === 2 ? true : false , loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
            })
    }

    handleInputs = (name, value) => {
        this.setState({ [name]: value });
        if(name === 'categoria'){
            if(value === '1'){
                this.setState({ ['tipo']: '1' });
                this.setState({ showUpload: false });

            }else{
                this.setState({ ['tipo']: '2' });
                this.setState({ showUpload: true });

            }
        }
    }

    pegaArquivo(arquivo) {
        const {formData, nomeArquivo} = this.state;
        formData.set("url", arquivo[0])
    }
    
    
    validaCampos() {
        const { titulo, resumo, texto, tipo, url, destaque, ativo, categoria, arquivo, formData, showUpload } = this.state;
        var form = [
            { campo: 'Título',    attr: 'titulo',    valor: titulo },
            { campo: 'Resumo',    attr: 'resumo',    valor: resumo },
            { campo: 'Texto',     attr: 'texto',     valor: texto},
            { campo: 'Categoria', attr: 'categoria', valor: categoria},
            { campo: 'Tipo',      attr: 'tipo',      valor: tipo},
            { campo: 'Destaque',  attr: 'destaque',  valor: destaque},
            { campo: 'Ativo',     attr: 'ativo',     valor: ativo},
        ];
        if(showUpload){
            form = [
                { campo: 'Título',    attr: 'titulo',    valor: titulo },
                { campo: 'Categoria', attr: 'categoria', valor: categoria},
                { campo: 'Tipo',      attr: 'tipo',      valor: tipo},
                { campo: 'Destaque',  attr: 'destaque',  valor: destaque},
                { campo: 'Ativo',     attr: 'ativo',     valor: ativo},
            ];
        }

        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });

         
            formData.set("edufin_titulo", titulo);
            formData.set("edufin_resumo", resumo);
            formData.set("edufin_tipo", tipo);
            formData.set("edufin_destaque", destaque);
            formData.set("edufin_texto", texto);
            formData.set("edufin_urlimagem", url);
            formData.set("edufin_ativo", ativo);
            formData.set("edufin_categoria", categoria);
            formData.set("edufin_titulo", titulo);

            if (this.props.location.state) {
                formData.set('_method', 'PUT')
                this.editaEdufin(formData);
            } else {
                formData.set('_method', 'POST')
                this.adicionaEdufin(formData);
            }
        } else {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertMessage: '* ' + item.mensagem, alertType: 2, alert: true });
                    break;
                }
            }
        }
       
    }

    async adicionaEdufin(form) {
        
        ApiService.postGeralMultiformData('edufin', form)
            .then(result => {
                this.setState({ path: '/edufin', redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
            })
    }

    async editaEdufin(form) {
        const { edufin } = this.props.location.state;
        ApiService.postGeralMultiformData('edufin/' + edufin.id, form)
            .then(result => {
                this.getPost();
                this.setState({ alertMessage: 'A postagem foi alterada com sucesso!', alertType: 1, alert: true, loading: false});

            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
            })
    }

    async visualizarArquivo(nome_arquivo, download_name){
        const { edufin } = this.props.location.state;
        
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(nome_arquivo)[1]; 
        var type;

        if(ext == "txt"){
            type = "text/xml;"
        }else if(ext =="xlsx"){
            type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
        }

        let form = {
            "id": edufin.id,
            "nome_arquivo": nome_arquivo
        }
        var url = '/geral/edufin/arquivo/' + edufin.id+ "?nome_arquivo=" + nome_arquivo;

        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "text/xml");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: type });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = download_name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    async acaoExcluir(id, nomeArquivo){
        this.setState({ loading: true});

        let form = {
            "id": id,
            "nome_arquivo": nomeArquivo
        }

        await ApiService.postGeral('edufin/arquivo/delete', form)
        .then(result => {
            this.getPost();
            this.setState({ alertMessage: 'O arquivo foi apagado com sucesso!', alertType: 1, alert: true, loading: false});
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") { this.setState({ path: '' }); }
            if (valida.status === "badRequest") { this.setState({ path: '' }); }

            this.setState({ loading: false, alertMessage: valida.response, alertType: 2, alert: true });
        })
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, alertType, ativoOptions, tipoOptions, destaqueOptions, categoriaOptions,
                titulo, resumo, texto, tipo, url, destaque, ativo, categoria, arquivo, arquivos, id, showUpload } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => {
                    if (alertType === 1) {
                        this.setState({ alert: false });
                        this.getPost();
                    } else {
                        this.setState({ alert: false, redirect: path ? true : false })
                    }
                }} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={12} />

                <div className="body">
                    <Header />

                    <div className="configuracao mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>{this.props.location.state ? 'Editar' : 'Incluir'} Edufin</h1>
                                <p className="descricao">{this.props.location.state ? 'Editar' : 'Incluir novo'} usuário para acesso a plataforma.</p>
                            </div>
                        </div>
                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados de Categoria</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" > 
                                    <div className="multInput" >    
                                        <Input type="select" isFilterGroups={true}   optionsData={categoriaOptions} label="Categoria"
                                            value={categoria} onChange={e => this.handleInputs('categoria', e.target.value)} />    
                                        <Input type="select" disabled isFilterGroups={true}  optionsData={tipoOptions} label="Tipo"
                                            value={tipo} onChange={e => this.handleInputs('tipo', e.target.value)}  />    
                                    </div>    
                                </div>
                            </div>
                        </div> 
                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Informações Gerais</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" >    
                                    <Input type="text" label="Título" value={titulo}
                                        onChange={e => this.handleInputs('titulo', e.target.value)} />
                                    {showUpload ? null :  <Input type="text" label="Resumo"  value={resumo} 
                                        onChange={e => this.handleInputs('resumo', e.target.value)} />}
                                    {showUpload ? null : <Input type="text" label="Url" value={texto}
                                        onChange={e => this.handleInputs('texto', e.target.value)} />}
                                </div>             
                            </div>
                        </div>
                            
                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados de Status</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" > 
                                    <div className="multInput" >    
                                        <Input type="select" isFilterGroups={true}  optionsData={destaqueOptions} label="Destaque"
                                            value={destaque} onChange={e => this.handleInputs('destaque', e.target.value)}  />
                                        <Input type="select" isFilterGroups={true} optionsData={ativoOptions} label="Ativo"
                                            value={ativo} onChange={e => this.handleInputs('ativo', e.target.value)}  />
                                    </div>    
                                </div>
                            </div>
                        </div>   
                        
                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados de Arquivo</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" > 
                                    {showUpload ? <div className="multInput" >    
                                        <Input type="file" id="url"  label="Arquivo" pegaArquivo={this.pegaArquivo}/>                                        
                                    </div> : null }            
                                </div>
                                {showUpload ? null : <div className="align-items-center" > <h3>Thumbnail</h3>
                                 <img src={"https://img.youtube.com/vi/"+ texto +"/0.jpg"}></img> 
                                 </div>
                                }
                                
                                <div className="multInput" > 
                                    {arquivos.map((arq, i) => {                   
                                                return (
                                                <Fragment>
                                                    <div className="row"> 
                                                        
                                                        <Link key={i} className="col-2 mt-3" to={{state: this.props.location.state}} value={arq} onClick={e =>  
                                                            this.visualizarArquivo(e.target.getAttribute('value'), e.target.text)
                                                            }>Arquivo-{i+1}.{/(?:\.([^.]+))?$/.exec(arq)[1]}</Link>
                                                        <img key = {i} className="col-1 mt-3"  src={iconLixeira} alt="Ícone exclusão" value={arq} style={{ maxWidth: 45,vmarginLeft: 25,cursor: 'pointer' }}
                                                            onClick={e => this.acaoExcluir(id, e.target.getAttribute('value'))}/>
                                                    </div>    
                                                </Fragment>) 
                                            })}  
                                </div>
                            </div>
                        </div>   
                        <div className="actions">
                            <Link to="/edufin" className="btn">{'<'} Voltar</Link>
                            <button className="btn bg-roxo cl-branco" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Salvar'}</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}