import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';

export default class Leads extends Component {

    constructor() {
        super();

        this.acaoSelecionavel = this.acaoSelecionavel.bind(this);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            table: {
                campos: [
                    { titulo: 'Data Hora', dado: 'datahora' },
                    { titulo: 'Nome Empresa', dado: 'empresa' },
                    { titulo: 'Telefone Empresa', dado: 'telefone' },
                    { titulo: 'Email Empresa', dado: 'email' }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            leadsDefault: {},
            totalSelecionado: 0,
            acoes: []
        };
    }

    componentDidMount() {
        this.carregaLeads(1);
    }

    async carregaLeads(page) {
        const { table } = this.state;

        this.setState({ loading: true });

        var url, pagina, pagAtual;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "lead?per_page=20&page=" + pagAtual;
        } else {
            url = "lead?per_page=20";
        }

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.lead_id, datahora: Funcoes.dataHora2Brazilian(item.lead_datahora), empresa: item.lead_nomeempresa, telefone: item.lead_telefoneempresa, email: item.lead_emailempresa
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, leadsDefault: result.data, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/leads?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/leads', redirect: true });
        }
        this.carregaLeads(page);
    }

    async exportarLeads() {
        this.setState({ loading: true });
        var checkboxs, leads = [], i;
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                leads.push(checkboxs[i].getAttribute('rel'));
        }

        var url = '/geral/lead?per_page=-1&export=true';
        if(leads.length > 0){
            url += '&lead_id=' + leads.join();
        }

        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Leads.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    acaoSelecionavel(e, header) {
        const { totalSelecionado } = this.state;
        if (header === 1) {
            const checkboxs = document.querySelectorAll('input[type="checkbox"]');
            var totalSelected = 0;
            var ativo;
            for (var i = 1; i < checkboxs.length; i++) {
                ativo = checkboxs[i].checked;
                totalSelected += parseFloat(checkboxs[i].value);
            }
            this.setState({ totalSelecionado: ativo ? totalSelected : 0 });
        } else {
            const total = parseFloat(e.target.value);
            this.setState({ totalSelecionado: e.target.checked ? total + totalSelecionado : totalSelecionado - total });
        }
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, leadsDefault, exibeDados, mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={17} />

                <div className="body">
                    <Header />

                    <div className="leads mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Leads</h1>
                                <p className="descricao">Abaixo a listagem de todos os leads cadastrados pelo aplicativo</p>
                            </div>
                            <button className="btn btnAzul" onClick={() => this.exportarLeads()}>Exportar (.xlsx)</button>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        selecionavel={true}
                                        acaoSelecionavel={this.acaoSelecionavel} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhum colaborador encontrado!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <p>Total de registros: {leadsDefault.total}</p>
                                    <Paginacao dados={leadsDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}