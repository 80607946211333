import React, { Component, Fragment } from 'react';
import Button from './Button';
export default class Panel extends Component {

    render() {
        const {children, header, actions} = this.props;
        return (
            <Fragment>
                <div className="panel-component">
                    {
                        header ?
                            <div className="panel-header">
                                <h3>{header}</h3>
                            </div>
                        : null
                    }
                    <div className="panel-actions">
                        { 
                            actions ? 
                                actions.map((action, index) => {
                                    return (
                                        <Button key={index} name={action.name} action={action.function}/>
                                    )
                                }
                            ): null
                        }
                    </div>
                    {children}
                </div>
            </Fragment>
        );
    }
}
