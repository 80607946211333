import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/img/icon-aviso.svg';
import iconCheck from '../../resources/img/icon-sucesso.svg';
import iconCopiar from '../../resources/img/icon-copiar.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { dataBrMask } from '../../Utils/mask';

import { Link } from 'react-router-dom';

export default class ContractDetails extends Component {

    constructor(props) {
        super(props);

        this.acaoSelecionavel = this.acaoSelecionavel.bind(this);
        this.state = {
            path: this.props.location.state ? '' : '/contratos',
            redirect: this.props.location.state ? false : true,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 0,
            empresasOptions: [{ value: '', text: 'Selecione' }],
            tipoBaixaOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Pagamento' },
                { value: 2, text: 'Averbação' },
                { value: 3, text: 'Quitação' }
            ],
            modalCancelamento: false,
            modalTransferir: false,
            modalGeraBoleto: false,
            modalBaixar: false,
            modalBaixarTipo: false,
            modalBaixarParcelas: false,
            mostraNovoValor: false,
            motivoReusa: undefined,
            modalVpl: false,
            novoValor: 0,
            valorVpl: 0,
            modalBoleto: false,
            proposta: {},
            propostasRefinanciadas: [],
            valorVplTotal: 0,
            valorSolicitadoRefinanciamento: 0,
            parcelas: [],
            motivoCancelamento: '',
            empresa: '',
            dtPagto: '',
            tipobaixa: '',
            retornoBoletoGerado: {},
            table: {
                campos: [
                    { titulo: 'Data/Hora', dado: 'dtHr' },
                    { titulo: 'Status', dado: 'status' }
                ],
                dados: []
            },
            table2: {
                campos: [
                    { titulo: 'Parcela', dado: 'parcela' },
                    { titulo: 'Vencimento', dado: 'vencimento'},
                    { titulo: 'Valor', dado: 'valor'},
                    { titulo: 'Status', dado: 'status', tdTagStyle: true },
                    { titulo: 'Tipo Baixa', dado: 'tipoBaixa', tdTagStyle: true},

                ],
                dados: []
            },
            tableRefinanciamento: {
                campos: [
                    { titulo: 'CCB', dado: 'ccb' },
                    { titulo: 'Valor Solicitado', dado: 'valorSolicitado'},
                    { titulo: 'Status', dado: 'status', tdTagStyle: true },
                ],
                dados: []
            },
            btnDownload: false,
            btnGerar: false,
            token: '',
            acoes: this.props.location.state ? this.props.location.state.acoes : []
        };
    }

    componentDidMount() {
        this.carregaProposta();
        this.carregaMotivoRecusa();
    }

    async carregaProposta() {
        const { table, table2, tableRefinanciamento } = this.state;
        const { contrato } = this.props.location.state;
        this.setState({ loading: true });
        await ApiService.getGeral('contratos/' + contrato.id)
            .then(result => {
                const res = result.data.data;
                if (!result.data.vazio) {
                    var statusObj = [], parcelasObj = [], propostasRefinanciadas = [];
                    for (var x = 0; x < res.propostastatus.length; x++) {
                        const item = res.propostastatus[x];
                        const dtHr = item.stat_datahora.split(' ');
                        const separaHora = dtHr[1].split(':');
                        statusObj[x] = {
                            id: item.ppst_id, dtHr: Funcoes.data2Brazilian(dtHr[0], '-') + ' ' + separaHora[0] + ':' + separaHora[1],
                            status: item.stat_descricao
                        }
                    }
                    for (var i = 0; i < res.parcelas.length; i++) {
                        const item = res.parcelas[i];
                        var status="A vencer inactive";
                        let tipoBaixa = "Vencimento inactive";
                        if (item.pparc_status === 1) {
                            status = 'Pago'
                        } else if(item.pparc_status === 2) {
                            status = 'Em cobrança warning'
                        }else if(item.pparc_status === 3) {
                            status = 'Vencida error'
                        }else{
                            status = 'Á vencer inactive'
                        }

                        if(item.pparc_tipobaixa == 0)
                        {
                            tipoBaixa = "Vencimento inactive"
                        }else if(item.pparc_tipobaixa == 1 )
                        {
                            tipoBaixa = "Vencimento"
                        }else if(item.pparc_tipobaixa == 2)
                        {
                            tipoBaixa = "Desligamento warning"
                        }else if(item.pparc_tipobaixa == 3)
                        {
                            tipoBaixa = "Quitação verdeEscuro"
                        }else if(item.pparc_tipobaixa == 4)
                        {
                            tipoBaixa = "Refinanciamento info-dark"
                        }

                        parcelasObj[i] = {
                            id: item.pparc_id, 
                            parcela: item.pparc_numero + "/" + res.ppst_prazo,
                            vencimento: Funcoes.data2Brazilian(item.pparc_datavencto, '-'), 
                            valor: item.pparc_valorpagto ?  ("R$" + Funcoes.float2Preco(item.pparc_valorpagto)): ("R$ "+Funcoes.float2Preco(item.pparc_valor)),
                            status: status,
                            tipoBaixa: tipoBaixa
                        }
                    }
                    
                    if(res.simulacao && res.simulacao.propostas)
                    {
                        for(var i = 0; i < res.simulacao.propostas.length; i++)
                        {
                            const item = res.simulacao.propostas[i].proposta;
                            let statusPropota = "";
                            console.log(item)
                            if(item.ppst_status == 0)
                            {
                                statusPropota = "Em análise"
                            }else if(item.ppst_status == 1)
                            {
                                statusPropota = "Aguardando assinatura"
                            }else if(item.ppst_status == 3)
                            {
                                statusPropota = "Aguardando Liberação do dinheiro"
                            }else if(item.ppst_status == 4)
                            {
                                statusPropota = "Aberta verdeEscuro"
                            }
                            else if(item.ppst_status == 5)
                            {
                                statusPropota = "Finalizada info-dark"
                            }
                            else if(item.ppst_status == 6)
                            {
                                statusPropota = "Cancelada error"
                            }
    
                            propostasRefinanciadas[i] = {
                                id: item.ppst_id,
                                ccb: item.ppst_numeroccb,
                                valorSolicitado: "R$ " + Funcoes.float2Preco(item.ppst_valorsolicitado),
                                status: statusPropota
                            }
                        }
                        this.setState({
                            tableRefinanciamento: {campos: [...tableRefinanciamento.campos], dados: propostasRefinanciadas},
                            valorVplTotal: res.simulacao.ppsim_valorvpl, valorSolicitadoRefinanciamento: res.simulacao.ppsim_valorsolicitado
                        });
                    }
                 
                    this.setState({
                        proposta: res, table: { campos: [...table.campos], dados: statusObj },
                        tableRefinanciamento: {campos: [...tableRefinanciamento.campos], dados: propostasRefinanciadas},
                        table2: { campos: [...table2.campos], dados: parcelasObj, parcelas: res.parcelas }
                    });
                }
                this.calculaValorVpl(res.parcelas);
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })

    }


    async carregaEmpresas() {
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions, loading: false, modalTransferir: true });
            })
            .catch(err => this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }], loading: false }))
    }

    async carregaMotivoRecusa(){
        const { contrato } = this.props.location.state;

        await ApiService.getGeral('motivorecusaproposta/' + contrato.id)
        .then(result => {
            const res = result.data.data;
            this.setState({ motivoReusa: res.mtvrh_motivo});
        })
        .catch(err => {
            if(err.response.status === 404){
                return
            }
            this.setState({ loading: false, alertText: "Ops, ocorreu um erro interno em nosso servidor!", alert: true });
        })
    }

    async cancelaContrato() {
        const { proposta, motivoCancelamento } = this.state;
        if (motivoCancelamento) {
            this.setState({ loading: true, modalCancelamento: false });
            await ApiService.putGeral('contratos/' + proposta.ppst_id, { mtv_id: motivoCancelamento, ppst_status: 7 })
                .then(res => {
                    this.setState({
                        alertText: 'Contrato cancelado com sucesso! Visualize o termo de recompra gerado.', textoBtn: 'Visualizar',
                        alertType: 1, modalCancelamento: false, loading: false, alert: true
                    });
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") {
                        this.setState({ path: null });
                    }

                    if (valida.status === "badRequest") {
                        this.setState({ path: null });
                    }
                    this.setState({ loading: false, alertText: valida.response, alert: true });
                })
        } else {
            this.setState({ alertType: 0, textoBtn: 'OK', alertText: 'Selecione um motivo antes de continuar!', alert: true });
        }
    }

    async cancelaSolicitacoes() {
        const { proposta } = this.state;
        this.setState({ loading: true, modalCancelamento: false });
        await ApiService.postGeral('contratos/cancelarpropostas/' + proposta.ppst_id, {})
            .then(res => {
                this.setState({ alertText: 'Solicitações canceladas com sucesso!', alertType: 1, modalCancelamento: false, loading: false, alert: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async transferirContrato() {
        const { proposta, empresa } = this.state;
        if (empresa) {
            this.setState({ loading: true, modalTransferir: false });
            await ApiService.putGeral('funcionario/' + proposta.funcionario.func_id, { emp_id: empresa })
                .then(res => {
                    this.setState({ alertText: 'Contrato transferido com sucesso.', textoBtn: '', alertType: 1, alert: true });
                    this.carregaProposta();
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") {
                        this.setState({ path: null });
                    }

                    if (valida.status === "badRequest") {
                        this.setState({ path: null });
                    }
                    this.setState({ loading: false, alertText: valida.response, alert: true });
                })
        } else {
            this.setState({ alertText: 'Escolha uma empresa para continuar', alertType: 0, alert: true });
        }
    }

    async calculaFatura() {
        const { dtPagto, proposta } = this.state;
        this.setState({ loading: true, modalBaixarTipo: false });
        var checkboxs, parcelas = [], i;
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                parcelas.push(checkboxs[i].getAttribute('rel'));
        }
        const form = {
            emp_id: proposta.funcionario.empresa.emp_id,
            pparc_id: parcelas.join(),
            data_pagamento: Funcoes.data2Americano(dtPagto)
        };
        await ApiService.postGeral('faturas/calculavalor', form)
            .then(result => result.data)
            .then(res => {
                this.setState({ novoValor: res.data.valor,  modalVpl: true, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async calculaValorVpl(parcelas)
    {
        const {proposta} = this.state;
        let diaCorrente = new Date()
        let parcelasPendentes = [];
       
        for (let i = 0; i < parcelas.length; i++) {
            if (parcelas[i].pparc_status == 0 || parcelas[i].pparc_status == 2 || parcelas[i].pparc_status == null )
            {
                parcelasPendentes.push(parcelas[i].pparc_id);
            }
        }
        if(parcelasPendentes.length == 0)
        {
            this.setState({ valorVpl: 0, loading: false });
            return true;
        }
        const form = {
            emp_id: proposta.funcionario.empresa.emp_id,
            pparc_id: parcelasPendentes.join(),
            data_pagamento: diaCorrente.toISOString().split('T')[0]
        };
        await ApiService.postGeral('faturas/calculavalor', form)
            .then(res => {
                this.setState({ valorVpl: res.data.valor, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async gerarBoleto() {
        const { dtPagto, proposta, tipobaixa, novoValor } = this.state;
        
        if(tipobaixa === ''){
            this.setState({ alertText: 'Selecione o tipo da baixa', alert: true });
            return;
        }
        
        this.setState({ loading: true, modalVpl: false });
        var checkboxs, parcelas = [], i;
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                parcelas.push(checkboxs[i].getAttribute('rel'));
            checkboxs[i].checked =false;

        }
        const form = {
            emp_id: proposta.funcionario.empresa.emp_id,
            ppst_id: proposta.ppst_id,
            parcelas: parcelas.join(),
            data_pagamento: Funcoes.data2Americano(dtPagto),
            pparc_tipobaixa: tipobaixa,
            valor_boleto: novoValor
        };
        await ApiService.postGeral('faturas/contrato', form)
            .then(res => {
                this.carregaProposta();
                this.setState({ retornoBoletoGerado: res.data.data, loading: false, modalBoleto: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async baixarParcelas()
    {
        const { proposta } = this.state;

        this.setState({ loading: true, modalBaixarParcelas: false });
        var checkboxs, parcelas = [], i;
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                parcelas.push(checkboxs[i].getAttribute('rel'));
            checkboxs[i].checked =false;

        }
        const form = {
            ppst_id: proposta.ppst_id,
            parcelas: parcelas.join(),
            pparc_tipobaixa: 1,
            pparc_status: 1,
        };
        await ApiService.postGeral('proposta/baixaparcelas', form)
            .then(res => {
                this.setState({ alertText: 'Parcelas baixadas com sucesso!', textoBtn: '', alertType: 1, alert: true, loading: false });
                this.carregaProposta();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async gerarNovoLinkDeAssinatura(){
        const { proposta } = this.state;

        this.setState({ loading: true });
        await ApiService.postGeral('clicksgin/novo_link/' + proposta.ppst_id)
            .then(res => {
                this.setState({
                    alertText: 'Link de assinatura gerado com sucesso.', alertType: 1, loading: false, alert: true
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async baixarVencimentos() {
        const { proposta, tipobaixa } = this.state;
        if(tipobaixa === ''){
            this.setState({ alertText: 'Selecione o tipo da baixa', alert: true });
            return;
        }
        this.setState({ loading: true });
        var checkboxs, parcelas = [], i;
        checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (i = 0; i < checkboxs.length; i++) {
            if (checkboxs[i].getAttribute('rel'))
                parcelas.push(checkboxs[i].getAttribute('rel'));
        }
        const form = {
            pparc_id: parcelas.join(),
            pparc_tipobaixa: tipobaixa
        }
        await ApiService.putGeral('vencimentos/' + proposta.ppst_id, form)
            .then(res => {
                this.setState({ tipobaixa: '', modalBaixarTipo: false });
                this.carregaProposta();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async repagamentoContrato(){
        const { contrato, acoes } = this.props.location.state;
        const { proposta } = this.state;
        let id = contrato.id;

        this.setState({path: '/contratos/repagamento', propsToPath:  { acoes: acoes, proposta: proposta ,contrato: { id } },  redirect: true })
    }

    acaoSelecionavel(e, header, selecionaTodos) {
        if (header === 1) {
            var checkboxs;
            if (selecionaTodos) {
                checkboxs = document.querySelectorAll('input[type="checkbox"]');
                for (var i = 1; i < checkboxs.length; i++) {
                    checkboxs[i].checked = true;
                }
            }
        }
        const checkStatus = document.querySelectorAll('input[type="checkbox"]:checked');
        this.setState({ btnDownload: checkStatus.length > 0 ? true : false, btnGerar: checkStatus.length > 0 ? true : false });
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, textoBtn, alertType, proposta, table, table2, tableRefinanciamento, empresasOptions,
            empresa, modalCancelamento, modalTransferir, modalBaixar, dtPagto, modalGeraBoleto, retornoBoletoGerado, modalBoleto,
            btnDownload, btnGerar, mostraNovoValor, motivoReusa, modalVpl, valorVpl, novoValor, valorVplTotal, valorSolicitadoRefinanciamento, acoes, tipobaixa, tipoBaixaOptions, modalBaixarParcelas, modalBaixarTipo } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <SideMenu menuAtivo={5} />
                <Modal show={modalCancelamento}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de aviso" />
                        <p className="description">Você realmente deseja cancelar as solicitações?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.cancelaSolicitacoes()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalCancelamento: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalTransferir}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de aviso" />
                        <p className="description">Selecione para qual empresa do grupo deseja transferir o contrato.</p>
                        <Input type="select" optionsData={empresasOptions} value={empresa}
                            onChange={e => this.setState({ empresa: e.target.value })} />
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.transferirContrato()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalTransferir: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalGeraBoleto}>
                    <div className="" >
                        {!mostraNovoValor ? (<>
                            <p className="warning">Atenção</p>
                            <img src={iconAviso} alt="Ícone de aviso" />
                            <p className="description">Informe a data que o pagamento será realizado.</p>
                            <Input type="tel" placeholder="99/99/9999" value={dtPagto} className="custom" maxLength="10"
                                onChange={e => this.setState({ dtPagto: dataBrMask(e.target.value) })} />
                            <div className="cancelamentoAcao">
                                <button className="btn"
                                    style={dtPagto.length === 10 ? { backgroundColor: '#EA5353', color: '#FFF' } : { backgroundColor: '#999' }}
                                    onClick={() => dtPagto.length === 10 ? this.calculaFatura() : {}}>Confirmar</button>
                                <button className="btn btnAzul" onClick={() => this.setState({ modalGeraBoleto: false })}>Cancelar</button>
                            </div>
                        </>) : (<>
                            <p>O valor atualizado da fatura é de</p>
                            <h2>R$ {Funcoes.float2Preco(novoValor)}</h2>
                            <p>Confirmar geração do boleto?</p>
                            <div className="cancelamentoAcao">
                                <button className="btn" style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                    onClick={() => this.gerarBoleto()}>Confirmar</button>
                                <button className="btn btnAzul" onClick={() => this.setState({ modalGeraBoleto: false, mostraNovoValor: false })}>Cancelar</button>
                            </div>
                        </>)}
                    </div>
                </Modal>
                <Modal show={modalBoleto}>
                    <div className="">
                        <div>
                            <button className="close"  onClick={() => this.setState({ modalBoleto: false })}>X</button>
                        </div>
                        <img src={iconCheck} alt="Ícone de aviso" />
                        <p className="description">O seu boleto foi gerado com sucesso!</p>
                        <p style={{ fontSize: 14, marginBottom: 5 }}>Linha Digitável</p>
                        <div className="boletoGerado">
                            <Input value={retornoBoletoGerado.linha_digitavel} className="custom" id="linhaDigitavel" style={{ fontSize: 16}} readOnly={true}
                                onClick={() => document.getElementById("linhaDigitavel").select()} />
                            <button onClick={() => {
                                const input = document.getElementById("linhaDigitavel");
                                input.select();
                                input.setSelectionRange(0, 99999)
                                document.execCommand("copy");
                            }}>
                                <img src={iconCopiar} alt="Copiar linha digitável" title="Copiar linha digitável"></img>
                            </button>
                        </div>
                        <button className="btn visualizaBoleto" onClick={() => window.open(retornoBoletoGerado.url_boleto, '_blank')}>Visualizar boleto</button>
                    </div>
                </Modal>
                <Modal show={modalBaixarTipo}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de aviso" />
                        <Input label="Tipo de baixa" type="select" optionsData={tipoBaixaOptions} value={tipobaixa}
                            onChange={e => this.setState({ tipobaixa: e.target.value })} />
                        <Input label="Data de vencimento" type="tel" placeholder="dd/mm/aaaa" value={dtPagto} className="custom" maxLength="10"
                            onChange={e => this.setState({ dtPagto: dataBrMask(e.target.value) })} />
                        <div className="cancelamentoAcao">
                            {/*<button className="btn" onClick={() => this.baixarVencimentos()}>Confirmar</button>*/}
                            <button className="btn" onClick={() => dtPagto.length === 10 ? this.calculaFatura(): {}}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalBaixarTipo: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalBaixarParcelas}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de aviso" />
                        <p className="description">Você realmente deseja baixar essas parcelas? A ação não poderá ser desfeita!</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.baixarParcelas()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalBaixarParcelas: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalVpl}>
                    <div className="modal-tipobaixa">
                        <p>O valor atualizado da fatura é de</p>
                        <h2>R$ {Funcoes.float2Preco(novoValor)}</h2>
                        <p>Confirmar geração do boleto?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                onClick={() => this.gerarBoleto()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalVpl: false})}>Cancelar</button>
                        </div>
                    </div>

                </Modal>
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} textoBtn={textoBtn} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />

                <div className="body">
                    <Header />

                    <div className="contratos-detalhes mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Contrato - {proposta.ppst_numeroccb}
                                    <span className={proposta.ppst_status === 5 ? 'tag finalizado' : proposta.ppst_status === 7 || proposta.ppst_status === 6 ? 'tag cancelado' : 'tag aberto'}>
                                        {proposta.ppst_status === 5 ? 'Finalizado' : proposta.ppst_status === 6 ? 'Recusado' : proposta.ppst_status === 7 ? 'Cancelado' : 'Aberto'}
                                    </span>
                                </h1>
                                <p className="descricao">Abaixo detalhes do contrato selecionado.</p>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                {proposta.ppst_status === 1 ? (
                                    <Fragment>
                                        <button className="btn" onClick={() => this.gerarNovoLinkDeAssinatura()}>Gerar novo link de assinatura</button>
                                        <button className="btn" onClick={() => this.setState({ modalCancelamento: true })}>Cancelar Solicitações</button>
                                    </Fragment>
                                ) : null}
                                {acoes.indexOf(9) !== -1 ?
                                    <button className="btn" onClick={() => this.carregaEmpresas()}>Transferir Contrato</button>
                                    : null}
                                <button className="btn" onClick={() => this.repagamentoContrato()}>Repagamento</button>
                                {acoes.indexOf(10) !== -1 ?
                                    <button className="btn btnAzul" onClick={() => {
                                        const url = ApiService.InManoApiUrl();
                                        const token = ApiService.getUserToken();
                                        const id = proposta.ppst_linkcontrato.replace('/geral/arquivo/', '').replace('/ver', '');
                                        this.setState({loading: true})
                                        fetch(url + '/geral/ccb/' + id +'/ver', {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer ' + token
                                            }
                                            })
                                            .then(response => response.blob())
                                            .then(blob => {
                                                // Create a URL for the PDF file
                                                const url = URL.createObjectURL(blob);

                                                // Open the PDF file in a new window
                                               window.open(url);
                                               this.setState({loading: false})

                                            })
                                            .catch(err => {
                                                const valida = ApiService.handleErros(err.response);
                                                if (valida.status === "unauthorized") {
                                                    sessionStorage.removeItem('userData');
                                                    this.setState({ path: '/' });
                                                }
                                                if (valida.status === "serverError") {
                                                    this.setState({ path: null });
                                                }
                                    
                                                if (valida.status === "badRequest") {
                                                    this.setState({ path: null });
                                                }
                                                this.setState({ loading: false, alertText: valida.response, alert: true });
                                            })

                                    }}>Visualizar CCB</button>
                                    : null}
                            </div>
                        </div>

                        <div className="panel detalhes">
                            <div className="panelHeader">
                                <h3>Detalhe do Contrato</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multArea">
                                    <p><span>Nº: </span> {proposta.ppst_numeroccb}</p>
                                    <p><span>Tipo: </span> {proposta.ppst_tipo === 1 ? 'Empréstimo' : 'Refinanciamento'}</p>
                                    <p><span>Cliente/Colaborador: </span> {proposta.funcionario ? proposta.funcionario.func_nome : ''}</p>
                                    <p><span>CPF: </span> {proposta.funcionario ? proposta.funcionario.func_cpf : ''}</p>
                                </div>
                                <div className="multArea">
                                    <p><span>Empresa: </span> {proposta.funcionario ? proposta.funcionario.empresa.emp_nomefantasia : ''}</p>
                                    <p><span>Valor Emprestado: </span> R$ {Funcoes.float2Preco(proposta.ppst_valorsolicitado)}</p>
                                    <p><span>Parcelas: </span> {Funcoes.completarNumero(proposta.ppst_prazo)}</p>
                                </div>
                                <div className="multArea">
                                    <p><span>Taxa Juros: </span> {Funcoes.float2Preco(proposta.ppst_taxajuros)}% a.m</p>
                                    <p><span>Valor Parcela: </span> R$ {Funcoes.float2Preco(proposta.ppst_valorparcela)}</p>
                                    <p><span>Parc. Paga: </span> {Funcoes.completarNumero(proposta.ppst_parcelaspagas)}</p>
                                    <p><span>Data Assinatura: </span> {proposta.ppst_dthrsolicitacao ? Funcoes.data2Brazilian(proposta.ppst_dthrsolicitacao.split(' ')[0], '-') : null}</p>
                                </div>
                                <div className="multArea">
                                    <p><span>Primeiro Vencimento: </span> {Funcoes.data2Brazilian(proposta.ppst_primeirovencimento, '-')}</p>
                                    <p><span>Término: </span> {Funcoes.data2Brazilian(proposta.ppst_ultimovencimento, '-')}</p>
                                    <p><span>Saldo Devedor (vpl): </span>R$ {Funcoes.float2Preco(valorVpl)}</p>
                                    
                                    {proposta.ppst_tipo == 2 ? <p><span>Saldo Líquido desembolsado (vpl): </span>R$ {Funcoes.float2Preco(valorSolicitadoRefinanciamento - valorVplTotal)}</p> : null}

                                </div>
                                {motivoReusa ? 
                                    <div className="multArea">
                                        <p><span>Motivo de recusa: </span> {motivoReusa} </p>
                                    </div> : null
                                }
                                
                            </div>
                        </div>

                        {proposta.ppst_tipo === 2 ? (
                           <div className="panel">
                           <div className="panelHeader">
                               <h3>Propostas Refinanciadas</h3>
                           </div>
                           <div className="panelBody">
                               {tableRefinanciamento.dados.length > 0 ? (
                                   <Table campos={tableRefinanciamento.campos}
                                       dados={tableRefinanciamento.dados}
                                     />
                               ) : null}
                           </div>
                           <p style={{ marginLeft: 'auto' }}>Vpl dos contratos: <span>R$ {Funcoes.float2Preco(valorVplTotal)}</span></p>
                       </div>
                        ) : null}

                        <div className="historico-parcela">
                            <div className="panel">
                                <div className="panelHeader">
                                    <h3>Histórico de Status</h3>
                                </div>
                                <div className="panelBody">
                                    {table.dados.length > 0 ? (
                                        <Table campos={table.campos}
                                            dados={table.dados} />
                                    ) : null}
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panelHeader">
                                    <h3>Parcelas</h3>
                                </div>
                                <div className="panelBody">
                                    {table2.dados.length > 0 ? (
                                        <Table campos={table2.campos}
                                            dados={table2.dados}
                                            selecionavel={true}
                                            acaoSelecionavel={this.acaoSelecionavel}
                                            validaParcelas={true} />
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/contratos" className="btn btnVoltar">{'<'} Voltar</Link>
                            <div style={{ marginLeft: 'auto' }}>
                                {acoes.indexOf(12) !== -1 ?
                                    <button className="btn" style={btnDownload ? {} : { backgroundColor: '#999999', color: '#777' }}
                                        onClick={() => btnDownload ? this.setState({ modalBaixarParcelas: true }) : {}}>Baixar Parcelas</button>  
                                    : null}
                                {acoes.indexOf(12) !== -1 ?
                                    <button className="btn" style={btnDownload ? {} : { backgroundColor: '#999999', color: '#777' }}
                                        onClick={() => btnDownload ? this.setState({ modalBaixarTipo: true }) : {}}>Gerar Boleto</button>  
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}