import React, { Component, Fragment } from 'react';

export default class Summary extends Component {

    render() {
        const {title} = this.props;
        return (
            <Fragment>
                <h1 className="header-component">{title}</h1>
            </Fragment>
        );
    }
}
