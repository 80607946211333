import React, { Component, Fragment } from 'react';

import SummaryHeader from './SummaryHeader';

export default class Summary extends Component {

    render() {
        const {title, description, children} = this.props;
        return (
            <Fragment>
                 <div className="summary-body">
                    <div>
                        <SummaryHeader title={title}/>
                        <p>{description}</p>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                        {children}             
                    </div>
                </div>
            </Fragment>
        );
    }
}
