import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

import { Link } from 'react-router-dom';

export default class EditCollaboratorAttendance extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            mensagem: '',
            acoes: [],
            showEditar: true,
            showStatus: false,
            usuario: '',
            colaborador: '',
            nome: '',
            cpf: '',
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const { usuario } = this.state;
    
        const url = window.location.pathname.split("/");
        const acoes = Funcoes.getAcoesPage(userData.menus, "/colaboradores");
        this.setState({showEditar: acoes.indexOf(35) !== -1 ? true : false});
        if (this.props.location.state) {
            this.setState({ loading: true, usuario: userData.usuario.nome });
            this.carregaObservacao();

        }
    }

    async carregaObservacao() {
        const { observacao } = this.props.location.state;
        const { showStatus } = this.state;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname.split("/");
        const acoes = Funcoes.getAcoesPage(userData.menus, ["/anotacoes"] );
        await ApiService.getGeral('funcionarioacompanhamento/' + observacao.id)
            .then(result => {
                const item = result.data.data;
                this.setState({
                    mensagem: item.funca_mensagem, colaborador: item.func_id
                });
                this.carregaColaborador();
            })
            .catch(err => {
                console.log(err);
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    updateValues(attr, val, type) {
        const { mensagem } = this.state;
        this.setState({ [attr]: val })
    }
    async carregaColaborador() {
        const { colaborador } = this.state;
        await ApiService.getGeral('funcionario/' + colaborador)
            .then(result => {
                const item = result.data.data;
                this.setState({
                    nome: item.func_nome, cpf: item.func_cpf, loading: false
                });
            })
            .catch(err => {
                console.log(err);
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async alterarObservacao() {
        const { mensagem , colaborador} = this.state;
        const { observacao } = this.props.location.state;
        const userData = JSON.parse(localStorage.getItem('userData'));

        this.setState({ loading: true });

        let dataPost = {
            funca_mensagem: mensagem,
            usu_id: userData.usuario.id,
        };
        await ApiService.putGeral('funcionarioacompanhamento/' + observacao.id, dataPost)
            .then(res => {
                this.setState({ path: '/colaboradores/anotacoes', propsToPath: { observacao: { id: observacao.id }, colaborador: {id: colaborador}} , loading: false, redirect: true });
            })
            .catch(err => {
                console.log(err);
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }
    
    voltar() {
        const {colaborador} = this.state;
        this.setState({ path: '/colaboradores/anotacoes', propsToPath: { colaborador: {id: colaborador}}, loading: false, redirect: true });
    }
    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, alertType, 
                showEditar, mensagem, usuario, nome, cpf } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="editar-colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Editar Anotação</h1>
                                <p className="descricao">Parametrize abaixo a anotação.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="textarea" label="Anotação" value={mensagem} onChange={e => this.updateValues('mensagem', e.target.value)} />
                                </div>
                                <div className="panelHeader">
                                    <h3>Dados do funcionário</h3>
                                </div>
                                <div className="multInput">
                                    <Input type="text" label="Funcionário" value={nome}  className="disabled" />
                                    <Input type="text" label="CPF" value={cpf}  className="disabled" />
                                </div>
                                 <div className="panelHeader">
                                    <h3>Dados do usuário</h3>
                                </div>
                                <div className="multInput">
                                    <Input type="text" label="Usuário" value={usuario}  className="disabled" />
                                </div>
                            </div>
                        </div>
                        <div className="acoes">
                            <div className="acoes">
                                <button className="btn btnVoltar" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.voltar()}>{'<'} Voltar</button>
                            </div>                            {showEditar ? (<>
                                <button className="btn btnCadastrar" onClick={() => this.alterarObservacao()}>Alterar</button>
                            </>) : '' }
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}