import axios from 'axios';

import Funcoes from '../Utils/functions';

// const InManoVersion = '1.0.0';
const InManoApiVersion = process.env.REACT_APP_INMANO_API_VERSION;
const InManoApiType = process.env.REACT_APP_INMANO_API_TYPE;
//const InManoApiUrl =  process.env.REACT_APP_INMANO_API_URL_PRD + "/api/v" + InManoApiVersion //: process.env.REACT_APP_INMANO_API_URL_DEV + "/api/v" + InManoApiVersion;
const InManoApiUrl = InManoApiType === "production" ? process.env.REACT_APP_INMANO_API_URL_PRD + "/api/v" + InManoApiVersion : process.env.REACT_APP_INMANO_API_URL_DEV + "/api/v" + InManoApiVersion;
//const InManoApiUrl = "http://localhost/api/v1.0.0";

const InManoApiResourceUrl = InManoApiType === "production" ? process.env.REACT_APP_INMANO_API_URL_PRD : process.env.REACT_APP_INMANO_API_URL_DEV;
const EWallyApiType = process.env.REACT_APP_EWALLY_API_TYPE;
const EWallyApiUrl = EWallyApiType === "production" ? process.env.REACT_APP_EWALLY_API_URL_PRD : process.env.REACT_APP_EWALLY_API_URL_DEV;

export default class ApiService {

    static getUserToken(){
        let userData = JSON.parse(localStorage.getItem('userData'));
        if (!userData) {
            userData = { token: '' };
            localStorage.setItem('userData', JSON.stringify(userData));
        }

        let token = userData.token;
        return token;
    }
    static getUserEwallyToken(){
        const userData = JSON.parse(localStorage.getItem('userData'));
        let token = userData.tokenEWally;
        return token;
    }
    static InManoApiUrl() {
        return InManoApiUrl;
    }

    static InManoApiResourceUrl() {
        return InManoApiResourceUrl;
    }
    
    static getHeaders(employer) {
        let token = this.getUserToken();
        return {
            "Access-Control-Allow-Origin": "*",
            "Content-type": 'application/json',
            'Accept': 'application/json',
            'Authorization':  'Bearer ' + token,
            'Employ': employer ? employer : '',
            'X-Api-Version': InManoApiVersion
        }
    }
    
    static getHeadersMultiformData(employer) {
        let token = this.getUserToken();
        return {
            "Access-Control-Allow-Origin": "*",
            "Content-type": 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization':  'Bearer ' + token,
            'Employ': employer ? employer : '',
            'X-Api-Version': InManoApiVersion
        }
    }
    
    static getEWallyHeaders(identifier) {
        let token = this.getUserEwallyToken();
        return {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": 'application/json',
            "Authorization": 'Bearer ' + token,
            "Ewally-Broker-Client": identifier ? identifier : ''
        }
    }

    static getGeral(url) {
        const options = {
            url: `${InManoApiUrl}/geral/${url}`,
            method: 'GET',
            headers: this.getHeaders()
        };
        return axios(options);
    }

    static postGeral(url, dados) {
        const options = {
            url: `${InManoApiUrl}/geral/${url}`,
            method: 'POST',
            headers: this.getHeaders(),
            data: dados
        };
        return axios(options);
    }

    static postGeralMultiformData(url, dados) {
        let token = this.getUserToken();
        const options = {
            url: `${InManoApiUrl}/geral/${url}`,
            method: 'POST',
            headers: this.getHeadersMultiformData(token),
            data: dados
        };
        return axios(options);
    }

    static patchGeral(url, dados) {
        const options = {
            url: `${InManoApiUrl}/geral/${url}`,
            method: 'PATCH',
            headers: this.getHeaders(),
            data: dados
        };
        return axios(options);
    }

    static putGeral(url, dados, employ) {
        const options = {
            url: `${InManoApiUrl}/geral/${url}`,
            method: 'PUT',
            headers: this.getHeaders(employ),
            data: dados
        };
        return axios(options);
    }

    static getEWally(url, identifier) {
        let token = this.getUserEwallyToken();
        const options = {
            url: `${EWallyApiUrl}/${url}`,
            method: 'GET',
            headers: this.getEWallyHeaders(token, identifier)
        }
        return axios(options);
    }

    static getDownloadEWally(url, dados, identifier) {
        const options = {
            url: `${EWallyApiUrl}/${url}`,
            method: 'POST',
            headers: this.getEWallyHeaders(identifier),
            responseType: 'blob',
            data:dados
        }
        return axios(options);
    }
    static postEWally(url, data, identifier, method){
        let token = this.getUserEwallyToken();

        const options = {
            url: `${EWallyApiUrl}/${url}`,
            method: method ? method : 'POST',
            headers: this.getEWallyHeaders(token, identifier),
            data: JSON.stringify(data)
        }
        return axios(options);
    }

    static customRequest(url, metodo, dados, employer) {
        const options = {
            url: `${InManoApiUrl}/${url}`,
            method: metodo,
            headers: this.getHeaders(employer),
            data: dados ? dados : {}
        }
        return axios(options);
    }

    static handleErros(response) {
        if (response.status === 400) {
            return { status: 'badRequest', response: response.data.mensagem }
        } else if (response.status === 401) {
            return { status: 'unauthorized', response: 'Ops, parece que sua sessão expirou, faça um novo login para continuar!' }
        }else if (response.status === 422) {
            let error_message = 'Falha na requisição,\n'
            let isSequential = Funcoes.isSequentialArray(response.data.errors)

            if(!isSequential)
            {
                Object.keys( response.data.errors).map(function(key, index) {
                    error_message += response.data.errors[key][0] +'\n';
                    return 1;
                });  
            }else
            {
                Object.keys( response.data.errors).map(function(index) {
                    error_message += response.data.errors[index] +'\n';
                    return 1;
                }); 
            }
            
            return { status: 'badRequest', response: error_message }
        } else {
            return { status: 'serverError', response: 'Ops, ocorreu um erro interno em nosso servidor!' }
        }
    }
}