import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import iconCopiar from '../../resources/img/icon-copiar.svg';
import iconCheck from '../../resources/img/icon-sucesso.svg';
import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

export default class ConsultationNeurotechCollaborator extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            score: 0,
            neurotechResponse: '',
            operationCode: '',
            createdAt: '',
            usu_id: null,
            usu_nome: '',
            func_nome: '',
            func_cpf: ''
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.carregaHistoricoNeurotech();
    }

    async carregaHistoricoNeurotech(){
        this.setState({ loading: true });
        const { colaborador } = this.props.location.state;

        var url = 'logneurotech/ultimaconsulta/' + colaborador;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                let data, score ='';
                
                if(res.created_at){
                    data = Funcoes.data2Brazilian(res.created_at.split(" ")[0]) +" "+ res.created_at.split(" ")[1];
                }
                if(res.quod_score){
                    score = res.quod_score.split("#")[0]
                }
                this.setState({score: score, neurotechResponse: res.result, operationCode: res.operation_code, createdAt: data, usu_id: res.usu_id, func_nome: res.func_nome, func_cpf: res.func_cpf});
                this.carregaUsuario(res.usu_id)
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
          
    }

    async carregaUsuario(usu_id){
        if(usu_id){
            let url = "usuario/" + usu_id
            await ApiService.getGeral(url)
                .then(result => {
                    const res = result.data.data;
                    this.setState({usu_nome: res.usu_nome});
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") {
                        this.setState({ path: null });
                    }
    
                    if (valida.status === "badRequest") {
                        this.setState({ path: null });
                    }
                    this.setState({ loading: false, alertText: valida.response, alert: true });
                })
        }
        this.setState({ loading: false})
       
    }

    voltar() {
        const { colaborador } = this.props.location.state;
        this.setState({ path: '/colaboradores/editar', propsToPath: {colaborador: {id: colaborador}}, redirect: true });
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, score, neurotechResponse, operationCode, createdAt, usu_nome, func_nome, func_cpf } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={7} />

                <div className="body">
                    <Header />

                    <div className="faturas mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Ultima consulta na Neurotech</h1>
                                <p className="descricao">Abaixo os dados da última consulta no serviço da Neurotech .</p>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                <Input type = "tel" label = "Usuário" divStyle = {{ flexGrow: 0, maxWidth: 204 }} value = {usu_nome} disabled = { true } className = "disabled"/>
                                <Input type = "tel" label = "Colaborador" divStyle = {{ flexGrow: 0, maxWidth: 204 }} value = {func_nome} disabled = { true } className = "disabled"/>
                                <Input type = "tel" label = "CPF" divStyle = {{ flexGrow: 0, maxWidth: 204 }} value = {func_cpf} disabled = { true } className = "disabled"/>
                                <Input type = "tel" label = "Data de Consulta" divStyle = {{ flexGrow: 0, maxWidth: 204 }} value = {createdAt} disabled = { true } className = "disabled"/>
                                <Input type = "tel" label = "Código de Operação" divStyle = {{ flexGrow: 0, maxWidth: 204 }} value = {operationCode} disabled = { true } className = "disabled"/>
                                <Input type = "tel" label = "Score Neurotech" divStyle = {{ flexGrow: 0, maxWidth: 204 }} value = {score} disabled = { true } className = "disabled"
                                    maxLength = "4" />
                                <Input type = "tel" label = "Resultado" divStyle = {{ flexGrow: 0, maxWidth: 204 }} value = {neurotechResponse} disabled = { true } className = "disabled"/>
                            </div>
                        </div>    
                        
                        <div className="actions">
                            <button className="btn btnVoltar" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.voltar()}>{'<'} Voltar</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}