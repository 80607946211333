import React, { Component, Fragment } from 'react';
//import {Row,Col} from 'react-bootstrap';
import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import { cpfMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';

export default class Collaborator extends Component {

    constructor() {
        super();

        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoExtrato = this.acaoExtrato.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            empresasOptions: [{ value: '', text: 'Selecione' }],
            gruposEconomicosOptions: [{ value: '', text: 'Selecione' }],
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: '1', text: 'Cadastrado' },
                { value: '2', text: 'Apto' },
                { value: '3', text: 'Inapto' },
                { value: '4', text: 'Desligado' }
            ],
            empresa: '',
            grupoEconomico: '',
            colaborador: '',
            status: '',
            table: {
                campos: [
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Colaborador', dado: 'colaborador' },
                    { titulo: 'CPF', dado: 'cpf', tdStyle: {width: "180px"} },
                    { titulo: 'Status', dado: 'status' },
                    { titulo: 'Conveniado', dado:'conveniado'},
                    { titulo: '', dado: 'acoes', tdStyle: {width: "120px"} }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            colaboradoresDefault: {},
            acoes: [],
            conveniado: '',
            statusConveniadoOptions: [
                { value: '', text: 'Selecione' },
                { value: '0', text: 'Não' },
                { value: '1', text: 'Sim' },
            ] 
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });

        this.carregaEmpresas();
        this.carregaGrupos();
        this.carregaColaboradores(1, acoes);
    }

    async carregaEmpresas() {
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions });
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaGrupos() {
        this.setState({ loading: true });
        await ApiService.getGeral('grupoeconomico?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var gruposEconomicosOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    gruposEconomicosOptions[i + 1] = { value: item.gpec_id, text: item.gpec_nome };
                }
                this.setState({ gruposEconomicosOptions });
            })
            .catch(err => {
                this.setState({ gruposEconomicosOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaEmpresasPorGrupo(grupo){
        this.setState({ loading: true});
        if(grupo) this.setState({ grupoEconomico: grupo });
        let url = 'empresa?per_page=-1';
        if(grupo) url+= "&gpec_id=" + grupo;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions, loading: false});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async recarregaEmpresaSelect(grupo){
        this.carregaEmpresasPorGrupo(grupo);
    }

    async carregaColaboradores(page, acao) {
        const { table, empresa, grupoEconomico, colaborador, status, conveniado } = this.state;

        this.setState({ loading: true });

        var url, pagina, pagAtual;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "funcionario?per_page=20&page=" + pagAtual;
        } else {
            url = "funcionario?per_page=20";
        }

        if (empresa) url = url + "&emp_id=" + empresa;
        if(grupoEconomico) url = url + "&grupo_economico=" + grupoEconomico;
        if (colaborador) {
            if (colaborador.startsWith('0') || colaborador.startsWith('1') || colaborador.startsWith('2') || colaborador.startsWith('3') || colaborador.startsWith('4') ||
                colaborador.startsWith('5') || colaborador.startsWith('6') || colaborador.startsWith('7') || colaborador.startsWith('8') || colaborador.startsWith('9')) {
                url = url + "&func_cpf=" + colaborador;
            } else {
                url = url + "&func_nome=" + colaborador;
            }
        }
        
        url+= conveniado ? "&func_conveniado=" + conveniado : "";
        url += status ? '&func_situacao='+status : "";

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    var arrStatus = ['', 'Cadastrado', 'Apto', 'Inapto', 'Desligado'];
                    objects[i] = {
                        id: item.func_id, empresa: item.empresa ? item.empresa.emp_nomefantasia : "-", colaborador: item.func_nome, cpf: item.func_cpf,
                        status: arrStatus[item.func_situacao], conveniado: item.func_conveniado ? "Sim" : "Não",
                        acoes: 'default|extrato'
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, colaboradoresDefault: result.data, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        this.setState({ path: '/colaboradores/editar', propsToPath: { colaborador: { id } }, redirect: true });
    }

    acaoExtrato(id) {
        this.setState({ path: '/colaboradores/extrato', propsToPath: { colaborador: { id } }, redirect: true });
    }

    handleFiltro(e) {
        const val = e.target.value;
        if (val.startsWith(0) || val.startsWith(1) || val.startsWith(2) || val.startsWith(3) || val.startsWith(4) || val.startsWith(5) || val.startsWith(6) || val.startsWith(7) ||
            val.startsWith(8) || val.startsWith(9)) {
            document.getElementById('filtro').setAttribute('maxlength', 14);
            this.setState({ colaborador: cpfMask(val) });
        } else {
            document.getElementById('filtro').removeAttribute('maxLength');
            this.setState({ colaborador: val });
        }
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/colaboradores?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/colaboradores', redirect: true });
        }
        this.carregaColaboradores(page);
    }

    async exportarColaboradores() {
        const { empresa, colaborador, status, grupoEconomico, conveniado } = this.state;
        this.setState({ loading: true });

        var url = '/geral/funcionario?per_page=-1&export=true';
        if (colaborador) {
            if (colaborador.startsWith('0') || colaborador.startsWith('1') || colaborador.startsWith('2') || colaborador.startsWith('3') || colaborador.startsWith('4') ||
                colaborador.startsWith('5') || colaborador.startsWith('6') || colaborador.startsWith('7') || colaborador.startsWith('8') || colaborador.startsWith('9')) {
                url = url + "&func_cpf=" + colaborador;
            } else {
                url = url + "&func_nome=" + colaborador;
            }
        }
        
        if (empresa) url = url + "&emp_id=" + empresa;
        if(grupoEconomico) url = url + "&grupo_economico=" + grupoEconomico;
        if (colaborador) {
            if (colaborador.startsWith('0') || colaborador.startsWith('1') || colaborador.startsWith('2') || colaborador.startsWith('3') || colaborador.startsWith('4') ||
                colaborador.startsWith('5') || colaborador.startsWith('6') || colaborador.startsWith('7') || colaborador.startsWith('8') || colaborador.startsWith('9')) {
                url = url + "&func_cpf=" + colaborador;
            } else {
                url = url + "&func_nome=" + colaborador;
            }
        }
        
        url += conveniado ? "&func_conveniado=" + conveniado : "";
        url += status ? '&func_situacao=' + status : "";


        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Colaboradores.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, empresasOptions, gruposEconomicosOptions, statusConveniadoOptions, empresa, grupoEconomico, colaborador, table, colaboradoresDefault,
            exibeDados, mostraVazio, statusOptions, status, conveniado } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Colaboradores</h1>
                                <p className="descricao">Abaixo a listagem de todos colaboradores cadastrados na plataforma</p>
                            </div>
                            <div className="headerActions">
                               {/* <button className="btn" onClick={() => this.setState({ path: '/colaboradores/simuladornaoconveniado', redirect: true })}>Simulador Não Conveniado</button>*/}
                                <button className="btn" onClick={() => this.setState({ path: '/colaboradores/recuperacao', redirect: true })}>Alterar PIN</button>
                                <button className="btn btnAzul" onClick={() => this.exportarColaboradores()}>Exportar (.xlsx)</button>
                            </div>    
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row" >
                                    <Input type="select" isFilterGroups={true} optionsData={gruposEconomicosOptions} label="Grupo Econômico" value={grupoEconomico} 
                                        onChange={e => this.recarregaEmpresaSelect(e.target.value)} />      
                                    <Input type="select" isFilterGroups={true} label="Empresa" optionsData={empresasOptions} value={empresa}
                                        onChange={e => this.setState({ empresa: e.target.value })} />                
                                    <Input type="text" isFilterGroups={true} label="Colaborador ou CPF" value={colaborador} id="filtro"
                                        onChange={e => this.handleFiltro(e)} />  
                                    <Input type="select" isFilterGroups={true} label="Status" optionsData={statusOptions} value={status} 
                                        onChange={e => this.setState({ status: e.target.value })} />   
                                    <Input type="select" isFilterGroups={true} label="Conveniado" optionsData={statusConveniadoOptions} value={conveniado} 
                                        onChange={e => this.setState({ conveniado: e.target.value })} />  
                                     <button className="btn filtrar" onClick={() => this.carregaColaboradores(1)}>Filtrar</button>
                                </div>
                                
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar}
                                        acaoExtrato={this.acaoExtrato} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhum colaborador encontrado!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <p>Total de registros: {colaboradoresDefault.total}</p>
                                    <Paginacao dados={colaboradoresDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}
