import React, { Component, Fragment } from 'react';

export default class Button extends Component {

    render() {
        const {name, action, type} = this.props;
        let style;
        if(type === "back")
        {
            style = "back-button-component"
        }
        else if(type ==="outiline")
        {
            style ="button-outline-component"
        }
        else
        {
            style = "button-component"
        }
        return (
            <Fragment>
                 <button className={style} onClick={action}>{name}</button>
            </Fragment>
        );
    }
}
