import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/img/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import { cpfMask, dataBrMask } from '../../Utils/mask';

export default class TradeRepresentative extends Component {

    constructor() {
        super();

        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoExtrato = this.acaoExtrato.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            modal: false,
            modalExportacao: false,
            alert: false,
            alertText: '',
            nome: '',
            table: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: 'CPF', dado: 'cpf' },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Grupo Econômico', dado: 'grupoEconomico' },
                    { titulo: 'Status', dado: 'status',  tdTagStyle: true },
                    { titulo: 'Ações', dado: 'acoes' },
                ],
                dados: []
            },
            gruposEconomicosOptions: [{ value: '', text: 'Selecione' }],
            empresasOptions: [{ value: '', text: 'Selecione' }],
            statusOptions: [{value: '', text: 'Selecione'}],
            grupoEconomico: '',
            empresa: '',
            status: '',
            exibeDados: false,
            mostraVazio: false,
            dataInicio: '',
            dataFim: '',
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });
        this.carregaRepresentantesEmpresa(acoes);
        this.carregaEmpresas();
        this.carregaGrupos();
        this.carregaRepresentanteComercialStatus();
    }

    async carregaRepresentantesEmpresa(acao) {
        const { table, nome, grupoEconomico,empresa, status } = this.state;
        this.setState({ loading: true });
        var url = "representante_empresa?per_page=20";
        if(grupoEconomico){url = url+ "&gpec_id=" + grupoEconomico}
        if(empresa){url = url+ "&emp_id=" + empresa}
        if(status){url = url+ "&reps_id=" + status}

        if (nome && !nome.match(/^\d/)) url = url + "&repc_nome=" + nome;
        if (nome && nome.match(/^\d/)) url = url + "&repc_cpf=" + nome;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.repc_id, 
                        nome: item.repc_nome.toUpperCase(), 
                        cpf: item.repc_cpf,
                        empresa: item.emp_nomefantasia,
                        grupoEconomico: item.gpec_nome,
                        status: item.repcemps_id === 1 ? 'Vigente' : 'Cancelado error', 
                        acoes: "default|extrato"
                    };
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async carregaEmpresas() {
        let url = 'empresa?per_page=-1';
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    acaoEditar(id) {
        this.setState({ path: '/representantes/novo', propsToPath: { usuario: { id } }, redirect: true });
    }

    acaoExtrato(id){
        const {table} =this.state;
        this.setState({ propsToPath:  { representante: { id } }, path: '/representantes/historico-pagamento', redirect: true })
    }
    acaoExcluir(idUsu, nome) {
        this.setState({ nome, idUsu, modal: true });
    }

    async carregaGrupos() {
        this.setState({ loading: true });
        await ApiService.getGeral('grupoeconomico?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var gruposEconomicosOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    gruposEconomicosOptions[i + 1] = { value: item.gpec_id, text: item.gpec_nome };
                }
                this.setState({ gruposEconomicosOptions})
            })
            .catch(err => {
                this.setState({ gruposEconomicosOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaEmpresasPorGrupo(grupo){
        this.setState({ loading: true});
        if(grupo) this.setState({ grupoEconomico: grupo });
        let url = 'empresa?per_page=-1';
        if(grupo) url+= "&gpec_id=" + grupo;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions: empresasOptions, loading: false});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async recarregaEmpresaSelect(grupo){
        this.setState({ grupoEconomico: grupo});
        this.carregaEmpresasPorGrupo(grupo);
    }

    async carregaRepresentanteComercialStatus(){
        this.setState({ loading: true });
        await ApiService.getGeral('representante_comercial_empresa_status')
            .then(result => {
                const res = result.data;
                let statusOptions = [{ value: '', text: 'Selecione' }];
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    statusOptions[i + 1] = { value: item.repcemps_id, text: item.repcemps_nome };
                }
                this.setState({ statusOptions})
            })
            .catch(err => {
                this.setState({ statusOptions: [{ value: '', text: 'Nenhum status encontrado!' }] });
            })
    }

    async exportarRepresentanteComercialEmpresa(){
        const { dataInicio, dataFim } = this.state;

        this.setState({ loading: true });

        let url = '/geral/representante_empresa/export/comissao?data_inicio=' +  Funcoes.data2Americano(dataInicio) + "&data_fim=" +  Funcoes.data2Americano(dataFim);
        
        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";
        let hoje = new Date()
        hoje = hoje.toISOString().split('T')[0];
        
        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'comissao_'+ hoje + '.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();

    }

    handleNameFilter(e) {
        const val = e.value;
        if (val.startsWith(0) || val.startsWith(1) || val.startsWith(2) || val.startsWith(3) || val.startsWith(4) || val.startsWith(5) || val.startsWith(6) || val.startsWith(7) ||
            val.startsWith(8) || val.startsWith(9)) {
            e.setAttribute('maxlength', 14);
            this.setState({ nome: cpfMask(val) });
        } else {
            e.removeAttribute('maxLength');
            this.setState({ nome: val });
        }
    }

    render() {
        const { loading, redirect, path, propsToPath, modal, modalExportacao, alert, alertText, table, exibeDados, mostraVazio, acoes, 
            nome, status, empresasOptions, gruposEconomicosOptions, statusOptions, empresa, grupoEconomico, dataInicio, dataFim } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                
                <Modal show={modalExportacao}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de aviso" />
                        <p className="description">Filtro Data Solicitação de Contratos</p>
                        <Input label="De" type="tel" placeholder="dd/mm/aaaa" value={dataInicio} className="custom" maxLength="10"
                            onChange={e => this.setState({ dataInicio: dataBrMask(e.target.value) })} />
                        <Input label="Até" type="tel" placeholder="dd/mm/aaaa" value={dataFim} className="custom" maxLength="10"
                            onChange={e => this.setState({ dataFim: dataBrMask(e.target.value) })} />
                        <div className="cancelamentoAcao">
                            <button 
                                className="btn"
                                style={ (dataInicio.length === 10 && dataFim.length === 10)? { backgroundColor: '#EA5353', color: '#FFF' } : { backgroundColor: '#999' }}
                                 
                                onClick={() =>  (dataInicio.length === 10 && dataFim.length === 10) ? this.exportarRepresentanteComercialEmpresa() : {}}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalExportacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>

                <Modal show={modal}>
                    <div className="">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Icone de Aviso" />
                        <p>Você realmente deseja excluir o usuário <b>{this.state.nome}</b>?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn" onClick={() => this.excluirUsuario()}>Confirmar</button>
                            <button className="btn" onClick={() => this.setState({ modal: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>

                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={13} />

                <div className="body">
                    <Header />

                    <div className="usuario-empresa mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Representantes Comerciais</h1>
                                <p className="descricao">Abaixo a listagem de todos os representantes de empresas</p>
                            </div>
                            <div className="buttons">
                                {acoes.indexOf(27) !== -1 ?
                                    <button className="btn" onClick={() => this.setState({ path: '/representantes/novo', redirect: true })}>Novo Representante</button>
                                : null}
                                <button className="btn btnAzul" onClick={() => this.setState({modalExportacao: true})}>Exportar (.xlsx)</button>   
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="text" value={nome} label="Nome ou CPF" 
                                        onChange={e => this.handleNameFilter(e.target)} />
                                </div>
                                <div className="row">
                                    <Input type="select" isFilterGroups={true} optionsData={gruposEconomicosOptions} label="Grupo Econômico" value={grupoEconomico} 
                                        onChange={e => this.recarregaEmpresaSelect(e.target.value)} />      
                                    <Input type="select" isFilterGroups={true} label="Empresa" optionsData={empresasOptions} value={empresa}
                                        onChange={e => this.setState({ empresa: e.target.value })} />
                                    <Input type="select" isFilterGroups={true} optionsData={statusOptions} label="Status" value={status} 
                                        onChange={e => this.setState({status: e.target.value})} /> 
                                     <button className="btn filtrar" onClick={() => this.carregaRepresentantesEmpresa()}>Filtrar</button>

                                </div>

                            </div>
                        </div>

                        <div className="detalhes">

                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar} 
                                        acaoExtrato={this.acaoExtrato}/>
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhum representante comercial!</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}