import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Modal from '../../Components/Modal';
import iconAviso from '../../resources/img/icon-aviso.svg';

import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Paginacao from '../../Components/Paginacao';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { cpfMask, dataBrMask } from '../../Utils/mask';

export default class ContractApproval extends Component {

    constructor() {
        super();

        this.acaoRegras = this.acaoRegras.bind(this);
        this.acaoAprovar = this.acaoAprovar.bind(this);
        this.acaoReprovar = this.acaoReprovar.bind(this);

        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            tipo_colaborador: 'nome',
            empresasOptions: [{ value: '', text: 'Selecione' }],
            table: {
                campos: [
                    { titulo: 'Tipo Contrato', dado: 'tipoContrato' },
                    { titulo: 'Cliente/Colaborador', dado: 'cliColab', thStyle: { minWidth: 200 } },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Valor Emprestado', dado: 'valEmprestado'},
                    { titulo: 'Prazo', dado: 'prazo' },
                    { titulo: 'Data Solicitação', dado: 'data' },
                    { titulo: 'Risco Autentify', dado: 'tag', thStyle: { minWidth:75 } },
                    { titulo: 'Ações', dado: 'acoes',  }
                ],
                dados: []
            },
            statusOptions: [
                {value: '', text: "Selecione"},
                {value: 0, text: "Pendente RH"},
                {value: 1, text: "Aprovado RH/Aguardando assinatura"},
                {value: 2, text: "Assinado"},
                {value: 3, text: "Aguardando liberação dinheiro"},
                {value: 4, text: "Pago"},
                {value: 5, text: "Finalizado"},
                {value: 6, text: "Negado RH"}
            ],
            tiposOptions: [
                {value: '', text: "Selecione"},
                {value: 1, text: "Empréstimo"},
                {value: 2, text: "Refinanciamento"}
            ],
            propostasDefault: {},
            exibeDados: false,
            mostraVazio: true,
            numContrato: '',
            cliente: '',
            empresa: '',
            proposta_status: '',
            proposta_tipo: '',
            acoes: [],
            cpf: '',
            cep: '',
            email: '',
            emailMsgRisco: '',
            emailPrimeiraVisualizacao: '',
            emailPrimeiraVisualizacaoDias: 0,
            emailGratuito: true,
            emailDescricao: '',
            ipMsgRisco: '', 
            ipTipousuario: '', 
            ipEstaticoRiscoMsg: '',
            ipIsp: '', 
            ipDistanciaDoCep: 0,
            geoTimeZone: '', 
            geoCidade: '', 
            geoEstado: "", 
            geoPais: "", 
            geoCidadePrecisao: "", 
            geoEstadoPrecisao: "", 
            geoPaisPrecisao: "",
            modalConfirmacao: false,
            modalReprovacao: false,
            idDeAprovacao: null,
            idDeReprovacao: null,
            mostraVazio: true,
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });

        this.carregaEmpresas();
        this.carregaPropostas(1, acoes);
    }

    async carregaEmpresas() {
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions });
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaPropostas(page, acao) {
        const { table, numContrato, cliente, empresa, proposta_status, proposta_tipo, acoes, tipo_colaborador } = this.state;
        this.setState({ loading: true });

        var url, pagina, pagAtual, actions;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;
        actions = acao ? acao : acoes;

        if (pagAtual) {
            url = "proposta/pendente_de_aprovacao?per_page=20&page=" + pagAtual;
        } else {
            url = "proposta/pendente_de_aprovacao?per_page=20";
        }
        url += "&ppst_status=8"
        if (cliente) url = url + (tipo_colaborador == 'nome' ? "&func_nome=" : "&func_cpf=") + cliente;
        if (empresa) url = url + "&emp_id=" + empresa;
        if (proposta_tipo) url = url + "&ppst_tipo=" + proposta_tipo;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.ppst_id, 
                        cliColab: item.func_nome.toUpperCase(),
                        func_id: item.func_id,
                        tipoContrato: item.ppst_tipo_label,
                        empresa: item.emp_nomefantasia.toUpperCase(),
                        prazo: item.ppst_prazo,
                        valEmprestado: 'R$ ' + Funcoes.float2Preco(item.ppst_valorsolicitado),
                        data: item.ppst_dthrsolicitacao ? Funcoes.data2Brazilian(item.ppst_dthrsolicitacao.split(' ')[0]) : '',
                        tag: this.processaStatusDeRisco(item.histautscore_score_mensagem),
                        acoes: 'aprova/reprova|regras',
                        cpf: item.histautscore_cpf,
                        cep: item.histautscore_cep,
                        email: item.histautscore_email, 
                        emailMsgRisco: item.histautscore_email_mensagem_risco ,
                        emailPrimeiraVisualizacao:  Funcoes.data2Brazilian(item.histautscore_email_primeira_visualizacao_na_rede),
                        emailPrimeiraVisualizacaoDias: item.histautscore_email_primeira_visualizacao_em_dias,
                        emailGratuito: item.histautscore_email_gratuito ? 'Sim' : "Não",
                        emailDescricao: item.histautscore_email_descricao,
                        ip: item.histautscore_ip,
                        ipMsgRisco: item.histautscore_ip_risco_mensagem, 
                        ipTipousuario: item.histautscore_ip_tipo_usuario, 
                        ipEstaticoRiscoMsg: item.histautscore_ip_estatico_risco_score,
                        ipIsp: item.histautscore_ip_isp, 
                        ipDistanciaDoCep: item.histautscore_ip_distancia_do_cep,
                        geoTimeZone: item.histautscore_geo_time_zone, 
                        geoCidade: item.histautscore_geo_cidade, 
                        geoEstado: item.histautscore_geo_estado, 
                        geoPais: item.histautscore_geo_pais, 
                        geoCidadePrecisao: item.histautscore_geo_cidade_precisao, 
                        geoEstadoPrecisao: item.histautscore_geo_estado_precisao,
                        geoPaisPrecisao: item.histautscore_geo_pais_precisao
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, propostasDefault: result.data });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async acaoAprovar(id) {
        const { acoes } = this.state;
        this.setState({modalConfirmacao: true, idDeAprovacao: id});
    }

    async aprovarContrato(id)
    {
        this.setState({loading: true, modalConfirmacao: false});
        let form = {
            "ppst_status": 0
        }
        await ApiService.patchGeral('proposta/aprovar/' + id, form)
        .then(result => {
            this.carregaPropostas(1)
            this.setState({ alertText: "Proposta aprovada com sucesso", alert: true, alertType: 1 });
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    async reprovarContrato(id)
    {
        this.setState({loading: true, modalReprovacao: false});
        let form = {
            "ppst_status": 6
        }
        await ApiService.patchGeral('proposta/aprovar/' + id, form)
        .then(result => {
            this.carregaPropostas(1)
            this.setState({ alertText: "Proposta reprovada com sucesso", alert: true, alertType: 1 });
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    async acaoReprovar(id) {
        const { acoes } = this.state;
        this.setState({modalReprovacao: true, idDeReprovacao: id});        
    }
    
    acaoRegras(id) {
        const {table} = this.state;

        let historico = table["dados"].filter(historico => historico["id"] == id)[0];
                        
        this.setState({ 
                mostraVazio: true,
                cpf: historico.cpf,
                cep: historico.cep,
                email: historico.email, 
                emailMsgRisco: historico.emailMsgRisco ,
                emailPrimeiraVisualizacao:  historico.emailPrimeiraVisualizacao,
                emailPrimeiraVisualizacaoDias: historico.emailPrimeiraVisualizacaoDias,
                emailGratuito: historico.emailGratuito ? 'Sim' : "Não",
                emailDescricao: historico.emailDescricao,
                ip: historico.ip,
                ipMsgRisco: historico.ipMsgRisco, 
                ipTipousuario: historico.ipTipousuario, 
                ipEstaticoRiscoMsg: historico.ipEstaticoRiscoMsg,
                ipIsp: historico.ipIsp, 
                ipDistanciaDoCep: historico.ipDistanciaDoCep,
                geoTimeZone: historico.geoTimeZone, 
                geoCidade: historico.geoCidade, 
                geoEstado: historico.geoEstado, 
                geoPais: historico.geoPais, 
                geoCidadePrecisao: historico.geoCidadePrecisao, 
                geoEstadoPrecisao: historico.geoEstadoPrecisao,
                geoPaisPrecisao: historico.geoPaisPrecisao,
            });
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/contratos/aprovacao?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/contratos/aprovacao', redirect: true });
        }
        this.carregaPropostas(page);
    }

    handleFiltro(e) {
        const val = e.target.value;
        if (val.startsWith(0) || val.startsWith(1) || val.startsWith(2) || val.startsWith(3) || val.startsWith(4) || val.startsWith(5) || val.startsWith(6) || val.startsWith(7) ||
            val.startsWith(8) || val.startsWith(9)) {
            document.getElementById('filtro').setAttribute('maxlength', 14);
            this.setState({ cliente: cpfMask(val), tipo_colaborador: 'cpf' });
        } else {
            document.getElementById('filtro').removeAttribute('maxLength');
            this.setState({ cliente: val, tipo_colaborador: 'nome' });
        }
    }

    processaStatusDeRisco(status)
    {
        if(status == "low")
        {
            return "Baixo";

        }
        
        if(status == "mid")
        {
            return "Medio";

        }

        if(status == "high")
        {
            return "Alto";
        }

        return status;
    }
    
    render() {
        const { loading, redirect, path, email, emailMsgRisco, emailPrimeiraVisualizacao, emailPrimeiraVisualizacaoDias, emailGratuito, emailDescricao, 
            ip, ipMsgRisco, ipTipousuario, ipEstaticoRiscoMsg, ipIsp, ipDistanciaDoCep, cpf,cep ,modalConfirmacao, modalReprovacao,
            geoTimeZone , geoCidade, geoEstado, geoPais, geoCidadePrecisao, geoEstadoPrecisao, geoPaisPrecisao, 
            idDeAprovacao,idDeReprovacao, mostraVazio,
            propsToPath, alert, alertText, alertType, table, exibeDados, empresasOptions, statusOptions, tiposOptions, numContrato, cliente, empresa, proposta_status, proposta_tipo, propostasDefault } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path}  />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                
                <Modal show={modalConfirmacao}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <p className="description">Você realmente deseja aprovar a proposta?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn"
                                style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                onClick={() =>  this.aprovarContrato(idDeAprovacao) }>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ idDeAprovacao: null, modalConfirmacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={modalReprovacao}>
                    <div className="modal-tipobaixa">
                        <p className="warning">Atenção</p>
                        <img src={iconAviso} alt="Ícone de aviso" />
                        <p className="description">Você realmente deseja reprovar a proposta?</p>
                        <div className="cancelamentoAcao">
                            <button className="btn"
                                style={{ backgroundColor: '#EA5353', color: '#FFF' }}
                                onClick={() =>  this.reprovarContrato(idDeReprovacao) }>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ idDeReprovacao: null, modalReprovacao: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>
                <Loading show={loading} />
                <SideMenu menuAtivo={5} />

                <div className="body">
                    <Header />

                    <div className="contratos mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Mesa de Aprovação</h1>
                                <p className="descricao">Consulte abaixo todos os contratos pendentes de aprovação.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="select"  isFilterGroups={true} optionsData={tiposOptions} label="Tipo" value={proposta_tipo} 
                                        onChange={e => this.setState({ proposta_tipo: e.target.value })} />    
                                    <Input type="text"  isFilterGroups={true} label="Colaborador ou CPF" value={cliente} id="filtro"
                                        onChange={e => this.handleFiltro(e)} />  
                                    <Input type="select" isFilterGroups={true} optionsData={empresasOptions} label="Empresa" value={empresa} 
                                        onChange={e => this.setState({ empresa: e.target.value })} />
                                   
                                    <button className="btn filtrar" onClick={() => this.carregaPropostas(1)}>Filtrar</button>
                                </div>
                            </div>
                        </div>
                    
                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoRegras={this.acaoRegras}
                                        acaoAprovar={this.acaoAprovar}
                                        acaoReprovar={this.acaoReprovar} />
                                ) : null}
                            </div>
                            {exibeDados ? (
                                <div className="info">
                                    <p>Total de registros: {propostasDefault.total}</p>
                                    <Paginacao dados={propostasDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                     {mostraVazio ? (
                                    <div id="historico" className="contratos mgDefault">
                                    <div className="panel">
                                        <div className="panelHeader">
                                            <h3>Dados da Consulta</h3>
                                        </div>
                                        <div className="panelBody">
                                            <p><span>Cpf: </span> {cpf}</p>
                                            <p><span>Email: </span> {email}</p>
                                            <p><span>Ip: </span> {this.state.ip}</p>
                                            <p><span>Cep: </span> {this.state.cep}</p>
                                            <div className="panel">
                                                <div className="panelHeader">
                                                    <h3>Email</h3>
                                                </div>
                                                <div className="panelBody">
                                                    <div className="multArea">
                                                        <p><span>Email: </span> {this.state.email}</p>
                                                        <p><span>Risco: </span> {this.state.emailMsgRisco}</p>
                                                        <p><span>Gratuito: </span> {this.state.emailGratuito}</p>
                                                        <p><span>Primeira visualização na rede: </span> {this.state.emailPrimeiraVisualizacao}</p>
                                                        <p><span>Primeira visualização na rede(Dias): </span> {this.state.emailPrimeiraVisualizacaoDias}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel">
                                                <div className="panelHeader">
                                                    <h3>Ip</h3>
                                                </div>
                                                <div className="panelBody">
                                                        <p><span>Ip: </span> {this.state.ip}</p>
                                                        <p><span>Risco: </span> {this.state.ipMsgRisco}</p>
                                                        <p><span>Tipo de usuário: </span> {this.state.ipTipousuario}</p>
                                                        <p><span>Risco Ip estático: </span> {this.state.ipEstaticoRiscoMsg}</p>
                                                        <p><span>Provedora: </span> {this.state.ipIsp}</p>
                                                        <p><span>Distância do CEP: </span> {this.state.ipDistanciaDoCep} Km</p>
                                                </div>
                                            </div>
                                            <div className="panel">
                                                <div className="panelHeader">
                                                    <h3>Geolocalização</h3>
                                                </div>
                                                <div className="panelBody">
                                                        <p><span>Horário: </span> {this.state.geoTimeZone}</p>
                                                        <p><span>Cidade: </span> {this.state.geoCidade}</p>
                                                        <p><span>Estado: </span> {this.state.geoEstado}</p>
                                                        <p><span>País: </span> {this.state.geoPais}</p>
                                                        <p><span>Acertividade Cidade: </span> {this.state.geoCidadePrecisao}</p>
                                                        <p><span>Acertividade Esdado: </span> {this.state.geoEstadoPrecisao}</p>
                                                        <p><span>Acertiviadade País: </span> {this.state.geoPaisPrecisao}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                ) : null}   
                        
                    </div>
                    
                <Footer />
            </Fragment>
        )
    }
}