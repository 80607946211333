import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import ApiService from '../../Utils/services';

import { Link } from 'react-router-dom';

export default class NewCollaboratorAttendance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            mensagem: '',
            usuario: null,
            nome: '',
            cpf: '',

        };
    }

    componentDidMount() {
        const { usuario } = this.state;
        this.setState({ loading: true});
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.setState({usuario: userData.usuario.nome});

        this.carregaColaborador();


    }
    async carregaColaborador() {
        const { colaborador } = this.props.location.state;
        await ApiService.getGeral('funcionario/' + colaborador.id)
            .then(result => {
                const item = result.data.data;
              
                this.setState({
                    nome: item.func_nome, cpf: item.func_cpf, loading: false
                });
            })
            .catch(err => {
                console.log(err);
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleChange(event, attr, type) {
        const { colaborador } = this.props.location.state;
        this.setState({ [attr]: event.target.value }); 
    }
    async novaConta(form) {
       
        await ApiService.postGeral('funcionarioacompanhamento', form)
            .then(res => {
                this.setState({ path: '/colaboradores/anotacoes', propsToPath: this.props.location.state, loading: false, redirect: true });           
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }
    validaCampos() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const {mensagem, usuario} = this.state;
        const { colaborador } = this.props.location.state;
        this.setState({ loading: true });

        let form = [
            { campo: 'Anotação', attr: 'mensagem', valor: mensagem },
            { campo: 'Usuário', attr: 'usuario', valor: usuario }
        ];
        const formData = {
                    func_id: colaborador.id,
                    funca_mensagem: mensagem,
                    usu_id: userData.usuario.id
                }
        if (!mensagem || mensagem ==='') {
            this.setState({ alertText: '* ' + "A anotação precisa estar preenchida", alert: true });
        }
        this.novaConta(formData)

    }

    voltar() {
        this.setState({ path: '/colaboradores/anotacoes', propsToPath: this.props.location.state, loading: false, redirect: true });
    }

    render() {
        const { loading, redirect, path, alert, alertText, propsToPath, statusOptions, mensagem, nome, cpf, usuario} = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />

                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={3} />

                <div className="body">
                    <Header />

                    <div className="usuario-empresa-novo mgDefault">
                        <h1>{'Nova'} Anotação</h1>
                        <p className="descricao">Informe abaixo os dados da anotação.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="textarea" label="Anotação" value={mensagem} onChange={e => this.handleChange(e, "mensagem")} />
                                </div>
                                <div className="panelHeader">
                                    <h3>Dados do funcionário</h3>
                                </div>
                                <div className="multInput">
                                    <Input type="text" label="Funcionário" value={nome}  className="disabled" />
                                    <Input type="text" label="CPF" value={cpf}  className="disabled" />
                                </div>
                                <div className="panelHeader">
                                    <h3>Dados do usuário</h3>
                                </div>
                                <div className="multInput">
                                    <Input type="text" label="Usuário" value={usuario}  className="disabled" />
                                </div>
                            </div>
                        </div>

                        <div className="acoes">
                             <div className="acoes">
                                <button className="btn btnVoltar" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.voltar()}>{'<'} Voltar</button>
                            </div>
                            <button className="btn btnCadastrar" onClick={() => this.validaCampos()}>{'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}