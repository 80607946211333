import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { cpfMask, onlyNumbersMask, celularMask, dataBrMask, cepMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

import { Link } from 'react-router-dom';

export default class NewEconomicGroup extends Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            empresasOptions: [],
            nome: '',
            empresas: [],
            empresasGrupo: []
        };
    }

    componentDidMount() {
        if (this.props.location.state){ 
            this.pegaGrupoEditavel()
        }else{
            this.carregaEmpresas();
        }
    }

    async carregaEmpresas(empresasGrupo) {
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?emp_id_grupoeconomico=true&per_page=-1')
            .then(result => {
                const res = result.data.data;
                var objects = [];
                if(empresasGrupo !== undefined){
                    for(var i = 0; i < empresasGrupo.length; i++){
                        objects.push({ id: empresasGrupo[i].emp_id, empresa: empresasGrupo[i].empresa.emp_nomefantasia, checado: true });
                    }
                }
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ id: item.emp_id, empresa: item.emp_nomefantasia, checado: false })
                }
                this.setState({ empresasOptions: objects, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async pegaGrupoEditavel() {
        const { grupo } = this.props.location.state;
        this.setState({ loading: true });
        const userData = JSON.parse(localStorage.getItem('userData'));

        await ApiService.getGeral('grupoeconomico/' + grupo.id)
            .then(result => {
                const res = result.data.data;
                var i, empresas = [];
                for (i = 0; i < res.empresas.length; i++) {
                    empresas[i] = res.empresas[i].emp_id;
                }
                this.setState({
                    id: res.gpec_id, nome: res.gpec_nome, empresasGrupo: empresas
                });
                this.carregaEmpresas(res.empresas);
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    validaCampos() {
        const { nome, empresasOptions } = this.state;
        let form = [
            { campo: 'Nome', attr: 'nome', valor: nome }
        ];

        const valida = validaForm(form);
        if (valida.valido) {
            var i, emps = [], empresasCheck = document.querySelectorAll('input[type="checkbox"]:checked');
            if (empresasCheck.length > 0) {
                for (i = 0; i < empresasCheck.length; i++) {
                    emps.push({emp_id: empresasCheck[i].getAttribute('rel')});
                }

                this.setState({ loading: true });

                const formData = {
                    gpec_nome: nome,
                    empresas: emps
                }

                const userData = JSON.parse(localStorage.getItem('userData'));
                if (this.props.location.state) {
                    this.editarConta(formData);
                } else {
                    this.novaConta(formData);
                }
            }else{
                this.setState({ alertText: '* Você deve selecionar alguma empresa para o representante', alert: true });
                return;
            }
        }

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaConta(form) {
        await ApiService.postGeral('grupoeconomico', form)
            .then(res => {
                this.setState({ path: '/grupos-economicos', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async editarConta(form) {
        const { grupo } = this.props.location.state;
        await ApiService.putGeral('grupoeconomico/' + grupo.id, form)
            .then(res => {
                this.pegaGrupoEditavel();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async updateValues(attr, val, type) {
        this.setState({ [attr]: val })
    }

    render() {
        const { loading, redirect, path, alert, alertText, nome, empresasGrupo, empresasOptions } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={14} />

                <div className="body">
                    <Header />

                    <div className="usuario-empresa-novo mgDefault">
                        <h1>{this.props.location.state ? 'Editar' : 'Novo'} Grupo</h1>
                        <p className="descricao">Informe abaixo os dados do grupo econômico.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Básicos</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Nome" value={nome} onChange={e => this.updateValues("nome", e.target.value, "nome")} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Empresas</h3>
                            </div>
                            <div className="panelBody" style={{ marginTop: -16 }}>
                                {empresasOptions.map((item, index) => {
                                    var checked = false;
                                    if (empresasGrupo.includes(item.id)) checked = true;
                                    if (empresasOptions[index].checado) checked = true;
                                    return (
                                        <div className="empresaInput">
                                            <Input type="checkbox" className="checkBoxtable" label={item.empresa} defaultChecked={checked}
                                                rel={item.id} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/representantes" className="btn">{'<'} Voltar</Link>
                            <button className="btn btnCadastrar" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}