import {
    ADD_ANTECIPACAO,
    REMOVE_ANTECIPACAO,
    DELETE_ALL_ANTECIPACAO,
    RETRIEVE_ALL_ANTECIPACAO
} from "../Actions/types";

const initialState = [];

function antecipacaoReducer(antecipacoes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ANTECIPACAO:
      if(antecipacoes.find(x => x.id === payload.id)){
        return antecipacoes;
      }
      return [...antecipacoes, payload];

    case REMOVE_ANTECIPACAO:
      return antecipacoes.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_ANTECIPACAO:
      return [];
      
    case RETRIEVE_ALL_ANTECIPACAO:
      return antecipacoes;
      
    default:
      return antecipacoes;  
  }
};

export default antecipacaoReducer;