import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import ApiService from '../../Utils/services';
import { validaForm } from '../../Utils/FormValidator';

import { Link } from 'react-router-dom';

export default class ContractRepayment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertType: 2,
            alertText: '',
            banco: '',
            bancoOptions: [{ value: '', text: 'Selecione' }],
            agencia: '',
            digito: null,
            numero: '',
            codigoProposta: '',
            ppst_ccb: '',
            func_id: '',
            func_nome: '',
            func_cpf: '',
        };
    }

    componentDidMount() {
        const { contrato, ppst_codigo, proposta } = this.props.location.state;
        this.setState({
            loading: true,
            codigoProposta: proposta.ppst_codigo,
            func_nome: proposta.funcionario.func_nome,
            func_cpf: proposta.funcionario.func_cpf,
            ppst_ccb: proposta.ppst_numeroccb

        });
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.carregaBancos();

    }
    async handleChange(event, attr, type) {
        const { colaborador } = this.props.location.state;
        this.setState({ [attr]: event.target.value }); 
    }

    async carregaBancos() {
        await ApiService.getGeral('banco')
            .then(result => {
                const res = result.data.data;
                var objects = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ value: item.bco_numero, text: item.bco_numero + ' - ' + item.bco_nome });
                }
                this.setState({ bancoOptions: objects, loading: false });
            })
            .catch(err => this.setState({ bancoOptions: [{ value: '', text: 'Nenhum banco disponível' }] }))
    };

    async novoRepagamento(form) {   
        const { contrato, acoes } = this.props.location.state;
  
        await ApiService.postGeral('contratos/repagamento', form)
            .then(res => {
                this.setState({ alertText: 'O repagamento foi feito com sucesso!', alertType: 1,
                 alert: true,  path: '/contratos/detalhes', 
                 propsToPath: { acoes: acoes, contrato: {id: contrato.id}}, 
                 loading: false});
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertType: 2, alertText: valida.response, alert: true });
            })
        
    }
    
    validaCampos() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const {banco, agencia, digito, numero, codigoProposta} = this.state;
        const { colaborador } = this.props.location.state;
        this.setState({ loading: true });

        let form = [
            { campo: 'Banco', attr: 'banco', valor: banco },
            { campo: 'Agência', attr: 'agencia', valor: agencia },
            { campo: 'Dígito', attr: 'digito', valor: digito },
            { campo: 'Número', attr: 'numero', valor: numero },
            { campo: 'Código da proposta', attr: 'codigoProposta', valor: codigoProposta },
            
        ];
        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });

            const formData = {
                banco: banco,
                agencia: agencia,
                digito: digito,
                numero: numero,
                ppst_codigo: codigoProposta,
                usu_id: userData.usuario.id
            }
            this.novoRepagamento(formData)

        }
        
        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true, loading: false });
                    break;
                }
            }
        }
    }

    voltar() {
        const { contrato, acoes } = this.props.location.state;
        this.setState({ path: '/contratos/detalhes', propsToPath: { acoes: acoes, contrato: {id: contrato.id}}, loading: false, redirect: true });
    }

    render() {
        const { 
            loading, redirect, path, alert, alertText, alertType, propsToPath, statusOptions, mensagem, agencia, digito, numero,
             banco, ppst_ccb, func_cpf, func_nome, bancoOptions} = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />

                <Alert show={alert} texto={alertText}  tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={5} />

                <div className="body">
                    <Header />
                    <div className="usuario-empresa-novo mgDefault">
                        <h1>{'Nova'} Repagamento</h1>
                        <p className="descricao">Informe abaixo os dados para o repagamento.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados do Contrato</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Funcionário " value={func_nome} className="disabled"/>
                                    <Input type="text" label="CPF " value={func_cpf} className="disabled"/>
                                </div>
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="text" label="CCB " value={ppst_ccb} className="disabled"/>
                                </div>
                            </div>
                            <div className="panelHeader">
                                <h3>Dados bancários</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="select" optionsData={bancoOptions} label="Banco" value={banco}
                                        onChange={e => this.setState({banco: e.target.value})} />
                                </div>
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Agência" value={agencia}                                           
                                        onChange={e => this.setState({agencia: e.target.value})} />
                                    <Input type="text" label="Número da Conta" value={numero}  
                                        onChange={e => this.setState({numero: e.target.value})} />
                                    <Input type="text" label="Digito" value={digito}  
                                        onChange={e => this.setState({digito: e.target.value})} />
                                    
                                </div>
                            </div>
                        </div>

                        <div className="acoes">
                             <div className="acoes">
                                <button className="btn btnVoltar" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.voltar()}>{'<'} Voltar</button>
                            </div>
                            <button className="btn btnCadastrar" onClick={() => this.validaCampos()}>{'Enviar'}</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}