import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { cepMask, cnpjMask, telefoneMask, onlyNumbersMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';

import { Link } from 'react-router-dom';

export default class NewUserCompany extends Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Ativo' },
                { value: 2, text: 'Inativo' }
            ],
            aprovacaoOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Sim' },
                { value: 0, text: 'Não' }
            ],
            empresasOptions: [],
            funcoesOptions: [
                {value: 1, text: 'Administrador', checado: false},
                {value: 2, text: 'Aprovador', checado: false},
                {value: 3, text: 'Conciliador', checado: false},

            ],
            funcoes: [],
            nome: '',
            status: '',
            habilitaaprovacao: '1',
            email: '',
            senha: '',
            empresas: [],
            empresasUsuario: []
        };
    }

    componentDidMount() {
        if (!this.props.location.state) this.carregaEmpresas();
        if (this.props.location.state) this.pegaUsuarioEditavel();
    }

    async carregaEmpresas(empresasUser) {
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ id: item.emp_id, empresa: item.emp_nomefantasia, habilita_aprovacao: false, checado: false });
                }
                if(empresasUser !== undefined){
                    for(var i = 0; i < objects.length; i++){
                        for(var x = 0; x < empresasUser.length; x++){
                            if(objects[i].id == empresasUser[x].emp_id){
                                objects[i].habilita_aprovacao = empresasUser[x].uemp_habilitaaprovacao == 1 ? "1" : "0";
                                objects[i].checado = true;
                            }
                        }
                    }
                }
                this.setState({ empresasOptions: objects, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async pegaUsuarioEditavel() {
        const { usuario } = this.props.location.state;
        this.setState({ loading: true });
        const userData = JSON.parse(localStorage.getItem('userData'));

        await ApiService.getGeral('empresausuario/' + usuario.id)
            .then(result => {
                const res = result.data.data;
                var i, empresas = [];
                for (i = 0; i < res.usuarioempresa.length; i++) {
                    empresas[i] = res.usuarioempresa[i].emp_id;
                }
                this.setState({
                    id: res.eusu_id, nome: res.eusu_nome, email: res.eusu_email, status: res.eusu_status, habilitaaprovacao: res.eusu_habilitaaprovacao, empresasUsuario: empresas
                });
                this.carregaEmpresas(res.usuarioempresa);
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleChange(event, attr, type) {
        if (type === "cnpj") {
            this.setState({ [attr]: cnpjMask(event.target.value) });
        } else if (type === "cep") {
            this.setState({ [attr]: cepMask(event.target.value) });
            if (event.target.value.length === 9) {
                this.setState({ loading: true });
                await ApiService.customRequest('geral/consultacep', 'POST', { cep: cepMask(event.target.value) })
                    .then(result => {
                        const res = result.data.data;
                        this.setState({
                            logradouro: res.logradouro, complemento: res.complemento, bairro: res.bairro, cidade: res.cidade.cid_nome,
                            cidId: res.cidade.cid_id, uf: res.uf, loading: false
                        });
                    })
                    .catch(err => this.setState({ loading: false }))
            }
        } else if (type === "telefone") {
            this.setState({ [attr]: telefoneMask(event.target.value) });
        } else if (type === "onlyNumbers") {
            this.setState({ [attr]: onlyNumbersMask(event.target.value) });
        } else {
            this.setState({ [attr]: event.target.value });
        }
    }

    validaCampos() {
        const { nome, status, habilitaaprovacao, email, senha, empresasOptions, funcoes } = this.state;
        let form = [
            { campo: 'Nome', attr: 'nome', valor: nome },
            { campo: 'Status', attr: 'status', valor: status },
            //{ campo: 'Habilita Aprovação', attr: 'habilitaaprovacao', valor: habilitaaprovacao },
            { campo: 'E-mail', attr: 'email', valor: email, tipo: "email" }
        ];
        if (!this.props.location.state)
            form.push({ campo: 'Senha', attr: 'senha', valor: senha, minLength: 5 });

        const valida = validaForm(form);
        if (valida.valido && status) {
            var i, emps = [], empresasCheck = document.querySelectorAll('input[type="checkbox"]:checked');
            if (empresasCheck.length > 0) {
                for (i = 0; i < empresasOptions.length; i++) {
                    if(empresasOptions[i].checado){
                        emps.push({ "id": empresasOptions[i].id, "habilita_aprovacao": empresasOptions[i].habilita_aprovacao}); 
                    }
                }
                this.setState({ loading: true });
                const formData = {
                    eusu_nome: nome,
                    eusu_email: email,
                    eusu_senha: senha,
                    eusu_status: status,
                    eusu_habilitaaprovacao: habilitaaprovacao,
                    emp_id: emps,
                    funcoes: funcoes.join()
                }
                const userData = JSON.parse(localStorage.getItem('userData'));
                if (this.props.location.state) {
                    this.editarConta(formData);
                } else {
                    this.novaConta(formData);
                }
            } else {
                this.setState({ alertText: 'Selecione uma empresa antes de continuar!', alert: true });
            }
        }

        if (!status)
            this.setState({ alertText: '* O campo Status não foi preenchido', alert: true });

        //if (!habilitaaprovacao)
        //    this.setState({ alertText: '* O campo Status não foi preenchido', alert: true });

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaConta(form) {
        await ApiService.postGeral('empresausuario', form)
            .then(res => {
                this.setState({ path: '/usuario-empresa', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async editarConta(form) {
        const { usuario } = this.props.location.state;
        await ApiService.putGeral('empresausuario/' + usuario.id, form)
            .then(res => {
                this.pegaUsuarioEditavel();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { loading, redirect, path, alert, alertText, statusOptions, aprovacaoOptions, empresasOptions, funcoesOptions, funcoes , nome, status, habilitaaprovacao, email, senha, empresasUsuario } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={3} />

                <div className="body">
                    <Header />

                    <div className="usuario-empresa-novo mgDefault">
                        <h1>{this.props.location.state ? 'Editar' : 'Novo'} Usuário</h1>
                        <p className="descricao">Informe abaixo os dados do usuário.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Básicos</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Nome" value={nome} onChange={e => this.handleChange(e, "nome")} />
                                    <Input type="select" label="Status" optionsData={statusOptions} divStyle={{ maxWidth: 346 }}
                                        onChange={e => this.handleChange(e, 'status')} value={status} style={{ marginRight: 0 }} />
                                    {/*<Input type="select" label="Habilita Aprovação?" optionsData={aprovacaoOptions} divStyle={{ maxWidth: 200 }}
                                        onChange={e => this.handleChange(e, 'habilitaaprovacao')} value={habilitaaprovacao} />*/}
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados de Acesso</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="tel" label="E-mail" value={email} onChange={e => this.handleChange(e, "email")} />
                                    <Input type="password" label="Senha" value={senha} onChange={e => this.handleChange(e, "senha")}
                                        style={{ marginRight: 0 }} divStyle={{ maxWidth: 600 }} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Funções</h3>
                            </div>
                            <div className="panelBody" style={{ marginTop: -16 }}>
                                {funcoesOptions.map((item, index) => {
                                    var checked = false;
                                    if(funcoesOptions[index].checado){
                                        checked = true;
                                    }
                                    return (
                                        <div className="empresaInput">
                                            <Input type="checkbox" className="checkBoxtable" value={item.value} label={item.text} defaultChecked={checked}
                                                rel={item.id} onChange={e => { 
                                                    let arrFuncoes = [];
                                                    if(e.target.checked){
                                                        funcoes.push(item.value);
                                                        arrFuncoes = funcoes;
                                                    }else{
                                                        arrFuncoes = funcoes.filter(function(element) {
                                                            return element !== item.value
                                                        })
                                                    }
                                                    for(var i = 0; i < funcoesOptions.length; i++){
                                                        if(funcoesOptions[i].id == item.id){
                                                            funcoesOptions[i].checado = e.target.checked;
                                                        }
                                                    }
                                                    
                                                    console.log(funcoes);
                                                    this.setState({ funcoesOptions: funcoesOptions, funcoes: arrFuncoes });
                                                }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Empresas</h3>
                            </div>
                            <div className="panelBody" style={{ marginTop: -16 }}>
                                {empresasOptions.map((item, index) => {
                                    var checked = false;
                                    if(empresasOptions[index].checado){
                                        checked = true;
                                    }
                                    return (
                                        <div className="empresaInput">
                                            <Input type="checkbox" className="checkBoxtable" label={item.empresa} defaultChecked={checked}
                                                rel={item.id} onChange={e => { 
                                                    for(var i = 0; i < empresasOptions.length; i++){
                                                        if(empresasOptions[i].id == item.id){
                                                            empresasOptions[i].checado = e.target.checked;
                                                        }
                                                    }
                                                    this.setState({ empresasOptions: empresasOptions });
                                                }} />
                                            {empresasOptions[index].checado ? (<>
                                                <Input type="select" label="Habilita Aprovação?" optionsData={aprovacaoOptions} divStyle={{ maxWidth: 200, marginTop: 10 }} style={{ marginRight: 0 }}
                                                    onChange={e => {
                                                    for(var i = 0; i < empresasOptions.length; i++){
                                                        if(empresasOptions[i].id == item.id){
                                                            empresasOptions[i].habilita_aprovacao =e.target.value
                                                        }
                                                    }
                                                    this.setState({ empresasOptions: empresasOptions });
                                                }} value={item.habilita_aprovacao} />
                                            </>) : null}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/usuario-empresa" className="btn">{'<'} Voltar</Link>
                            <button className="btn btnCadastrar" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}