import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Paginacao from '../../Components/Paginacao';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

export default class Company extends Component {

    constructor(props) {
        super(props);

        this.acaoEditar = this.acaoEditar.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            table: {
                campos: [
                    { titulo: 'Rating', dado: 'rating' },
                    { titulo: 'Taxa', dado: 'taxa' },
                    { titulo: 'Status', dado: 'tag' },
                    { titulo: 'Ações', dado: 'acoes' },
                ],
                dados: []
            },
            regrasDefault: [],
            exibeDados: false,
            mostraVazio: false,
            acoes: [],
            regra: ''
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        var acoes = [];

        for (var i = 0; i < userData.menus.length; i++) {
            const item = userData.menus[i];
            if (item.bmnu_url === url) {
                for (var x = 0; x < item.acoes.length; x++) {
                    acoes.push(item.acoes[x].acmnu_id);
                }
            }
        }
        if (this.props.location.state) {
            this.setState({ acoes });
            this.carregaRegras(1, acoes);
        }
    }

    async carregaRegras(page, acao) {
        const { table, acoes } = this.state;
        const empresa = this.props.location.state;

        this.setState({ loading: true });

        var url, pagina, pagAtual, actions;
        const userData = JSON.parse(localStorage.getItem('userData'));
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;
        actions = acao ? acao : acoes; 

        if (pagAtual) {
            url = "empresaregra?emp_id=" + empresa.id + "&per_page=20&page=" + pagAtual;
        } else {
            url = "empresaregra?emp_id=" + empresa.id;
        }
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.ereg_id, rating: item.ereg_rating.toUpperCase(), taxa: item.ereg_taxa.toFixed(2),
                        tag: item.ereg_ativo === 1 ? 'Ativo' : 'Inativo', acoes: 'default'
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, regrasDefault: result.data });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        const empresa = this.props.location.state;
        this.setState({ path: '/empresas/regras/nova', propsToPath: { regra: id , empresa: empresa.id }, redirect: true });
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/empresas/regras?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/empresas/regras', redirect: true });
        }
        this.carregaEmpresas(page);
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, regrasDefault, exibeDados, mostraVazio, acoes } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Regras de empréstimo</h1>
                                <p className="descricao">Abaixo a listagem de todas as regras de empréstimo cadastradas para a empresa</p>
                            </div>
                                <button className="btn" onClick={() => this.setState({ path: '/empresas/regras/nova', propsToPath:{regra: '', empresa: this.props.location.state.id }, redirect: true })}>Nova Regra</button>
                        </div>
                        <div className="detalhes">

                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhuma regra de empréstimo encontrada!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <p>Total de registros: {regrasDefault.total}</p>
                                    <Paginacao dados={regrasDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}