import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import { cpfMask, dataBrMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';
import Paginacao from '../../Components/Paginacao';
import { connect } from "react-redux";

import { addConciliacao, removeConciliacao, retrieveAllConciliacao, deleteAllConciliacaos} from "../../Actions/Conciliation";

class VertConciliation extends Component {

    constructor() {
        super();

        this.acaoSelecionavel = this.acaoSelecionavel.bind(this);
        this.handlePage = this.handlePage.bind(this);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            btnAutorizar: false,
            table: {
                campos: [
                    { titulo: 'CCB', dado: 'ccb' },
                    { titulo: 'Funcionario', dado: 'funcionario' },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Valor', dado: 'valor' },
                    { titulo: 'Dt. Solicitação', dado: 'data' }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            vertsDefault: {},
            totalSelecionado: 0,
            data_inicio: '',
            data_fim: '',
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
    }

    async carregaVerts(resetState, page) {
        const { table, data_inicio, data_fim } = this.state;

        this.setState({ loading: true });
        if(resetState){
            await this.props.deleteAllConciliacaos()
        }

        var url, pagina, pagAtual, actions;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "propostastatus?per_page=20&page=" + pagAtual;
        } else {
            url = "propostastatus?per_page=20";
        }

        url += data_inicio ? "&data_inicio="+Funcoes.data2Americano(data_inicio, '/') : "";
        url += data_fim ? "&data_fim="+Funcoes.data2Americano(data_fim, '/') : "";

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.stat_id, ccb: item.proposta.ppst_numeroccb, funcionario: item.proposta.funcionario.func_nome, empresa: item.proposta.funcionario.empresa.emp_nomefantasia, valor: 'R$ '+ Funcoes.float2Preco(item.proposta.ppst_valorsolicitado), data: Funcoes.data2Brazilian(item.proposta.ppst_dthrsolicitacao.split(" ")[0]), valSelect: item.proposta.ppst_valorsolicitado,
                        vertsDefault: result.data
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, leadsDefault: result.data, loading: false, vertsDefault: result.data });
                this.reloadCheckbox();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/conciliacao-vert?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/conciliacao-vert', redirect: true });
        }
        this.carregaVerts(false,page);
    }

    async autorizarVert() {
        this.setState({ loading: true });
        const  {conciliacoes}  = this.props;

        var verts = [];
        //checkboxs = document.querySelectorAll('input[type="checkbox"]:checked');
        for (let i = 0; i < conciliacoes.length; i++) {
            //if (checkboxs[i].getAttribute('rel'))
            verts.push(conciliacoes[i].id);
        }
    
        if(verts.length < 1){
            this.setState({ loading: false, alertText: 'Selecione ao menos uma proposta para continuar!', path: null, alert: true });
        }else{
            verts = verts.join();
            await ApiService.postGeral('propostastatus', {ppst_id: verts})
                .then(result => {
                    this.setState({ loading: false, alertType: 1, alertText: 'Propostas autorizadas com sucesso!', path: null, alert: true });
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") {
                        this.setState({ path: null });
                    }

                    if (valida.status === "badRequest") {
                        this.setState({ path: null });
                    }
                    this.setState({ loading: false, alertText: valida.response, alert: true });
                })
        }
    }

    async acaoSelecionavel(e, header) {
        const { totalSelecionado, table } = this.state;
        const checkboxs = document.querySelectorAll('input[type="checkbox"]');
        if (header === 1) {
            this.setState({ loading: true });
            for (var i = 1; i < checkboxs.length; i++) {
                let checked = table.dados.find(x => x.id === parseInt(checkboxs[i].attributes.rel.value));
                let total = parseFloat(checkboxs[i].value);
                if(checkboxs[i].checked)
                {
                    await this.props.addConciliacao(checked)
                }
                else{
                    await this.props.removeConciliacao(checked) 
                }   
                this.setState({ totalSelecionado: checkboxs[i].checked ? total + totalSelecionado : totalSelecionado - total });
            }
        } else {
            let checked = table.dados.find(x => x.id === parseInt(e.target.attributes.rel.value));
            this.setState({ loading: true });
            if(e.target.checked)
            {
                await this.props.addConciliacao(checked)
            }
            else{
                await this.props.removeConciliacao(checked) 
            }   
        }       
        const checkStatus = document.querySelectorAll('input[type="checkbox"]:checked');
        
      
        console.log(checkStatus)
        const  {conciliacoes}  = this.props;
        if(conciliacoes.length > 0 )
            this.setState({btnAutorizar: true});
        if(conciliacoes.length === 0 )
            this.setState({btnAutorizar: false});

        let total = 0;
        for (let i = 0; i < conciliacoes.length; i++) {
            if(conciliacoes.length === 0)
                break;
            let preco =  conciliacoes[i].valor.split(" ")[1]
            let valor = Funcoes.preco2float(preco);
            if(isNaN(valor)) continue;
            total += valor
        }
       

        this.setState({ totalSelecionado: total});
        this.setState({ loading: false });
        if(checkStatus.length > 0 && !checkStatus[0].hasAttribute('rel') ){
            checkStatus[0].checked = false
        }
        if(checkStatus.length === 21)
        {
            checkStatus[0].checked = true
        }
    }
    async reloadCheckbox(){
        const  {conciliacoes}  = this.props;
        var totalCheckbox = document.querySelectorAll('input[type="checkbox"]');
        let result = '';
        if(conciliacoes == undefined)
        {
            return null;
        }
        for (var i = 1; i < totalCheckbox.length; i++) {
            
            result = conciliacoes.find(obj => {
                return obj.id === parseInt(totalCheckbox[i].attributes.rel.value)
            });  
            if (totalCheckbox[i].checked == false) {
                if(result != undefined){
                    totalCheckbox[i].checked = true;
                }
            }
            if(totalCheckbox[i].checked){
                if(result == undefined){
                    totalCheckbox[i].checked = false;
                }
            }
        }  
        var checkStatus = document.querySelectorAll('input[type="checkbox"]:checked');

        if(conciliacoes.length > 0 )
            this.setState({btnAutorizar: true});
        if(conciliacoes.length === 0 )
            this.setState({btnAutorizar: false});
        
        let total = 0;
        for (let i = 0; i < conciliacoes.length; i++) {
            if(conciliacoes.length === 0)
                break;
            let preco =  conciliacoes[i].valor.split(" ")[1]
            let valor = Funcoes.preco2float(preco);
            if(isNaN(valor)) continue;
            total += valor
        }
        console.log(conciliacoes)
        this.setState({ totalSelecionado: total});
        if(totalCheckbox.length > 0){
            totalCheckbox[0].checked = false;

        }
    }
     
    updateValues(attr, val, type) {
        this.setState({ [attr]: dataBrMask(val) })
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, alertType, table, vertsDefault, exibeDados, mostraVazio, totalSelecionado, data_inicio, data_fim, btnAutorizar } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={18} />

                <div className="body">
                    <Header />

                    <div className="colaboradores mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Cessão diária</h1>
                                <p className="descricao">Abaixo a listagem das propostas para conciliação vert</p>
                            </div>
                            {btnAutorizar ? (<>
                                <button className="btn btnAzul" onClick={() => this.autorizarVert()}>Autorizar VERT</button>
                            </>) : null}
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row" >
                                    <Input type="tel" isFilterGroups={true} label="Dt. Início" value={data_inicio}
                                        onChange={e => this.updateValues('data_inicio', e.target.value)} />
                                    <Input type="tel" isFilterGroups={true} label="Dt. Fim"  value={data_fim}
                                        onChange={e => this.updateValues('data_fim', e.target.value)} />
                                    <button className="btn filtrar" onClick={() => data_inicio || data_fim ? this.carregaVerts(true) :
                                        this.setState({ alertText: 'Informe uma data para continuar!', path: null, alert: true })}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        selecionavel={true}
                                        acaoSelecionavel={this.acaoSelecionavel} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhuma proposta encontrada!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <p>Total selecionado: <span>R$ {Funcoes.float2Preco(totalSelecionado)}</span></p>
                                    <Paginacao dados={vertsDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        conciliacoes: state.Conciliation
    };
}

export default connect(mapStateToProps,{ addConciliacao ,removeConciliacao, retrieveAllConciliacao, deleteAllConciliacaos })(VertConciliation);