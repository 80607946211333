import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { cpfMask, onlyNumbersMask, celularMask, dataBrMask, cepMask, moneyMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';

import { Link } from 'react-router-dom';

export default class NewTradeRepresentative extends Component {

    constructor() {
        super();
        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoExcluir = this.acaoExcluir.bind(this);

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            statusRepresentanteOptions: [{value: '', text: 'Selecione'}],
            statusRepresentanteEmpresaOptions: [{value: '', text: 'Selecione'}],
            gruposEconomicosOptions: [{ value: '', text: 'Selecione' }],
            empresasOptions: [{ value: '', text: 'Selecione' }],
            aprovacaoOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Sim' },
                { value: 0, text: 'Não' }
            ],
            sexoOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Feminino' },
                { value: 0, text: 'Masculino' }
            ],
            nome: '',
            email: '',
            cpf: '',
            dtnascimento: '',
            endereco: {
                endereco: '',
                numero: '',
                bairro: '',
                complemento: '',
                cidade: '',
                cidId: '',
                cep: '',
                estado: ''
            },
            telefone: '',
            celular: '',
            sexo: '',
            dtadmissao: '',
            tableEmpresas: {
                campos: [
                    { titulo: 'Empresa',dado: 'empresa' },
                    { titulo: 'Data inicial', dado: 'dtInicial' },
                    { titulo: 'Prazo(meses)', dado: 'prazo' },
                    { titulo: 'Comissao(%)', dado: 'comissao' },
                    { titulo: 'Status', tdStyle: {width: 80},dado: 'status',  tdTagStyle: true },
                    { titulo: 'Ações', dado: 'acoes' },
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            status: '',
            empresasVinculadas: [],
            dataInicio: '',
            prazoComissao: 0,
            taxaComissao: 0,
            idEmpresaEditavel: undefined,
            editaTaxaComissao: undefined,
            editaPrazoComissao: undefined,
            editaDataInicio: undefined,
            editaStatusContrato: undefined,
            editaNomeEmpresa: undefined,
            grupoEconomico: '',
            empresaId: '',
            empresa: '',
            statusContrato: '',
            modalEditarVinculoEmpresa: false,
        };
    }

    componentDidMount() {
        this.carregaRepresentanteComercialStatus();
        if (this.props.location.state){
            this.pegaUsuarioEditavel()
        }else{
            this.carregaEmpresas();
            this.carregaGrupos();
        }
        this.carregaRepresentanteComercialEmpresaStatus();
    }

    async carregaEmpresas() {
        let url = 'empresa?per_page=-1';
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions: empresasOptions, loading: false});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaGrupos() {
        this.setState({ loading: true });
        await ApiService.getGeral('grupoeconomico?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var gruposEconomicosOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    gruposEconomicosOptions[i + 1] = { value: item.gpec_id, text: item.gpec_nome };
                }
                this.setState({ gruposEconomicosOptions})
            })
            .catch(err => {
                this.setState({ gruposEconomicosOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaEmpresasPorGrupo(grupo){
        this.setState({ loading: true});
        if(grupo) this.setState({ grupoEconomico: grupo });
        let url = 'empresa?per_page=-1';
        if(grupo) url+= "&gpec_id=" + grupo;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions: empresasOptions, loading: false});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }


    async recarregaEmpresaSelect(grupo){
        this.setState({ grupoEconomico: grupo});
        this.carregaEmpresasPorGrupo(grupo);
    }
    
    async carregaRepresentanteComercialStatus(){
        this.setState({ loading: true });
        await ApiService.getGeral('representante_comercial_status')
            .then(result => {
                const res = result.data;
                let statusRepresentanteOptions = [{ value: '', text: 'Selecione' }];
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    statusRepresentanteOptions[i + 1] = { value: item.reps_id, text: item.reps_nome };
                }
                this.setState({ statusRepresentanteOptions})
            })
            .catch(err => {
                this.setState({ statusRepresentanteOptions: [{ value: '', text: 'Nenhum status encontrado!' }] });
            })
    }

    async carregaRepresentanteComercialEmpresaStatus(){
        this.setState({ loading: true });
        await ApiService.getGeral('representante_comercial_empresa_status')
            .then(result => {
                const res = result.data;
                let statusRepresentanteEmpresaOptions = [{ value: '', text: 'Selecione' }];
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    statusRepresentanteEmpresaOptions[i + 1] = { value: item.repcemps_id, text: item.repcemps_nome };
                }
                this.setState({ statusRepresentanteEmpresaOptions})
            })
            .catch(err => {
                this.setState({ statusRepresentanteEmpresaOptions: [{ value: '', text: 'Nenhum status encontrado!' }] });
            })
    }

    async pegaUsuarioEditavel() {
        const { usuario } = this.props.location.state;
        const {tableEmpresas} = this.state;

        this.setState({ loading: true });

        await ApiService.getGeral('representante/' + usuario.id)
            .then(result => {
                const res = result.data.data;
                let empresas = [];
                res.empresas.forEach(empresa => {
                    console.log(empresa)
                    let status = empresa.repcemps_id;
                    let contratoRepresentante = {
                        id: Number(empresa.emp_id),
                        empresaId: empresa.emp_id,
                        empresa: empresa.emp_nomefantasia,
                        dtInicial: empresa.repcemp_dtinicio,
                        prazo: empresa.repcemp_prazo,
                        comissao: empresa.repcemp_comissao,
                        status: this.processaRepresentanteComercialEmpresaStatus(status.toString()),
                        statusId: status,
                        acoes: 'default|excluir'
                    }
                    empresas.push(contratoRepresentante);
                });
                
                this.setState({
                    id: res.repc_id, nome: res.repc_nome, email: res.repc_email, cpf: res.repc_cpf, dtnascimento: Funcoes.data2Brazilian(res.repc_dtnascimento),
                    endereco: { endereco: res.repc_endlogradouro, numero: res.repc_endnumero, complemento: res.repc_endcomplemento, 
                    bairro: res.repc_endbairro, cep: res.repc_endcep, cidade: res.cid_nome, cidId: res.cid_id, estado: res.uf_sigla },
                    telefone: res.repc_telefone, celular: res.repc_celular, sexo: res.repc_sexo, 
                    dtadmissao: Funcoes.data2Brazilian(res.repc_dtadmissao), status: res.reps_id
                });
                this.setState({ tableEmpresas: { campos: [...tableEmpresas.campos], dados: empresas, }, empresasVinculadas: empresas })

                this.carregaEmpresas();
            })
    }

    validaCampos() {
        const { nome, email, cpf, dtnascimento, telefone, celular, endereco, sexo, dtadmissao, status, 
             empresasVinculadas } = this.state;
        let form = [
            { campo: 'Nome', attr: 'nome', valor: nome },
            { campo: 'CPF', attr: 'cpf', valor: cpf, minLength: 14 },
            { campo: 'Dt. Nascimento', attr: 'dtnascimento', valor: dtnascimento },
            { campo: 'Dt. Admissão', attr: 'dtadmissao', valor: dtadmissao }
        ];

        const valida = validaForm(form);
        if (valida.valido && status) {
            var empresas = [];
            var total = 0;
            empresasVinculadas.forEach(element => {
                total += 1;
                if(element.dtInicial === ''){
                    this.setState({ alertText: '* A data de início não foi preenchida', alert: true });
                    return;
                }
                empresas.push({ 
                    emp_id: element.empresaId, 
                    repcemp_dtinicio: Funcoes.data2Americano(element.dtInicial, '/'),
                    repcemp_prazo: Number(element.prazo),
                    repcemp_comissao: Funcoes.preco2float(element.comissao),
                    repcemps_id: Number(element.statusId),
                });

            });

            if(total === 0){
                this.setState({ alertText: '* Você precisa cadastrar alguma empresa para o representante', alert: true });
                return;
            }

            this.setState({ loading: true });

            const formData = {
                repc_nome: nome,
                repc_email: email,
                repc_cpf: cpf,
                repc_dtnascimento: Funcoes.data2Americano(dtnascimento, '/'),
                repc_dtadmissao: Funcoes.data2Americano(dtadmissao, '/'),
                repc_endlogradouro: endereco.endereco,
                repc_endnumero: endereco.numero,
                repc_endbairro: endereco.bairro,
                repc_endcomplemento: endereco.complemento,
                repc_endcep: endereco.cep,
                cid_id: endereco.cidId,
                repc_telefone: telefone,
                repc_sexo: sexo,
                repc_celular: celular,
                reps_id: status,
                empresas: empresas
            }

            if (this.props.location.state) {
                this.editarConta(formData);
            } else {
                this.novaConta(formData);
            }
        }

        if (!status)
            this.setState({ alertText: '* O campo Status não foi preenchido', alert: true });

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaConta(form) {
        await ApiService.postGeral('representante', form)
            .then(res => {
                this.setState({ path: '/representantes', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async editarConta(form) {
        const { usuario } = this.props.location.state;
        await ApiService.putGeral('representante/' + usuario.id, form)
            .then(res => {
                this.pegaUsuarioEditavel();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async updateValues(attr, val, type) {
        if (type === "cpf") {
            this.setState({ [attr]: cpfMask(val) })
        } else if (type === "dataBr") {
            this.setState({ [attr]: dataBrMask(val) })
        } else if (type === "numbers") {
            this.setState({ [attr]: onlyNumbersMask(val) })
        } else if (type === "celular") {
            this.setState({ [attr]: celularMask(val) })
        } else {
            this.setState({ [attr]: val })
        }
    }

    async handleCep(e) {
        const { endereco } = this.state;
        const val = e.target.value;
        this.setState({ endereco: { ...endereco, cep: cepMask(val) } });
        if (val.length === 9) {
            this.setState({ loading: true });
            await ApiService.postGeral('consultacep', { cep: val })
                .then(result => {
                    const res = result.data.data;
                    document.getElementById('endNum').focus();
                    this.setState({
                        endereco: { cep: res.cep, endereco: res.logradouro, bairro: res.bairro, complemento: res.complemento, cidade: res.localidade, cidId: res.cidade, estado: res.uf },
                        loading: false
                    });
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ loading: false });
                })
        }
    }

    vinculaContrato(){
        const { empresaId, empresa, empresasVinculadas, taxaComissao, dataInicio, prazoComissao, tableEmpresas, statusContrato } = this.state;
        //pegar os dados dos inputs e colocar na grid o resultado
        let contratoRepresentante = {
            id: Number(empresaId),
            empresaId: empresaId,
            empresa: empresa,
            dtInicial: dataInicio,
            prazo: prazoComissao,
            comissao: taxaComissao,
            status: this.processaRepresentanteComercialEmpresaStatus(statusContrato),
            statusId: statusContrato,
            acoes: 'default|excluir'
        }
      
        let novasEmpresasVinculadas = empresasVinculadas;
        novasEmpresasVinculadas.push(contratoRepresentante);
        this.setState({ tableEmpresas: { campos: [...tableEmpresas.campos], dados: novasEmpresasVinculadas, }, empresasVinculadas: novasEmpresasVinculadas })
    }

    processaRepresentanteComercialEmpresaStatus(status){
        if(status === '1')
            return 'Vigente'
        else if(status === '2')
            return 'Cancelado error'
 
        return 'N/A inactive'
    }

    async validaVinculoEmpresa()
    {
        const { empresaId, empresasVinculadas, taxaComissao, dataInicio, prazoComissao, statusContrato } = this.state;
        //pegar os dados dos inputs e colocar na grid o resultado
    
        
        let form = [
            { campo: 'Empresa', attr: 'empresaId', valor: empresaId },
            { campo: 'Data Inicial', attr: 'dtInicial', valor: dataInicio, minLength: 10 },
            { campo: 'Prazo', attr: 'prazo', valor: prazoComissao },
            { campo: 'Comissão', attr: 'comissao', valor: taxaComissao },
            { campo: 'Status', attr: 'status', valor: statusContrato }
        ];

        const valida = validaForm(form);
        let empresaJaCadastrada = empresasVinculadas.some(obj => obj.id === Number(empresaId));
        
       
        if(valida.valido && empresaJaCadastrada){
            this.setState({ alertText: "* A empresa que está tentando vincular já está cadastrada", alert: true });

        }
        else if(valida.valido){
            this.vinculaContrato()
        }
        else{
            let mensagem = "";
            for (let item of valida.campos) {
                if (!item.valido) {
                    mensagem += item.mensagem + "\n"
                }
            }           
            this.setState({ alertText: '* ' + mensagem, alert: true });
        }

    }

    acaoEditar(id){
        const {empresasVinculadas } = this.state;
        
        let elementoEditavel;
        empresasVinculadas.forEach(element => {
            if(element.id === id){
                elementoEditavel = element
                return;
            }
        });
        this.setState({modalEditarVinculoEmpresa: true, editaDataInicio: elementoEditavel.dtInicial, editaPrazoComissao: elementoEditavel.prazo,
            editaStatusContrato: elementoEditavel.statusId, editaTaxaComissao: elementoEditavel.comissao, idEmpresaEditavel: id, editaNomeEmpresa: elementoEditavel.empresa
        });    
    }

    confirmarEdicaoDeVinculoDeRepresentante(){
        const {empresasVinculadas, idEmpresaEditavel, editaDataInicio, editaPrazoComissao, editaStatusContrato, 
            editaTaxaComissao, tableEmpresas} = this.state;
        empresasVinculadas.forEach(element => {
            if(element.id === idEmpresaEditavel){
                element.dtInicial = editaDataInicio
                element.prazo = editaPrazoComissao
                element.status = this.processaRepresentanteComercialEmpresaStatus(editaStatusContrato)
                element.statusId = editaStatusContrato
                element.comissao = editaTaxaComissao
                return;
            }
        });
        //buscar elemento em empresas vinculadas e alterar os dados e salvar estado
        this.setState({ tableEmpresas: { campos: [...tableEmpresas.campos], dados: empresasVinculadas}, empresasVinculadas: empresasVinculadas, modalEditarVinculoEmpresa: false})
    }

    async acaoExcluir(id) {
        const {empresasVinculadas, tableEmpresas } = this.state;
        let empresas = empresasVinculadas;

        let novasEmpresasVinculadas = [];
        empresas.forEach(element => {
            if(element.id !== id){
                novasEmpresasVinculadas.push(element)
            }
        });
        this.setState({ tableEmpresas: { campos: [...tableEmpresas.campos], dados: novasEmpresasVinculadas}, empresasVinculadas: novasEmpresasVinculadas})
    }

    buscaEmpresaEmSelect(idEmpresa){
        const { empresasOptions} = this.state;
        let nomeEmpresa;
        empresasOptions.forEach(element => {
            if(element.value === Number(idEmpresa)){
                nomeEmpresa = element.text;
                return;
            }
        });
        return nomeEmpresa
    }

    render() {
        const { loading, redirect, path, alert, alertText, statusRepresentanteOptions , empresaId, grupoEconomico, gruposEconomicosOptions, 
            empresasOptions, sexoOptions,tableEmpresas, mostraVazio, nome, email, cpf, dtnascimento, endereco, telefone, celular, 
            sexo, dtadmissao, status, dataInicio, prazoComissao, statusRepresentanteEmpresaOptions,statusContrato,editaTaxaComissao,
            editaPrazoComissao, editaDataInicio,editaStatusContrato, taxaComissao, modalEditarVinculoEmpresa, editaNomeEmpresa } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={13} />

                <Modal show={modalEditarVinculoEmpresa}>
                    <div className="modal-tipobaixa">
                        <p className="warning">{editaNomeEmpresa}</p>
                        <Input label="Data de início" type="tel" placeholder="dd/mm/aaaa" value={editaDataInicio} className="custom" maxLength="10"
                                        onChange={e => this.setState({ editaDataInicio: dataBrMask(e.target.value) })} />
                        <Input type="number" label="Prazo (Em meses)" placeholder="0" value={editaPrazoComissao} className="custom" 
                            onChange={e => this.setState({ editaPrazoComissao: e.target.value })} /> 
                        <Input type="tel" label="Comissão (Em %)" className="txJuros" value={editaTaxaComissao} 
                            onChange={e => this.setState({ editaTaxaComissao: moneyMask(e.target.value, false) })} placeholder="0,00"/>
                        <Input type="select" label="Status" optionsData={statusRepresentanteEmpresaOptions}
                            onChange={e => this.updateValues('editaStatusContrato', e.target.value, 'editaStatusContrato')} value={editaStatusContrato} />
                        
                        <div className="cancelamentoAcao">
                            <button 
                                className="btn"
                                style={ { backgroundColor: '#EA5353', color: '#FFF' }}
                                 
                                onClick={() => this.confirmarEdicaoDeVinculoDeRepresentante()}>Confirmar</button>
                            <button className="btn btnAzul" onClick={() => this.setState({ modalEditarVinculoEmpresa: false })}>Cancelar</button>
                        </div>
                    </div>
                </Modal>

                <div className="body">
                    <Header />

                    <div className="representante-novo mgDefault">
                        <h1>{this.props.location.state ? 'Editar' : 'Novo'} Representante</h1>
                        <p className="descricao">Informe abaixo os dados do representante comercial.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Básicos</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Nome" value={nome} onChange={e => this.updateValues("nome", e.target.value, "nome")} />
                                    <Input type="text" label="E-mail" value={email} onChange={e => this.updateValues('email', e.target.value)} />
                                    <Input type="tel" label="CPF" value={cpf} onChange={e => this.updateValues('cpf', e.target.value, 'cpf')}
                                        maxLength="14" />
                                    <Input type="select" label="Sexo" optionsData={sexoOptions} divStyle={{ maxWidth: 120 }} style={{ marginRight: 0 }}
                                        onChange={e => this.updateValues('sexo', e.target.value, 'sexo')} value={sexo} />
                                </div>
                                <div className="multInput" style={{ marginTop: 24 }}>
                                    <Input type="tel" label="Data de Nascimento" maxLength="10" value={dtnascimento} onChange={e => this.updateValues('dtnascimento', e.target.value, 'dataBr')} divStyle={{ maxWidth: 180 }} />
                                    <Input type="tel" label="Telefone" maxLength="14" placeholder="(00) 00000-0000" value={telefone} onChange={e => this.updateValues('telefone', e.target.value, 'celular')} />
                                    <Input type="tel" label="Celular" maxLength="14" placeholder="(00) 00000-0000" value={celular} onChange={e => this.updateValues('celular', e.target.value, 'celular')} />
                                    <Input type="select" label="Status" optionsData={statusRepresentanteOptions}
                                        onChange={e => this.updateValues('status', e.target.value, 'status')} value={status} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Endereço</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="text" label="CEP" value={endereco.cep} onChange={e => this.handleCep(e)} />
                                    <Input type="text" label="Endereço" divStyle={{ flexGrow: 10 }} value={endereco.endereco}
                                        onChange={e => this.setState({ endereco: { ...endereco, endereco: e.target.value } })} />
                                    <Input type="tel" label="Número" value={endereco.numero} id="endNum"
                                        onChange={e => this.setState({ endereco: { ...endereco, numero: e.target.value } })} />
                                    
                                </div>
                                <div className="multInput">
                        
                                    <Input type="text" label="Cidade" value={endereco.cidade} disabled className="disabled" />
                                    <Input type="text" label="Estado" value={endereco.estado} disabled className="disabled" />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Profissional</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="tel" label="Data de Admissão" value={dtadmissao} onChange={e => this.updateValues('dtadmissao', e.target.value, 'dataBr')} divStyle={{ maxWidth: 180 }} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Empresas</h3>
                            </div>
                            <div className="panelBody" style={{ marginTop: -16 }}>
                                <div className="multInput">
                                    <Input label="Data de início" type="tel" placeholder="dd/mm/aaaa" value={dataInicio} className="custom" maxLength="10"
                                        onChange={e => this.setState({ dataInicio: dataBrMask(e.target.value) })} />
                                    <Input type="number" label="Prazo (Em meses)" placeholder="0" value={prazoComissao} className="custom" 
                                        onChange={e => this.setState({ prazoComissao: e.target.value })} /> 
                                    <Input type="tel" label="Comissão (Em %)" className="txJuros" value={taxaComissao} 
                                        onChange={e => this.setState({ taxaComissao: moneyMask(e.target.value, false) })} placeholder="0,00"/>
                                    <Input type="select" label="Status" optionsData={statusRepresentanteEmpresaOptions}
                                        onChange={e => this.updateValues('statusContrato', e.target.value, 'statusContrato')} value={statusContrato} />
                                    
                                </div>
                                <div className="row">
                                    <Input type="select" isFilterGroups={true} optionsData={gruposEconomicosOptions} label="Grupo Econômico" value={grupoEconomico} 
                                        onChange={e => this.recarregaEmpresaSelect(e.target.value)} />      
                                    <Input type="select" isFilterGroups={true}   label="Empresa" optionsData={empresasOptions} value={empresaId}
                                        onChange={e => this.setState({ empresa: this.buscaEmpresaEmSelect(e.target.value),empresaId: e.target.value })} />
                                </div>

                                <button className="btn filtrar" onClick={() => this.validaVinculoEmpresa()}>Vincular Contrato</button>

                                <Table campos={tableEmpresas.campos}
                                    dados={tableEmpresas.dados}
                                    acaoEditar={this.acaoEditar}
                                    acaoExcluir={this.acaoExcluir}
                                    />

                                {   
                                    mostraVazio ? (
                                        <p className="textEmpty">Nenhuma comissão associada!</p>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/representantes" className="btn">{'<'} Voltar</Link>
                            <button className="btn btnCadastrar" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}