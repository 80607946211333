import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { cpfMask, onlyNumbersMask } from '../../Utils/mask';
import Paginacao from '../../Components/Paginacao';

export default class PersonalLoan extends Component {

    constructor() {
        super();

        this.acaoVisualizar = this.acaoVisualizar.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '/',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            dtPagto: '',
            retornoBoletoGerado: {},
            dataFiltroInicio: '',
            dataFiltroFim: '',
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 0, text: 'Inadimplente' }, //emprestimo com saldo final 
                { value: 1, text: 'Quitado' },  //status 3, emprestimo quitado
            ],
            colaborador: '',
            ccb: '',
            status: '',
            empresa: '',
            empresasOptions: [{ value: '', text: 'Selecione' }],
            table: {
                campos: [
                    { titulo: 'Cliente', dado: 'cliente', thStyle: { width: 120 } },
                    { titulo: 'CPF', dado: 'cpf', thStyle: { width: 145 } },
                    { titulo: 'CCB', dado: 'ccb', thStyle: { width: 100 } },
                    { titulo: 'Dt. empréstimo', dado: 'emppes_data', thStyle: { width: 100 } },
                    { titulo: 'Valor Emprestado', dado: 'valEmprestado', thStyle: { textAlign: 'right', width: 140 }, tdStyle: { textAlign: 'right' } },
                    { titulo: 'Dt. Recisão', dado: 'dtRecisao', thStyle: { width: 100 } },
                    { titulo: 'Saldo Devedor', dado: 'saldoDevedor', thStyle: { width: 100 } },
                    { titulo: 'Valor Averbação', dado: 'valAverbacao', thStyle: { textAlign: 'right', width: 140 }, tdStyle: { textAlign: 'right' } },
                    { titulo: 'Saldo Final', dado: 'saldoFinal', thStyle: { width: 100 } },
                    { titulo: 'Status', dado: 'statusEmprestimo', tdTagStyle: true, thStyle: { width: 30, textAlign: 'center' } },
                    { titulo: 'Ações', dado: 'acoes', thStyle: { textAlign: 'center', width: 112 }, tdStyle: { textAlign: 'center' } }
                ],
                dados: []
            },
            dadosDefault: [],
            emprestimosDefault: {},
            exibeDados: false,
            mostraVazio: false,
            btnGerar: false
        };
    }

    componentDidMount() {
        this.carregaEmpresas();
        this.carregaEmprestimos(1);
    }

    async carregaEmpresas() {
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions, loading: false });
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaEmprestimos(page) {
        const { ccb, colaborador, empresa, table, dataFiltroInicio, dataFiltroFim, status } = this.state;
        this.setState({ loading: true });

        var url, pagina, pagAtual;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "inadimplentes?per_page=20&page=" + pagAtual ;
        } else {
            url = "inadimplentes?per_page=20";
        }

        if (colaborador) {
            if (colaborador.startsWith('0') || colaborador.startsWith('1') || colaborador.startsWith('2') || colaborador.startsWith('3') || colaborador.startsWith('4') ||
                colaborador.startsWith('5') || colaborador.startsWith('6') || colaborador.startsWith('7') || colaborador.startsWith('8') || colaborador.startsWith('9')) {
                url = url + "&func_cpf=" + colaborador;
            } else {
                url = url + "&func_nome=" + colaborador;
            }
        }

        url += dataFiltroInicio ? "&emppes_data_inicio=" + dataFiltroInicio : "";
        url += dataFiltroFim ? "&emppes_data_fim=" + dataFiltroFim : "";
        url += status ? "&ina_status="+ status : "";
        url += ccb ? "&ppst_numeroccb=" + ccb : ""; 
        
        if (empresa) url = url + "&emp_id=" + empresa;

        //url += "&emppes_pago=1";

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];

                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    var status;
                    if(item.ina_status === 0){
                        status = 'Inadimplente warning';
                   
                    }else if(item.ina_status === 1){
                        status = 'Quitado';
                    }
                    objects.push({
                        id: item.ina_id, 
                        cliente: item.func_nome.toUpperCase(), 
                        cpf: item.func_cpf,
                        ccb: item.ppst_numeroccb,
                        emppes_data: Funcoes.data2Brazilian(item.emppes_data),
                        valEmprestado: 'R$ ' + Funcoes.float2Preco(item.ppst_valorsolicitado),
                        dtRecisao: Funcoes.data2Brazilian(item.emppes_datapagtorescisao),
                        valAverbacao: 'R$ ' + Funcoes.float2Preco(item.emppes_valorpagtorescisao),
                        saldoDevedor: 'R$ ' + Funcoes.float2Preco(item.emppes_saldodevedor),
                        saldoFinal: 'R$ ' + Funcoes.float2Preco(item.emppes_saldofinal),
                        statusEmprestimo: status ? status : "Sem EmpPessoal error", 
                        acoes: 'visualizar' 
                    })
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, mostraVazio: false });
                } else {
                    this.setState({ exibeDados: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, emprestimosDefault: result.data , dadosDefault: objects, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: '' });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: '' });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/emprestimo-pessoal?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/emprestimo-pessoal', redirect: true });
        }
        this.carregaEmprestimos(page);
    }
    
    handleFiltro(e) {
        const val = e.target.value;
        if (val.startsWith(0) || val.startsWith(1) || val.startsWith(2) || val.startsWith(3) || val.startsWith(4) || val.startsWith(5) || val.startsWith(6) || val.startsWith(7) ||
            val.startsWith(8) || val.startsWith(9)) {
            document.getElementById('filtro').setAttribute('maxlength', 14);
            this.setState({ colaborador: cpfMask(val) });
        } else {
            document.getElementById('filtro').removeAttribute('maxLength');
            this.setState({ colaborador: val });
        }
    }

    async acaoVisualizar(id) {
        this.setState({ loading: true });
        await ApiService.getGeral('inadimplentes/' + id)
            .then(res => {
                this.setState({ loading: false, propsToPath: { emprestimo: res.data.data }, path: '/emprestimo-pessoal/detalhes', redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: '' });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: '' });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, exibeDados, mostraVazio, statusOptions, empresa,
             empresasOptions, ccb, colaborador, status, emprestimosDefault, dataFiltroInicio, dataFiltroFim, } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={9} />

                <div className="body">
                    <Header />

                    <div className="emprestimo-pessoal mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Empréstimo Pessoal</h1>
                                <p className="descricao">Consulte abaixo todos os contratos de empréstimo pessoal.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="select" isFilterGroups={true} label="Empresa" optionsData={empresasOptions} value={empresa} 
                                        onChange={e => this.setState({ empresa: e.target.value })} />
                                    <Input type="text" isFilterGroups={true} label="Colaborador ou CPF" value={colaborador} id="filtro"
                                        onChange={e => this.handleFiltro(e)} /> 

                                    <Input type="text" isFilterGroups={true} label="CCB" value={ccb} 
                                        onChange={e => this.setState({ccb: onlyNumbersMask(e.target.value)})} />

                                    <Input type = "date" isFilterGroups={true} label = "Periodo de:" value={dataFiltroInicio} onChange={e => this.setState({ dataFiltroInicio: e.target.value })} />
                                    <Input type = "date" isFilterGroups={true} label = "Periodo até:" value={dataFiltroFim} onChange={e => this.setState({ dataFiltroFim: e.target.value })}  />    
                                    <Input type="select" isFilterGroups={true} optionsData={statusOptions} label="Status" 
                                        value={status} onChange={e => this.setState({ status: e.target.value })} />
                                    <button className="btn filtrar" onClick={() => this.carregaEmprestimos(1)}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoVisualizar={this.acaoVisualizar} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhum empréstimo pessoal encontrado!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (
                                <div className="info">
                                    <p>Total de registros: {emprestimosDefault.total}</p>
                                    <Paginacao dados={emprestimosDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}