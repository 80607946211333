export const rgMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2')
}

export const cpfMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2')
}

export const cnpjMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
}

export const cepMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
}

export const onlyTextMask = value => {
    return value
    .replace(/[0-9!@#$%^&*()_+=[\]{};':"\\|,.<>?]/g, '')
}

export const onlyNumbersMask = value => {
    return value
        .replace(/\D/g, '')
}

export const dataBrMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
}

export const periodoMask = value => {
  
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
}

export const dataEnMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
}

export const celularMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
}

export const telefoneMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
}

export const moneyMask = (value: string, simbol = true) => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
  
    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    )  
    if(result === 'NaN'){
        return "R$ 0,00"
    }
    if(simbol){
        return 'R$ ' + result

    }
    
    return result;
    
  }