import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import PageBottomActions from '../../Components/PageBottomActions';
import Summary from '../../Components/Summary/Summary';
import Panel from '../../Components/Panel';
import Row from '../../Components/Row';

import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';

export default class NewPenalty extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            nomePenalidade: '',
            intervalos: [

            ],
            politicaId: undefined,
            idPenalidade: undefined
        };
    }

    componentDidMount() 
    {        
        this.setState({politicaId: this.props.location.state.politica?.id})
        if (this.props.location.state.penalidade?.id) {
            let idPenalidade = this.props.location.state.penalidade.id;
            this.setState({idPenalidade: idPenalidade, loading: true})
            this.carregaPenalidade(idPenalidade);
        }


    }
    
    async carregaPenalidade(id)
    {
        let url = 'penalidade/' + id;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                let intervalos = res.intervalos.map( (item) => {
                    return {
                        ...item
                    };
                })
                this.setState({ 
                    nomePenalidade: res.pen_descricao,
                    intervalos: intervalos,
                    loading: false});
            })
            .catch(err => {
               
            })
    }

    async atualizaPenalidade(form){
        const { idPenalidade } = this.state;
        await ApiService.putGeral('penalidade/' + idPenalidade, form)
        .then(res => {
            this.setState({ loading: false,  alertText: 'Intervalos de penalidade alterada com sucesso!', alert: true , 
            alertType: 1});
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }


    validaCampos() {
        const { idPenalidade, nomePenalidade, intervalos } = this.state;
        let form = [
            { campo: 'Nome', attr: 'nomePenalidade', valor: nomePenalidade },
            { campo: 'Rating', attr: 'intervalos', valor: intervalos, minLength: 1 }

        ];

        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            const formData = {
                regua: {
                    pen_descricao: nomePenalidade,
                },
                intervalos: intervalos
            }
            if(idPenalidade){
                this.atualizaPenalidade(formData);
            }else{
                this.novaPenalidade(formData);
            }
            
        }

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async removerIntervalo(index){
        const { intervalos } = this.state;
        intervalos.splice(index, 1);
        this.setState({intervalos: intervalos});
    }

    async novoIntervalo(){
        const { idPenalidade, intervalos } = this.state;
        if(idPenalidade){
            intervalos.push({ pen_id: idPenalidade, peni_intervalode: 0, peni_intervaloate: 0, peni_valor: 0 });
        }else{
            intervalos.push({ peni_intervalode: 0, peni_intervaloate: 0, peni_valor: 0 });

        }
        this.setState({intervalos: intervalos});
     
    }

    async novaPenalidade(form) {
        await ApiService.postGeral('penalidade', form)
            .then(res => {
                this.setState({ idPenalidade: res.data.data.pen_id,loading: false,  alertText: 'Régua de penalidade cadastrada com sucesso!', alert: true , 
                alertType: 1});
                this.carregaPenalidade(res.data.data.pen_id);
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleValor(e, index, campo){
        const { intervalos } = this.state;
        var newValores = [];
        for(var i = 0; i < intervalos.length; i++){
            newValores[i] = intervalos[i];
            if(i === index){
                if(campo === "peni_intervalode"){
                    newValores[i].peni_intervalode = e.target.value;
                }else if(campo === "peni_intervaloate"){
                    newValores[i].peni_intervaloate = e.target.value;
                }else if(campo === "peni_valor"){
                    newValores[i].peni_valor = e.target.value;
                }
            }
        }
        this.setState({ intervalos: newValores });
    }
    render() {
        const { loading, propsToPath, redirect, path, alert, alertText, alertType, nomePenalidade, intervalos, 
            idPenalidade } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert}  tipo={alertType}  texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-nova mgDefault">
                        <Summary title={idPenalidade ? 'Editar penalidade' : 'Nova penalidade' } description="Parametrize a penalidade." />
                       
                        <Panel header="Dados Cadastrais" >
                            <Input type="text" label="Nome de penalidade" value={nomePenalidade} divStyle={{ flexGrow: 10 }}
                                onChange={e => this.setState({nomePenalidade: e.target.value})} />
                        </Panel>
                        
                        <Panel header="Intervalos">
                          
                            {intervalos.map((campo,index) => {
                            
                                return (<>
                                    <Row>
                                        <Input isFilterGroups={true} type="number" label="De" className="tx" id={index+'-inicial'} value={campo.peni_intervalode} onChange={e => this.handleValor(e, index, 'peni_intervalode')} placeholder="0" />
                                        <Input isFilterGroups={true} type="number" label="Até" className="tx" id={index+'-final'}  value={campo.peni_intervaloate} onChange={e => this.handleValor(e, index, 'peni_intervaloate')}  placeholder="0" />
                                        <Input isFilterGroups={true} type="number" label="Valor de penalidade" className="tx" id={index+'-final'}  value={campo.peni_valor} onChange={e => this.handleValor(e, index, 'peni_valor')}  />
                                        <Button name="Excluir" isFilterGroups={true} action={() => this.removerIntervalo(index)} />
                                    </Row>
                                </>)
                            })}
                            <Button name="Novo Intervalo" action={() => this.novoIntervalo()} />
                        </Panel>
                        <PageBottomActions>
                            <Button type="back" name={'< Voltar'} action={() => this.setState({ path: '/politicas/novo', propsToPath: { politica: { id: this.state.politicaId } }, redirect: true })} />
                            <Button name={idPenalidade ? 'Alterar' : 'Cadastrar'} action={() => this.validaCampos()} />
                        </PageBottomActions>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
    
}