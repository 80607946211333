import React, { Component } from 'react';

import iconEditar from '../resources/img/icon-editar.svg';
import iconVisualizar from '../resources/img/icon-visualizar.svg';
import iconLixeira from '../resources/img/icon-lixeira.svg';
import iconAprovado from '../resources/img/icon-aprovado.svg';
import iconReprovado from '../resources/img/icon-reprovado.svg';
import iconLista from '../resources/img/icon-lista.svg';
import iconContratos from '../resources/img/icon-contratos-lista.svg';

export default class Table extends Component {

    constructor() {
        super();
        this.state = {
            checkboxGeral: false
        }
    }

    selecionaTodos() {
        const { checkboxGeral } = this.state;
        var checkboxes = document.querySelectorAll('input[type="checkbox"]');
        for (var i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = checkboxGeral ? false : true;
        }
        this.setState({ checkboxGeral: !checkboxGeral });
    }

    render() {
        const { checkboxGeral } = this.state;
        const { idTabela,campos, dados, selecionavel, validaParcelas, acaoEditar, acaoExtrato, acaoVisualizar, acaoAprovar, acaoReprovar, acaoExcluir, acaoSelecionavel, acaoRegras } = this.props;
        const keys = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        return (
            <table className="table" id={idTabela}>
                <thead>
                    <tr>
                        {selecionavel ? (
                            <th style={{ width: '5%' }}>
                                <input type="checkbox" className="checkBoxtable" checked={checkboxGeral} onChange={e => {
                                    if (acaoSelecionavel){
                                        this.selecionaTodos()
                                        acaoSelecionavel(e, 1, !checkboxGeral)
                                    }                              
                                }} />
                            </th>
                        ) : null}
                        {campos.map((campo, index) => {
                            return (
                                <th key={keys[index]} style={campo.thStyle}>{campo.titulo}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {dados.map((item, index) => {
                        return (
                            <tr key={index}>
                                {selecionavel ? (
                                    validaParcelas ? (
                                        item.tag !== "Pago" ? (
                                            <td><input type="checkbox" className="checkBoxtable" onChange={e => acaoSelecionavel(e)} rel={item['id']} /></td>
                                        ) : (<td></td>)
                                    ) : (<td><input type="checkbox" className="checkBoxtable" rel={item['id']} value={item['valSelect']}
                                        onChange={e => acaoSelecionavel(e, 0)} /></td>)
                                ) : null}
                                {campos.map((campo, index) => {
                                    if (campo.dado === "tag") {
                                        const val = item[campo.dado];
                                        const splitVal = val.split(' ');
                                        const selectTag = splitVal.length > 1 ? splitVal[1] : splitVal[0];
                                        const arrTags = {
                                            Ativo: { backgroundColor: '#27AE60', color: '#FFFFFF' },
                                            Inativo: { backgroundColor: '#BDBDBD', color: '#FFFFFF' },
                                            Aberto: { backgroundColor: '#27AE60', color: '#FFFFFF' },
                                            Finalizado: { backgroundColor: '#3E4F74', color: '#FFFFFF' },
                                            Pago: { backgroundColor: '#27AE60', color: '#FFFFFF' },
                                            Vencer: { backgroundColor: '#999999', color: '#FFFFFF' },
                                            Vencida: { backgroundColor: '#EB5757', color: '#FFFFFF' },
                                            Cancelado: { backgroundColor: '#EB5757', color: '#FFFFFF' },
                                            Recusado: { backgroundColor: '#BBBABA', color: '#FFFFFF' },
                                            Aprovado: { backgroundColor: '#27AE60', color: '#FFFFFF' },
                                            Sem_Averbacao: { backgroundColor: '#EA5353', color: '#FFFFFF' },
                                            Quitado: { backgroundColor: '#27AE60', color: '#FFFFFF' },
                                            Pendente: { backgroundColor: '#F3945D', color: '#FFFFFF' },
                                            Reprovado: { backgroundColor: '#EA5353', color: '#FFFFFF' },
                                            Baixo: { backgroundColor: '#27AE60', color: '#FFFFFF' },
                                            Medio: { backgroundColor: '#F3945D', color: '#FFFFFF' },
                                            Alto: { backgroundColor: '#EB5757', color: '#FFFFFF' },

                                        }
                                        return (
                                            <td key={index} className="tag" style={campo.tdStyle}>
                                                <span style={arrTags[selectTag]}>{val}</span>
                                            </td>
                                        )
                                    } else if (campo.dado === "acoes") {
                                        if (item[campo.dado]) {
                                            const splitValue = item[campo.dado].split('|');
                                            return (
                                                <td key={index} className="acoes" style={campo.tdStyle}>
                                                    {splitValue[0] === "default" ? (
                                                        <img src={iconEditar} alt="Ícone editar" style={{ marginRight: 18, cursor: 'pointer' }}
                                                            onClick={() => acaoEditar(item['id'])} />
                                                    ) : null}
                                                    {splitValue[1] === "extrato" ? (
                                                        <img src={iconContratos} alt="Ícone extrato" style={{ marginRight: 18, cursor: 'pointer' }}
                                                            onClick={() => acaoExtrato(item['id'])} />
                                                    ) : null}
                                                    {splitValue[0] === "visualizar" ? (
                                                        <img src={iconVisualizar} alt="Ícone visualizar" style={{ cursor: 'pointer' }}
                                                            onClick={() => acaoVisualizar(item['id'])} />
                                                    ) : null}
                                                    {splitValue[1] === "excluir" ? (
                                                        <img src={iconLixeira} alt="Ícone exclusão" style={{ cursor: 'pointer' }}
                                                            onClick={() => acaoExcluir(item['id'], item['nomeExcluir'])} />
                                                    ) : null}
                                                    {splitValue[1] === "regras" ? (
                                                        <img src={iconLista} alt="Ícone Regras" style={{ cursor: 'pointer' }}
                                                            onClick={() => acaoRegras(item['id'])} />
                                                    ) : null}
                                                    {splitValue[0] === "aprova/reprova" ? (<>
                                                        <button style={{ marginRight: 10 }} title="Aprovar"
                                                            onClick={() => acaoAprovar(item['id'])}>
                                                            <img src={iconAprovado} alt="Icone de aprovado" />
                                                        </button>
                                                        <button title="Reprovar" onClick={() => acaoReprovar(item['id'])}>
                                                            <img src={iconReprovado} alt="Icone de reprovado" />
                                                        </button>
                                                    </>) : null}
                                                    {item[campo.dado] === "dadosBoleto" ? (
                                                        <div style={{ flexDirection: 'row', alignItems: 'center',width: '145px' }}>
                                                            <button onClick={() => acaoVisualizar(item['id'])}
                                                                style={{
                                                                    backgroundColor: '#3E4F74', color: '#FFF', padding: '3px 8px', borderRadius: 4,
                                                                    fontWeight: 'bold', fontSize: 13, marginRight: 10
                                                                }}>Dados do Boleto</button>
                                                            <img src={iconVisualizar} alt="Ícone visualizar" style={{ cursor: 'pointer' }}
                                                                onClick={() => acaoEditar(item['id'])} />
                                                        </div>) : null}
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td className="acoes" style={campo.tdStyle}></td>
                                            )
                                        }
                                    } else if (campo.dado === "acao") {
                                        if (item[campo.dado]) {
                                            const splitValue = item[campo.dado].split('|');
                                            return (
                                                <td className="acoes" style={campo.tdStyle}>
                                                    {splitValue[0] === "aprova" ?
                                                        <button style={{ marginRight: splitValue[1] ? 20 : 0 }} title="Aprovar" onClick={() => acaoVisualizar(1, item['id'])}>
                                                            <img src={iconAprovado} alt="Icone de aprovado" />
                                                        </button>
                                                    : null}
                                                    {splitValue[1] === "reprova" ?
                                                        <button title="Reprovar" onClick={() => acaoVisualizar(2, item['id'])}>
                                                            <img src={iconReprovado} alt="Icone de reprovado" />
                                                        </button>
                                                    : null}
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td className="acoes" style={campo.tdStyle}></td>
                                            )
                                        }
                                    }else if(campo.dado === "select"){
                                        return (
                                            <td  style={campo.tdStyle}>
                                                <select defaultValue = {item[campo.dado].selected}>
                                                    {item[campo.dado].options.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value}>{item.text}</option>
                                                        );             
                                                    })}
                                                </select> 
                                            </td>
                                        )
                                    }else {
                                        if(campo.tdTagStyle){
                                            let tdStyle = { backgroundColor: '#27AE60', color: '#FFFFFF' };
                                            let stringReplace ="";

                                            if(item[campo.dado].includes("error")){
                                                tdStyle = { backgroundColor: '#EA5353', color: '#FFFFFF', width: 75 }
                                                stringReplace="error"
                                            }
                                            if(item[campo.dado].includes("warning")){
                                                tdStyle = { backgroundColor: '#F3945D', color: '#FFFFFF' }
                                                stringReplace="warning"
                                            }
                                            
                                            if(item[campo.dado].includes("inactive")){
                                                tdStyle = { backgroundColor: '#BDBDBD', color: '#FFFFFF' }
                                                stringReplace="inactive"
                                            }

                                            if(item[campo.dado].includes("info-dark")){
                                                tdStyle = { backgroundColor: '#3E4F74', color: '#FFFFFF' }
                                                stringReplace="info-dark"
                                            }

                                            if(item[campo.dado].includes("verdeEscuro")){
                                                tdStyle = { backgroundColor: '#166336', color: '#FFFFFF' }
                                                stringReplace="verdeEscuro"
                                            }
                                            return (
                                                <td key={index} className="tag" >
                                                    <span style={tdStyle}>{item[campo.dado].replace(stringReplace, "")}</span>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td key={index} style={campo.tdStyle}>{item[campo.dado]}</td>
                                        )
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

}