import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { cepMask, cnpjMask, cpfMask, telefoneMask, onlyNumbersMask, moneyMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

import { Link } from 'react-router-dom';

export default class NewCompany extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            politica: null,
            politicaOptions: [{value: '', text: 'Selecione'}],
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Ativo' },
                { value: 2, text: 'Inativo' },
                { value: 3, text: 'Bloqueado' }
            ],
            aprovacaoOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Sim' },
                { value: 0, text: 'Não' }
            ],
            rzSocial: '',
            nomeFantasia: '',
            cnpj: '',
            telefone: '',
            emailContato: '',
            emailFinanceiro: '',
            status: '',
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            cidId: '',
            uf: '',
            minParcelas: '',
            maxParcelas: '',
            vlMinProp: '',
            minSal: '',
            maxSal: '',
            minTx: '',
            maxTx: '',
            diaPagamento: '',
            tac: '',
            limComp: '',
            validadePropa: '',
            diaFechaFolha: '',
            habilitaAprovacao: 1,
            aprovacaoInterna: true,
            carregouEmpresa: false
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.pegaEmpresaEditavel();
        }else{
            this.setState({ carregouEmpresa: true});
        }
        this.carregaPoliticas();
    }

    async carregaPoliticas(value)
    {

        let url = 'politica/select';
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data;
                var politicaOptions = [{ value: null, text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    politicaOptions[i + 1] = { value: item.pol_id, text: item.pol_nome };
                }
                this.setState({ politicaOptions: politicaOptions});
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });            })
    }

    async pegaEmpresaEditavel() {
        const { empresa } = this.props.location.state;
        this.setState({ loading: true });
        const userData = JSON.parse(localStorage.getItem('userData'));

        await ApiService.getGeral('empresa/' + empresa.id)
            .then(result => {
                const res = result.data.data;

                this.setState({
                    rzSocial: res.emp_razao, nomeFantasia: res.emp_nomefantasia, cnpj: res.emp_cnpj, telefone: res.emp_telefone,
                    emailContato: res.emp_emailcontato, emailFinanceiro: res.emp_emailfinanceiro, status: res.emp_status, cep: res.emp_endcep,
                    logradouro: res.emp_endlogradouro, numero: res.emp_endnumero, complemento: res.emp_endcomplemento, cidId: res.cid_id,
                    uf: res.cidade.estado.uf_sigla, cidade: res.cidade.cid_nome, bairro: res.emp_endbairro, minParcelas: res.emp_prazominimo,
                    maxParcelas: res.emp_prazomaximo, minSal: res.emp_qtdsalariominimo, maxSal: res.emp_qtdsalariomaximo, diaPagamento: res.emp_diapagamento,
                    limComp: res.emp_limitecomprometimento, validadePropa: res.emp_validadeproposta, 
                    diaFechaFolha: res.emp_diafechafolha, habilitaAprovacao: res.emp_habilitaaprovacao, tac: Funcoes.float2Preco(res.emp_tac),
                    vlMinProp: Funcoes.float2Preco(res.emp_valorminimoproposta), minTx: Funcoes.float2Preco(res.emp_taxaminimo),
                    maxTx: Funcoes.float2Preco(res.emp_taxamaximo), politica: res.pol_id, aprovacaoInterna: res.emp_aprovacao_interna,carregouEmpresa: true ,loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleChange(event, attr, type) {
        if (type === "cnpj") {
            this.setState({ [attr]: cnpjMask(event.target.value) });
        } 
        else if(type === "cpf"){
            this.setState({[attr]: cpfMask(event.target.value)})
        }
        else if (type === "cep") {
            this.setState({ [attr]: cepMask(event.target.value) });
            if (event.target.value.length === 9) {
                this.setState({ loading: true });
                await ApiService.customRequest('geral/consultacep', 'POST', { cep: cepMask(event.target.value) })
                    .then(result => {
                        const res = result.data.data;
                        this.setState({
                            logradouro: res.logradouro, complemento: res.complemento, bairro: res.bairro, cidade: res.localidade,
                            cidId: res.cidade, uf: res.uf, loading: false
                        });
                        document.getElementsByClassName('numFocus')[0].focus()
                    })
                    .catch(err => this.setState({ loading: false }))
            }
        } else if (type === "telefone") {
            this.setState({ [attr]: telefoneMask(event.target.value) });
        } else if (type === "onlyNumbers") {
            this.setState({ [attr]: onlyNumbersMask(event.target.value) });
        } else {
            this.setState({ [attr]: event.target.value });
        }
    }

    validaCampos() {
        const { rzSocial, nomeFantasia, cnpj, telefone, emailContato, emailFinanceiro, status, cep, logradouro, numero, complemento, bairro, cidade, cidId,
            uf, minParcelas, maxParcelas, minSal, maxSal, diaPagamento, limComp, validadePropa,aprovacaoInterna, diaFechaFolha, habilitaAprovacao,
            minTx, maxTx, vlMinProp,tac, politica } = this.state;
        let form = [
            { campo: 'Razão Social', attr: 'rzSocial', valor: rzSocial },
            { campo: 'Nome Fantasia', attr: 'nomeFantasia', valor: nomeFantasia },
            { campo: 'CNPJ', attr: 'cnpj', valor: cnpj, minLength: 14, maxLength: 18 },
            { campo: 'Telefone', attr: 'telefone', valor: telefone, minLength: 14 },
            { campo: 'E-mail Contato', attr: 'emailContato', valor: emailContato, minLength: 8, tipo: "email" },
            { campo: 'E-mail Financeiro', attr: 'emailFinanceiro', valor: emailFinanceiro, minLength: 8, tipo: "email" },
            { campo: 'CEP', attr: 'cep', valor: cep, minLength: 9 },
            { campo: 'Rua, Av..', attr: 'logradouro', valor: logradouro, minLength: 5 },
            { campo: 'Número', attr: 'numero', valor: numero },
            { campo: 'Política', attr: 'politica', valor: politica },
            { campo: 'Bairro', attr: 'bairro', valor: bairro, minLength: 4 },
            { campo: 'Cidade', attr: 'cidade', valor: cidade, minLength: 4 }
        ];

        const valida = validaForm(form);
        if (valida.valido && status && uf) {
            this.setState({ loading: true });
            const formData = {
                emp_nomefantasia: nomeFantasia,
                emp_razao: rzSocial,
                emp_cnpj: cnpj,
                emp_endlogradouro: logradouro,
                emp_endnumero: numero,
                emp_endbairro: bairro,
                emp_endcomplemento: complemento,
                emp_endcep: cep,
                emp_telefone: telefone,
                emp_emailcontato: emailContato,
                emp_emailfinanceiro: emailFinanceiro,
                emp_prazominimo: minParcelas,
                emp_prazomaximo: maxParcelas,
                emp_taxaminimo: Funcoes.preco2float(minTx),
                emp_taxamaximo: Funcoes.preco2float(maxTx),
                emp_diapagamento: diaPagamento,
                emp_qtdsalariominimo: minSal,
                emp_qtdsalariomaximo: maxSal,
                emp_limitecomprometimento: limComp,
                emp_diafechafolha: diaFechaFolha,
                emp_validadeproposta: validadePropa,
                emp_tac: Funcoes.preco2float(tac),
                pol_id: politica,
                emp_status: status,
                cid_id: cidId,
                emp_valorminimoproposta: Funcoes.preco2float(vlMinProp),
                emp_habilitaaprovacao: habilitaAprovacao,
                emp_aprovacao_interna: aprovacaoInterna
            }
            const userData = JSON.parse(localStorage.getItem('userData'));
            if (this.props.location.state) {
                this.editarConta(formData);
            } else {
                this.novaConta(formData);
            }
        }

        if (!status)
            this.setState({ alertText: '* O campo Status não foi preenchido', alert: true });

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaConta(form) {
        await ApiService.postGeral('empresa', form)
            .then(res => {
                this.setState({ path: '/empresas', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async editarConta(form) {
        const { empresa } = this.props.location.state;
        await ApiService.customRequest('geral/empresa/' + empresa.id, 'PUT', form)
            .then(res => {
                this.pegaEmpresaEditavel();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    preencher() {
        this.setState({
            rzSocial: 'Empresa Rz Social', nomeFantasia: 'Empresa', cnpj: '11.111.111/1111-11', telefone: '(11) 1111-1111',
            emailContato: 'contato@empresa.com', emailFinanceiro: 'financeiro@empresa.com', status: 1, cep: '18682-050',
            logradouro: 'R. Coronel Anselmo Martins', numero: '1730', complemento: '', cidade: 'Lençóis Paulista', cidId: 3570, bairro: 'Centro',
            uf: 'SP', minParcelas: 1, maxParcelas: 10, minSal: 2, maxSal: 4, minTx: 2.50, maxTx: 3, tac: 5, limComp: 10.50, validadePropa: '20',
            diaFechaFolha: '28', loading: false
        });
    }

    render() {
        const { loading, redirect, path, alert, alertText, statusOptions, aprovacaoOptions, rzSocial, nomeFantasia, cnpj, telefone, emailContato, emailFinanceiro, status,
            cep, logradouro, numero, complemento, bairro, cidade, uf, minParcelas, maxParcelas, aprovacaoInterna, minTx, maxTx, tac, vlMinProp, minSal, maxSal, 
            diaPagamento, limComp, validadePropa, diaFechaFolha, politica, politicaOptions, habilitaAprovacao, carregouEmpresa } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-nova mgDefault">
                        <h1>{this.props.location.state ? 'Editar' : 'Nova'} Empresa</h1>
                        <p className="descricao">Parametrize abaixo a sua plataforma.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Cadastrais</h3>
                                {/* <button onClick={() => this.preencher()}>Preencher</button> */}
                            </div>
                            <div className="panelBody">
                                <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Razão Social" value={rzSocial} divStyle={{ flexGrow: 10 }}
                                        onChange={e => this.handleChange(e, 'rzSocial')} />
                                    <Input type="text" label="Nome Fantasia" value={nomeFantasia} divStyle={{ flexGrow: 10 }}
                                        onChange={e => this.handleChange(e, 'nomeFantasia')} />
                                    <Input type="tel" label="CNPJ ou CPF" value={cnpj} style={{ marginRight: 0 }} maxLength="18"
                                        onChange={ e => e.target.value.length > 14 ? this.handleChange(e, 'cnpj', 'cnpj') : this.handleChange(e, 'cnpj', 'cpf') } />
                                </div>
                                <div className="multInput">
                                    <Input type="tel" label="Telefone" value={telefone} maxLength="14"
                                        onChange={e => this.handleChange(e, 'telefone', 'telefone')} />
                                    <Input type="text" label="E-mail Contato" value={emailContato} divStyle={{ flexGrow: 10 }}
                                        onChange={e => this.handleChange(e, 'emailContato')} />
                                    <Input type="text" label="E-mail Financeiro" value={emailFinanceiro} divStyle={{ flexGrow: 10 }}
                                        onChange={e => this.handleChange(e, 'emailFinanceiro')} />
                                    <Input type="select" label="Status" optionsData={statusOptions} style={{ marginRight: 0 }}
                                        value={status} onChange={e => this.handleChange(e, 'status')} />
                                    {/*<Input type="select" label="Habilita Aprovação" optionsData={aprovacaoOptions} style={{ marginRight: 0 }}
                                        value={habilitaAprovacao} onChange={e => this.handleChange(e, 'habilitaAprovacao')} />*/}
                                </div>
                                {
                                        carregouEmpresa ?
                                        (
                                            <Fragment>
                                               <div className="multInput">
                                                    <Input type="checkbox" defaultChecked={aprovacaoInterna}  className="checkBoxtable" style={{marginBottom: 0}} label="Aprovação interna de propostas" value={aprovacaoInterna}  onChange={e => this.setState({aprovacaoInterna: e.target.checked})} />
                                                </div> 
                                            </Fragment>
                                        ) : null
                                }
                                
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados de Endereço</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput marginInputs">
                                    <Input type="tel" label="CEP" value={cep} maxLength="9" onChange={e => this.handleChange(e, "cep", "cep")} />
                                    <Input type="text" label="Rua, Av.." value={logradouro} divStyle={{ flexGrow: 10 }}
                                        onChange={e => this.handleChange(e, 'logradouro')} />
                                    <Input type="tel" label="Número" value={numero} maxLength="5"
                                        onChange={e => this.handleChange(e, 'numero', 'onlyNumbers')} style={{ marginRight: 0 }} className="numFocus" />
                                </div>
                                <div className="multInput">
                                    <Input type="text" label="Complemento" value={complemento} onChange={e => this.handleChange(e, 'complemento')} />
                                    <Input type="text" label="Bairro" value={bairro} onChange={e => this.handleChange(e, 'bairro')} />
                                    <Input type="text" label="Cidade" value={cidade} onChange={e => this.handleChange(e, 'cidade')} />
                                    <Input type="text" label="UF" value={uf} onChange={e => this.handleChange(e, 'uf')} maxLength="2" disabled={true}
                                        className="disabled" style={{ marginRight: 0 }} divStyle={{ maxWidth: 100 }} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Configuração</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="select" optionsData={politicaOptions} label="Política" value={politica} 
                                            onChange={e => this.setState({politica: e.target.value})} />  
                                </div>
                                <div className="multInput">
                                    <Input type="tel" label="Valor Mínimo proposta" className="vlMinProp" value={vlMinProp}  onChange={e => this.setState({vlMinProp: moneyMask(e.target.value, false)} )} placeholder="0,0" />
                                    <Input type="tel" label="Mínimo de Parcelas" maxLength="2" value={minParcelas}
                                        onChange={e => this.handleChange(e, 'minParcelas', 'onlyNumbers')} />
                                    <Input type="tel" label="Máximo de Parcelas" maxLength="2" value={maxParcelas}
                                        onChange={e => this.handleChange(e, 'maxParcelas', 'onlyNumbers')} />
                                    <Input type="tel" label="Mínimo de Salários" maxLength="2" value={minSal} style={{ marginRight: 0 }} 
                                        onChange={e => this.handleChange(e, 'minSal', 'onlyNumbers')} />
                                </div>
                                <div className="multInput">
                                    <Input type="tel" label="Máximo de Salários" maxLength="2" value={maxSal}
                                        onChange={e => this.handleChange(e, 'maxSal', 'onlyNumbers')} />
                                    <Input type="tel" label="Taxa Mínima (a.m)" className="txMin" value={minTx}  onChange={e => this.setState({minTx: moneyMask(e.target.value, false)} )} placeholder="0,0" maxLength="5" />
                                    <Input type="tel" label="Taxa Máxima (a.m)" className="txMax" value={maxTx}  onChange={e => this.setState({maxTx: moneyMask(e.target.value, false)} )} placeholder="0,0" maxLength="5" />
                                    <Input type="tel" label="Dia Pagamento (em dias)" maxLength="2" value={diaPagamento} style={{ marginRight: 0 }}
                                        onChange={e => this.handleChange(e, 'diaPagamento', 'onlyNumbers')}  />
                                </div>
                                <div className="multInput">
                                    <Input type="tel" label="TAC (%)" className="tac" value={tac}  onChange={e => this.setState({tac: moneyMask(e.target.value, false)} )} maxLength="5" />
                                    <Input type="tel" label="Limite Comp. (%)" value={limComp} maxLength="3"
                                        onChange={e => this.handleChange(e, 'limComp')} />
                                    <Input type="tel" label="Validade Proposta" value={validadePropa} maxLength="5"
                                        onChange={e => this.handleChange(e, 'validadePropa', 'onlyNumbers')} />
                                    <Input type="tel" label="Dia Fecha Folha (em dias)" maxLength="2" value={diaFechaFolha}
                                        onChange={e => this.handleChange(e, 'diaFechaFolha', 'onlyNumbers')} style={{ marginRight: 0 }} />
                                </div>
                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/empresas" className="btn btnVoltar">{'<'} Voltar</Link>
                            <button className="btn btnCadastrar" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
    
}