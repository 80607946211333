import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Summary from '../../Components/Summary/Summary';
import Panel from '../../Components/Panel';
import Row from '../../Components/Row';
import Button from '../../Components/Button';
import PageBottomActions from '../../Components/PageBottomActions';

import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';
import {  moneyMask } from '../../Utils/mask';
import Funcoes from '../../Utils/functions';

//import { Link } from 'react-router-dom';

export default class NewScalingRule extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            propsToPath: null,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            nomeRegua: '',
            intervalos: [],
            politicaId: undefined,
            idReguaEscalonamento: undefined
        };
    }

    componentDidMount() 
    {
        this.setState({politicaId: this.props.location.state.politica.id})
        if (this.props.location.state.reguaEscalonamentoDeParcela) {
            let idReguaEscalonamento = this.props.location.state.reguaEscalonamentoDeParcela;
            this.setState({idReguaEscalonamento: idReguaEscalonamento, loading: true})
            this.carregaReguasDeEscalonamento(idReguaEscalonamento);
        }
    }

    async carregaReguasDeEscalonamento(id)
    {
        let url = 'regua_escalonamento_parcela/' + id;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                let intervalos = res.intervalo_de_escalonamento.map( (item) => {
                    // let maskedItem = {
                    //     iep_intervaloate: moneyMask(item.iep_intervaloate, true),
                    //     iep_intervalode: moneyMask(item.iep_intervalode, true)
                    // }
                    return {
                        ...item, 
                        iep_intervaloate: "R$ " + Funcoes.float2Preco(item.iep_intervaloate), 
                        iep_intervalode: "R$ " + Funcoes.float2Preco(item.iep_intervalode)
                    };
                })
                this.setState({ 
                    nomeRegua: res.reep_descricao,
                    intervalos: intervalos,
                    loading: false});
            })
            .catch(err => {
               
            })
    }

    async atualizaReguaEscalonamento(form){
        const { idReguaEscalonamento } = this.state;
        await ApiService.putGeral('regua_escalonamento_parcela/' + idReguaEscalonamento, form)
        .then(res => {
            this.setState({ loading: false,  alertText: 'Régua de escalonamento atualizado com sucesso!', alert: true , 
            alertType: 1});
        })
        .catch(err => {
            const valida = ApiService.handleErros(err.response);
            if (valida.status === "unauthorized") {
                sessionStorage.removeItem('userData');
                this.setState({ path: '/' });
            }
            if (valida.status === "serverError") {
                this.setState({ path: null });
            }

            if (valida.status === "badRequest") {
                this.setState({ path: null });
            }
            this.setState({ loading: false, alertText: valida.response, alert: true });
        })
    }

    validaCampos() {
        const { idReguaEscalonamento, nomeRegua, intervalos } = this.state;
        let form = [
            { campo: 'Nome', attr: 'nomeRegua', valor: nomeRegua },
            { campo: 'Intervalo', attr: 'intervalos', valor: intervalos, minLength: 1 }

        ];

        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            let intervaloFloat = intervalos.map(intervalo => {
                return {
                    ...intervalo,
                    iep_intervaloate: Funcoes.preco2float(intervalo.iep_intervaloate,"R$"),
                    iep_intervalode: Funcoes.preco2float(intervalo.iep_intervalode,"R$")
                };
            });
            const formData = {
                regua: {
                    reep_descricao: nomeRegua,
                },
                intervalos: intervaloFloat
            }
           if(idReguaEscalonamento){
            this.atualizaReguaEscalonamento(formData);
           }else
           {
            this.novaRegua(formData);
           }
        }

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async removerIntervalo(index){
        const { intervalos } = this.state;
        intervalos.splice(index, 1);
        this.setState({intervalos: intervalos});
    }

    async novoIntervalo(){
        const { idReguaEscalonamento, intervalos } = this.state;
       
        if(idReguaEscalonamento){
            intervalos.push({reep_id: idReguaEscalonamento, iep_intervalode: "R$ 0,00", iep_intervaloate: "R$ 0,00", iep_prazo: 0});
        }else{
            intervalos.push({iep_intervalode: "R$ 0,00", iep_intervaloate: "R$ 0,00", iep_prazo: 0});
        }
        this.setState({intervalos: intervalos});
    }

    async novaRegua(form) {
        await ApiService.postGeral('regua_escalonamento_parcela', form)
            .then(res => {
                this.setState({ idReguaEscalonamento: res.data.data.reep_id,loading: false,  alertText: 'Régua de escalonamento cadastrada com sucesso!', alert: true , 
                alertType: 1});
                this.carregaReguasDeEscalonamento(res.data.data.reep_id);

            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleValor(e, index, campo){
        const { intervalos } = this.state;
        var newValores = [];
        for(var i = 0; i < intervalos.length; i++){
            newValores[i] = intervalos[i];
            if(i === index){
                if(campo === "iep_intervalode"){
                    newValores[i].iep_intervalode = moneyMask(e.target.value, true);
                }else if(campo === "iep_intervaloate"){
                    newValores[i].iep_intervaloate = moneyMask(e.target.value, true);
                }else if(campo === "iep_prazo"){
                    newValores[i].iep_prazo = e.target.value;
                }
            }
        }
        this.setState({ intervalos: newValores });
    }
    render() {
        const { loading, propsToPath, redirect, path, alert, alertText, alertType, nomeRegua, 
            intervalos, idReguaEscalonamento } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} tipo={alertType} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-nova mgDefault">
                        <Summary title={(idReguaEscalonamento ? 'Editar' : 'Nova') + " régua de escalonamento" } 
                            description="Parametrize a régua de escalonamento."
                        />
                        
                        <Panel header="Dados Cadastrais">
                            <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Nome" value={nomeRegua} divStyle={{ flexGrow: 10 }}
                                        onChange={e => this.setState({nomeRegua: e.target.value})} />
                            </div>
                        </Panel>
                        <Panel header="Intervalos">
                            {intervalos.map((campo,index) => {
                                  
                                  return (<>
                                        <div key={index} className="multInput marginInputs" style={{ marginTop: 24 }}>
                                            <Row>
                                                <Input type="tel" isFilterGroups={true} placeholder="0,00" label="Valor solicitado de" className="tx" id={index+'-inicial'} value={campo.iep_intervalode} onChange={e => this.handleValor(e, index, 'iep_intervalode')} />
                                                <Input type="tel" isFilterGroups={true} label="Valor solicitado até" className="tx" id={index+'-final'}  value={campo.iep_intervaloate} onChange={e => this.handleValor(e, index, 'iep_intervaloate')}  placeholder="0,00" />
                                                <Input type="number" isFilterGroups={true} label="Máximo de Parcelas" maxLength="2" value={campo.iep_prazo} onChange={e => this.handleValor(e, index, 'iep_prazo')} style={{ marginRight: 0 }} placeholder="0" />
                                                <Button name="Excluir" isFilterGroups={true} action={() => this.removerIntervalo(index)} />
                                            </Row>

                                        </div>
                                  </>)
                            })}
                            <Button name="Novo Intervalo" action={() => this.novoIntervalo()} />
                        </Panel>

                        <PageBottomActions>
                            <Button type="back" name={'< Voltar'} action={() => this.setState({ path: '/politicas/novo', propsToPath: { politica: { id: this.state.politicaId } }, redirect: true })} />
                            <Button name={idReguaEscalonamento ? 'Alterar' : 'Cadastrar'} action={() => this.validaCampos()} />
                        </PageBottomActions>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
    
}