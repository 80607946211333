import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { cepMask, cnpjMask, telefoneMask, onlyNumbersMask, moneyMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import imgErro from '../../resources/img/icon-erro.png';

import { Link } from 'react-router-dom';
import $ from "jquery";

export default class NewCompany extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            alertType: 2,
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Ativo' },
                { value: 2, text: 'Inativo' }
            ],
            ratingOptions:[
                { value: '', text: 'Selecione'},
                { value: 'A', text: 'Rating A'},
                { value: 'B', text: 'Rating B'},
                { value: 'C', text: 'Rating C'},
                { value: 'D', text: 'Rating D'},
                { value: 'E', text: 'Rating E'},
                { value: 'F', text: 'Rating F'}
            ],
            rating: '',
            taxa: '',
            minParcelas: '',
            minSalario: '',
            maxSalario: '',
            ativo: '',
            valores: [],
            empresa: ''
        };
    }

    componentDidMount() {
        const params = this.props.location.state;
        this.setState({empresa: params.empresa});
        if (params.regra !== '') {
            this.pegaRegraEditavel();
        }
    }

    async pegaRegraEditavel() {
        const params = this.props.location.state;
        this.setState({ loading: true });
        const userData = JSON.parse(localStorage.getItem('userData'));

        await ApiService.getGeral('empresaregra/' + params.regra)
            .then(result => {
                const res = result.data.data;
                this.setState({
                    rating: res.ereg_rating, taxa: Funcoes.float2Preco(res.ereg_taxa), minParcelas: res.ereg_minparcelas, minSalario: res.ereg_minsalarios, maxSalario: res.ereg_maxsalarios, ativo: res.ereg_ativo,
                    valores: res.valores, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleChange(event, attr, type) {
        if (type === "onlyNumbers") {
            this.setState({ [attr]: onlyNumbersMask(event.target.value) });
        } else {
            this.setState({ [attr]: event.target.value });
        }
    }

    validaCampos() {
        const { rating, minParcelas, minSalario, maxSalario, ativo, valores, empresa, taxa } = this.state;
        let form = [
            { campo: 'Faixa de Rating', attr: 'rating', valor: rating },
            { campo: 'Taxa', attr: 'taxa', valor: taxa },
            { campo: 'Mínimo de parcelas', attr: 'minParcelas', valor: minParcelas },
            { campo: 'Máximo de salários', attr: 'maxSalario', valor: maxSalario }
        ];

        const valida = validaForm(form);
        if (valida.valido) {
            // Valida os valores
            if(valores.length < 1){
                this.setState({alert: true, alertText: 'É necessário informar pelo menos 1 valor para a regra!'});
                return;
            }else{
                for(var i = 0; i < valores.length; i++){
                    if(valores[i].eregv_valorinicial <= 0){
                        this.setState({alert: true, alertText: 'Informe um valor válido para o valor inicial!'});
                        return;
                    }else if(valores[i].eregv_parcelas <= 0){
                        this.setState({alert: true, alertText: 'Informe um valor válido para o valor das parcelas!'});
                        return;
                    }
                }
            }
            this.setState({ loading: true });

            const formData = {
                emp_id: empresa,
                ereg_rating: rating,
                ereg_taxa: Funcoes.preco2float(taxa) / 100,
                ereg_minparcelas: minParcelas,
                ereg_minsalarios: minSalario,
                ereg_maxsalarios: maxSalario,
                ereg_ativo: ativo,
                valores: valores
            }
            const userData = JSON.parse(localStorage.getItem('userData'));
            const params = this.props.location.state;
            if (params.regra !== '') {
                this.editarRegra(formData);
            } else {
                this.novaRegra(formData);
            }
        }

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaRegra(form) {
        await ApiService.postGeral('empresaregra', form)
            .then(res => {
                const params = this.props.location.state;
                this.setState({ path: '/empresas/regras', propsToPath: { id: params.empresa }, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async editarRegra(form) {
        const params = this.props.location.state;
        await ApiService.customRequest('geral/empresaregra/' + params.regra, 'PUT', form)
            .then(res => {
                this.pegaRegraEditavel();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleValor(e, index, campo){
        const { valores } = this.state;
        var newValores = [];
        for(var i = 0; i < valores.length; i++){
            newValores[i] = valores[i];
            if(i === index){
                if(campo == "parcelas"){
                    newValores[i].eregv_parcelas = e.target.value;
                }else if(campo == "valorInicial"){
                    newValores[i].eregv_valorinicial = parseFloat($('#'+e).priceToFloat() / 100);
                }else if(campo == "valorFinal"){
                    newValores[i].eregv_valorfinal = parseFloat($('#'+e).priceToFloat() / 100);
                }
            }
        }
        console.log(newValores);
        this.setState({ valores: newValores });
    }

    async removerValor(index){
        const { valores } = this.state;
        valores.splice(index, 1);
        this.setState({valores: valores});
    }

    async novoValor(){
        const { valores } = this.state;
        valores.push({ereg_id: "", eregv_valorinicial: '', eregv_valorfinal: '', eregv_parcelas: ''});
        this.setState({valores: valores});
    }

    voltar() {
        const params = this.props.location.state;
        this.setState({ path: '/empresas/regras', propsToPath: { id: params.empresa }, redirect: true });
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, alertType, statusOptions, ratingOptions, rating, taxa, minParcelas, minSalario, maxSalario, ativo, valores } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-nova mgDefault">
                        <h1>{this.props.location.state ? 'Editar' : 'Nova'} Regra de Empréstimo</h1>
                        <p className="descricao">Parametrize abaixo os dados da regra de negócio.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Cadastrais</h3>
                                {/* <button onClick={() => this.preencher()}>Preencher</button> */}
                            </div>
                            <div className="panelBody">
                                <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                    <Input type="select" label="Faixa de Rating" optionsData={ratingOptions}
                                        value={rating} onChange={e => this.handleChange(e, 'rating')} style={{ marginRight:27 }} />
                                    <Input type="tel" label="Taxa (%)" className="tx taxa" value={taxa} onChange={e => this.setState({taxa: moneyMask(e.target.value, false)} )} placeholder="0,0" maxLength="5" />
                                    <Input type="tel" label="Mínimo de Parcelas" maxLength="2" value={minParcelas}
                                        onChange={e => this.handleChange(e, 'minParcelas', 'onlyNumbers')} style={{ marginRight: 0}} />
                                </div>
                                <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                    <Input type="tel" label="Mínimo de Salários" maxLength="2" value={minSalario}
                                        onChange={e => this.handleChange(e, 'minSalario', 'onlyNumbers')} />
                                    <Input type="tel" label="Máximo de Salários" maxLength="2" value={maxSalario}
                                        onChange={e => this.handleChange(e, 'maxSalario', 'onlyNumbers')} />
                                    <Input type="select" label="Status" optionsData={statusOptions}
                                        value={ativo} onChange={e => this.handleChange(e, 'ativo')} style={{ marginRight: 0}} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Valores</h3>
                            </div>
                            <div className="panelBody">
                                {valores.map((campo,index) => {
                                    var valInicial = Funcoes.float2Preco(campo.eregv_valorinicial);
                                    var valFinal = Funcoes.float2Preco(campo.eregv_valorfinal);
                                   
                                    return (<>
                                        <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                            <Input type="tel" label="Valor Inicial" className="tx" id={index+'-inicial'} onChange={e => this.handleValor(index+'-inicial', index, 'valorInicial')} defaultValue={valInicial} placeholder="0,00" />
                                            <Input type="tel" label="Valor Final" className="tx" id={index+'-final'} onChange={e => this.handleValor(index+'-final', index, 'valorFinal')} defaultValue={valFinal} placeholder="0,00" />
                                            <Input type="tel" label="Máximo Parcelas" maxLength="2" value={campo.eregv_parcelas}
                                                onChange={e => this.handleValor(e, index, 'parcelas')} style={{ marginRight: 0 }} />
                                            <button title="Remover" onClick={() => this.removerValor(index)}>
                                                <img src={imgErro} alt="Icone de reprovado" style={{ width: 30 }} />
                                            </button>
                                        </div>
                                    </>)
                                })}
                                <button className="btn btnNovo" onClick={() => this.novoValor()}>Novo Valor</button>
                            </div>
                        </div>

                        <div className="acoes">
                            <button className="btn btnVoltar" style={{paddingLeft: 20, paddingRight: 20}} onClick={() => this.voltar()}>{'<'} Voltar</button>
                            <button className="btn btnCadastrar" onClick={() => this.validaCampos()}>{this.props.location.state.regra !== '' ? 'Alterar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}