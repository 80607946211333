import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Paginacao from '../../Components/Paginacao';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { cpfMask, dataBrMask } from '../../Utils/mask';

export default class Contract extends Component {

    constructor() {
        super();

        this.acaoVisualizar = this.acaoVisualizar.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            tipo_colaborador: 'nome',
            empresasOptions: [{ value: '', text: 'Selecione' }],
            gruposEconomicosOptions: [{ value: '', text: 'Selecione' }],
            table: {
                campos: [
                    { titulo: 'Nº Contrato', dado: 'numContrato', thStyle: { minWidth: 120 } },
                    { titulo: 'Tipo Contrato', dado: 'tipoContrato', thStyle: { minWidth: 180 } },
                    { titulo: 'Cliente/Colaborador', dado: 'cliColab', thStyle: { minWidth: 200 } },
                    { titulo: 'Empresa', dado: 'empresa', thStyle: { minWidth: 200 } },
                    { titulo: 'Valor Emprestado', dado: 'valEmprestado', thStyle: { textAlign: 'right', minWidth: 200 }, tdStyle: { textAlign: 'right' } },
                    { titulo: 'Parc. Paga', dado: 'parcela', thStyle: { minWidth: 150, textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Data Solicitação', dado: 'data', thStyle: { textAlign: 'center', minWidth: 200 }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Status', dado: 'tag', thStyle: { minWidth:75 } },
                    { titulo: 'Ações', dado: 'acoes', thStyle: { textAlign: 'center', minWidth: 100 }, tdStyle: { textAlign: 'center' } }
                ],
                dados: []
            },
            statusOptions: [
                {value: '', text: "Selecione"},
                {value: 0, text: "Pendente RH"},
                {value: 1, text: "Aprovado RH/Aguardando assinatura"},
                {value: 2, text: "Assinado"},
                {value: 3, text: "Aguardando liberação dinheiro"},
                {value: 4, text: "Pago"},
                {value: 5, text: "Finalizado"},
                {value: 6, text: "Negado RH"}
            ],
            tiposOptions: [
                {value: '', text: "Selecione"},
                {value: 1, text: "Empréstimo"},
                {value: 2, text: "Refinanciamento"}
            ],
            propostasDefault: {},
            exibeDados: false,
            mostraVazio: false,
            numContrato: '',
            cliente: '',
            empresa: '',
            grupoEconomico: '',
            proposta_status: '',
            proposta_tipo: '',
            data_inicio: '',
            data_fim: '',
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        this.setState({ acoes });

        this.carregaEmpresas();
        this.carregaGrupos();
        this.carregaPropostas(1, acoes);
    }

    async carregaEmpresas() {
        this.setState({ loading: true });
        await ApiService.getGeral('empresa?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions });
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaPropostas(page, acao) {
        const { table, numContrato, cliente, empresa, grupoEconomico, proposta_status, proposta_tipo, data_inicio, data_fim, acoes, tipo_colaborador } = this.state;
        this.setState({ loading: true });

        var url, pagina, pagAtual, actions;
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;
        actions = acao ? acao : acoes;

        if (pagAtual) {
            url = "contratos?per_page=20&page=" + pagAtual;
        } else {
            url = "contratos?per_page=20";
        }
        if (numContrato) url = url + "&ppst_numeroccb=" + numContrato;
        if (cliente) url = url + (tipo_colaborador === 'nome' ? "&func_nome=" : "&func_cpf=") + cliente;
        if (empresa) url = url + "&emp_id=" + empresa;
        if (grupoEconomico) url = url + "&gpec_id=" + grupoEconomico;
        if (data_inicio) url = url + "&data_inicio=" + Funcoes.data2Americano(data_inicio, '/');
        if (data_fim) url = url + "&data_fim=" + Funcoes.data2Americano(data_fim, '/');
        if (proposta_status) url = url + "&ppst_status=" + proposta_status;
        if (proposta_tipo) url = url + "&ppst_tipo=" + proposta_tipo;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.ppst_id, 
                        numContrato: item.ppst_numeroccb ? item.ppst_numeroccb : '-', cliColab: item.funcionario.func_nome.toUpperCase(),
                        tipoContrato: item.ppst_tipo === 1 ? 'Empréstimo' : 'Refinanciamento',
                        empresa: item.funcionario.empresa.emp_nomefantasia.toUpperCase(),
                        valEmprestado: 'R$ ' + Funcoes.float2Preco(item.ppst_valorsolicitado),
                        parcela: Funcoes.completarNumero(item.ppst_parcelaspagas) + ' de ' + Funcoes.completarNumero(item.ppst_prazo), 
                        data: item.ppst_dthrsolicitacao ? Funcoes.data2Brazilian(item.ppst_dthrsolicitacao.split(' ')[0]) : '',
                        tag: item.ppst_status === 5 ? 'Finalizado' : item.ppst_status === 6 ? 'Recusado' : item.ppst_status === 7 ? 'Cancelado' : 'Aberto',
                        acoes: actions.indexOf(7) !== -1 ? 'visualizar' : ''
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, propostasDefault: result.data });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoVisualizar(id) {
        const { acoes } = this.state;
        this.setState({ path: '/contratos/detalhes', propsToPath: { contrato: { id }, acoes }, redirect: true });
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/contratos?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/contratos', redirect: true });
        }
        this.carregaPropostas(page);
    }

    async exportarContratos(){
        const { numContrato, cliente, empresa, grupoEconomico, data_inicio,data_fim, proposta_status, proposta_tipo, tipo_colaborador } = this.state;
        this.setState({ loading: true });

        var url = '/geral/contratos?export=true';

        if (numContrato) url = url + "&ppst_numeroccb=" + numContrato;
        if (cliente) url = url + (tipo_colaborador === 'nome' ? "&func_nome=" : "&func_cpf=") + cliente;
        if (empresa) url = url + "&emp_id=" + empresa;
        if (grupoEconomico) url = url + "&gpec_id=" + grupoEconomico;
        if (data_inicio) url = url + "&data_inicio=" + Funcoes.data2Americano(data_inicio, '/');
        if (data_fim) url = url + "&data_fim=" + Funcoes.data2Americano(data_fim, '/');
        if (proposta_status) url = url + "&ppst_status=" + proposta_status;
        if (proposta_tipo) url = url + "&ppst_tipo=" + proposta_tipo;

        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Contratos.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    async carregaGrupos() {
        this.setState({ loading: true });
        await ApiService.getGeral('grupoeconomico?per_page=-1')
            .then(result => {
                const res = result.data.data;
                var gruposEconomicosOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    gruposEconomicosOptions[i + 1] = { value: item.gpec_id, text: item.gpec_nome };
                }
                this.setState({ gruposEconomicosOptions });
            })
            .catch(err => {
                this.setState({ gruposEconomicosOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }

    async carregaEmpresasPorGrupo(grupo){
        this.setState({ loading: true});
        if(grupo) this.setState({ grupoEconomico: grupo });
        let url = 'empresa?per_page=-1';
        if(grupo) url+= "&gpec_id=" + grupo;
        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var empresasOptions = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    empresasOptions[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresasOptions, loading: false});
            })
            .catch(err => {
                this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa encontrada!' }] });
            })
    }
    
    async recarregaEmpresaSelect(grupo){
        this.carregaEmpresasPorGrupo(grupo);
    }

    handleFiltro(e) {
        const val = e.target.value;
        if (val.startsWith(0) || val.startsWith(1) || val.startsWith(2) || val.startsWith(3) || val.startsWith(4) || val.startsWith(5) || val.startsWith(6) || val.startsWith(7) ||
            val.startsWith(8) || val.startsWith(9)) {
            document.getElementById('filtro').setAttribute('maxlength', 14);
            this.setState({ cliente: cpfMask(val), tipo_colaborador: 'cpf' });
        } else {
            document.getElementById('filtro').removeAttribute('maxLength');
            this.setState({ cliente: val, tipo_colaborador: 'nome' });
        }
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, exibeDados, gruposEconomicosOptions ,empresasOptions, statusOptions, tiposOptions, 
            numContrato, cliente, empresa, grupoEconomico, proposta_status, proposta_tipo,data_inicio, data_fim, propostasDefault } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={5} />

                <div className="body">
                    <Header />

                    <div className="contratos mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Contratos</h1>
                                <p className="descricao">Consulte abaixo todos os contratos assinado na plataforma.</p>
                            </div>
                            <button className="btn btnAzul" onClick={() => this.exportarContratos()}>Exportar (.xlsx)</button>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="tel"  isFilterGroups={true} label="Nº Contrato" value={numContrato} 
                                        onChange={e => this.setState({ numContrato: e.target.value })} />
                                    <Input type="select"  isFilterGroups={true} optionsData={tiposOptions} label="Tipo" value={proposta_tipo} 
                                        onChange={e => this.setState({ proposta_tipo: e.target.value })} />    
                                    <Input type="text"  isFilterGroups={true} label="Colaborador ou CPF" value={cliente} id="filtro"
                                        onChange={e => this.handleFiltro(e)} />  
                                    <Input type="select" isFilterGroups={true} optionsData={gruposEconomicosOptions} label="Grupo Econômico" value={grupoEconomico} 
                                        onChange={e => this.recarregaEmpresaSelect(e.target.value)} />
                                    <Input type="select" isFilterGroups={true} optionsData={empresasOptions} label="Empresa" value={empresa} 
                                        onChange={e => this.setState({ empresa: e.target.value })} />
                                    <Input type="select"  isFilterGroups={true} optionsData={statusOptions} label="Status" value={proposta_status} 
                                        onChange={e => this.setState({ proposta_status: e.target.value })} />
                                    <Input type="tel" isFilterGroups={true} label="Dt. Início" value={data_inicio}
                                        onChange={e => this.setState({ data_inicio: dataBrMask(e.target.value)} )} />
                                    <Input type="tel" isFilterGroups={true} label="Dt. Fim"  value={data_fim}
                                        onChange={e => this.setState( {data_fim: dataBrMask(e.target.value)})} />   
                                    <button className="btn filtrar" onClick={() => this.carregaPropostas(1)}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoVisualizar={this.acaoVisualizar} />
                                ) : null}
                            </div>
                            {exibeDados ? (
                                <div className="info">
                                    <p>Total de registros: {propostasDefault.total}</p>
                                    <Paginacao dados={propostasDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}