import {
    ADD_CONCILIACAO,
    REMOVE_CONCILIACAO,
    DELETE_ALL_CONCILIACAO,
    RETRIEVE_ALL_CONCILIACAO
} from "./types";


import ConciliationDataService from "../Services/ConciliationDataService";


export const addConciliacao = (obj) => async (dispatch) => {
  try {
    
    const res = await ConciliationDataService.retrieve(obj);
    dispatch({
      type: ADD_CONCILIACAO,
      payload: res,
    });
    return Promise.resolve( res);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const removeConciliacao = (obj) => async (dispatch) => {
  try {
    const res = await ConciliationDataService.retrieve(obj);
    dispatch({
      type: REMOVE_CONCILIACAO,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteAllConciliacaos = () => async (dispatch) => {
  try {

    dispatch({
      type: DELETE_ALL_CONCILIACAO,
      payload: {  },
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveAllConciliacao = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_ALL_CONCILIACAO,
      payload: {  },
    });
  } catch (err) {
    console.log(err);
  }
};




