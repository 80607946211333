import {
    ADD_EMPRESTIMO,
    REMOVE_EMPRESTIMO,
    DELETE_ALL_EMPRESTIMO,
    RETRIEVE_ALL_EMPRESTIMO
} from "../Actions/types";

const initialState = [];

function disconnectReducer(emprestimos = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_EMPRESTIMO:
      if(emprestimos.find(x => x.id === payload.id)){
        return emprestimos;
      }
      return [...emprestimos, payload];

    case REMOVE_EMPRESTIMO:
      return emprestimos.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_EMPRESTIMO:
      return [];
      
    case RETRIEVE_ALL_EMPRESTIMO:
      return emprestimos;
      
    default:
      return emprestimos;  
  }
};

export default disconnectReducer;