import {
    ADD_CONCILIACAO,
    REMOVE_CONCILIACAO,
    DELETE_ALL_CONCILIACAO,
    RETRIEVE_ALL_CONCILIACAO
} from "../Actions/types";

const initialState = [];

function conciliationReducer(conciliacoes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_CONCILIACAO:
      if(conciliacoes.find(x => x.id === payload.id)){
        return conciliacoes;
      }
      return [...conciliacoes, payload];

    case REMOVE_CONCILIACAO:
      return conciliacoes.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_CONCILIACAO:
      return [];
      
    case RETRIEVE_ALL_CONCILIACAO:
      return conciliacoes;
      
    default:
      return conciliacoes;  
  }
};

export default conciliationReducer;