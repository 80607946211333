import React, { Component, Fragment } from 'react';

import iconCopiar from '../../resources/img/icon-copiar.svg';
import iconCheck from '../../resources/img/icon-sucesso.svg';
import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import Paginacao from '../../Components/Paginacao';

export default class Bill extends Component {

    constructor() {
        super();

        this.acaoVisualizar = this.acaoVisualizar.bind(this);
        this.acaoEditar = this.acaoEditar.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            empresasOptions: [{ value: '', text: 'Selecione' }],
            mesesOptions: [{ value: '', text: 'Selecione' }],
            empresa: '',
            periodo: '',
            boletoid: '',
            table: {
                campos: [
                    { titulo: 'Nº Fatura', dado: 'numFatura' },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Código de Boleto', dado: 'boletoid' },
                    { titulo: 'Contratos', dado: 'contratos' },
                    { titulo: 'Vencimento', dado: 'vencimento', thStyle: { width: 100 } },
                    { titulo: 'Total da Fatura', dado: 'totalFatura', thStyle: { textAlign: 'right', width: 200 }, tdStyle: { textAlign: 'right' } },
                    { titulo: '', dado: 'tag', tdStyle: { textAlign: 'center' } },
                    { titulo: 'Ações', dado: 'acoes', thStyle: { textAlign: 'center', width: 132 }, tdStyle: { textAlign: 'center' } }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            faturasDefault: {},
            dadosBoleto: {},
            modalBoleto: false,
            acoes: []
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const url = window.location.pathname;
        const acoes = Funcoes.getAcoesPage(userData.menus, url);

        var x, meses = [{ value: '', text: 'Selecione' }];
        for (var x = 0; x < 7; x++) {
            const date = new Date();
            const item = new Date(date.setMonth(date.getMonth() + x)).toJSON().split('T')[0];
            const separete = item.split('-');
            meses[x + 1] = { value: separete[0] + '-' + separete[1], text: separete[1] + '/' + separete[0] };
        }
        this.setState({ acoes, mesesOptions: meses });
        this.carregaEmpresas();
        this.carregaFaturas(1);
    }

    async carregaEmpresas() {
        await ApiService.getGeral('empresa')
            .then(result => {
                const res = result.data.data;
                var objects = [{ value: '', text: 'Selecione' }];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ value: item.emp_id, text: item.emp_nomefantasia })
                }
                this.setState({ empresasOptions: objects });
            })
            .catch(err => this.setState({ empresasOptions: [{ value: '', text: 'Nenhuma empresa disponível' }] }))
    }

    async carregaFaturas(page) {
        const { empresa, periodo, boletoid, table } = this.state;
        this.setState({ loading: true });

        var url = 'faturas?per_page=20';
        if(page)
        {
            url+= '&page=' + page
        }
        if (empresa) url = url + '&emp_id=' + empresa;
        if (periodo) url = url + '&periodo=' + periodo;
        if(boletoid) url = url + '&fat_boletoid=' + boletoid;

        await ApiService.getGeral(url)
            .then(result => {
                const res = result.data.data;
                var objects = [], totalFat = 0;
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({
                        id: item.fat_id, 
                        numFatura: item.fat_id,
                        boletoid: item.fat_boletoid, 
                        empresa: item.empresa.emp_nomefantasia, 
                        contratos: item.contratos,
                        vencimento: Funcoes.data2Brazilian(item.fat_dtvencto), totalFatura: 'R$ ' + Funcoes.float2Preco(item.fat_valor),
                        tag: item.fat_pago === 1 ? 'Pago' : 'À Vencer', acoes: 'dadosBoleto'
                    });
                    totalFat += item.fat_valor
                }
                if (res.length > 0) {
                    objects.push({
                        id: 'last', numFatura: '', empresa: '', vencimento: 'Total:', totalFatura: 'R$ ' + Funcoes.float2Preco(totalFat), tag: ''
                    });
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, loading: false, faturasDefault: result.data });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async exportarFaturas() {
        this.setState({ loading: true });
        const { empresa, periodo } = this.state;
        this.setState({ loading: true });

        var url = '/geral/faturas?export=true';
        if (empresa) url = url + '&emp_id=' + empresa;
        if (periodo) url = url + '&periodo=' + periodo;

        var urlBase = ApiService.InManoApiUrl();
        const token = ApiService.getUserToken();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", urlBase + url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'faturas.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    async acaoVisualizar(id) {
        this.setState({ loading: true });
        await ApiService.getGeral('faturas/boleto/' + id)
            .then(result => result.data)
            .then(res => this.setState({ dadosBoleto: res.data, modalBoleto: true, loading: false }))
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async acaoEditar(id) {
        this.setState({ loading: true });
        await ApiService.getGeral('faturas/' + id)
            .then(res => {
                this.setState({ path: '/faturas/detalhes', propsToPath: res.data, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/faturas?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/faturas', redirect: true });
        }
        this.carregaFaturas(page);
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, exibeDados, mostraVazio, faturasDefault, empresasOptions, mesesOptions,
            empresa, periodo, dadosBoleto, boletoid, modalBoleto, acoes } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Modal show={modalBoleto}>
                    <div className="">
                        <div>
                            <button type="button" className="close"  onClick={() => this.setState({ modalBoleto: false })}>X</button>
                        </div>
                        <img src={iconCheck} alt="Ícone de aviso" />
                        <p style={{ fontSize: 14, marginBottom: 5 }}>Linha Digitável</p>
                        <div className="boletoGerado">
                            <Input value={dadosBoleto.linha_digitavel} className="custom" id="linhaDigitavel" style={{ fontSize: 16 }}
                                readOnly={true} onClick={() => document.getElementById("linhaDigitavel").select()} />
                            <button onClick={() => {
                                const input = document.getElementById("linhaDigitavel");
                                input.select();
                                input.setSelectionRange(0, 99999)
                                document.execCommand("copy");
                            }}>
                                <img src={iconCopiar} alt="Copiar linha digitável" title="Copiar linha digitável"></img>
                            </button>
                        </div>
                        <button className="btn visualizaBoleto" onClick={() => window.open(dadosBoleto.url_boleto, '_blank')}>Visualizar boleto</button>
                    </div>
                </Modal>
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={7} />

                <div className="body">
                    <Header />

                    <div className="faturas mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Faturas</h1>
                                <p className="descricao">Consulte abaixo todos os contratos assinado na plataforma.</p>
                            </div>
                            {acoes.indexOf(15) !== -1 ?
                                <button className="btn btnAzul" onClick={() => this.exportarFaturas()}>Exportar (.xlsx)</button>
                                : null}
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <div className="row">
                                    <Input type="select" isFilterGroups={true} optionsData={empresasOptions} label="Empresa" 
                                        value={empresa} onChange={e => this.setState({ empresa: e.target.value })} />
                                    <Input type="text" isFilterGroups={true} label="Código do boleto" value={boletoid} id="filtro"
                                        onChange={e => this.setState({ boletoid: e.target.value })} /> 
                                    <Input type="select" isFilterGroups={true} optionsData={mesesOptions} label="Mês"
                                        value={periodo} onChange={e => this.setState({ periodo: e.target.value })} />
                                    <button className="btn filtrar" onClick={() => this.carregaFaturas()}>Filtrar</button>
                                </div>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoVisualizar={this.acaoVisualizar}
                                        acaoEditar={this.acaoEditar} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p className="textEmpty">Nenhuma fatura pendente!</p>
                                ) : null}
                            </div>
                             {exibeDados ? (<>
                                <div className="info">
                                    <Paginacao dados={faturasDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}